<template>
  <div class="px-4 sm:px-6 lg:px-8">
    <div class="sm:flex sm:items-center">
      <div class="sm:flex-auto">
        <BaseTitle text="Playbook templates" />
        <p class="mt-2 text-sm text-gray-700">
          These are the template playbooks that are added to new clients when a
          user signs up.
        </p>
      </div>
    </div>

    <div v-if="playbookTemplates.length > 0" class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                >
                  Name of Playbook
                </th>
                <th
                  scope="col"
                  class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                >
                  Source playbook
                </th>

                <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
              <tr
                v-for="(
                  playbookTemplate, playbookTemplateIndex
                ) in playbookTemplates"
                :key="playbookTemplateIndex"
              >
                <td
                  class="flex items-center whitespace-nowrap py-4 pl-4 text-sm font-medium text-gray-900 sm:pl-0"
                >
                  {{ playbookTemplate.name }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  <span class="text-xs text-blue-500">
                    {{ clientName(playbookTemplate.clientId) }} -
                    {{
                      playbookName(
                        playbookTemplate.clientId,
                        playbookTemplate.playbookId
                      )
                    }}
                  </span>
                  {{
                    numberOfEntries(
                      playbookTemplate.clientId,
                      playbookTemplate.playbookId
                    )
                  }}
                </td>

                <td
                  class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0"
                >
                  <BaseButton
                    @action="deletePlaybookTemplate(playbookTemplateIndex)"
                    buttonStyle="link"
                  >
                    Remove
                  </BaseButton>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <form class="mt-4 space-y-4">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Add new playbook template
      </h3>
      <BaseSelect
        label="Client"
        v-model="clientId"
        :options="clientOptions"
        placeholder="Select a client"
      />

      <BaseSelect
        :disabled="!clientId"
        label="Playbook"
        v-model="playbookId"
        :options="playbookOptions"
        placeholder="Select a playbook"
      />

      <BaseInput
        v-model="name"
        label="Name"
        class="max-w-lg"
        placeholder="Give a name for this new playbook"
      />

      <BaseButton @action="createPlaybookTemplate">
        Add playbook template
      </BaseButton>
    </form>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { PlaybookTemplate } from "@/types";
import ApiService from "@/services/ApiService";
import BaseTitle from "@/components/BaseTitle.vue";
import { useAdmin } from "@/components/shared/Admin";

const { clients } = useAdmin();

const playbookTemplates = ref<PlaybookTemplate[]>([]);

onMounted(async () => {
  const response = await ApiService.getPlaybookTemplates();
  playbookTemplates.value = response.data.playbookTemplates;
});

const clientId = ref<number | null>(null);
const playbookId = ref<number | null>(null);
const name = ref("");

const client = computed(() => {
  return clients.value.find((client) => client.id === clientId.value);
});

const clientName = (clientId: number) => {
  const client = clients.value.find((client) => client.id === clientId);
  return client ? client.name : "";
};

const playbookName = (clientId: number, playbookId: number) => {
  const client = clients.value.find((client) => client.id === clientId);
  if (!client) {
    return "";
  }

  const playbook = client.playbooks.find(
    (playbook) => playbook.id === playbookId
  );
  return playbook ? playbook.name : "";
};

const clientOptions = computed(() => {
  return clients.value.map((client) => ({
    value: client.id,
    name: client.name ?? "[Blank name]"
  }));
});

const numberOfEntries = (clientId: number, playbookId: number) => {
  const client = clients.value.find((client) => client.id === clientId);
  if (!client) {
    return "";
  }

  const playbook = client.playbooks.find(
    (playbook) => playbook.id === playbookId
  );
  return playbook ? `(${playbook.numberOfEntries} entries)` : "";
};

const playbookOptions = computed(() => {
  if (!client.value) {
    return [];
  }

  return client.value.playbooks.map((playbook) => ({
    value: playbook.id,
    name: playbook.name ?? "[Blank name]"
  }));
});

const deletePlaybookTemplate = async (playbookTemplateIndex: number) => {
  const playbookTemplateToDelete =
    playbookTemplates.value[playbookTemplateIndex];
  if (playbookTemplateToDelete === null) return;
  await ApiService.destroyPlaybookTemplate(playbookTemplateToDelete);

  playbookTemplates.value.splice(playbookTemplateIndex, 1);
};

const createPlaybookTemplate = async () => {
  if (playbookId.value === null) return;
  const response = await ApiService.createPlaybookTemplate({
    name: name.value,
    playbookId: playbookId.value,
    position: playbookTemplates.value.length
  });
  playbookTemplates.value.push(response.data.playbookTemplate);
  name.value = "";
  clientId.value = null;
  playbookId.value = null;
};
</script>
