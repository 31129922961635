import { defineStore } from "pinia";
import { Invitation, User } from "@/types";

import ApiService from "@/services/ApiService";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    invitations: [] as Invitation[],
    loadedTeamMembers: false,
    teamMembers: [] as User[],
    users: [] as User[]
  }),

  getters: {
    activeTeamMembers(): User[] {
      return this.teamMembers.filter(
        (user) => user.status === "active" && user.name.length > 0
      );
    },

    notSuspended(): User[] {
      return this.teamMembers.filter((user) => user.status !== "suspended");
    }
  },

  actions: {
    createTemporaryPassword(
      invitation: Invitation,
      temporaryPassword: string
    ): Promise<void> {
      return new Promise((resolve) => {
        ApiService.createAccountFromInvitation(
          temporaryPassword,
          invitation.verifyToken,
          true
        ).then(() => {
          this.invitations = this.invitations.filter(
            (inv) => inv.id !== invitation.id
          );
          this.loadUsers();
          resolve();
        });
      });
    },

    deleteInvitation(invitation: Invitation): Promise<void> {
      return new Promise((resolve) => {
        ApiService.destroyInvitation(invitation).then(() => {
          this.invitations = this.invitations.filter(
            (inv) => inv.id !== invitation.id
          );
          resolve();
        });
      });
    },

    loadInvitations(): Promise<void> {
      this.invitations = [];
      return new Promise((resolve) => {
        ApiService.getInvitations().then(({ data }) => {
          this.invitations = data.invitations;
          resolve();
        });
      });
    },

    loadTeamMembers(): Promise<void> {
      return new Promise((resolve) => {
        if (this.loadedTeamMembers) {
          resolve();
        } else {
          ApiService.getTeamMembers().then(({ data }) => {
            this.teamMembers = data.users;
            this.loadedTeamMembers = true;
            resolve();
          });
        }
      });
    },

    loadUsers(): Promise<void> {
      return new Promise((resolve) => {
        ApiService.getUsers().then(({ data }) => {
          this.users = data.users;
          resolve();
        });
      });
    }
  }
});
