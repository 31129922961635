<template>
  <div class="py-2">
    <div class="prose prose-sm">
      <div
        @click="select"
        class="cursor-pointer text-sm leading-6 hover:text-lexoo text-left text-gray-800"
        v-html="heading"
      ></div>
      <div
        v-if="isTruncated"
        class="text-red-600 hover:underline text-xs cursor-pointer"
        @click="showMore = true"
      >
        Show more +
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { Issue } from "@/types";

const emit = defineEmits(["select"]);
const props = defineProps({
  issue: {
    type: Object as PropType<Issue>,
    required: true
  }
});

const showMore = ref(false);

const truncateLength = 200;

const heading = computed(() => {
  if (showMore.value || props.issue.description.length <= truncateLength) {
    return props.issue.description;
  }
  // First 100 characters of the issue description
  return props.issue.description.slice(0, truncateLength) + "...";
});

const isTruncated = computed(() => {
  return props.issue.description.length > truncateLength && !showMore.value;
});

const select = () => {
  emit("select");
};
</script>

<style scoped>
.prose :deep(h1) {
  @apply text-sm mt-2;
}

.prose :deep(h2) {
  @apply text-sm mt-2;
}

.prose :deep(h3) {
  @apply text-sm mt-2;
}
</style>
