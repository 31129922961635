<template>
  <div class="relative">
    <PlaybookHeader v-model="currentTab" />
    <PlaybookSidebar
      v-if="currentTab === 'playbookInstance'"
      :loadFromWordDocumentIfPossible="loadFromWordDocumentIfPossible"
      :jumpToEntryId="jumpToEntryId"
    />
    <PeekSidebar v-if="currentTab === 'peek'" />
  </div>
  <ReloadPlaybookPrompt v-if="playbook" :playbook="playbook" />
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";

import PeekSidebar from "@/views/sidebar/PeekSidebar.vue";
import PlaybookHeader from "@/components/sidebar/PlaybookHeader.vue";
import PlaybookSidebar from "@/views/sidebar/PlaybookSidebar.vue";
import ReloadPlaybookPrompt from "@/components/ReloadPlaybookPrompt.vue";
import { useSidebarStore } from "@/store/SidebarStore";

const sidebarStore = useSidebarStore();

const playbook = computed(() => sidebarStore.playbook);

const currentTab = ref("playbookInstance") as Ref<
  "playbookInstance" | "peek" | "issues"
>;

defineProps({
  jumpToEntryId: {
    type: String,
    required: false
  },

  loadFromWordDocumentIfPossible: {
    type: Boolean,
    default: true
  },

  playbookId: {
    type: String,
    required: false
  }
});
</script>
