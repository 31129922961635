<template>
  <p>Logging in. Please wait...</p>
</template>

<script setup lang="ts">
/* global Office */
import { onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";

import AuthService from "@/services/AuthService";
import { getObjectFromHash } from "@/helpers/links";
import { useAlertStore } from "@/store/AlertStore";
import { useSidebarStore } from "@/store/SidebarStore";

const sidebarStore = useSidebarStore();

const loginSuccessful = (code: string, state: string) => {
  const authService = new AuthService();
  const redirect_uri = window.location.origin + "/auth/okta/callback";
  authService
    .passOktaOAuthToServer({ code, state, redirect_uri })
    .then(() => {
      if (sidebarStore.withinWord) {
        Office.onReady(() => {
          if (Office.context.ui) {
            Office.context.ui.messageParent("true");
          } else {
            window.location.replace(authService.startingPath());
          }
        });
      } else {
        window.location.replace(authService.startingPath());
      }
    })
    .catch(({ response }) => {
      const message = response.data.data.error;
      loginUnsuccessful(message);
    });
};

const router = useRouter();
const loginUnsuccessful = (message?: string) => {
  let errorMessage = message || "";
  const alertStore = useAlertStore();
  if (!errorMessage) {
    errorMessage =
      new URLSearchParams(window.location.search).get("error_description") ??
      "There was an error logging in";
  }
  errorMessage =
    errorMessage + "<br><br>Please contact your Okta administrator.";

  alertStore.addAlert({
    message: errorMessage,
    status: "caution",
    title: "Login unsuccessful"
  });
  if (sidebarStore.withinWord) {
    Office.onReady(() => {
      if (Office.context.ui) {
        Office.context.ui.messageParent("false");
      } else {
        router.push("/login");
      }
    });
  } else {
    router.push("/login");
  }
};

const getCode = () => {
  const route = useRoute();
  if (route.query && route.query.code) {
    return route.query.code;
  }

  const params = getObjectFromHash(route.hash);
  return params["code"];
};

const getState = () => {
  const route = useRoute();
  if (route.query && route.query.state) {
    return route.query.state;
  }

  const params = getObjectFromHash(route.hash);
  return params["state"];
};

onMounted(() => {
  const code = getCode();
  if (code) {
    const state = getState();
    loginSuccessful(code, state);
  } else {
    loginUnsuccessful();
  }
});
</script>
