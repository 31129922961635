<template>
  <div v-if="search.length > 2">
    <LoadingPanel v-if="loading" />
    <ul v-else-if="entries.length > 0" class="divide-y divide-gray-100">
      <li
        v-for="entry in entries"
        :key="entry.id"
        @click.prevent="jumpToEntry(entry)"
        class="group relative flex cursor-pointer items-center space-x-4 py-4 px-2 text-xs text-gray-500 hover:bg-gray-50"
      >
        <div v-html="entryHeading(entry)" class="min-w-0 flex-auto"></div>
        <ChevronRightIcon
          class="h-5 w-5 flex-none text-gray-400 group-hover:text-lexoo"
          aria-hidden="true"
        />
      </li>
    </ul>
    <div v-else class="text-xs text-gray-500">
      No entries found search term {{ search }}.
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref, watch } from "vue";
import { useRouter } from "vue-router";
import { debounce } from "throttle-debounce";
import { ChevronRightIcon } from "@heroicons/vue/20/solid";

import { Entry } from "@/types";
import ApiService from "@/services/ApiService";
import LoadingPanel from "@/components/LoadingPanel.vue";
import { useSidebarStore } from "@/store/SidebarStore";
import PlaybookInstanceCreator from "@/services/PlaybookInstanceCreator";

const props = defineProps({
  search: {
    type: String,
    default: ""
  }
});

const loadedClients = ref(false);

const sidebarStore = useSidebarStore();

const clients = computed(() => {
  return sidebarStore.clients;
});

const clientForEntry = (entry: Entry) => {
  if (clients.value) {
    return clients.value.find((client) => client.id === entry.clientId) ?? null;
  }
  return null;
};

const playbookForEntry = (entry: Entry) => {
  const client = clientForEntry(entry);
  if (client) {
    return client.playbooks.find(
      (playbook) => playbook.id === entry.playbookId
    );
  }
};

const entries = ref([]) as Ref<Entry[]>;
const loading = ref(true);

const debouncedSearch = debounce(1000, () => {
  if (!loadedClients.value) {
    // only need to ensure the clients are loaded once
    sidebarStore.loadClients();
    loadedClients.value = true;
  }
  ApiService.searchEntries(props.search, null, null).then(({ data }) => {
    entries.value = data.entries;
    loading.value = false;
  });
});

const entryHeading = (entry: Entry) => {
  let heading = entry.heading;
  const playbook = playbookForEntry(entry);
  if (playbook) {
    const playbookNameElement = `<span class='text-blue-500'>${playbook.name}</span>`;
    heading = `${playbookNameElement} ${heading}`;
  }

  return heading;
};

const router = useRouter();
const jumpToEntry = (entry: Entry) => {
  const playbookId = entry.playbookId;
  const entryId = entry.id;
  if (playbookId !== sidebarStore.playbookId) {
    sidebarStore.playbookId = playbookId;
    sidebarStore.clearPlaybookInstance();
    sidebarStore.entries = [];
    const creator = new PlaybookInstanceCreator(playbookId, entryId);
    creator.create();
  }

  router.push(`/sidebar/playbooks/${playbookId}/entries/${entryId}`);
};

const searchEntries = () => {
  if (props.search.length < 3) {
    entries.value = [];
    return;
  }
  loading.value = true;
  debouncedSearch();
};

watch(
  () => props.search,
  () => {
    searchEntries();
  }
);
</script>
