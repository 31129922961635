<template>
  <BaseTitle class="flex">
    <UsersIcon class="mr-2 h-6 w-6 text-lexoo" aria-hidden="true" />
    Invite team
  </BaseTitle>
  <template v-if="usesOtka">
    <p class="my-4 text-sm text-gray-500">
      Team management is handled with Otka by your IT administrator. Please
      contact them to invite new users.
    </p>
  </template>
  <template v-else>
    <p class="my-4 text-sm text-gray-500">
      As an editor on this account, you can invite other users to join your
      account.
    </p>

    <BaseButton @action="showInviteUserModal = true" size="small" class="mt-4">
      <svg
        class="mx-auto mr-2 h-6 w-6"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 48 48"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M34 40h10v-4a6 6 0 00-10.712-3.714M34 40H14m20 0v-4a9.971 9.971 0 00-.712-3.714M14 40H4v-4a6 6 0 0110.713-3.714M14 40v-4c0-1.313.253-2.566.713-3.714m0 0A10.003 10.003 0 0124 26c4.21 0 7.813 2.602 9.288 6.286M30 14a6 6 0 11-12 0 6 6 0 0112 0zm12 6a4 4 0 11-8 0 4 4 0 018 0zm-28 0a4 4 0 11-8 0 4 4 0 018 0z"
        />
      </svg>
      Invite a{{ userStore.teamMembers.length > 1 ? "nother" : "" }} team member
    </BaseButton>

    <div class="my-6 border-b border-gray-200 pb-5 sm:pb-0">
      <div class="mt-3 sm:mt-4">
        <div class="hidden sm:block">
          <nav class="-mb-px flex space-x-8">
            <a
              href="#"
              @click.prevent="currentTab = 'active'"
              :class="{ selected: currentTab === 'active' }"
              class="tab"
            >
              Active
            </a>

            <a
              href="#"
              @click.prevent="currentTab = 'invitations'"
              :class="{ selected: currentTab === 'invitations' }"
              class="tab"
            >
              Invited
            </a>
          </nav>
        </div>
      </div>
    </div>
    <div
      v-if="teamMembersForCurrentTab.length === 0"
      class="my-8 max-w-lg text-sm"
    >
      None yet. Invite a team member to get started.
    </div>
    <ul v-else role="list" class="divide-y divide-gray-100">
      <li
        v-for="user in teamMembersForCurrentTab"
        :key="user.id"
        class="flex items-center justify-between gap-x-6 py-5"
      >
        <div class="flex min-w-0 gap-x-4">
          <img
            class="h-6 w-6 flex-none rounded-full bg-gray-50"
            :src="user.image"
            alt=""
          />
          <div class="min-w-0 flex-auto">
            <p class="text-sm font-semibold text-gray-900">
              {{ user.name }}
              <RoleTag :user="user" />
            </p>
            <p class="mt-1 truncate text-xs text-gray-500">
              {{ user.email }}
            </p>
            <p
              v-if="user.status == 'invited'"
              class="mt-1 text-xs leading-5 text-gray-500"
            >
              Invited
              <time :datetime="user.createdAt.toString()">{{
                relativeTimeFormat(user.createdAt)
              }}</time>
              (they haven't created their account yet).
              <BaseButton
                @action="resendInvitation(user)"
                size="small"
                buttonStyle="white"
              >
                Resend invitation
              </BaseButton>
            </p>
            <p
              v-else-if="user.lastAccessedAt"
              class="mt-1 text-xs leading-5 text-gray-500"
            >
              Last seen
              <time :datetime="user.lastAccessedAt.toString()">{{
                relativeTimeFormat(user.lastAccessedAt)
              }}</time>
            </p>
          </div>
        </div>
      </li>
    </ul>

    <InviteUserModal
      :show="showInviteUserModal"
      @close="closeModalAndLoadTeamMembers"
      @cancel="showInviteUserModal = false"
    />

    <BaseAlertModal
      v-if="showResendModal && invitedUser"
      title="Invitation sent"
      @close="showResendModal = false"
    >
      <div class="space-y-4 text-sm text-gray-500">
        <p>
          We've sent an invitation to
          <strong>{{ invitedUser.name }}</strong> at
          <strong>{{ invitedUser.email }} </strong>
        </p>
        <p>You will receive notification when they create their account.</p>
      </div>
    </BaseAlertModal>
  </template>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { UsersIcon } from "@heroicons/vue/24/outline";

import { User } from "@/types";
import ApiService from "@/services/ApiService";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useUserStore } from "@/store/UserStore";
import { relativeTimeFormat } from "@/helpers/DateHelpers";
import BaseAlertModal from "@/components/base/BaseAlertModal.vue";
import RoleTag from "@/components/admin/RoleTag.vue";
import InviteUserModal from "@/views/InviteUserModal.vue";

const currentTab = ref("active");

const userStore = useUserStore();
userStore.loadTeamMembers();

const showInviteUserModal = ref(false);
const showResendModal = ref(false);
const invitedUser = ref<User | null>(null);

const closeModalAndLoadTeamMembers = () => {
  userStore.loadedTeamMembers = false;
  userStore.loadTeamMembers();
  showInviteUserModal.value = false;
  showResendModal.value = true;
};

const resendInvitation = async (user: User) => {
  const createdInvitation = await ApiService.createInvitation(user);
  if (createdInvitation) {
    invitedUser.value = user;
    closeModalAndLoadTeamMembers();
  }
};

const teamMembersForCurrentTab = computed(() => {
  if (currentTab.value === "active") {
    return userStore.teamMembers.filter((user) => user.status === "active");
  } else if (currentTab.value === "invitations") {
    return userStore.teamMembers.filter((user) => user.status === "invited");
  }
  return [];
});

const usesOtka = computed(() => {
  const authorisationStore = useAuthorisationStore();
  return authorisationStore.usesOtka;
});
</script>

<style scoped lang="scss">
.tab.selected {
  @apply border-lexoo text-lexoo;
}

.tab {
  @apply whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium;
}

.tab:not(.selected) {
  @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700;
}
</style>
