<template>
  <div class="pt-4">
    <slot></slot>
    <ul class="space-y-7">
      <li v-for="comment in comments" :key="comment" class="hover-trigger">
        <div class="flex space-x-3">
          <div class="flex-shrink-0">
            <img :src="comment.user.image" class="h-5 w-5 rounded-full" />
          </div>
          <div>
            <div class="text-xs font-medium text-gray-900">
              {{ comment.user.name }}
            </div>

            <div class="mt-1 text-xs text-gray-700" v-html="comment.content" />
            <div class="mt-2 space-x-2 text-xs">
              <span class="font-medium text-gray-500">
                {{ comment.relativeTime }}
              </span>
              <span class="hover-target font-medium text-gray-500">
                &middot;
              </span>

              <button
                type="button"
                class="hover-target font-medium text-gray-400"
                @click="deleteComment(comment)"
              >
                Delete
              </button>
              <span class="hover-target font-medium text-gray-500">
                &middot;
              </span>
              <button
                type="button"
                class="hover-target font-medium text-gray-400"
                @click="replyTo(comment)"
              >
                Reply
              </button>
            </div>
          </div>
        </div>
      </li>
    </ul>

    <div class="-mx-3 p-3">
      <div class="flex space-x-3">
        <span v-if="user && user.image" class="flex-shrink-0">
          <img
            class="inline-block h-5 w-5 rounded-full align-middle"
            :src="user.image"
            alt=""
          />
        </span>
        <span class="min-w-0 flex-1">
          <BaseCommentText
            :commenters="commenters"
            v-model:content="commentContent"
            @update:mentioned="mentioned = $event"
            @saveComment="saveComment"
            ref="baseComment"
          />
        </span>
        <span class="flex-shrink-0 text-left">
          <button class="text-lexoo hover:opacity-70" @click="saveComment">
            <PaperAirplaneIcon class="mt-1 h-6 w-6" />
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseCommentText from "@/components/base/comments/BaseCommentText.vue";
import ApiService from "@/services/ApiService";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useUserStore } from "@/store/UserStore";
import { Comment, Entry, NewComment, User } from "@/types";
import { PaperAirplaneIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  setup() {
    const authorisationStore = useAuthorisationStore();
    const userStore = useUserStore();
    return {
      authorisationStore,
      userStore
    };
  },

  components: {
    BaseCommentText,
    PaperAirplaneIcon
  },

  props: {
    entry: {
      type: Object as PropType<Entry>,
      required: true
    }
  },

  data() {
    return {
      comments: [] as Comment[],
      commentContent: "",
      mentioned: [] as User[]
    };
  },

  computed: {
    commenters(): User[] {
      return this.userStore.activeTeamMembers;
    },

    user(): User | null {
      return this.authorisationStore.user;
    }
  },

  watch: {
    entry: {
      immediate: true,
      handler(value) {
        this.comments = value.comments;
      }
    }
  },

  methods: {
    deleteComment(comment: Comment) {
      ApiService.destroyComment(comment).then(() => {
        this.comments = this.comments.filter((el) => el.id !== comment.id);
      });
    },

    replyTo(comment: Comment) {
      const commenter = comment.user;
      const mention = `<span data-type='mention' data-id="${commenter.id}" data-label="${commenter.name}">@${commenter.name}</span>&nbsp;`;
      this.commentContent = mention;
      (this.$refs as { baseComment: HTMLElement }).baseComment.focus();
    },

    saveComment() {
      const comment: NewComment = {
        entryId: this.entry.id,
        content: this.commentContent,
        mentionedIds: this.mentioned.map((m) => m.id)
      };
      ApiService.createComment(comment).then(({ data }) => {
        this.comments.push(data.comment);
        this.commentContent = "";
      });
    }
  }
});
</script>

<style scoped>
.hover-trigger .hover-target {
  visibility: hidden;
}

.hover-trigger:hover .hover-target {
  visibility: visible;
}
</style>
