import { Command, Node, mergeAttributes } from "@tiptap/core";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface TaskListOptions {
  HTMLAttributes: Record<string, any>;
}
/* eslint-enable @typescript-eslint/no-explicit-any */

declare module "@tiptap/core" {
  interface Commands {
    taskList: {
      /**
       * Toggle a task list
       */
      toggleTaskList: () => Command;
    };
  }
}

export const TaskList = Node.create<TaskListOptions>({
  name: "taskList",

  group: "block list",

  content: "taskItem+",

  parseHTML() {
    return [
      {
        tag: 'ul[data-type="taskList"]',
        priority: 51
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "ul",
      mergeAttributes(HTMLAttributes, { "data-type": "taskList" }),
      0
    ];
  },

  addCommands() {
    return {
      toggleTaskList:
        () =>
        ({ commands }) => {
          return commands.toggleList("taskList", "taskItem");
        }
    };
  },

  addOptions() {
    return { HTMLAttributes: {} };
  }
});
