<template>
  <div
    aria-live="assertive"
    class="pointer-events-none fixed inset-0 z-50 flex items-end px-2 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="flex-shrink-0">
                <BookOpenIcon
                  class="h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  Playbook updated
                </p>
                <p class="mt-1 text-sm text-gray-500">
                  This playbook has been updated by another user. Refresh the
                  page to see the latest version.
                </p>
                <div class="mt-3 flex space-x-7">
                  <button
                    @click="refreshPage()"
                    type="button"
                    class="rounded bg-white text-sm font-medium text-lexoo hover:underline focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                  >
                    Refresh page
                  </button>
                  <button
                    @click="show = false"
                    type="button"
                    class="rounded bg-white text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                  >
                    Dismiss
                  </button>
                </div>
              </div>
              <div class="ml-4 flex flex-shrink-0">
                <button
                  type="button"
                  @click="show = false"
                  class="inline-flex rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                >
                  <span class="sr-only">Close</span>
                  <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import { BookOpenIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { Playbook } from "@/types";
import { createCable } from "@/services/ActionCableService";

export default defineComponent({
  setup(props) {
    const cable = createCable();
    const show = ref(false);
    const cableHandlers = {
      connected() {
        console.log("Connected");
      },

      rejected() {
        console.log("Disconnected");
      },

      received(receivedMessage: Record<string, unknown>) {
        console.log("Received message ", receivedMessage);
        if (props.playbook && receivedMessage.updated_at) {
          const authorisationStore = useAuthorisationStore();
          if (
            authorisationStore.actionCableIdentifier &&
            receivedMessage.action_cable_identifier ==
              authorisationStore.actionCableIdentifier
          ) {
            return;
          }

          if (typeof receivedMessage.updated_at == "string") {
            const dateUpdated = new Date(receivedMessage.updated_at);
            if (dateUpdated > props.playbook.updatedAt) {
              console.log("Playbook updated");
              show.value = true;
            }
          }
        }
      },

      disconnected() {
        console.log("Disconnected");
      }
    };
    return { cable, cableHandlers, show };
  },

  props: {
    playbook: {
      type: Object as PropType<Playbook>,
      required: true
    }
  },

  components: {
    BookOpenIcon,
    XMarkIcon
  },

  channels: {
    "ApplicationCable::PlaybookChannel": {
      connected() {
        console.log("Connected");
      },

      rejected() {
        console.log("Disconnected");
      },

      received(receivedMessage: Record<string, unknown>) {
        console.log("Received message ", receivedMessage);
        if (this.playbook && receivedMessage.updated_at) {
          const authorisationStore = useAuthorisationStore();
          if (
            authorisationStore.actionCableIdentifier &&
            receivedMessage.action_cable_identifier ==
              authorisationStore.actionCableIdentifier
          ) {
            return;
          }

          if (typeof receivedMessage.updated_at == "string") {
            const dateUpdated = new Date(receivedMessage.updated_at);
            if (dateUpdated > this.playbook.updatedAt) {
              console.log("Playbook updated");
              this.show = true;
            }
          }
        }
      },

      disconnected() {
        console.log("Disconnected");
      }
    }
  },

  methods: {
    refreshPage() {
      window.location.reload();
    }
  },

  watch: {
    playbook() {
      if (this.playbook && this.playbook.id) {
        this.cable.subscriptions.create(
          {
            channel: `ApplicationCable::PlaybookChannel`,
            room: this.playbook.id
          },
          this.cableHandlers
        );
      }
    }
  }
});
</script>
