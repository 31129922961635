<template>
  <details
    ref="details"
    class="mb-2"
    :open="isOpen"
    @toggle="trackOpenedPlaybookEntry"
  >
    <summary
      @keyup.space.prevent
      class="relative p-2 pl-3 pr-6"
      :class="{
        'bg-green-50 hover:bg-green-100': checkedStatus == 'checked',
        'bg-gray-50 hover:bg-gray-100': checkedStatus != 'checked' && !issue,
        'text-gray-400 line-through': checkedStatus == 'ignored',
        'bg-red-50 hover:bg-red-100':
          checkedStatus != 'checked' && checkedStatus != 'ignored' && issue
      }"
    >
      <HoveringCheckBox
        v-if="playbookInstance && !editMode"
        v-model="checkedStatus"
        targetPosition="left"
        class="absolute right-2 top-2"
      />
      <div v-if="editMode" class="flex select-none justify-between">
        <input
          v-if="editMode"
          type="text"
          v-model="modelHeading"
          class="-my-4 ml-2 w-5/6 border-0 p-1 text-sm font-medium text-gray-500 focus:text-gray-900 focus:ring-0"
          placeholder="Entry heading"
          ref="headingInput"
        />
        <ColourDropdown class="absolute right-2" v-model="modelColour" />
      </div>
      <div v-else class="flex justify-between">
        <span>
          <FlagIcon
            v-if="checkedStatus == 'flagged'"
            class="mr-1 inline h-3 w-3 text-red-500"
          />
          <span
            class="select-none"
            v-html="displayStringWithFilterResult(entryHeading)"
          />
          <SparklesIcon
            v-if="issue"
            class="ml-1 inline-block h-4 w-4 text-red-500"
          />
          <ColourDropdown
            class="absolute top-1"
            :class="withinWord ? 'right-6' : 'right-2'"
            :readonly="true"
            v-model="modelColour"
          />
        </span>
      </div>
    </summary>
    <div class="details-content group">
      <!-- Entry panel -->
      <AiAlertButton
        v-if="
          !editMode &&
          playbook &&
          playbookInstance &&
          entry &&
          authorisationStore.isAdmin
        "
        :playbook="playbook"
        :playbookInstance="playbookInstance"
        :entry="entry"
      />
      <ClausesPanel :clauses="clauses" />
      <div v-if="showIssuesPanel">
        <div class="rounded-sm border bg-gray-50 p-2">
          <h3 class="font-medium text-sm text-lexoo">
            {{ issue ? "Issues found in review" : "No issues found" }}
          </h3>
          <IssueHeading
            v-if="issue"
            :issue="issue"
            class="mb-2"
            @select="goToIssue"
          />
        </div>
      </div>
      <h3 v-if="showIssuesPanel" class="mt-4 font-medium text-sm">
        Playbook positions:
      </h3>
      <BaseSuperText
        :readonly="!editMode"
        v-model="modelDescription"
        :checkedTaskIds="checkedTaskIdsForEntry"
        @taskItemChecked="taskItemChecked"
        :filterText="filterText"
        :showAIButton="authorisationStore.isBeta"
        @generateAI="showAiEntryModal = true"
        :generatingAI="streaming"
        :variableIdentifiers="variableIdentifiers"
      />
      <div v-if="aiError" class="m-2 mt-4 max-w-lg rounded border p-4">
        <h3 class="font-medium text-sm">AI features currently unavailable</h3>
        <p class="mt-2 text-sm text-gray-500">
          Our partner OpenAI is reporting issues on their end. They are
          currently investigating. LexPlay's AI features are currently
          unavailable.
        </p>
      </div>
      <EscalationsSection v-if="escalations" :escalations="escalations" />
      <div class="flex justify-between">
        <div>
          <BaseButton
            v-if="editMode"
            @click.prevent="attemptDeletion"
            buttonStyle="link"
            size="small"
          >
            Delete entry
          </BaseButton>
        </div>
        <div>
          <BaseButton
            v-if="showEscalationButton"
            @click="escalationModalOpen = true"
            size="small"
            buttonStyle="white"
          >
            <ArrowUpRightIcon class="-ml-0.5 mr-2 h-3 w-3" />
            Escalate
          </BaseButton>
        </div>
      </div>
    </div>
    <BaseConfirmationModal
      v-if="confirmDelete"
      title="Delete entry"
      description="Deleting this entry can't be undone. Are you sure you want to continue?"
      button-text="Delete"
      @confirm="deleteEntry"
      @close="confirmDelete = false"
    />
    <GenerateAiEntryModal
      v-if="playbook && entry"
      :show="showAiEntryModal"
      :playbook="playbook"
      :entry="entry"
      :entryHeading="modelHeading"
      @generate="generateAiEntry"
      @close="showAiEntryModal = false"
    />
    <EscalationModal
      v-if="playbookInstance && client"
      :show="escalationModalOpen"
      :approvers="approvers"
      :playbookInstance="playbookInstance"
      :entry="entry"
      @close="escalationModalOpen = false"
    />
  </details>
</template>

<script setup lang="ts">
// stolen from https://tailwindcomponents.com/component/working-contact-form-no-backend
import { computed, nextTick, onMounted, PropType, ref, Ref, watch } from "vue";
import { ArrowUpRightIcon, FlagIcon } from "@heroicons/vue/24/outline";

import { SparklesIcon } from "@heroicons/vue/24/solid";
import {
  CheckedStatus,
  Entry,
  Escalation,
  NewInteraction,
  PlaybookInstance
} from "@/types";
import HoveringCheckBox from "@/components/sidebar/HoveringCheckBox.vue";
import ApiService from "@/services/ApiService";
import AiAlertButton from "@/components/AiAlertButton.vue";
import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";
import BaseSuperText from "@/components/base/BaseSuperText.vue";
import ClausesPanel from "@/components/sidebar/ClausesPanel.vue";
import ColourDropdown from "@/components/ColourDropdown.vue";
import EscalationModal from "@/components/sidebar/EscalationModal.vue";
import EscalationsSection from "@/components/sidebar/EscalationsSection.vue";
import GenerateAiEntryModal from "@/components/sidebar/GenerateAiEntryModal.vue";
import IssueHeading from "@/components/sidebar/IssueHeading.vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useClausesStore } from "@/store/ClausesStore";
import { useIssuesStore } from "@/store/IssuesStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { blankText, displayStringWithMarkedText } from "@/helpers/TextHelpers";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import { useAIStreamer } from "@/services/AI/AIStreamer";
import { useRouter } from "vue-router";
const router = useRouter();

const props = defineProps({
  editMode: {
    type: Boolean,
    required: false,
    default: false
  },

  entry: {
    type: Object as PropType<Entry>,
    required: true
  },

  filterText: {
    type: String,
    required: false
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: false
  }
});

const emits = defineEmits(["autoSaveChange"]);
const authorisationStore = useAuthorisationStore();
const sidebarStore = useSidebarStore();
const withinWord = computed(() => sidebarStore.withinWord);

const confirmDelete = ref(false);
const details = ref(null);
const headingInput = ref(null);

const showAiEntryModal = ref(false);

const escalationModalOpen = ref(false);

const escalations = computed((): Escalation[] => {
  return props.entry.escalations ?? [];
});

const clauses = computed(() => {
  const clausesStore = useClausesStore();
  return clausesStore.getClausesByEntryId(props.entry.id);
});

const issue = computed(() => {
  const issuesStore = useIssuesStore();
  return issuesStore.getIssuesByEntryId(props.entry.id)[0];
});

const showIssuesPanel = computed(() => {
  return (
    issue.value ||
    (props.playbookInstance && props.playbookInstance.issuesGenerated)
  );
});

const variableIdentifiers = computed(() => sidebarStore.variableIdentifiers);

const modelDescription = ref(props.entry.entryDescription);
const modelHeading = ref(props.entry.heading);
const modelColour = ref(props.entry.colour);

const prompt = ref("");
const superPrompt = ref("");
const llmModel = ref("gpt-4");

const playbook = computed(() => {
  return sidebarStore.playbook;
});

const templateClause = computed(() => {
  if (props.entry) {
    return ((props.entry as Entry).aiVariables || {}).templateClause;
  }
  return "";
});

const {
  aiError,
  startStreaming,
  generatedOutputWithVariablesAdded,
  streaming
} = useAIStreamer(templateClause);

onMounted(() => {
  modelDescription.value = props.entry.entryDescription ?? "";
  if (
    props.editMode &&
    modelDescription.value.length == 0 &&
    !props.entry.partiallyLoaded
  ) {
    modelDescription.value = "<p></p><p></p><p></p>";
  }
  isOpen.value =
    (blankText(props.entry.heading) && props.editMode) || isOpen.value;

  if (
    props.editMode &&
    (!props.entry.heading || props.entry.heading.length == 0) &&
    headingInput.value
  ) {
    nextTick(() => {
      if (headingInput.value) {
        (headingInput.value as HTMLInputElement).focus();
      }
    });
  }
});

const approvers = computed(() => sidebarStore.approvers);

const attemptDeletion = () => {
  if (blankText(modelDescription.value)) {
    deleteEntry();
    return;
  } else {
    confirmDelete.value = true;
  }
};

const blankEntryBody = computed(() => {
  return props.entry.partiallyLoaded || blankText(props.entry.entryDescription);
});

const checkedStatus = computed({
  get(): string {
    return props.entry.checkedStatus ?? "unchecked";
  },

  set(status: string) {
    updateCheckedStatus(status);
  }
});

const checkedTaskIdsForEntry = computed(() => {
  if (!props.entry) {
    return [];
  }
  const entryId = (props.entry as Entry).id.toString();
  return checkedTasksForPlaybookInstance.value[entryId] ?? [];
});

const checkedTasksForPlaybookInstance = computed(() => {
  if (!props.playbookInstance) {
    return {};
  }
  return (
    props.playbookInstance.checkedTasks ?? ({} as { [key: string]: string[] })
  );
});

const client = computed(() => {
  if (clients.value) {
    return (
      clients.value.find((client) => client.id === props.entry.clientId) ?? null
    );
  }
  return null;
});

const clients = computed(() => {
  return sidebarStore.clients;
});

const entryHeading = computed(() => {
  let heading = modelHeading.value ?? "";
  if (!props.playbookInstance) {
    // In other words, we're in peek mode
    if (client.value) {
      const playbook =
        client.value.playbooks.find(
          (playbook) => playbook.id === props.entry.playbookId
        ) ?? null;
      if (playbook) {
        const playbookNameElement = `<span class='text-blue-500 text-xs'>${playbook.name}</span>`;
        heading = `${playbookNameElement} ${heading}`;

        if (authorisationStore.isAdmin) {
          const clientId = playbook.clientId;
          const client = clients.value.find((client) => client.id === clientId);
          if (client) {
            const clientNameElement = `<span class='text-blue-500 text-xs'>${client.name} - </span>`;
            heading = `${clientNameElement} ${heading}`;
          }
        }
      }
    }
  }

  if (heading.length == 0) {
    return "No heading";
  }

  if (blankEntryBody.value) {
    return `<span class='text-gray-400'>${heading}</span>`;
  }

  return heading;
});

const goToIssue = () => {
  const playbookId = props.entry.playbookId;
  const entryId = (props.entry as Entry).id;
  const url = `/sidebar/playbooks/${playbookId}/entries/${entryId}/issues`;
  router.push(url);
};

const aiVariables = computed(() => {
  let result = {};
  if (playbook.value) {
    result = {
      ...result,
      ...playbook.value.aiVariables
    };
  }
  if (props.entry) {
    result = {
      ...result,
      ...(props.entry as Entry).aiVariables
    };
  }
  return result;
});

const generateAiEntry = () => {
  showAiEntryModal.value = false;
  modelDescription.value = "";
  nextTick(async () => {
    await loadPromptTemplate();
    const { entryTopic } = (props.entry as Entry).aiVariables;
    if (entryTopic) {
      modelHeading.value = entryTopic;
    }

    const promptForStream = parsePrompt(prompt.value, aiVariables.value);
    startStreaming({
      llmModel: llmModel.value,
      prompt: promptForStream,
      superPrompt: superPrompt.value
    });

    trackAiEntryGeneration(llmModel.value, promptForStream);
  });
};

const trackAiEntryGeneration = (llmModel: string, promptForStream: string) => {
  const interaction = {
    action: "generated AI entry",
    entryId: (props.entry as Entry).id,
    extraInfo: {
      prompt: promptForStream,
      superPrompt: superPrompt.value,
      llmModel
    },
    playbookId: props.entry.playbookId
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};

watch(generatedOutputWithVariablesAdded, () => {
  if (generatedOutputWithVariablesAdded.value && streaming.value) {
    modelDescription.value = generatedOutputWithVariablesAdded.value;
  }
});

const isOpen = computed({
  get() {
    const store = useSidebarStore();
    return store.isEntryOpen(props.entry as Entry);
  },

  set(isOpen: boolean) {
    const store = useSidebarStore();
    store.setEntryOpen(props.entry as Entry, isOpen);
  }
});

const deleteEntry = async () => {
  await sidebarStore.deleteEntry(props.entry as Entry);
};

const displayStringWithFilterResult = (value: string) => {
  return displayStringWithMarkedText(value, props.filterText ?? "");
};

const emitAutoSaveChange = () => {
  if (
    props.editMode &&
    (props.entry.entryDescription !== modelDescription.value ||
      props.entry.heading !== modelHeading.value ||
      props.entry.colour !== modelColour.value)
  ) {
    emits("autoSaveChange", {
      ...props.entry,
      colour: modelColour.value,
      entryDescription: modelDescription.value,
      heading: modelHeading.value
    });
  }
};

const showEscalationButton = computed((): boolean => {
  if (!withinWord.value) {
    return false;
  }
  const entry = props.entry as Entry;
  return Boolean(
    entry.escalationTemplate &&
      entry.escalationTemplate.text &&
      entry.escalationTemplate.text.length > 0
  );
});

const taskItemChecked = (taskItemId: string, checked: boolean) => {
  if (!props.playbookInstance) {
    return;
  }

  const entryId = (props.entry as Entry).id;
  const checkedItems = checked
    ? [...checkedTaskIdsForEntry.value, taskItemId]
    : checkedTaskIdsForEntry.value.filter((id) => id !== taskItemId);
  checkedTasksForPlaybookInstance.value[entryId.toString()] = checkedItems;
  const playbookInstance = {
    id: props.playbookInstance.id,
    checkedTasks: checkedTasksForPlaybookInstance.value
  };
  ApiService.updatePlaybookInstanceCheckedTasks(playbookInstance);
};

const trackOpenedPlaybookEntry = () => {
  if (props.playbookInstance) {
    if (details.value && details.value.open) {
      const interaction: NewInteraction = {
        action: "opened playbook entry",
        entryId: (props.entry as Entry).id,
        extraInfo: {
          word_doc_id: props.playbookInstance.wordDocId ?? undefined
        },
        playbookId: props.entry.playbookId
      };
      ApiService.trackInteraction(interaction);
    }
  }
};

const updateCheckedStatus = (status: string) => {
  if (props.playbookInstance) {
    sidebarStore.setCheckedStatus(
      props.entry as Entry,
      status as CheckedStatus
    );
  }
};

watch(
  () => props.entry,
  () => {
    modelDescription.value = props.entry.entryDescription ?? "";
    if (
      props.editMode &&
      modelDescription.value.length == 0 &&
      !props.entry.partiallyLoaded
    ) {
      modelDescription.value = "<p></p><p></p><p></p>";
    }
  },
  { immediate: true }
);

watch(modelDescription, () => {
  emitAutoSaveChange();
});

watch(modelHeading, () => {
  emitAutoSaveChange();
});

watch(modelColour, () => {
  emitAutoSaveChange();
});

watch(
  () => props.editMode,
  () => {
    modelDescription.value = props.entry.entryDescription ?? "";
    if (
      props.editMode &&
      modelDescription.value.length == 0 &&
      !props.entry.partiallyLoaded
    ) {
      modelDescription.value = "<p></p><p></p><p></p>";
    }
  }
);

const loadPromptTemplate = async () => {
  const { promptType } = aiVariables.value ?? "Own template";
  const response = await ApiService.getPromptTemplate(promptType);
  const promptTemplate = response.data.promptTemplate;
  prompt.value = promptTemplate.prompt;
  superPrompt.value = promptTemplate.superPrompt;
  llmModel.value = promptTemplate.llmModel;
};
</script>

<style lang="scss" scoped>
details {
  summary {
    @apply cursor-pointer border border-gray-300 text-sm leading-6 focus-visible:ring-gray-200;
    > * {
      display: inline;
    }
  }

  &:not([open]) summary {
    @apply rounded-lg;
  }

  &[open] summary {
    @apply rounded-t-lg;
  }

  .details-content {
    @apply relative rounded-b-lg border-b border-l border-r border-gray-300 p-2;
    overflow-wrap: anywhere;
  }
}
</style>
