<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2
              class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
            >
              We’ve sent you password reset instructions
            </h2>
            <p class="mt-10 text-center text-sm text-gray-500">
              You should receive an email any minute with your access token to
              reset your password.
            </p>
            <p class="mt-4 mb-2 text-center text-sm text-gray-500">
              Once you've received it, paste the access token here:
            </p>
            <form class="space-y-6" @submit.prevent="submitAccessToken">
              <div>
                <div class="mt-1">
                  <input
                    autofocus
                    type="text"
                    name="access-code"
                    v-model="accessToken"
                    class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
                    placeholder="Access token"
                    :class="
                      error
                        ? 'border-none ring-2 ring-red-400 focus:ring-red-500'
                        : ''
                    "
                  />
                  <p
                    v-if="error"
                    class="mt-3 text-left text-xs font-medium text-red-600"
                  >
                    We can't find that access token in our system. You can
                    request a new access token by clicking
                    <router-link
                      to="/forgot-password"
                      class="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      here.
                    </router-link>
                  </p>
                </div>
                <div class="mt-5 sm:mt-6">
                  <button
                    type="submit"
                    class="inline-flex w-full justify-center rounded border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50 sm:text-sm"
                    :disabled="accessToken.length === 0 || loading"
                  >
                    {{ loading ? "Verifying..." : "Verify access token" }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/services/ApiService";

export default defineComponent({
  data() {
    return {
      accessToken: "",
      error: false,
      loading: false
    };
  },

  methods: {
    submitAccessToken() {
      this.loading = true;
      this.validateToken(this.accessToken)
        .then(() => {
          this.$router.push(`/reset-password/${this.accessToken}`);
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },

    validateToken() {
      return ApiService.validatePasswordResetToken(this.accessToken);
    }
  }
});
</script>

<style lang="scss" scoped></style>
