<template>
  <details class="mb-2" :open="isOpen">
    <summary
      @keyup.space.prevent
      class="top-0 z-10 p-2 pl-3"
      :class="{
        'bg-green-50 hover:bg-green-100': checkedStatus == 'checked',
        'bg-gray-50 hover:bg-gray-100': checkedStatus != 'checked',
        'text-gray-400 line-through': checkedStatus == 'ignored'
      }"
    >
      <div class="flex justify-between">
        <span>
          <FlagIcon
            v-if="checkedStatus == 'flagged'"
            class="mr-1 inline h-3 w-3 text-red-500"
          />
          <span
            class="select-none"
            v-html="displayStringWithFilterResult(entryHeading)"
          />
        </span>

        <HoveringCheckBox
          v-if="playbookInstance"
          v-model="checkedStatus"
          targetPosition="left"
          class="relative float-right block flex"
        />
      </div>

      <!-- Entry panel -->
    </summary>

    <div v-if="model" class="details-content">
      <EntryBody
        v-model="model"
        :playbook="playbook"
        :filterText="filterText"
        :playbookInstance="playbookInstance"
      />
      <template v-if="!editMode">
        <!-- Button group -->
        <span
          v-if="hasPlaybookChangeAccess && showEscalationButton"
          class="flex justify-end rounded"
        >
          <BaseButton
            @click="editEntry"
            size="small"
            buttonStyle="white"
            class="mr-2"
          >
            <PencilIcon class="-ml-0.5 mr-2 h-4 w-4" />
            Edit entry
          </BaseButton>

          <BaseButton
            @click="escalationModalOpen = true"
            size="small"
            buttonStyle="white"
          >
            <ArrowsPointingOutIcon class="-ml-0.5 mr-2 h-4 w-4" />
            Escalate
          </BaseButton>
        </span>

        <!-- Not button group-->
        <span v-else class="mt-4 flex justify-end" ref="editButton">
          <BaseButton
            v-if="hasPlaybookChangeAccess"
            @click="editEntry"
            buttonStyle="white"
            size="small"
          >
            <PencilIcon class="-ml-0.5 mr-2 h-4 w-4" />
            Edit entry
          </BaseButton>

          <BaseButton
            v-if="showEscalationButton"
            @click="escalationModalOpen = true"
            buttonSize="small"
            buttonStyle="white"
          >
            <ArrowsPointingOutIcon class="-ml-0.5 mr-2 h-4 w-4" />
            Escalate
          </BaseButton>
        </span>
      </template>
      <div v-else-if="hasPlaybookChangeAccess" class="pt-5" ref="buttons">
        <div class="flex justify-between">
          <span>
            <BaseButton
              v-if="!newRecord"
              @click.prevent="confirmDelete = true"
              buttonStyle="link"
              size="small"
            >
              Delete entry
            </BaseButton>
          </span>
          <span class="flex justify-end">
            <span>
              <BaseButton class="mr-1" @click.prevent="saveEntry">
                Save entry
              </BaseButton>

              <BaseButton @click.prevent="cancelEdit" buttonStyle="link">
                Cancel
              </BaseButton>
            </span>
          </span>
        </div>
      </div>
    </div>
  </details>
  <BaseConfirmationModal
    v-if="confirmDelete"
    title="Delete entry"
    description="Deleting this entry can't be undone. Are you sure you want to continue?"
    button-text="Delete"
    @confirm="deleteEntry"
    @close="confirmDelete = false"
  />
  <EscalationModal
    v-if="playbookInstance && client && !newRecord"
    :show="escalationModalOpen"
    :approvers="approvers"
    :playbookInstance="playbookInstance"
    :entry="entry as Entry"
    @close="escalationModalOpen = false"
  />
</template>

<script lang="ts">
// stolen from https://tailwindcomponents.com/component/working-contact-form-no-backend
import { defineComponent, nextTick, ref, Ref, PropType } from "vue";
import { mapState } from "pinia";
import VueScrollTo from "vue-scrollto";
import { ArrowsPointingOutIcon, FlagIcon } from "@heroicons/vue/24/outline";
import clone from "just-clone";
import { PencilIcon } from "@heroicons/vue/24/solid";
import {
  CheckedStatus,
  Client,
  Entry,
  FallbackScenario,
  NewEntry,
  NewInteraction,
  Playbook,
  PlaybookInstance
} from "@/types";
import HoveringCheckBox from "@/components/sidebar/HoveringCheckBox.vue";
import ApiService from "@/services/ApiService";
import EntryBody from "@/components/sidebar/EntryBody.vue";
import EscalationModal from "@/components/sidebar/EscalationModal.vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { blankText, displayStringWithMarkedText } from "@/helpers/TextHelpers";
import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";

export default defineComponent({
  emits: ["newEntryCreated"],

  setup() {
    const authorisationStore = useAuthorisationStore();
    const sidebarStore = useSidebarStore();
    const model = ref(null) as Ref<Entry | NewEntry | null>;

    return {
      authorisationStore,
      model,
      sidebarStore
    };
  },

  components: {
    ArrowsPointingOutIcon,
    BaseConfirmationModal,
    EntryBody,
    EscalationModal,
    FlagIcon,
    HoveringCheckBox,
    PencilIcon
  },

  props: {
    entry: {
      type: Object as PropType<Entry | NewEntry>,
      required: true
    },

    filterText: {
      type: String,
      required: false
    },

    playbook: {
      type: Object as PropType<Playbook>,
      required: false
    },

    playbookInstance: {
      type: Object as PropType<PlaybookInstance>,
      required: false
    }
  },

  mounted() {
    this.model = clone(this.entry);
  },

  data() {
    return {
      confirmDelete: false,
      escalationModalOpen: false
    };
  },

  computed: {
    ...mapState(useSidebarStore, ["approvers", "clients"]),

    blankEntryBody(): boolean {
      return (
        this.entry.partiallyLoaded ||
        (this.entry.fallbackScenarios.length == 0 &&
          blankText(this.entry.entryDescription) &&
          blankText(this.entry.description) &&
          blankText(this.entry.templateComment) &&
          blankText(this.entry.templateWording))
      );
    },

    checkedStatus: {
      get(): string {
        return this.entry.checkedStatus ?? "unchecked";
      },

      set(status: string) {
        this.updateCheckedStatus(status);
      }
    },

    client(): Client | null {
      if (this.clients) {
        return (
          this.clients.find((client) => client.id === this.entry.clientId) ??
          null
        );
      }
      return null;
    },

    entryHeading() {
      let heading = this.entry.heading ?? "";

      if (!this.playbook) {
        // In other words, we're in peek mode
        if (this.client) {
          const playbook =
            this.client.playbooks.find(
              (playbook) => playbook.id === this.entry.playbookId
            ) ?? null;
          if (playbook) {
            const playbookNameElement = `<span class='text-blue-500 text-xs'>${playbook.name}</span>`;
            heading = `${playbookNameElement} ${heading}`;

            if (this.authorisationStore.isAdmin) {
              const clientId = playbook.clientId;
              const client = this.clients.find(
                (client) => client.id === clientId
              );
              if (client) {
                const clientNameElement = `<span class='text-blue-500 text-xs'>${client.name} - </span>`;
                heading = `${clientNameElement} ${heading}`;
              }
            }
          }
        }
      }

      if (heading.length == 0) {
        if (this.newRecord) {
          return "New entry";
        }
        return "No heading";
      }

      if (this.blankEntryBody) {
        return `<span class='text-gray-400'>${heading}</span>`;
      }

      return heading;
    },

    editMode: {
      get(): boolean {
        const store = useSidebarStore();
        if (!this.entry) {
          return false;
        }
        if (this.newRecord) {
          return true;
        }
        return store.entryEdited(this.entry as Entry);
      },

      set(editMode: boolean) {
        const store = useSidebarStore();
        if (!this.entry) {
          return;
        }
        if (editMode) {
          store.setEntryEdited(this.entry as Entry);
          this.scrollToTop();
        } else {
          store.clearEntryEdited(this.entry as Entry);
          this.scrollToTop();
        }
      }
    },

    hasPlaybookChangeAccess(): boolean {
      return this.authorisationStore.hasPlaybookChangeAccess;
    },

    isOpen: {
      get() {
        if (this.newRecord) return true;
        const store = useSidebarStore();
        return store.isEntryOpen(this.entry as Entry);
      },

      set(isOpen: boolean) {
        if (!this.newRecord) {
          const store = useSidebarStore();
          store.setEntryOpen(this.entry as Entry, isOpen);
        }
      }
    },

    newRecord(): boolean {
      return !(this.entry as Entry).id;
    },

    showEscalationButton(): boolean {
      if (this.newRecord) return false;
      const entry = this.entry as Entry;
      return Boolean(
        entry.escalationTemplate &&
          entry.escalationTemplate.text &&
          entry.escalationTemplate.text.length > 0
      );
    }
  },

  methods: {
    cancelEdit() {
      this.editMode = false;
      if (this.newRecord) {
        this.sidebarStore.removeEntry(this.entry);
      } else {
        this.model = clone(this.entry);
      }
    },

    async deleteEntry() {
      await this.sidebarStore.deleteEntry(this.entry as Entry);
      this.editMode = false;
    },

    editEntry() {
      this.editMode = true;
      this.model = clone(this.entry);
      const originalCheckedStatus = this.entry.checkedStatus;
      ApiService.getEntry((this.entry as Entry).id).then(({ data }) => {
        this.sidebarStore.replaceEntry(data.entry);
        if (
          !data.entry.fallbackScenarios ||
          data.entry.fallbackScenarios.length == 0
        ) {
          const fallbackScenario = {
            fallbacks: [{}]
          };
          data.entry.fallbackScenarios = [fallbackScenario as FallbackScenario];
        }

        this.model = clone(this.entry);
        if (this.model) {
          this.model.checkedStatus = originalCheckedStatus;
        }
      });
    },

    openOrCloseEntry() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.trackOpenedPlaybookEntry();
      }
    },

    scrollToTop() {
      nextTick(() => {
        VueScrollTo.scrollTo(this.$refs.topOfEntry, { duration: 500 });
      });
    },

    trackOpenedPlaybookEntry() {
      if (this.playbookInstance && !this.newRecord) {
        const interaction: NewInteraction = {
          action: "opened playbook entry",
          entryId: (this.entry as Entry).id,
          extraInfo: {
            word_doc_id: this.playbookInstance.wordDocId ?? undefined
          },
          playbookId: this.entry.playbookId
        };
        ApiService.trackInteraction(interaction);
      }
    },

    updateCheckedStatus(status: string) {
      if (this.playbookInstance) {
        this.sidebarStore.setCheckedStatus(
          this.entry as Entry,
          status as CheckedStatus
        );
      }
    },

    displayStringWithFilterResult(value: string) {
      return displayStringWithMarkedText(value, this.filterText ?? "");
    },

    async saveEntry() {
      if (this.model) {
        if (this.newRecord) {
          const newEntry = await this.sidebarStore.createEntry(this.model);
          this.$emit("newEntryCreated", newEntry);
        } else {
          if (this.entry) {
            const originalCheckedStatus = this.entry.checkedStatus;
            this.model.checkedStatus = originalCheckedStatus;
          }
          await this.sidebarStore.updateEntry(this.model as Entry);
          this.editMode = false;
        }
      }
    }
  },

  watch: {
    checkedStatus() {
      if (!this.editMode) {
        this.isOpen = false;
      }
    },

    entry: {
      deep: true,
      handler() {
        if (this.entry) {
          this.model = clone(this.entry);
        }
      }
    }
  }
});
</script>

<style lang="scss" scoped>
details {
  summary {
    @apply cursor-pointer text-sm leading-6 focus-visible:ring-gray-200;
    > * {
      display: inline;
    }
  }

  &:not([open]) summary {
    @apply rounded-lg;
  }

  &[open] summary {
    @apply rounded-t-lg;
  }

  .details-content {
    @apply relative rounded-b-lg border border-gray-50 p-2;
    overflow-wrap: anywhere;

    :first-child {
      margin-top: 0;
    }
  }
}
</style>
