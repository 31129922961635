import { computed, ref, Ref, watch } from "vue";
import { Marked } from "@ts-stack/markdown";

import { PromptTemplate } from "@/types";

import ApiService from "@/services/ApiService";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import { useAIStreamer } from "@/services/AI/AIStreamer";
import { Clause, Issue } from "@/types";

import { useAIStreamsStore } from "@/store/AIStreamsStore";
import { useIssuesStore } from "@/store/IssuesStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { formatStringAsObjectWithLineNumbers } from "@/helpers/TextHelpers";

export function useIssuesGenerator() {
  const issueDescription = ref("");
  let promptTemplate: PromptTemplate | null = null;
  const aiStreamsStore = useAIStreamsStore();
  const issuesStore = useIssuesStore();
  const sidebarStore = useSidebarStore();

  const {
    aiError: issuesGeneratorError,
    continueStreaming,
    startStreaming,
    generatedOutputWithVariablesAdded,
    streaming
  } = useAIStreamer();

  const role = ref("");
  const contractType = ref("");
  const clauses = ref([]) as Ref<Clause[]>;
  const entryId = ref(null) as Ref<null | string>;
  const playbookBody = ref("");

  const clausesAsJson = computed(() => {
    const text = clauses.value
      .map((clause: Clause) => {
        return clause.clauseBody;
      })
      .join("\n");
    return formatStringAsObjectWithLineNumbers(text);
  });
  const playbookInstance = computed(() => sidebarStore.playbookInstance);

  const checkStreamInProgress = async () => {
    if (streamIdentifiers().length === 0) {
      return;
    }
    const streamId = aiStreamsStore.getStream(streamIdentifiers());
    if (streamId) {
      continueStreaming(streamId);
    }
  };

  const generate = async ({
    pRole,
    pContractType,
    pPlaybookBody,
    pEntryId,
    pClauses
  }: {
    pRole: string;
    pContractType: string;
    pPlaybookBody: string;
    pEntryId: string;
    pClauses: Clause[];
  }) => {
    role.value = pRole;
    contractType.value = pContractType;
    playbookBody.value = pPlaybookBody;
    clauses.value = pClauses;
    entryId.value = pEntryId;
    // set up the prompt templates
    promptTemplate = await loadPromptTemplate("Issues");

    issueDescription.value = "";
    console.log("Searching for issues for entry id ", entryId.value);
    streamJson();
  };

  const streamIdentifiers = () => {
    if (!playbookInstance.value) {
      return [];
    }

    return ["issues", playbookInstance.value.id.toString()];
  };

  const streamJson = async () => {
    if (!promptTemplate) {
      console.log("Error loading prompt template");
      return;
    }

    const variables = {
      playbook: playbookBody.value,
      clausesAsJson: clausesAsJson.value,
      role: role.value,
      contractType: contractType.value
    };
    const prompt = parsePrompt(promptTemplate.prompt, variables);
    // if (entryId.value && entryId.value.toString() == "432") {
    //   console.log("Clauses as json: ", clausesAsJson.value);
    //   console.log("Prompt: ", prompt);
    // }

    const currentStreamId = await startStreaming({
      llmModel: promptTemplate.llmModel,
      prompt,
      superPrompt: promptTemplate.superPrompt,
      messages: []
    });

    aiStreamsStore.setStream(currentStreamId, streamIdentifiers());
  };

  const loadPromptTemplate = async (template: string) => {
    const response = await ApiService.getPromptTemplate(template);
    return response.data.promptTemplate;
  };

  watch(
    () => streaming.value,
    () => {
      // Just finished streaming
      if (!streaming.value) {
        issueDescription.value = Marked.parse(
          generatedOutputWithVariablesAdded.value
        );

        const newIssue = {
          description: issueDescription.value,
          entryId: parseInt(entryId.value!),
          refinements: []
        };
        issuesStore.createIssue(newIssue);
        aiStreamsStore.clearStream(streamIdentifiers());
      }
    }
  );

  watch(
    () => playbookInstance.value,
    () => {
      checkStreamInProgress();
    }
  );

  return {
    generate,
    issuesGeneratorError,
    streaming
  };
}
