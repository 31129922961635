<template>
  <node-view-wrapper as="span" @click="handleClick">
    <span
      v-html="displayText"
      class="whitespace-nowrap rounded-lg border bg-blue-100 px-1"
      :class="{
        'cursor-pointer hover:border-blue-200': !editMode,
        'bg-green-100': filledOut,
        'bg-blue-100': !filledOut
      }"
    />
    <Dialog
      v-model:open="showModal"
      class="fixed inset-0 z-50 overflow-y-auto whitespace-pre-wrap"
    >
      <div
        class="flex min-h-screen min-h-screen items-center justify-center px-4 text-center"
      >
        <DialogOverlay
          @click="showModal = false"
          class="fixed inset-0 bg-black opacity-30"
        />

        <!-- Modal content -->
        <div
          class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
        >
          <DialogTitle class="text-md font-medium leading-6 text-gray-900">
            Fill in
            <span class="whitespace-nowrap">{{ variablePlaceholder }}</span>
          </DialogTitle>
          <form @submit="saveVariable">
            <div class="mt-2">
              <BaseInput
                v-model="newVariableValue"
                placeholder=""
                class="w-full"
                :hint="`This will populate ${variablePlaceholder} everywhere in the playbook.`"
              />
            </div>
            <div class="mt-5 flex space-x-3 sm:mt-4">
              <BaseButton @click="saveVariable"> Update </BaseButton>
              <BaseButton buttonStyle="white" @click="showModal = false">
                Cancel
              </BaseButton>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  </node-view-wrapper>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";

import { useSidebarStore } from "@/store/SidebarStore";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";

const showModal = ref(false);
const newVariableValue = ref("");

const sideBarStore = useSidebarStore();

const props = defineProps(nodeViewProps);

const editMode = computed(() => {
  return sideBarStore.editMode;
});

const variableId = computed(() => {
  return props.node.attrs.id.trim();
});

const variableValue = computed(() => {
  return sideBarStore.getVariableById(variableId.value);
});

const variablePlaceholder = computed(() => {
  return `{ ${variableId.value} }`;
});

const displayText = computed(() => {
  if (!editMode.value && variableValue.value) {
    return variableValue.value;
  }

  return variablePlaceholder.value;
});

const handleClick = () => {
  if (editMode.value) {
    return;
  }
  newVariableValue.value = variableValue.value ?? "";
  showModal.value = true;
};

const saveVariable = () => {
  sideBarStore.updateVariable(variableId.value.trim(), newVariableValue.value);

  showModal.value = false;
};

const filledOut = computed(() => {
  return !!variableValue.value && !editMode.value;
});
</script>
