<template>
  <LoadingPanel v-if="entriesLoading" />
  <div v-else class="relative grid lg:max-w-2xl">
    <PlaybookSearchBar
      v-if="playbookInstance && client"
      :client="client"
      v-model:filterTags="filterTags"
      v-model:filterText="filterText"
      @addEntry="addEntry"
      @changedTags="savePlaybookInstance"
    />
    <div
      v-if="!reordering && entries && entries.length > 0"
      class="flex flex-row-reverse"
    >
      <template v-if="hasPlaybookChangeAccess && isPlaybookLite">
        <button
          v-if="editMode"
          type="button"
          class="mb-2 inline-flex items-center gap-x-1.5 justify-self-end rounded bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
          @click.prevent="editMode = false"
          :disabled="entriesSaving"
        >
          <ArrowPathIcon
            v-if="entriesSaving"
            class="-ml-0.5 h-4 w-4 animate-spin text-blue-500"
          />
          <CheckIcon v-else class="-ml-0.5 h-4 w-4 text-green-500" />
          <span>{{ entriesSaving ? "Saving..." : "Leave edit mode" }}</span>
        </button>
        <button
          v-else
          type="button"
          class="group mb-2 mr-1 inline-flex items-center rounded bg-white px-1.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
          @click.prevent="editMode = true"
          :disabled="streaming"
        >
          <PencilIcon class="mr-2 h-4 w-4 group-hover:text-lexoo" />
          <span> Edit playbook </span>
        </button>
      </template>
      <button
        v-if="editMode"
        type="button"
        class="group mb-2 mr-1 inline-flex items-center rounded bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
        @click.prevent="
          editMode = false;
          reordering = true;
        "
        :disabled="entriesSaving"
      >
        <svg
          class="mr-2 h-4 w-4 group-hover:text-lexoo"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
        >
          <path
            d="M2 11h21V6H2zm1-4h19v3H3zM2 18h21v-5H2zm1-4h19v3H3zm7 6h5l-2.5 2.574zm0-16l2.5-2.574L15 4z"
          />
          <path fill="none" d="M0 0h24v24H0z" />
        </svg>
        <span>Reorder entries</span>
      </button>
      <button
        v-if="!editMode && isAdmin && isPlaybookLite"
        type="button"
        class="group mb-2 mr-1 inline-flex items-center rounded bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
        @click.prevent="showIssueQuestionsModal = true"
        :disabled="streaming"
      >
        <SparklesIcon class="mr-2 h-4 w-4 text-yellow-500" />
        <span>{{ aiReviewLabel }}</span>
      </button>
    </div>
    <div v-if="entries && filteredEntries.length === 0" class="mb-10">
      <h3 class="mt-2 text-sm font-semibold text-gray-900">No entries</h3>
      <template v-if="showNewEntryButton">
        <p class="mt-1 text-sm text-gray-500">
          Get started by adding a new entry into this playbook.
        </p>
        <div class="mt-6">
          <BaseButton @click="setAddingEntry(0)" buttonStyle="white">
            <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New entry
          </BaseButton>
        </div>
      </template>
      <template v-else>
        <p class="mt-1 text-sm text-gray-500">
          The admin for this Playbook hasn't added any entries yet.
        </p>
        <p class="mt-1 text-sm text-gray-500">
          You can explore other Playbooks
          <router-link to="/sidebar/playbooks" class="underline">
            here.
          </router-link>
        </p>
      </template>
    </div>
    <div
      v-else-if="filterText.length > 0 && filteredEntries.length === 0"
      class="text-sm text-gray-500"
    >
      No entries found for search term `{{ filterText }}`
    </div>
    <div
      v-if="
        filterText.length > 0 && filteredEntries && filteredEntries.length > 0
      "
      class="text-sm text-gray-500"
    >
      {{ filteredEntries.length }}
      {{ filteredEntries.length == 1 ? "entry" : "entries" }} found for search
      term `{{ filterText }}`
    </div>
    <template v-if="playbook && reordering">
      <p class="text-sm leading-4 text-gray-600">
        Drag and drop entries to reorder them, and then click "Save order".
      </p>
      <table class="mt-4 min-w-full table-auto">
        <Draggable
          :list="entries"
          item-key="position"
          class="divide-y divide-gray-200 bg-white"
          animation="200"
          ghost-class="ghost"
          tag="tbody"
          @start="drag = true"
          @end="drag = false"
        >
          <template #item="{ element }">
            <tr
              class="ordering bg-white align-top"
              :class="{
                'hover:bg-gray-50': !drag
              }"
            >
              <td class="py-2 text-sm font-medium text-gray-800">
                <Bars4Icon class="mr-4 inline h-5 w-5 align-bottom" />
                {{ element.heading }}
              </td>
            </tr>
          </template>
        </Draggable>
      </table>
      <div class="sticky bottom-0 w-full bg-white/70 p-4">
        <button
          @click.prevent="saveOrder"
          class="w-full rounded border border-transparent bg-lexoo px-2.5 py-1.5 text-white bg-blend-overlay hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
        >
          Save order
        </button>
        <button
          @click.prevent="reordering = false"
          class="mt-2 w-full rounded border bg-gray-50 px-2.5 py-1.5 text-gray-700 bg-blend-overlay hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
        >
          Cancel
        </button>
      </div>
    </template>
    <div v-if="playbookInstance && playbook" v-show="!reordering">
      <ul :class="streaming ? 'generating-ai' : ''">
        <li
          v-for="entry in filteredEntries"
          :key="entry.id"
          :ref="(el) => setEntryElement(el as HTMLElement, entry.id)"
          :id="`entry-${entry.id}`"
        >
          <PlaybookSuperEntry
            v-if="isPlaybookLite"
            :playbookInstance="playbookInstance"
            :editMode="editMode"
            :entry="entry"
            :playbook="playbook"
            :filterText="filterText"
            @autoSaveChange="autoSave"
          />
          <PlaybookEntry
            v-else
            :playbookInstance="playbookInstance"
            :entry="entry"
            :playbook="playbook"
            :filterText="filterText"
          />
        </li>
      </ul>
    </div>
    <SearchResults
      v-if="playbook"
      :currentPlaybookId="playbook.id"
      :filterText="filterText"
      @autoSaveChange="autoSave"
      :editMode="editMode"
    />

    <div v-if="entries.length > 0 && showNewEntryButton" class="mt-4">
      <BaseButton
        @click="setAddingEntry(lastEntryPosition)"
        buttonStyle="white"
      >
        <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        New entry
      </BaseButton>
    </div>
    <div class="mt-4 flex w-full justify-center">
      <a
        v-if="client && client.dataPerContractUrl && isAdmin"
        buttonStyle="white"
        :href="client.dataPerContractUrl"
        class="inline-flex items-center rounded border border border-gray-300 bg-white px-3 py-2 text-sm font-normal leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
        target="_blank"
      >
        <DocumentTextIcon class="mr-1 h-4 w-4" />
        Data per contract
      </a>
    </div>
  </div>
  <SidebarWidthHint />

  <div
    v-if="!reordering && entries && entries.length > 0 && !entriesLoading"
    class="sticky bottom-1 z-20"
  >
    <button
      v-if="editMode"
      type="button"
      class="expanding-button group mb-2 mr-2 inline-flex items-center rounded bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
      @click.prevent="
        editMode = false;
        reordering = true;
      "
      :disabled="entriesSaving"
    >
      <svg
        class="h-4 w-4 group-hover:text-lexoo"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
      >
        <path
          d="M2 11h21V6H2zm1-4h19v3H3zM2 18h21v-5H2zm1-4h19v3H3zm7 6h5l-2.5 2.574zm0-16l2.5-2.574L15 4z"
        />
        <path fill="none" d="M0 0h24v24H0z" />
      </svg>
      <span>Reorder entries</span>
    </button>
    <template v-if="hasPlaybookChangeAccess && isPlaybookLite">
      <button
        v-if="editMode"
        type="button"
        class="mb-2 mr-1 inline-flex items-center gap-x-1.5 justify-self-end rounded bg-white px-2.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:cursor-not-allowed disabled:text-gray-400"
        @click.prevent="editMode = false"
        :disabled="entriesSaving"
      >
        <ArrowPathIcon
          v-if="entriesSaving"
          class="-ml-0.5 h-4 w-4 animate-spin text-blue-500"
        />
        <CheckIcon v-else class="-ml-0.5 h-4 w-4 text-green-500" />
        <span>{{ entriesSaving ? "Saving..." : "Leave edit mode" }}</span>
      </button>
      <button
        v-else
        type="button"
        class="expanding-button group mb-2 mr-1 inline-flex items-center rounded bg-white px-1.5 py-1.5 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100"
        @click.prevent="editMode = true"
      >
        <PencilIcon class="h-4 w-4 group-hover:text-lexoo" />
        <span> Edit playbook </span>
      </button>
    </template>
  </div>
  <IssuesQuestionsModal
    v-if="playbookInstance && playbook"
    :show="showIssueQuestionsModal"
    @generate="performAiReview"
    @close="showIssueQuestionsModal = false"
  />
</template>

<script setup lang="ts">
/* global Office */

import { computed, nextTick, onMounted, reactive, ref, Ref, watch } from "vue";

import {
  ArrowPathIcon,
  Bars4Icon,
  CheckIcon,
  DocumentTextIcon,
  PencilIcon,
  PlusIcon,
  SparklesIcon
} from "@heroicons/vue/24/outline";
import Draggable from "vuedraggable";
import { debounce } from "throttle-debounce";
import VueScrollTo from "vue-scrollto";
import { useRouter } from "vue-router";
import { useStorage } from "@vueuse/core";

import {
  Entry,
  NewEntry,
  NewInteraction,
  Playbook,
  PlaybookInstance,
  Tag
} from "@/types";
import { useAIStreamsStore } from "@/store/AIStreamsStore";
import { useAlertStore } from "@/store/AlertStore";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useClausesStore } from "@/store/ClausesStore";
import { useIssuesStore } from "@/store/IssuesStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { useUserStore } from "@/store/UserStore";
import ApiService from "@/services/ApiService";
import SearchResults from "@/components/sidebar/SearchResults.vue";
import DocumentParser from "@/services/DocumentParser";
import EntryFilter from "@/services/EntryFilter";
import LoadingPanel from "@/components/LoadingPanel.vue";

import IssuesQuestionsModal from "@/components/sidebar/IssuesQuestionsModal.vue";
import PlaybookEntry from "@/components/sidebar/PlaybookEntry.vue";
import PlaybookSuperEntry from "@/components/sidebar/PlaybookSuperEntry.vue";
import PlaybookSearchBar from "@/components/sidebar/PlaybookSearchBar.vue";
import SidebarWidthHint from "@/components/sidebar/SidebarWidthHint.vue";

import { useClausesFinder } from "@/services/AI/ClausesFinder";
import { useClauseSplitter } from "@/services/AI/ClauseSplitter";
import { useIssuesGenerator } from "@/services/AI/IssuesGenerator";
import { useLoadPlaybookInstance } from "@/services/PlaybookInstanceLoader";
import {
  getWordDocumentAsHtml,
  getWordDocumentAsText
} from "@/helpers/WordHelpers";
import { entriesToString } from "@/helpers/PromptHelpers";

const aiStreamsStore = useAIStreamsStore();
const authorisationStore = useAuthorisationStore();
const contractBody = useStorage("clauses-contract-body", "");
const clausesStore = useClausesStore();
const issuesStore = useIssuesStore();
const sidebarStore = useSidebarStore();
const userStore = useUserStore();
const { docIdToSearch, filePath, loadPlaybookInstance } =
  useLoadPlaybookInstance();
const showIssueQuestionsModal = ref(false);
const router = useRouter();

const {
  splitterError,
  foundSplitClauses,
  splitClauses,
  generateSplitClauses,
  streaming: splitStreaming
} = useClauseSplitter();

const role = ref("");
const contractType = ref("");
const definitions = computed(() => sidebarStore.definitions);

const {
  clausesFinderError,
  findClauses,
  streaming: clausesFinderStreaming
} = useClausesFinder();

issuesStore.$onAction(({ name, args }) => {
  if (name === "startFindingIssueForEntry") {
    searchForIssuesForEntry(args[0]);
  }
});

const entryIssueGenerators = ref({}) as Ref<
  Record<number, ReturnType<typeof useIssuesGenerator>>
>;

const props = defineProps({
  jumpToEntryId: {
    type: String,
    default: null
  },

  loadFromWordDocumentIfPossible: {
    type: Boolean,
    default: true
  }
});

const debounceSave: Ref<{ [entryId: number]: (entry: Entry) => void }> = ref(
  {}
);
const drag = ref(false);
const entryElements: { [key: string]: HTMLElement } = reactive({});
const filterTags: Ref<Tag[]> = ref([]);
const savingEntries = ref(new Set<number>());

const issuesGeneratorStreaming = computed((): boolean => {
  return Object.values(entryIssueGenerators.value).some(
    (v: ReturnType<typeof useIssuesGenerator>) => v.streaming
  );
});

const streaming = ref(false);
// Debounce function to delay the update
const updateStreaming = debounce(1500, () => {
  streaming.value =
    clausesFinderStreaming.value ||
    issuesGeneratorStreaming.value ||
    splitStreaming.value;
});

watch(
  [clausesFinderStreaming, issuesGeneratorStreaming, splitStreaming],
  () => {
    updateStreaming();
  }
);

const aiReviewLabel = computed(() => {
  if (splitStreaming.value) {
    return "Splitting clauses...";
  }
  if (clausesFinderStreaming.value) {
    return "Finding clauses...";
  }
  if (issuesGeneratorStreaming.value) {
    return "Generating AI review...";
  }
  if (streaming.value) {
    return "Please wait...";
  }
  return "AI review";
});
const client = computed(() => sidebarStore.client);
const clientId = computed({
  get: () => sidebarStore.clientId,
  set: (value: number | null) => {
    sidebarStore.clientId = value;
  }
});
const editMode = computed({
  get: () => sidebarStore.editMode,
  set: (value: boolean) => {
    sidebarStore.editMode = value;
  }
});
const entries = computed(() => sidebarStore.entries);
const fullPlaybookString = computed(() => {
  return entriesToString(entries.value);
});
const entriesLoading = computed(() => sidebarStore.entriesLoading);
const filterText = computed({
  get: () => sidebarStore.filterText,
  set: (value: string) => {
    sidebarStore.filterText = value;
  }
});
const playbookId = computed({
  get: () => sidebarStore.playbookId,
  set: (value: number | null) => {
    sidebarStore.playbookId = value;
  }
});
const playbookInstance = computed(() => sidebarStore.playbookInstance);
const reordering = computed({
  get: () => sidebarStore.reordering,
  set: (value: boolean) => {
    sidebarStore.reordering = value;
  }
});
const usedTags = computed(() => sidebarStore.usedTags);

const entriesSaving = computed((): boolean => {
  return savingEntries.value.size > 0;
});

const errorStreaming = computed(() => {
  return splitterError.value || clausesFinderError.value;
});

const filteredEntries = computed((): Entry[] => {
  if (!playbookInstance.value) {
    return [];
  }
  return new EntryFilter(entries.value).filter(
    filterText.value,
    filterTags.value
  );
});

const hasPlaybookChangeAccess = computed((): boolean => {
  return authorisationStore.hasPlaybookChangeAccess;
});

const lastEntryPosition = computed((): number => {
  return entries.value.length + 1;
});

const isAdmin = computed(() => {
  return authorisationStore.isAdmin;
});

const isPlaybookLite = computed((): boolean => {
  if (!playbook.value) {
    return false;
  }
  return playbook.value.lexplayLeap;
});

const playbook = computed((): Playbook | null => {
  if (playbookInstance.value && client.value) {
    const playbookId = playbookInstance.value.playbookId;
    return client.value.playbooks.filter(
      (p: Playbook) => p.id === playbookId
    )[0];
  }
  return null;
});

const showNewEntryButton = computed(() => {
  return hasPlaybookChangeAccess.value && !reordering.value;
});

onMounted(async () => {
  await tryLoadPlaybookInstance();
  userStore.loadTeamMembers();
  await issuesStore.loadIssues();
  await clausesStore.loadClauses();

  if (typeof Office !== "undefined") {
    Office.onReady(async () => {
      console.log("Office is ready");

      await loadDefinitions();
      contractBody.value = await getWordDocumentAsText();
    });
  }
});

watch(
  () => editMode.value,
  (inEditMode: boolean) => {
    if (!inEditMode) {
      loadEntryData();
    }
  }
);

watch(
  () => aiStreamsStore.hasTimedOut,
  (hasTimedOut) => {
    if (hasTimedOut) {
      streaming.value = false;
      entryIssueGenerators.value = {};
    }
  }
);

const searchForIssuesForEntry = async (entryId: number) => {
  const issueGenerator = useIssuesGenerator();
  const entry = entries.value.find(
    (e) => e.id.toString() === entryId.toString()
  );
  if (entry) {
    entryIssueGenerators.value[entryId] = issueGenerator;
    const playbookBody = entry.heading + "\n" + entry.entryDescription;
    const clauses = clausesStore.getClausesByEntryId(entry.id);
    issueGenerator.generate({
      pRole: role.value,
      pContractType: contractType.value,
      pPlaybookBody: playbookBody,
      pEntryId: entryId.toString(),
      pClauses: clauses
    });
  }
};

watch(
  () => playbookInstance.value,
  () => {
    if (playbookInstance.value) {
      const includedTagIds = playbookInstance.value.includedTagIds || [];
      filterTags.value = usedTags.value.filter((tag: Tag) => {
        if (tag.id) {
          return includedTagIds.includes(tag.id);
        }
      });
      clientId.value = playbookInstance.value.clientId ?? null;
      playbookId.value = playbookInstance.value.playbookId;
    }
  }
);

watch(
  () => streaming.value,
  (streaming) => {
    if (!streaming) {
      const updatedPlaybookInstance: PlaybookInstance = {
        ...playbookInstance.value,
        issuesGenerated: true
      };
      sidebarStore.updatePlaybookInstance(updatedPlaybookInstance);
    }
  }
);

watch(
  () => foundSplitClauses.value,
  (foundSplitClauses) => {
    if (foundSplitClauses) {
      tryToFindClauses();
    }
  }
);

watch(
  () => errorStreaming.value,
  (error) => {
    if (error) {
      console.log("Error streaming");
      useAlertStore().addAlert(
        "There was an error streaming from the AI service. Try again!"
      );
    }
  }
);

const addEntry = (entry: Entry) => {
  if (playbookInstance.value) {
    playbookInstance.value.entries.push(entry);
  }
};

const autoSave = (entry: Entry) => {
  savingEntries.value.add(entry.id);

  getDebounceSave(entry.id)(entry);
};

const getDebounceSave = (entryId: number) => {
  if (!debounceSave.value[entryId]) {
    debounceSave.value[entryId] = debounce(1000, (entryToUpdate: Entry) => {
      ApiService.updateEntry(entryToUpdate);
      savingEntries.value.delete(entryId);
    });
  }
  return debounceSave.value[entryId];
};

const loadDefinitions = async () => {
  const documentAsHtml = await getWordDocumentAsHtml();
  const parser = new DocumentParser(documentAsHtml);
  sidebarStore.setDefinitions(parser.parse().definitions);
};

const performAiReview = (params: {
  role: string;
  contractType: string;
  contractBody: string;
}) => {
  useAIStreamsStore().hasTimedOut = false;
  streaming.value = true;
  showIssueQuestionsModal.value = false;
  role.value = params.role;
  contractType.value = params.contractType;
  contractBody.value = params.contractBody;
  issuesStore.clearIssues();
  clausesStore.clearClauses();
  const updatedPlaybookInstance: PlaybookInstance = {
    ...playbookInstance.value,
    issuesGenerated: false
  };
  sidebarStore.updatePlaybookInstance(updatedPlaybookInstance);

  generateSplitClauses(contractBody.value);
};

// Function to assign refs dynamically
const setEntryElement = (el: HTMLElement, id: string | number) => {
  if (el) {
    entryElements[`entry-${id.toString()}`] = el;
  }
};

// Example of accessing a ref
const getEntryElement = (id: string | number) => {
  return entryElements[`entry-${id.toString()}`];
};

const jumpToEntry = () => {
  if (props.jumpToEntryId) {
    nextTick(() => {
      const entryElement = getEntryElement(props.jumpToEntryId);
      if (entryElement) {
        console.log("Scrolling to entry " + props.jumpToEntryId);
        VueScrollTo.scrollTo(entryElement, {
          offset: -100
        });

        const summary = entryElement.querySelector("summary") as HTMLElement;
        if (summary) {
          // Add original background color to a data attribute
          summary.classList.add("highlight");
          setTimeout(() => {
            summary.classList.remove("highlight");
          }, 3000);
        }
      }
    });
  }
};

const loadEntryData = async () => {
  if (playbookInstance.value) {
    await ApiService.getPlaybookInstance(playbookInstance.value.id, true).then(
      ({ data }) => {
        sidebarStore.setPlaybookInstance(data.playbookInstance);
      }
    );
  }
};

const trackGenerateAIIssues = () => {
  if (!playbook.value) {
    return;
  }
  const interaction = {
    action: "AI - generate issues",
    playbookId: playbook.value.id
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};

const tryLoadPlaybookInstance = async () => {
  await loadPlaybookInstance();
  if (playbookInstance.value) {
    console.log("Playbook instance: ", playbookInstance.value);
    playbookInstance.value.wordDocId = docIdToSearch.value;
    await loadEntryData();
    jumpToEntry();
    savePlaybookInstance(); // Save wordDocId and filepath
  } else {
    router.push("/sidebar/playbooks");
  }
};

const tryToFindClauses = async () => {
  trackGenerateAIIssues();
  const updatedPlaybookInstance: PlaybookInstance = {
    ...playbookInstance.value,
    issuesGenerated: false
  };
  sidebarStore.updatePlaybookInstance(updatedPlaybookInstance);
  findClauses(splitClauses.value, fullPlaybookString.value, definitions.value);
};

const saveOrder = () => {
  entries.value.forEach((entry: Entry | NewEntry, index) => {
    entry.position = index;
  });
  ApiService.updateEntriesOrder(entries.value).then(() => {
    reordering.value = false;
  });
};

const savePlaybookInstance = () => {
  if (playbookInstance.value) {
    // eslint-disable-next-line
    const includedTagIds = filterTags.value.map((tag: Tag) => tag.id!);
    // eslint-disable-next-line
    const updatedPlaybookInstance = {
      id: playbookInstance.value.id,
      filePath: null as null | string,
      includedTagIds,
      wordDocId: playbookInstance.value.wordDocId
    };
    if (filePath.value) {
      updatedPlaybookInstance.filePath = filePath.value;
    }
    ApiService.updatePlaybookInstanceTags(updatedPlaybookInstance);
  }
};

const setAddingEntry = (position: number) => {
  if (!playbook.value) {
    return;
  }

  if (isPlaybookLite.value) {
    editMode.value = true;
    const newEntry = {
      playbookId: playbook.value.id,
      position
    };
    sidebarStore.createEntry(newEntry as NewEntry);
  } else {
    sidebarStore.setAddingEntry(position);
  }
};
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  @apply bg-gray-100;
}

.ordering {
  @apply cursor-move;
}

.expanding-button {
  transition: all 0.1s ease-in-out;
}

.expanding-button span {
  width: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: width 0.1s ease-in-out;
  display: inline-block;
}

.expanding-button:hover span {
  width: 100px; /* Choose a width that accommodates your text */
}

.expanded span {
  width: 100px;
}

:deep(summary.highlight) {
  background-color: yellow;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.generating-ai {
  @apply pt-2 pb-1 px-2;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size:
      50% 50%,
      50% 50%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }

  :deep(.ProseMirror) {
    @apply m-1 bg-transparent;
  }
}
</style>
