<template>
  <div class="min-h-full">
    <NavSideBar v-if="showNavBar && user">
      <main class="py-6 lg:py-10 lg:pl-72">
        <BaseAlert class="-mt-10" />
        <div class="px-4 sm:px-6 lg:px-8">
          <router-view :key="$route.fullPath" />
          <BaseFooter v-if="showFooter" />
        </div>
      </main>
    </NavSideBar>
    <main v-else class="mx-auto max-w-7xl p-5 pb-12 sm:px-6 lg:px-8">
      <BaseAlert />
      <router-view :key="$route.fullPath" />
      <BaseFooter v-if="showFooter" />
    </main>

    <div id="bottom-of-body"></div>
    <ReloadAppPrompt />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import BaseAlert from "@/components/BaseAlert.vue";
import BaseFooter from "@/components/base/BaseFooter.vue";
import NavSideBar from "@/components/NavSideBar.vue";
import ReloadAppPrompt from "@/components/ReloadAppPrompt.vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";

const authorisationStore = useAuthorisationStore();
const user = computed(() => authorisationStore.user);

defineProps({
  showFooter: {
    type: Boolean,
    default: false
  },
  showNavBar: {
    type: Boolean,
    default: true
  }
});
</script>
