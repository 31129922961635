const supportsLocalStorage = () => {
  try {
    if ("localStorage" in window && window["localStorage"] !== null) {
      localStorage.setItem("testitem", "true");
      localStorage.removeItem("testitem");
      return true;
    }
  } catch (e) {
    return false;
  }
};

export { supportsLocalStorage };
