import { Ref, toValue } from "vue";

const templateClausePresentLanguage = `
[Keep this next bit, do not submit the template clause as part of your output]<template-wording> {{ OUTPUT TEMPLATE CLAUSE }} </template-wording>\n

`;

const templateClauseNullLanguage = `
<template-wording>[ please come up with a {{ ROLE }} friendly template clause for {{ ENTRY TOPIC }}, ensure the clause covers all elements typically included for this topic, as well as any context we may have provided you ] </template-wording>
`;

const templateClauseLogicLanguage = (
  templateClause: string | null | undefined
) => {
  if (!templateClause || templateClause.length == 0) {
    return templateClauseNullLanguage;
  } else {
    return templateClausePresentLanguage;
  }
};

const replaceVariables = (
  prompt: string | Ref<string>,
  aiVariables: Record<string, string | Ref<string>>
) => {
  let result = toValue(prompt) ?? "";

  for (const [key, value] of Object.entries(aiVariables)) {
    // Convert camelCase to uppercase words separated by spaces
    const formattedKey = toValue(key)
      .replace(/([A-Z])/g, " $1")
      .trim()
      .toUpperCase();

    // Replace the placeholder in the prompt with the value
    const pattern = new RegExp(
      `{{\\s*${formattedKey.replace(/\s+/g, "\\s+")}\\s*}}`,
      "gi"
    );
    result = result.replace(pattern, toValue(value) as string);
  }
  return result;
};

const replaceTemplateClauseLogicLanguage = (
  aiVariables: Record<string, string | Ref<string>>
) => {
  const { templateClause } = toValue(aiVariables);
  const logicLanguage = templateClauseLogicLanguage(toValue(templateClause));
  return replaceVariables(logicLanguage, aiVariables);
};

export const parsePrompt = (
  prompt: string | Ref<string>,
  aiVariables: Record<string, string | Ref<string>>
): string => {
  let result = replaceVariables(prompt, aiVariables);
  const templateClauseLogicLanguage =
    replaceTemplateClauseLogicLanguage(aiVariables);
  result = result.replace(
    new RegExp(`{{\\s?TEMPLATE CLAUSE LOGIC LANGUAGE\\s?}}`, "gi"),
    templateClauseLogicLanguage
  );
  return result;
};
