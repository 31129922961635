<template>
  <template v-if="invitations.length > 0">
    <p class="text-sm text-gray-500">
      The below people have been invited to use LexPlay but haven't logged in
      yet:
    </p>
    <div class="flex flex-col pb-5">
      <div class="sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div class="border-b border-gray-200 shadow sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  ></th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Client
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  >
                    Created on
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  ></th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-for="invitation in invitations" :key="invitation.id">
                  <td
                    class="whitespace-nowrap px-6 py-4 align-top text-sm font-medium text-gray-500"
                  >
                    {{ invitation.name }}
                    <RoleTag :user="invitation" />
                    <span class="block text-xs">{{ invitation.email }}</span>
                  </td>
                  <td
                    class="group flex items-center whitespace-nowrap px-6 py-4 px-3 py-2 align-top text-sm font-medium text-gray-600"
                  >
                    <span
                      v-if="invitation.clientId"
                      :class="[
                        bgColourClass(clientForId(invitation.clientId)),
                        'mr-2 h-2.5 w-2.5 rounded-full'
                      ]"
                      aria-hidden="true"
                    />
                    <span class="truncate">
                      {{ clientName(invitation.clientId) }}
                    </span>
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 align-top text-sm text-gray-500"
                  >
                    {{ dateTimeFormat(invitation.createdAt) }}
                  </td>
                  <td
                    class="whitespace-nowrap px-6 py-4 text-right align-top text-sm font-medium"
                  >
                    <HeadlessMenu
                      as="div"
                      class="relative inline-block text-left"
                    >
                      <div>
                        <MenuButton
                          class="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 focus:ring-offset-gray-100"
                        >
                          <span class="sr-only">Open options</span>
                          <EllipsisVerticalIcon
                            class="h-5 w-5"
                            aria-hidden="true"
                          />
                        </MenuButton>
                      </div>

                      <transition
                        enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95"
                      >
                        <MenuItems
                          class="absolute right-0 z-10 z-30 mt-2 w-56 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          <div class="py-1">
                            <MenuItem v-slot="{ active, close }" as="div">
                              <a
                                @click.prevent="
                                  close();
                                  resendInvitation(invitation);
                                "
                                href="#"
                                :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                ]"
                              >
                                Resend invitation
                              </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active, close }" as="div">
                              <a
                                @click.prevent="
                                  close();
                                  invitationForTemporaryPassword = invitation;
                                "
                                href="#"
                                :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                ]"
                              >
                                Give temporary password
                              </a>
                            </MenuItem>
                            <MenuItem v-slot="{ active }" as="div">
                              <a
                                @click.prevent="invitationToDelete = invitation"
                                href="#"
                                :class="[
                                  active
                                    ? 'bg-gray-100 text-gray-900'
                                    : 'text-gray-700',
                                  'block px-4 py-2 text-sm'
                                ]"
                              >
                                Delete invitation
                              </a>
                            </MenuItem>
                          </div>
                        </MenuItems>
                      </transition>
                    </HeadlessMenu>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </template>
  <div>
    <h3 class="text-lg font-medium leading-6 text-gray-900">
      Invite to LexPlay
    </h3>
    <div class="mt-2 max-w-xl text-sm text-gray-500">
      <p>This sets up a user for them to use the Playbook.</p>
    </div>
    <div class="md:grid md:grid-cols-3 md:gap-1">
      <div class="mt-5 md:col-span-2">
        <div class="col-span-6 sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700">
            Client
          </label>
          <select
            v-model="clientId"
            class="block w-full min-w-0 flex-grow rounded border-gray-300 focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
          >
            <option disabled :value="null">Select a client</option>
            <option
              v-for="client in clients"
              :key="client.id"
              :value="client.id"
            >
              {{ client.name }}
            </option>
          </select>
        </div>
      </div>
      <p
        v-if="client && client.playbooks.length == 0"
        class="text-sm text-indigo-500 md:col-span-2"
      >
        This client hasn't got a playbook setup yet. Create at least one
        playbook before inviting users to this client account.
      </p>
      <div class="mt-5 md:col-span-2">
        <div class="col-span-6 sm:col-span-3">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Name
          </label>
          <input
            type="text"
            name="name"
            v-model="invitationName"
            class="block w-full min-w-0 flex-grow rounded border-gray-300 focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
            placeholder="e.g. Jacinda Ardern"
          />
        </div>
      </div>
      <div class="mt-5 md:col-span-2">
        <div class="col-span-6 sm:col-span-3">
          <label for="email" class="block text-sm font-medium text-gray-700">
            Email
          </label>
          <input
            type="email"
            name="email"
            v-model="invitationEmail"
            class="block w-full min-w-0 flex-grow rounded border-gray-300 focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
            placeholder="e.g. you@example.com"
          />
        </div>
      </div>
      <div class="mt-5 md:col-span-2">
        <div class="col-span-6 sm:col-span-3">
          <label class="block text-sm font-medium text-gray-700"> Role </label>
          <select
            v-model="invitationRole"
            class="block w-full min-w-0 flex-grow rounded border-gray-300 focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
          >
            <option disabled :value="null">Select a role</option>
            <option value="viewer">Viewer</option>
            <option value="editor">Editor</option>
          </select>
        </div>
      </div>
      <p
        v-if="error"
        class="mt-3 text-left text-xs font-medium text-red-600 md:col-span-2"
      >
        {{ error }}
      </p>
      <div class="mt-5 md:col-span-2">
        <BaseButton
          :disabled="!clientId || !invitationEmail"
          @click="createInvitation"
        >
          Send invitation
        </BaseButton>
      </div>
    </div>
  </div>
  <BaseConfirmationModal
    v-if="invitationToDelete"
    title="Delete invitation"
    description="Are you sure you want to delete this invitation?"
    button-text="Delete"
    @confirm="deleteInvitation"
    @close="invitationToDelete = null"
  />
  <TemporaryPasswordModal
    v-if="invitationForTemporaryPassword"
    :invitation="invitationForTemporaryPassword"
    @close="invitationForTemporaryPassword = null"
  />
  <BaseAlertModal
    v-if="invitationSentSuccessfully"
    title="Invitation sent"
    @close="invitationSentSuccessfully = false"
  >
    <div class="space-y-4 text-sm text-gray-500">
      <p>If the user can't see it, get them to check their spam folder.</p>
    </div>
  </BaseAlertModal>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/24/solid";

import ApiService from "@/services/ApiService";
import { dateTimeFormat } from "@/helpers/DateHelpers";

import { Client, Invitation } from "@/types";
import { useAdmin } from "@/components/shared/Admin";
import { useUserStore } from "@/store/UserStore";

import BaseAlertModal from "@/components/base/BaseAlertModal.vue";
import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";
import RoleTag from "@/components/admin/RoleTag.vue";

import TemporaryPasswordModal from "@/components/admin/TemporaryPasswordModal.vue";

export default defineComponent({
  setup() {
    const { client, clientId, clients } = useAdmin();
    const userStore = useUserStore();

    return { client, clientId, clients, dateTimeFormat, userStore };
  },

  components: {
    BaseAlertModal,
    BaseConfirmationModal,
    EllipsisVerticalIcon,
    HeadlessMenu,
    MenuButton,
    MenuItem,
    MenuItems,
    RoleTag,
    TemporaryPasswordModal
  },

  data() {
    return {
      creating: false,
      error: "",
      invitationForTemporaryPassword: null as Invitation | null,
      invitationEmail: "",
      invitationName: "",
      invitationRole: "viewer",
      invitationSentSuccessfully: false,
      invitationToDelete: null as Invitation | null
    };
  },

  computed: {
    invitations(): Invitation[] {
      return this.userStore.invitations;
    }
  },

  methods: {
    bgColourClass(client: Client | null) {
      if (!client || !client.colour) {
        return "bg-indigo-500";
      } else {
        return `bg-${client.colour}-500`;
      }
    },

    clientForId(clientId: number): Client | null {
      return this.clients.find((client) => client.id === clientId) ?? null;
    },

    clientName(clientId: number): string {
      const client = this.clientForId(clientId);
      if (client) {
        return client.name;
      } else {
        return "Unknown";
      }
    },

    createInvitation() {
      if (this.invitationEmail && this.clientId) {
        this.error = "";
        this.creating = true;
        const invitation = {
          clientId: Number(this.clientId),
          email: this.invitationEmail,
          name: this.invitationName,
          role: this.invitationRole
        };
        ApiService.createInvitation(invitation)
          .then(() => {
            this.invitationName = "";
            this.invitationEmail = "";
            this.invitationRole = "viewer";
            this.creating = false;
            this.userStore.loadInvitations();
            this.invitationSentSuccessfully = true;
          })
          .catch(() => {
            this.error =
              "There was an error creating this invitation. Perhaps there's already an account with that email address?";
            this.creating = false;
          });
      }
    },

    deleteInvitation() {
      if (this.invitationToDelete) {
        this.userStore.deleteInvitation(this.invitationToDelete).then(() => {
          this.invitationToDelete = null;
        });
      }
    },

    resendInvitation(inviation: Invitation) {
      ApiService.resendInvitation(inviation?.id).then(() => {
        this.invitationSentSuccessfully = true;
      });
    }
  },

  mounted() {
    this.userStore.loadInvitations();
  }
});
</script>
