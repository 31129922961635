<template>
  <h3 class="text-lg font-medium leading-6 text-gray-900" id="variablesHeading">
    Old skool variables
  </h3>
  <p class="text-sm text-gray-500">
    Here's where we can add custom variables to this playbook. This lets you
    substitute in a variable name with the actual result in the template wording
    and template comments.
  </p>

  <div class="relative mt-4 flex-1">
    <div class="mb-2">
      <BaseInput
        label="Prompt"
        placeholder="e.g. What's the name of this vendor?"
        v-model="prompt"
      />
    </div>
    <div class="mb-6">
      <label class="block text-sm font-medium text-gray-700"> Type </label>
      <div class="relative mt-1">
        <select
          v-model="variableType"
          class="block w-full max-w-lg rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:max-w-xs sm:text-sm"
        >
          <option>Text</option>
          <option>Number</option>
        </select>
      </div>
      <p class="text-sm text-gray-500">
        If the variable type is a number, then when filling it out in the
        sidebar the user will be forced to enter a number.
      </p>
    </div>
    <div class="mb-6" v-if="variableType == 'Text'">
      <label class="block text-sm font-medium text-gray-700"> Options </label>
      <div class="relative mt-1">
        <textarea
          v-model="optionsString"
          class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
          placeholder="Make sure every option gets a new line - or leave blank if you want the user to enter what they want."
        />
      </div>
      <p class="text-sm text-gray-500">
        List options for the user to select from a drop down. Each line will
        show as an individual option in the dropdown. (Leave this blank if you
        want the user to enter anything they want)
      </p>
    </div>
    <div>
      <BaseInput
        label="Variable identifier"
        placeholder="e.g. [VENDOR NAME]"
        v-model="identifier"
      />
    </div>

    <div class="flex flex-row-reverse">
      <BaseButton @click="saveVariable" buttonStyle="white">
        {{ (id ? "Update" : "Create") + " variable" }}
      </BaseButton>
    </div>

    <h3
      class="mt-4 text-lg font-medium leading-6 text-gray-900"
      v-if="oldSkoolVariables.length > 0"
    >
      {{ `Variables for ${playbook.name}` }}
    </h3>
    <table v-if="reordering" class="mt-4 min-w-full table-auto">
      <Draggable
        :list="oldSkoolVariables"
        item-key="position"
        class="divide-y divide-gray-200 bg-white"
        animation="200"
        ghost-class="ghost"
        tag="tbody"
        @start="drag = true"
        @end="drag = false"
      >
        <template #item="{ element }">
          <tr
            class="ordering bg-white align-top"
            :class="{
              'hover:bg-gray-50': !drag
            }"
          >
            <td class="py-2 text-sm font-medium text-gray-800">
              <Bars4Icon class="mr-4 inline h-5 w-5 align-bottom" />
              {{ element.prompt }}
            </td>
          </tr>
        </template>
      </Draggable>
    </table>
    <div
      v-else
      v-for="variable in oldSkoolVariables"
      :key="variable.id"
      class="hover-trigger my-2 divide-gray-200 text-sm font-medium text-gray-800"
    >
      {{ variable.prompt }}
      <span
        v-if="variable.variableType == 'Number'"
        class="text-xs italic text-gray-900"
      >
        (number)
      </span>
      <br />
      <span class="font-light">{{ variable.identifier }}</span>
      <span>
        <BaseButton
          @click="editVariable(variable)"
          size="small"
          buttonStyle="link"
        >
          Edit
        </BaseButton>
        <BaseButton
          @click="deleteVariable(variable)"
          size="small"
          buttonStyle="link"
        >
          Delete
        </BaseButton>
      </span>
      <div class="font-light">{{ (variable.options ?? []).join(" | ") }}</div>
    </div>
    <div v-if="oldSkoolVariables.length > 1" class="flex flex-row-reverse">
      <BaseButton v-if="reordering" @click="saveOrder"> Save order </BaseButton>

      <BaseButton v-else @click="reordering = !reordering" buttonStyle="white">
        Order variables
      </BaseButton>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import Draggable from "vuedraggable";
import VueScrollTo from "vue-scrollto";
import { Bars4Icon } from "@heroicons/vue/24/outline";
import invariant from "tiny-invariant";
import { NewOldSkoolVariable, Playbook, OldSkoolVariable } from "@/types";
import { useAdminStore } from "@/store/AdminStore";
import ApiService from "@/services/ApiService";

export default defineComponent({
  setup() {
    const adminStore = useAdminStore();

    return {
      adminStore
    };
  },

  components: { Draggable, Bars4Icon },

  props: {
    playbook: {
      required: true,
      type: Object as PropType<Playbook>
    }
  },

  data() {
    return {
      drag: false,
      identifier: "",
      id: undefined as number | undefined,
      optionsString: "",
      prompt: "",
      reordering: false,
      oldSkoolVariables: [] as OldSkoolVariable[],
      variableType: "Text"
    };
  },

  watch: {
    playbook: {
      immediate: true,
      handler(playbook: Playbook) {
        this.oldSkoolVariables = playbook.oldSkoolVariables;
      }
    }
  },

  methods: {
    createVariable(options: string[]) {
      const variable: NewOldSkoolVariable = {
        playbookId: this.playbook.id,
        identifier: this.identifier,
        options,
        prompt: this.prompt,
        variableType: this.variableType as "Text" | "Number",
        position: this.playbook.oldSkoolVariables.length
      };

      this.adminStore
        .addOldSkoolVariable({
          playbook: this.playbook,
          variable
        })
        .then((variables) => {
          this.oldSkoolVariables = variables;
        });
    },

    deleteVariable(variable: OldSkoolVariable): void {
      this.adminStore.removeOldSkoolVariable({
        playbook: this.playbook,
        variable
      });
    },

    editVariable(variable: OldSkoolVariable): void {
      this.optionsString = (variable.options ?? []).join("\n");
      this.identifier = variable.identifier;
      this.prompt = variable.prompt;
      this.variableType = variable.variableType;
      this.id = variable.id;

      VueScrollTo.scrollTo("#variablesHeading");
    },

    resetForm() {
      this.optionsString = "";
      this.identifier = "";
      this.prompt = "";
      this.variableType = "Text";
      this.id = undefined;
    },

    saveOrder() {
      this.oldSkoolVariables.forEach((variable, index) => {
        variable.position = index;
      });
      ApiService.updateOldSkoolVariablesOrder(this.oldSkoolVariables).then(
        () => {
          this.reordering = false;
        }
      );
    },

    saveVariable(): void {
      let options: string[] = [];
      if (this.optionsString && this.optionsString.length > 0) {
        options = this.optionsString.split("\n");
      }

      const newRecord = !this.id;
      if (newRecord) {
        this.createVariable(options);
      } else {
        this.updateVariable(options);
      }
      this.resetForm();
    },

    updateVariable(options: string[]) {
      invariant(this.id, "Variable must have an id");

      const variable: OldSkoolVariable = {
        playbookId: this.playbook.id,
        identifier: this.identifier,
        id: this.id,
        options,
        prompt: this.prompt,
        variableType: this.variableType as "Text" | "Number"
      };

      this.adminStore
        .updateOldSkoolVariable({
          playbook: this.playbook,
          variable
        })
        .then((variables) => {
          this.oldSkoolVariables = variables;
        });
    }
  }
});
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  @apply bg-gray-100;
}

.ordering {
  @apply cursor-move;
}
</style>
