<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="text-center sm:mx-auto sm:w-full sm:max-w-[400px]">
      <BaseTitle>Logging out...</BaseTitle>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";

import { useRouter } from "vue-router";

import { useAlertStore } from "@/store/AlertStore";
import AuthService from "@/services/AuthService";

const router = useRouter();

const signOut = async () => {
  try {
    const authService = new AuthService();
    await authService.signOut();
    const alertStore = useAlertStore();
    alertStore.alert = {
      status: "success",
      title: "Signed out successfully.",
      message: "We've signed you out of LexPlay."
    };
    router.push("/login");
  } finally {
    router.push("/login");
  }
};

onMounted(() => {
  signOut();
});
</script>
