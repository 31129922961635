<template>
  <div v-if="playbook" class="divide-y-gray-200 max-w-lg space-y-8 divide-y">
    <div class="pt-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        General settings
      </h3>
      <p class="max-w-2xl text-sm text-gray-500">
        These are some general settings for this playbook.
      </p>

      <div class="pt-6">
        <BaseInput
          v-model="playbook.name"
          label="Name"
          placeholder="i.e. Master playbook"
        />
      </div>
      <div class="pt-6">
        <BaseSelect
          v-model="playbook.playbookType"
          label="Playbook type"
          placeholder="What type of playbook is this?"
          :options="['Universal', 'Third party paper', 'Clause bank']"
        />
      </div>
      <div class="mt-5 flex flex-row-reverse sm:mt-4">
        <BaseButton @click="savePlaybook" :disabled="saved" buttonStyle="white">
          Save general settings
        </BaseButton>
        <transition
          enter-active-class="transition ease-out duration-100 transform"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition ease-in duration-1000 transform"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <span
            v-show="saved"
            class="mt-3 inline-flex justify-center pt-1.5 text-indigo-600"
          >
            Saved!
          </span>
        </transition>
      </div>
    </div>
    <div class="pt-6">
      <TagsForm v-if="playbook" :playbook="playbook" />
    </div>
    <div class="pt-6">
      <OldSkoolVariablesForm v-if="playbook" :playbook="playbook" />
    </div>
    <div>
      <div class="mt-8 overflow-hidden bg-gray-50 px-4 py-6">
        <h3 class="text-lg font-medium leading-6 text-gray-900">Danger zone</h3>
        <div class="relative mt-4 flex flex-row-reverse">
          <BaseButton
            @click="invitationToDelete = true"
            buttonStyle="red"
            size="xlarge"
          >
            Delete playbook
          </BaseButton>
          <BaseConfirmationModal
            v-if="invitationToDelete"
            title="Delete playbook"
            :description="`Are you sure you want to delete the playbook '${playbook.name}'?`"
            button-text="Delete"
            @confirm="deletePlaybook"
            @close="invitationToDelete = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAdmin } from "@/components/shared/Admin";
import ApiService from "@/services/ApiService";

import TagsForm from "@/components/admin/TagsForm.vue";
import OldSkoolVariablesForm from "@/components/admin/OldSkoolVariablesForm.vue";
import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";

export default defineComponent({
  setup() {
    const { playbook } = useAdmin();
    return {
      playbook
    };
  },

  components: {
    BaseConfirmationModal,
    OldSkoolVariablesForm,
    TagsForm
  },

  data() {
    return {
      invitationToDelete: false,
      saved: false
    };
  },

  methods: {
    deletePlaybook() {
      if (this.playbook) {
        ApiService.destroyPlaybook(this.playbook).then(() => {
          window.location.href = "/admin/playbooks";
        });
      }
    },

    savePlaybook() {
      if (this.playbook) {
        ApiService.updatePlaybook(this.playbook).then(() => {
          this.saved = true;
          setTimeout(() => {
            this.saved = false;
          }, 1000);
        });
      }
    }
  }
});
</script>

<style scoped></style>
