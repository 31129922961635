<template>
  <node-view-wrapper>
    <li class="space-x-2 text-left">
      <BaseButton size="small" buttonStyle="white" @action="sendRefinement">
        <node-view-content as="span"></node-view-content>
      </BaseButton>
    </li>
  </node-view-wrapper>
</template>

<script setup lang="ts">
import { NodeViewContent, nodeViewProps, NodeViewWrapper } from "@tiptap/vue-3";

const props = defineProps(nodeViewProps);

const sendRefinement = () => {
  const refineOption = props.node.textContent;
  const extension = props.extension;
  extension.options.onRefineOptionClick(refineOption);
};
</script>
