<template>
  <node-view-wrapper
    data-type="details"
    class="details"
    :class="{ 'is-open': open }"
    v-bind="{ open: open ? '' : undefined }"
  >
    <button @click="toggle"></button>

    <node-view-content ref="innerDiv" />
  </node-view-wrapper>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { nodeViewProps, NodeViewContent, NodeViewWrapper } from "@tiptap/vue-3";

const innerDiv = ref(null);

const props = defineProps(nodeViewProps);
const open = computed({
  get: () => props.node.attrs.open,
  set: (newValue) => {
    props.updateAttributes({
      open: newValue
    });
  }
});

const toggle = () => {
  open.value = !open.value;
};
</script>
