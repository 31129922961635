import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import { Plugin, PluginKey } from "@tiptap/pm/state";

import TipTapTemplateComment from "@/components/base/TipTap/TipTapTemplateComment.vue";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    templateComment: {
      /**
       * Toggle a blockquote node
       */
      toggleTemplateComment: () => ReturnType;
    };
  }
}

export default Node.create({
  name: "templateComment",
  group: "block",
  content: "block+",
  isolating: true,

  parseHTML() {
    return [
      {
        tag: "template-comment"
      }
    ];
  },

  addCommands() {
    return {
      toggleTemplateComment:
        () =>
        ({ commands }) => {
          return commands.toggleWrap(this.name);
        }
    };
  },

  renderHTML({ HTMLAttributes }) {
    return ["template-comment", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(TipTapTemplateComment);
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("templateCommentPasteHandler"),
        props: {
          handlePaste: (view, event) => {
            if (!event.clipboardData) {
              return false;
            }
            // Make the paste handler only paste plain text
            if (this.editor.isActive(this.type.name)) {
              const text = event.clipboardData.getData("text/plain");
              const { tr } = view.state;

              tr.insertText(text);
              tr.setMeta("paste", true);
              view.dispatch(tr);
              return true;
            }
          }
        }
      })
    ];
  },

  addKeyboardShortcuts() {
    return {
      ArrowDown: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from } = selection;
        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        let depth = $from.depth;
        while (depth > 0) {
          const node = $from.node(depth);
          if (node.type.name === this.name) {
            // Additional condition: check if there are nodes underneath the current one
            const nextNode = $from.nodeAfter;
            if (!nextNode) {
              return editor.commands.exitBlock();
            }
          }
          depth -= 1;
        }

        return false;
      }
    };
  }
});
