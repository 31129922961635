<template>
  <BaseTitle text="Users" />

  <p class="my-6 max-w-2xl text-sm text-gray-600">
    <strong>Admin users</strong> have access to change playbooks entries, add
    new playbooks, and change user permissions. (basically Lexoo employees)
    <strong>Editor users</strong> can edit entries and add new entries
    <strong>Viewer users</strong> can only view playbooks
  </p>

  <p class="my-6 max-w-2xl text-sm text-gray-600">
    <strong>Suspended users</strong> can no longer log in.
  </p>

  <p v-if="error.length" class="-mt-4 text-sm text-red-600">{{ error }}</p>
  <div class="my-6 border-b border-gray-200 pb-5 sm:pb-0">
    <div class="mt-3 sm:mt-4">
      <div class="hidden sm:block">
        <nav class="-mb-px flex space-x-8">
          <a
            href="#"
            @click.prevent="currentTab = 'active'"
            :class="{ selected: currentTab === 'active' }"
            class="tab"
          >
            Active
          </a>

          <a
            href="#"
            @click.prevent="currentTab = 'invitations'"
            :class="{ selected: currentTab === 'invitations' }"
            class="tab"
          >
            Invited
          </a>

          <a
            href="#"
            @click.prevent="currentTab = 'suspended'"
            :class="{ selected: currentTab === 'suspended' }"
            class="tab"
          >
            Suspended
          </a>
        </nav>
      </div>
    </div>
  </div>
  <InteractionsModal
    v-if="interactionsUser"
    :show="interactionsUser !== null"
    @close="interactionsUser = null"
    :user="interactionsUser"
  />
  <UsersTable
    v-if="currentTab !== 'invitations'"
    :users="filteredUsers"
    @showInteractions="interactionsUser = $event"
  />
  <InvitationsTable v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import { User } from "@/types";
import { useUserStore } from "@/store/UserStore";

import BaseTitle from "@/components/BaseTitle.vue";
import InvitationsTable from "@/components/admin/InvitationsTable.vue";
import InteractionsModal from "@/components/admin/InteractionsModal.vue";
import UsersTable from "@/components/admin/UsersTable.vue";

export default defineComponent({
  setup() {
    const userStore = useUserStore();

    return {
      userStore
    };
  },

  components: {
    BaseTitle,
    InteractionsModal,
    InvitationsTable,
    UsersTable
  },

  data() {
    return {
      creating: false,
      createEmail: "",
      currentTab: "active" as "active" | "suspended" | "invitations",
      error: "",
      interactionsUser: null as User | null
    };
  },

  computed: {
    filteredUsers(): User[] {
      if (this.currentTab === "suspended") {
        return this.users.filter((user) => user.status !== "active");
      } else if (this.currentTab === "active") {
        return this.users.filter((user) => user.status === "active");
      } else {
        return [];
      }
    },

    users(): User[] {
      return this.userStore.users;
    }
  },

  mounted() {
    this.userStore.loadUsers();
  }
});
</script>

<style scoped lang="scss">
.tab.selected {
  @apply border-lexoo text-lexoo;
}

.tab {
  @apply whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium;
}

.tab:not(.selected) {
  @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700;
}
</style>
