<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8 lg:max-w-2xl">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2
              class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
            >
              {{ headingText }}
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
              Please sign in to continue.
            </p>
          </div>
        </div>
      </div>
    </div>
    <LoginPanel v-if="confirmed" :initialEmail="email" />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import ApiService from "@/services/ApiService";
import LoginPanel from "@/components/signin/LoginPanel.vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";

const confirmed = ref(false);
const email = ref("");

const route = useRoute();
const token = computed((): string => {
  const tokenParam = route.params.token;
  if (Array.isArray(tokenParam)) {
    return tokenParam[0];
  }
  return tokenParam;
});

const headingText = computed(() => {
  if (confirmed.value) {
    return "Email confirmed";
  } else {
    return "Confirming email...";
  }
});

const validateToken = () => {
  ApiService.validateConfirmationToken(token.value).then(({ data }) => {
    email.value = data.email;
    confirmed.value = true;
    const authorisationStore = useAuthorisationStore();
    authorisationStore.redirect = "/install-add-in-instructions";
  });
};

onMounted(() => {
  if (token.value) {
    validateToken();
  }
});
</script>
