import { defineStore } from "pinia";
import replaceAll from "string.prototype.replaceall";
import {
  Approver,
  CheckedStatus,
  Client,
  DocumentDefinition,
  Entry,
  Fallback,
  FallbackScenario,
  NewEntry,
  NewTag,
  Playbook,
  PlaybookInstance,
  Tag
} from "@/types";
import ApiService from "@/services/ApiService";
import { useStorage } from "@vueuse/core";

import {
  generateLexPlayIdInDocument,
  removeLexPlayIdFromDocument
} from "@/helpers/WordHelpers";

import { getVariableIdentifiersFromEntries } from "@/helpers/VariableHelpers";

export const useSidebarStore = defineStore("SidebarStore", {
  state: () => ({
    approvers: [] as Approver[],
    clientId: useStorage<number | null>(
      "sidebarClientId",
      null,
      sessionStorage
    ),
    clients: [] as Client[],
    clientsLoading: false,
    clientsLoaded: false,
    definitions: [] as { title: string; description: string }[],
    editMode: false,
    entries: [] as Entry[],
    entriesLoading: true,
    filterText: "",
    peekClientId: null as number | null,
    peekEntries: [] as Entry[],
    peekPlaybookId: null as number | null,

    entryOpen: new Set() as Set<number>,
    entryFallbacksOpen: new Set() as Set<number>,
    editedEntryIds: new Set() as Set<number>,
    fallbackOpen: new Set() as Set<number>,
    fallbackScenarioOpen: new Set() as Set<number>,
    matterIdentifier: useStorage<string | null>("matterIdentifier", null),
    reordering: false,
    playbookId: useStorage<number | null>(
      "sidebarPlaybookId",
      null,
      sessionStorage
    ),
    playbookInstance: null as PlaybookInstance | null,
    playbookInstanceId: useStorage<number | null>(
      "sidebarPlaybookInstanceId",
      null,
      sessionStorage
    ),

    withinWord: false
  }),

  actions: {
    addPlaybookToClient(client: Client, playbook: Playbook) {
      client.playbooks.push(playbook);
    },

    addTag({
      playbook,
      tag
    }: {
      playbook: Playbook;
      tag: NewTag;
    }): Promise<Tag[]> {
      return new Promise((resolve) => {
        ApiService.createTag(tag).then(({ data }) => {
          playbook.tags.push(data.tag);
          this.updatePlaybook(playbook);
          resolve(playbook.tags);
        });
      });
    },

    clearCheckboxes() {
      this.entries.forEach((entry) => {
        this.setCheckedStatus(entry as Entry, "unchecked");
      });
    },

    clearEntries() {
      this.entries = [];
    },

    clearPlaybookInstance() {
      this.playbookInstance = null;
      this.playbookInstanceId = null;
    },

    getEntry(entryId: number): Entry {
      return this.entries.find((e) => e.id === entryId) as Entry;
    },

    loadApprovers(): Promise<void> {
      if (!this.clientId) {
        return new Promise((resolve) => {
          resolve();
        });
      }
      return ApiService.getApprovers(this.clientId).then(({ data }) => {
        this.approvers = data.approvers;
      });
    },

    loadClients(): Promise<void> {
      if (this.clientsLoaded) {
        return new Promise((resolve) => {
          resolve();
        });
      }
      return new Promise((resolve) => {
        this.clientsLoading = true;
        ApiService.getClients().then(({ data }) => {
          this.clients = data.clients;
          this.clientsLoading = false;
          this.clientsLoaded = true;
          resolve();
        });
      });
    },

    setPlaybookInstance(playbookInstance: PlaybookInstance) {
      if (
        this.playbookInstance &&
        this.playbookInstance.id &&
        this.playbookInstance.id.toString() != playbookInstance.id.toString()
      ) {
        this.matterIdentifier = null;
      }
      this.playbookInstance = playbookInstance;
      this.playbookInstanceId = playbookInstance.id;

      this.entries = playbookInstance.entries;
      this.entriesLoading = false;

      this.loadApprovers();
    },

    setAddingEntry(position: number) {
      if (!this.playbookId || !this.playbook) {
        if (!this.client) {
          this.clientId = this.clients[0].id;
        }
        // By setting this.clientId in the previous line should mean that
        // this.client should now be set
        if (this.client) {
          this.playbookId = this.client.playbooks[0].id;
        }
      }
      const newFallbackScenario = {
        answer: "",
        fallbacks: [{}]
      };
      const entry = {
        entryDescription: "",
        fallbackScenarios: [newFallbackScenario as FallbackScenario],
        playbookId: this.playbookId,
        position,
        tags: [],
        templateWording: "",
        templateComment: "",
        description: "",
        thirdPartyDescription: "",
        thirdPartyTemplateWording: "",
        thirdPartyTemplateComment: ""
      };

      if (position == 0) {
        this.entries.unshift(entry);
      } else {
        this.entries.push(entry);
      }
    },

    setEntriesLoading() {
      this.entriesLoading = true;
    },

    setEntriesLoaded() {
      this.entriesLoading = false;
    },

    isEntryOpen(entry: Entry | null): boolean {
      if (entry && entry.id) {
        return this.entryOpen.has(entry.id);
      }
      return false;
    },

    setEntryOpen(entry: Entry, open: boolean): void {
      if (entry.id) {
        if (open) {
          this.entryOpen.add(entry.id);
        } else {
          this.entryOpen.delete(entry.id);
        }
      }
    },

    // This returns true if the 'fallbacks' section is open for a given entry
    isEntryFallbacksOpen(entry: Entry | null): boolean {
      if (entry && entry.id) {
        return this.entryFallbacksOpen.has(entry.id);
      }
      return false;
    },

    setEntryFallbacksOpen(entry: Entry, open: boolean): void {
      if (entry.id) {
        if (open) {
          this.entryFallbacksOpen.add(entry.id);
        } else {
          this.entryFallbacksOpen.delete(entry.id);
        }
      }
    },

    setDefinitions(definitions: DocumentDefinition[]) {
      this.definitions = definitions;
    },

    // This returns true if the 'fallback' section is open for a given fallback
    isFallbackOpen(fallback: Fallback | null): boolean {
      if (fallback && fallback.id) {
        return this.fallbackOpen.has(fallback.id);
      }
      return true;
    },

    setFallbackOpen(fallback: Fallback, open: boolean): void {
      if (fallback.id) {
        if (open) {
          this.fallbackOpen.add(fallback.id);
        } else {
          this.fallbackOpen.delete(fallback.id);
        }
      }
    },

    // This returns true if the 'scenario' section is open for a given entry
    isFallbackScenarioOpen(fallbackScenario: FallbackScenario | null): boolean {
      if (fallbackScenario && fallbackScenario.id) {
        return this.fallbackScenarioOpen.has(fallbackScenario.id);
      }
      return true;
    },

    setFallbackScenarioOpen(
      fallbackScenario: FallbackScenario,
      open: boolean
    ): void {
      if (fallbackScenario.id) {
        if (open) {
          this.fallbackScenarioOpen.add(fallbackScenario.id);
        } else {
          this.fallbackScenarioOpen.delete(fallbackScenario.id);
        }
      }
    },

    entryEdited(entry: Entry): boolean {
      if (entry.id) {
        return this.editedEntryIds.has(entry.id);
      }
      return true;
    },

    clearEntryEdited(entry: Entry) {
      if (entry.id) {
        this.editedEntryIds.delete(entry.id);
      }
    },

    decrementNumberOfEntries() {
      if (this.playbook && this.client) {
        const playbookToUpdate: Playbook = { ...this.playbook };
        if (
          !playbookToUpdate.numberOfEntries ||
          playbookToUpdate.numberOfEntries < 1
        ) {
          playbookToUpdate.numberOfEntries = 0;
        } else {
          playbookToUpdate.numberOfEntries -= 1;
        }

        const playbookIndex = this.client.playbooks.findIndex(
          (pb) => pb.id === playbookToUpdate.id
        );
        if (playbookIndex !== -1) {
          this.client.playbooks.splice(playbookIndex, 1, playbookToUpdate);
        }
      }
    },

    incrementNumberOfEntries() {
      if (this.playbook && this.client) {
        const playbookToUpdate: Playbook = { ...this.playbook };
        if (!playbookToUpdate.numberOfEntries) {
          playbookToUpdate.numberOfEntries = 1;
        } else {
          playbookToUpdate.numberOfEntries += 1;
        }

        const playbookIndex = this.client.playbooks.findIndex(
          (pb) => pb.id === playbookToUpdate.id
        );
        if (playbookIndex !== -1) {
          this.client.playbooks.splice(playbookIndex, 1, playbookToUpdate);
        }
      }
    },

    setEntryEdited(entry: Entry) {
      if (entry.id) {
        this.editedEntryIds.add(entry.id);
      }
    },

    updatePlaybookInstance(playbookInstance: PlaybookInstance) {
      this.setPlaybookInstance(playbookInstance);
      ApiService.updatePlaybookInstance(playbookInstance);
    },

    async createEntry(newEntry: NewEntry) {
      return ApiService.createEntry(newEntry).then(
        ({ data }: { data: { entry: Entry } }) => {
          this.removeEntry(newEntry);
          if (data.entry.position === 0) {
            this.entries.unshift(data.entry);
          } else {
            this.entries.push(data.entry);
          }
          this.incrementNumberOfEntries();
          this.setEntryOpen(data.entry, true);
        }
      );
    },

    async deleteEntry(entry: Entry) {
      this.removeEntry(entry);
      ApiService.destroyEntry(entry);
    },

    removeEntry(entry: Entry | NewEntry) {
      const numberOfEntries = this.entries.length;
      this.entries = this.entries.filter((el) => {
        // If the element has an id, match with entry id.
        if ("id" in el) {
          // Only filter out if entry is also an Entry and has the same id
          return !("id" in entry && el.id === (entry as Entry).id);
        }
        // If the element doesn't have an id, remove only if entry is a NewEntry
        else {
          return "id" in entry;
        }
      });
      if (numberOfEntries > this.entries.length) {
        this.decrementNumberOfEntries();
      }
    },

    removePlaybook(playbook: Playbook) {
      console.log("Removing");
      const client = this.clients.find((client) =>
        client.playbooks.some((pb) => pb.id === playbook.id)
      );

      if (client) {
        client.playbooks = client.playbooks.filter(
          (pb) => pb.id !== playbook.id
        );
      }
    },

    replaceEntry(entry: Entry | NewEntry) {
      // Process only if the entry is an Entry (i.e., it has an id)
      if ("id" in entry) {
        const index = this.entries.findIndex(
          (el) => "id" in el && el.id == entry.id
        );
        if (index !== -1) {
          this.entries.splice(index, 1, entry);
        }

        const peekIndex = this.peekEntries.findIndex(
          (el) => "id" in el && el.id == entry.id
        );
        if (peekIndex !== -1) {
          this.peekEntries.splice(peekIndex, 1, entry);
        }
      }
      // If the entry is a NewEntry
      else {
        const newEntryIndex = this.entries.findIndex((el) => !("id" in el));
        if (newEntryIndex !== -1) {
          this.entries.splice(newEntryIndex, 1, entry);
        }

        const peekNewEntryIndex = this.peekEntries.findIndex(
          (el) => !("id" in el)
        );
        if (peekNewEntryIndex !== -1) {
          this.peekEntries.splice(peekNewEntryIndex, 1, entry);
        }
      }
    },

    saveFallbackScenarios(entry: Entry, fallbackScenarios: FallbackScenario[]) {
      ApiService.saveFallbackScenarios(entry.id, fallbackScenarios).then(
        ({ data }: { data: { fallbackScenarios: FallbackScenario[] } }) => {
          entry.fallbackScenarios = data.fallbackScenarios;
          this.replaceEntry(entry);
        }
      );
    },

    setCheckedStatus(entry: Entry, checkedStatus: CheckedStatus) {
      entry.checkedStatus = checkedStatus;
      this.replaceEntry(entry);

      if (this.playbookInstance && entry.id) {
        ApiService.setCheckedStatusOnEntry(
          (entry as Entry).id,
          this.playbookInstance.id,
          checkedStatus
        );

        // Updating the checked status also sets the lexplay id in the document
        // so we need to update the playbook instance to reflect this
        if (checkedStatus === "checked" && !this.playbookInstance.lexPlayId) {
          this.playbookInstance.lexPlayId = generateLexPlayIdInDocument();
          ApiService.updatePlaybookInstance(this.playbookInstance);
        } else if (
          checkedStatus === "unchecked" &&
          this.playbookInstance.lexPlayId
        ) {
          // If this is the last checked entry, we need to remove the lexplay id
          const numberOfCheckedEntries = this.playbookInstance.entries.filter(
            (entry) =>
              entry.checkedStatus === "checked" ||
              entry.checkedStatus == "flagged" ||
              entry.checkedStatus == "ignored"
          ).length;
          if (numberOfCheckedEntries === 0) {
            this.playbookInstance.lexPlayId = null;
            ApiService.updatePlaybookInstance(this.playbookInstance);
            removeLexPlayIdFromDocument();
          }
        }
      }
    },

    setEntries(entries: Entry[]) {
      this.entries = entries;
    },

    async updateEntry(entry: Entry) {
      ApiService.updateEntry(entry).then(({ data }) => {
        const newEntry = data.entry;
        newEntry.checkedStatus = entry.checkedStatus;
        this.replaceEntry(newEntry);
      });
    },

    updateFallback(entryId: number, scenarioId: number, fallback: Fallback) {
      ApiService.updateFallback(fallback).then(() => {
        const entry = this.entries.find((el) => el.id == entryId);
        if (entry) {
          const fallbackScenario = entry.fallbackScenarios.find(
            (el) => el.id == scenarioId
          );
          if (fallbackScenario) {
            const fallbackIndex = fallbackScenario.fallbacks.findIndex(
              (el) => el.id == fallback.id
            );
            fallbackScenario.fallbacks[fallbackIndex] = fallback;
          }
        }
      });
    },

    updatePlaybook(playbook: Playbook) {
      const client = this.clients.find((client) =>
        client.playbooks.some((pb) => pb.id === playbook.id)
      );

      if (client) {
        const playbookIndex = client.playbooks.findIndex(
          (pb) => pb.id === playbook.id
        );
        if (playbookIndex !== -1) {
          client.playbooks.splice(playbookIndex, 1, playbook);
        }
      }
    },

    updateVariable(identifier: string, value: string) {
      if (this.playbookInstance) {
        this.playbookInstance.variables[identifier] = value;
        ApiService.updatePlaybookInstance(this.playbookInstance);
      }
    },

    updateVariables(variables: { [key: string]: string }) {
      if (this.playbookInstance) {
        // this.playbookInstance.variables[identifier] = value;
        Object.keys(variables).forEach((key) => {
          this.playbookInstance!.variables[key] = variables[key];
        });
        ApiService.updatePlaybookInstance(this.playbookInstance);
      }
    }
  },

  getters: {
    client(): Client | null {
      return this.clients.find((el) => el.id == this.clientId) || null;
    },

    getPlaybookById:
      (state) =>
      (playbookId: number): Playbook | undefined => {
        // Iterate through all clients and their playbooks to find the matching playbook
        for (const client of state.clients) {
          const playbook = client.playbooks.find((pb) => pb.id === playbookId);
          if (playbook) {
            return playbook;
          }
        }
        return undefined;
      },

    getVariableById:
      (state) =>
      (variableId: string): string | null => {
        if (state.playbookInstance) {
          return state.playbookInstance.variables[variableId] ?? null;
        }
        return null;
      },

    lexPlayId(): string | null {
      if (!this.playbookInstance) return null;
      if (!this.playbookInstance.lexPlayId) return null;
      if (this.playbookInstance.lexPlayId.length === 0) return null;
      return this.playbookInstance.lexPlayId;
    },

    notSelfServeClients(): Client[] {
      if (this.clients.length == 1) {
        return this.clients;
      }
      return this.clients.filter((client) => !client.selfServe);
    },

    playbooks(): Playbook[] {
      if (!this.client) return [];
      return this.client.playbooks;
    },

    playbook(): Playbook | null {
      return this.playbooks.find((el) => el.id == this.playbookId) || null;
    },

    textWithVariables() {
      return (text: string | undefined): string => {
        if (!text || !this.playbookInstance) return "";

        const variableIdentifiers = this.variableIdentifiers;
        variableIdentifiers.forEach((key) => {
          let value = this.playbookInstance!.variables[key];
          if (!value || value.length == 0) {
            value = `{ ${key} }`;
          }
          text = replaceAll(text, key, value);
        });
        return text;
      };
    },

    usedTags(): Tag[] {
      const tags = [] as Tag[];
      const tagIds = new Set<number>();
      this.entries.forEach((entry) => {
        (entry.tags || []).forEach((tag) => {
          if (!tagIds.has(tag.id)) {
            tagIds.add(tag.id);
            tags.push(tag);
          }
        });
      });
      return tags.sort((a, b) => a.name.localeCompare(b.name));
    },

    variableIdentifiers(): string[] {
      if (!this.entries) return [];

      const identifiers = getVariableIdentifiersFromEntries(this.entries);
      return identifiers.sort((a, b) => a.localeCompare(b));
    }
  }
});
