import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import AiScratchpadView from "@/views/admin/AiScratchpadView.vue";
import AuthService from "@/services/AuthService";
import AuditTableView from "@/views/admin/AuditTableView.vue";
import ChangePasswordView from "@/views/ChangePasswordView.vue";
import CheckYourEmailView from "@/views/CheckYourEmailView.vue";
import ClientsView from "@/views/admin/ClientsView.vue";
import ClientSettingsView from "@/views/admin/ClientSettingsView.vue";
import ConfirmEmailView from "@/views/ConfirmEmailView.vue";
import DefinitionsView from "@/views/sidebar/DefinitionsView.vue";
import DraftClauseView from "@/views/sidebar/DraftClauseView.vue";
import EntriesAdminView from "@/views/admin/EntriesView.vue";
import EntriesTableView from "@/views/admin/EntriesTableView.vue";
import EscalationsView from "@/views/EscalationsView.vue";
import EscalationResponseView from "@/views/EscalationResponseView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ForgotPasswordSuccessView from "@/views/ForgotPasswordSuccessView.vue";
import GoogleAuthCallback from "@/views/GoogleAuthCallback.vue";
import InstallAddInInstructionsView from "@/views/InstallAddInInstructionsView.vue";
import IssuesView from "@/views/sidebar/IssuesView.vue";
import LoginView from "@/views/LoginView.vue";
import LogoutView from "@/views/LogoutView.vue";
import OktaAuthCallback from "@/views/OktaAuthCallback.vue";
import OktaConfigurationManualView from "@/views/OktaConfigurationManualView.vue";
import PlaybookDownloadView from "@/views/PlaybookDownloadView.vue";
import PlaybookTemplatesTableView from "@/views/admin/PlaybookTemplatesTableView.vue";
import PlaybookSettingsView from "@/views/admin/PlaybookSettingsView.vue";
import PlaybooksView from "@/views/sidebar/PlaybooksView.vue";
import PrivacyPolicyView from "@/views/PrivacyPolicyView.vue";
import ProfileView from "@/views/ProfileView.vue";
import PromptTemplatesView from "@/views/admin/PromptTemplatesView.vue";
import PushbacksView from "@/views/sidebar/PushbacksView.vue";
import SampleTermsFileGroupsView from "@/views/admin/PlaybookBuilder/SampleTermsFileGroupsView.vue";
import SampleTermsFileGroupView from "@/views/admin/PlaybookBuilder/SampleTermsFileGroupView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import SidebarView from "@/views/sidebar/SidebarView.vue";
import SignUpView from "@/views/SignUpView.vue";
import SSOProviderView from "@/views/SSOProviderView.vue";
import SSOLoginView from "@/views/SSOLoginView.vue";
import SupportView from "@/views/SupportView.vue";
import TermsView from "@/views/TermsView.vue";
import TestView from "@/views/TestView.vue";
import UsersAdminView from "@/views/admin/UsersView.vue";
import UsersView from "@/views/UsersView.vue";
import VerifyInvitationView from "@/views/VerifyInvitationView.vue";
import VerifyInvitationSuccessView from "@/views/VerifyInvitationSuccessView.vue";
import WordMockupView from "@/views/WordMockupView.vue";
import { useJumpToEntryStore } from "@/store/JumpToEntryStore";

const routes: Array<RouteRecordRaw> = [
  {
    name: "Escalation response",
    path: "/escalation-response/:identifier",
    component: EscalationResponseView,
    props: true,
    meta: {
      title: "Escalation response",
      public: true,
      showFooter: true
    }
  },

  {
    name: "Install add-in instructions",
    path: "/install-add-in-instructions",
    component: InstallAddInInstructionsView,
    meta: {
      title: "Install Microsoft Word add-in",
      public: true
    }
  },

  {
    name: "Login",
    path: "/login",
    component: LoginView,
    props: (route) => ({
      redirect: route.query.redirect
    }),
    meta: {
      title: "Login",
      public: true
    }
  },

  {
    name: "Logout",
    path: "/logout",
    component: LogoutView,
    meta: {
      title: "Logging out...",
      hideNavBar: true
    }
  },

  {
    name: "SSO Login",
    path: "/sso-login",
    component: SSOLoginView,
    props: (route) => ({
      redirect: route.query.redirect
    }),
    meta: {
      title: "Login",
      public: true
    }
  },

  {
    name: "SSO Provider",
    path: "/sso-provider",
    component: SSOProviderView,
    meta: {
      title: "SSO Provider"
    }
  },

  {
    name: "Google callback",
    path: "/auth/google_oauth2/callback",
    component: GoogleAuthCallback,
    meta: {
      title: "Please wait...",
      public: true,
      hideNavBar: true
    }
  },

  {
    name: "Okta callback",
    path: "/auth/okta/callback",
    component: OktaAuthCallback,
    meta: {
      title: "Please wait...",
      public: true,
      hideNavBar: true
    }
  },

  {
    path: "/reset-password/:token",
    name: "Reset password",
    component: ResetPasswordView,
    meta: {
      title: "Reset password",
      public: true,
      showFooter: true
    },
    props: true
  },

  {
    path: "/change-password",
    name: "Change password",
    component: ChangePasswordView,
    meta: {
      title: "Change password"
    }
  },

  {
    path: "/test",
    component: TestView
  },

  {
    path: "/word-view",
    name: "Word view",
    component: SidebarView,
    meta: {
      title: "Word view"
    },
    props: (route) => {
      let params = {};
      if (route.query.loadFromWordDocumentIfPossible) {
        const loadFromWordDocumentIfPossible =
          route.query.loadFromWordDocumentIfPossible == "true";
        params = { loadFromWordDocumentIfPossible };
      }
      return params;
    }
  },

  {
    path: "/word-mockup",
    name: "Word mockup",
    component: WordMockupView,
    meta: {
      hideNavBar: true,
      title: "Word view"
    }
  },

  {
    path: "/playbook",
    redirect: "/word-view"
  },

  {
    path: "/",
    redirect: () => {
      const subdomain = window.location.hostname.split(".")[0];
      if (subdomain === "escalations") {
        return "/escalations";
      }
      return "/sidebar/playbooks";
    }
  },

  {
    path: "/admin/clients",
    name: "Clients view",
    component: ClientsView,
    meta: {
      title: "Clients",
      adminOnly: true
    }
  },

  // This is a redirect for the comments link
  {
    path: "/admin/clients/:client_id/entries/:entry_id",
    redirect: (to) => {
      const jumpToEntryStore = useJumpToEntryStore();
      jumpToEntryStore.entryId = to.params.entry_id as string;
      jumpToEntryStore.clientId = to.params.client_id as string;
      return {
        path: "/admin/playbooks/entries",
        name: "Entries table"
      };
    }
  },

  {
    path: "/forgot-password",
    name: "Forgot password",
    component: ForgotPasswordView,
    meta: {
      title: "Forgot password",
      public: true
    }
  },

  {
    path: "/profile",
    name: "Profile",
    component: ProfileView,
    meta: {
      title: "Profile"
    }
  },

  {
    path: "/support",
    name: "Support",
    component: SupportView,
    meta: {
      title: "Support"
    }
  },

  {
    path: "/users",
    name: "Users",
    component: UsersView,
    meta: {
      title: "Invite team"
    }
  },

  {
    path: "/playbook-download/:token.:format",
    name: "Playbook download",
    component: PlaybookDownloadView,
    meta: {
      title: "Download playbook",
      public: true,
      hideNavBar: true
    },
    props: true
  },

  {
    path: "/forgot-password-success",
    name: "Forgot password - success",
    component: ForgotPasswordSuccessView,
    meta: {
      title: "Forgot password",
      public: true
    }
  },

  {
    path: "/verify-invitation/:token",
    name: "Verify invitation",
    component: VerifyInvitationView,
    meta: {
      title: "Verify invitation",
      public: true,
      showFooter: true
    },
    props: true
  },

  {
    path: "/verify-invitation-success",
    name: "Verify invitation success",
    component: VerifyInvitationSuccessView,
    meta: {
      title: "Account created",
      public: true,
      showFooter: true
    }
  },

  {
    path: "/terms-and-conditions",
    name: "Terms and Conditions",
    component: TermsView,
    meta: {
      title: "Terms and Conditions",
      public: true,
      showFooter: true,
      hideNavBar: true
    }
  },

  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicyView,
    meta: {
      title: "Privacy Policy",
      public: true,
      showFooter: true,
      hideNavBar: true
    }
  },

  {
    path: "/okta-configuration-manual",
    name: "Okta Configuration Manual",
    component: OktaConfigurationManualView,
    meta: {
      title: "Okta Configuration Manual",
      public: true,
      showFooter: true,
      hideNavBar: true
    }
  },

  {
    path: "/admin/playbooks",
    name: "Playbooks admin",
    component: EntriesAdminView,
    meta: {
      title: "Playbooks admin",
      adminOnly: true
    },
    children: [
      {
        path: "client-settings",
        name: "Client settings",
        component: ClientSettingsView,
        meta: {
          adminOnly: true
        }
      },
      {
        path: "playbook-settings",
        name: "Playbook settings",
        component: PlaybookSettingsView,
        meta: {
          adminOnly: true
        }
      },
      {
        path: "entries",
        name: "Entries table",
        component: EntriesTableView,
        meta: {
          adminOnly: true
        }
      },
      {
        path: "",
        name: "Redirect to entries table",
        redirect: "/admin/playbooks/entries"
      }
    ]
  },
  {
    path: "/admin/playbook-builder",
    name: "SampleTermsFileGroups",
    component: SampleTermsFileGroupsView,
    meta: { title: "Playbook builder", adminOnly: true }
  },

  {
    path: "/admin/playbook-builder/by-group",
    name: "SampleTermsFileGroup",
    component: SampleTermsFileGroupView,
    meta: { title: "Playbook builder" },
    props: (route) => ({
      clientId: route.query.clientId,
      templateFile: route.query.templateFile
    })
  },

  {
    path: "/admin/users",
    name: "Users admin",
    component: UsersAdminView,
    meta: {
      title: "Users admin",
      adminOnly: true
    }
  },

  {
    path: "/admin/playbook-templates",
    name: "Playbook templates table",
    component: PlaybookTemplatesTableView,
    meta: {
      title: "Playbook templates",
      adminOnly: true
    }
  },

  {
    path: "/admin/prompt-templates",
    name: "Prompt templates table",
    component: PromptTemplatesView,
    meta: {
      title: "AI Prompts",
      adminOnly: true
    }
  },

  {
    path: "/admin/ai-scratchpad",
    name: "AI Scratchpad",
    component: AiScratchpadView,
    meta: {
      title: "AI Scratchpad",
      adminOnly: true
    }
  },

  {
    path: "/admin/audit",
    name: "Audit table",
    component: AuditTableView,
    meta: {
      title: "Audit table",
      adminOnly: true
    }
  },

  {
    path: "/sidebar/playbooks",
    children: [
      {
        path: "",
        name: "Playbooks view",
        component: PlaybooksView,
        meta: {
          title: "Playbooks"
        }
      },

      {
        path: ":playbookId",
        name: "Playbook view",
        component: SidebarView,
        meta: {
          title: "Playbook"
        },
        props: true
      },

      {
        path: ":playbookId/entries/:jumpToEntryId",
        name: "Playbook entry view",
        component: SidebarView,
        meta: {
          title: "Playbook"
        },
        props: true
      },

      {
        path: ":playbookId/entries/:entryId/issues",
        name: "Issues View",
        component: IssuesView,
        meta: {
          title: "Playbook - Issues"
        },
        props: true
      },

      {
        path: ":playbookId/tools/definitions",
        name: "Definitions view",
        component: DefinitionsView,
        meta: {
          title: "Definitions"
        }
      },

      {
        path: ":playbookId/tools/draft-clause",
        name: "Draft clause view",
        component: DraftClauseView,
        meta: {
          title: "Draft clause"
        }
      },

      {
        path: ":playbookId/tools/pushbacks",
        name: "Pushbacks view",
        component: PushbacksView,
        meta: {
          title: "Pushbacks"
        }
      }
    ]
  },

  {
    path: "/escalations",
    name: "Escalations",
    component: EscalationsView,
    meta: {
      title: "Escalations"
    }
  },

  {
    path: "/sign-up",
    name: "SignUp view",
    component: SignUpView,
    meta: {
      title: "Sign up to LexPlay",
      public: true,
      showFooter: true
    }
  },

  {
    path: "/check-your-email",
    name: "CheckYourEmail view",
    component: CheckYourEmailView,
    meta: {
      title: "Check your email",
      public: true,
      showFooter: true
    }
  },

  {
    path: "/confirm-email/:token",
    name: "ConfirmEmail view",
    component: ConfirmEmailView,
    meta: {
      title: "Confirm your email",
      public: true,
      showFooter: true
    },
    props: true
  },

  {
    path: "/:pathMatch(.*)*",
    redirect: "/"
  }
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes
});

// Require authentication
router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const authRequired = !to.meta.public;
  const authService = new AuthService();
  if (authRequired && !authService.signedIn()) {
    return next({ name: "SignUp view", query: { redirect: to.path } });
  } else {
    next();
  }
});

// Require password reset
router.beforeEach((to, from, next) => {
  const authService = new AuthService();
  const toPath = to.path.slice(0); // ok this is weird, office adds a / at the start
  const onChangePasswordPage = toPath === "/change-password";
  if (
    authService.signedIn() &&
    authService.passwordResetRequired() &&
    !onChangePasswordPage
  ) {
    return next({ name: "Change password" });
  } else {
    next();
  }
});

// Require admin
router.beforeEach((to, from, next) => {
  const toPath = to.path.slice(0); // ok this is weird, office adds a / at the start

  const adminRequired = !to.meta.public && to.meta.adminOnly;
  const authService = new AuthService();
  if (adminRequired && !authService.admin()) {
    return next(authService.startingPath());
  } else {
    next();
  }
});

export default router;
