import { defineStore } from "pinia";
import { useStorage } from "@vueuse/core";
import {
  Client,
  Entry,
  FallbackScenario,
  NewOldSkoolVariable,
  Playbook,
  OldSkoolVariable
} from "@/types";
import ApiService from "@/services/ApiService";

export const useAdminStore = defineStore("AdminStore", {
  state() {
    return {
      clients: [] as Client[],
      clientId: useStorage<number | null>(
        "adminClientId",
        null,
        sessionStorage
      ),
      clientLoader: null as Promise<void> | null,
      entries: [] as Entry[],
      entriesLoading: false,
      playbookId: useStorage<number | null>(
        "adminPlaybookId",
        null,
        sessionStorage
      )
    };
  },

  actions: {
    addClient({ name }: { name: string }) {
      ApiService.createClient({ name }).then(({ data }) => {
        this.clients.push(data.client);
      });
    },

    addPlaybookToClient(clientId: number, playbook: Playbook) {
      const client = this.clients.filter((c) => c.id === clientId)[0];
      if (client) {
        client.playbooks.push(playbook);
      }
    },

    // Duplicate of SidebarStore
    createEntry(newEntry: Entry) {
      newEntry.position = this.entries.length;
      ApiService.createEntry(newEntry).then(
        ({ data }: { data: { entry: Entry } }) => {
          this.entries.push(data.entry);
        }
      );
    },

    // Duplicate of SidebarStore
    async deleteEntry(entry: Entry) {
      ApiService.destroyEntry(entry).then(() => {
        this.entries = this.entries.filter((el) => el.id != entry.id);
      });
    },

    loadClients(): Promise<void> {
      if (!this.clientLoader) {
        this.clientLoader = new Promise((resolve) => {
          ApiService.getClients().then(({ data }) => {
            this.clients = data.clients;
            resolve();
          });
        });
      }

      return this.clientLoader;
    },

    loadEntriesFromPlaybook(playbook: Playbook) {
      this.entriesLoading = true;
      ApiService.getEntriesForPlaybook(playbook.id).then(
        ({ data }: { data: { entries: Entry[] } }) => {
          this.entries = data.entries;
          this.entriesLoading = false;
        }
      );
    },

    migratePlaybook(playbook: Playbook) {
      ApiService.migratePlaybook(playbook).then(({ data }) => {
        const newPlaybook = data.playbook;
        this.addPlaybookToClient(newPlaybook.clientId, newPlaybook);
        this.playbookId = newPlaybook.id;

        playbook.name = "[OLD] " + newPlaybook.name;
        this.updatePlaybook(playbook);
      });
    },

    refreshClients() {
      this.clientLoader = null;
      this.loadClients();
    },

    replaceEntry(entry: Entry) {
      const index = this.entries.findIndex((el) => el.id == entry.id);
      this.entries[index] = entry;
    },

    // Duplicate of SidebarStore
    saveFallbackScenarios(entry: Entry, fallbackScenarios: FallbackScenario[]) {
      ApiService.saveFallbackScenarios(entry.id, fallbackScenarios).then(
        ({ data }: { data: { fallbackScenarios: FallbackScenario[] } }) => {
          entry.fallbackScenarios = data.fallbackScenarios;
          this.replaceEntry(entry);
        }
      );
    },

    updateClient(client: Client) {
      this.clients = [
        ...this.clients.map((item: Client) =>
          item.id !== client.id ? item : { ...item, ...client }
        )
      ];
    },

    // Duplicate of SidebarStore
    async updateEntry(entry: Entry) {
      ApiService.updateEntry(entry).then(({ data }) => {
        const newEntry = data.entry;
        newEntry.checkedStatus = entry.checkedStatus;
        this.replaceEntry(newEntry);
      });
    },

    updateEntryPosition(entryId: number, position: number) {
      const entry = this.entries.find((el) => el.id == entryId);
      if (entry) {
        entry.position = position;
        this.replaceEntry(entry);
      }
    },

    addOldSkoolVariable({
      playbook,
      variable
    }: {
      playbook: Playbook;
      variable: NewOldSkoolVariable;
    }): Promise<OldSkoolVariable[]> {
      return new Promise((resolve) => {
        ApiService.createOldSkoolVariable(variable).then(({ data }) => {
          playbook.oldSkoolVariables.push(data.oldSkoolVariable);
          this.updatePlaybook(playbook);
          resolve(playbook.oldSkoolVariables);
        });
      });
    },

    removeOldSkoolVariable({
      playbook,
      variable
    }: {
      playbook: Playbook;
      variable: OldSkoolVariable;
    }): Promise<void> {
      return new Promise((resolve) => {
        const variableId = variable.id;
        ApiService.destroyOldSkoolVariable(variable).then(() => {
          const i = playbook.oldSkoolVariables.findIndex(
            (v: OldSkoolVariable) => v.id === variableId
          );
          playbook.oldSkoolVariables.splice(i, 1);
          this.updatePlaybook(playbook);
          resolve();
        });
      });
    },

    updatePlaybook(playbook: Playbook) {
      const client = this.clients.find((client) =>
        client.playbooks.some((pb) => pb.id === playbook.id)
      );

      if (client) {
        const playbookIndex = client.playbooks.findIndex(
          (pb) => pb.id === playbook.id
        );
        if (playbookIndex !== -1) {
          client.playbooks.splice(playbookIndex, 1, playbook);
          this.updateClient(client);
        }
      }
    },

    updateOldSkoolVariable({
      playbook,
      variable
    }: {
      playbook: Playbook;
      variable: OldSkoolVariable;
    }): Promise<OldSkoolVariable[]> {
      return new Promise((resolve) => {
        ApiService.updateVariable(variable).then(() => {
          const i = playbook.oldSkoolVariables.findIndex(
            (v: OldSkoolVariable) => v.id == variable.id
          );
          playbook.oldSkoolVariables[i] = variable;
          this.updatePlaybook(playbook);
          resolve(playbook.oldSkoolVariables);
        });
      });
    }
  }
});
