// src/services/cableService.js
import { createConsumer } from "@rails/actioncable";
import { useAuthorisationStore } from "@/store/AuthorisationStore";

const socketUrl = () => {
  const url = import.meta.env.VITE_ACTION_CABLE_URL;
  const authorisationStore = useAuthorisationStore();
  if (authorisationStore.authorisation) {
    return url + "?token=" + authorisationStore.authorisation;
  } else {
    return url;
  }
};

const createCable = () => createConsumer(socketUrl());

export { createCable };
