<template>
  <div>
    <button
      class="flex w-full justify-between border-indigo-200 bg-indigo-200 px-4 py-2 text-left text-sm font-medium hover:bg-indigo-300 focus:outline-none focus-visible:ring focus-visible:ring-lexoo focus-visible:ring-opacity-75"
      :class="{ 'rounded-t-lg': open, 'mb-2 rounded-lg': !open }"
      @click="open = !open"
      data-test="select-fallback-scenario"
    >
      <DisplayedEditableText :content="formattedText(answer)" />

      <ChevronUpIcon
        :class="{
          'rotate-180 transform': open
        }"
        class="ml-2 h-5 w-5 text-indigo-500"
      />
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { ChevronUpIcon } from "@heroicons/vue/24/outline";
import { formattedText } from "@/helpers/TextHelpers";
import { useSidebarStore } from "@/store/SidebarStore";
import { FallbackScenario } from "@/types";
import DisplayedEditableText from "@/components/base/DisplayedEditableText.vue";

const props = defineProps({
  editMode: {
    type: Boolean,
    required: true
  },

  fallbackScenario: {
    type: Object as PropType<FallbackScenario>,
    required: true
  },

  answer: {
    type: String,
    default: "New scenario"
  }
});

const sidebarStore = useSidebarStore();
const open = computed({
  get: () => sidebarStore.isFallbackScenarioOpen(props.fallbackScenario),
  set: (value) => {
    sidebarStore.setFallbackScenarioOpen(props.fallbackScenario, value);
  }
});
</script>
