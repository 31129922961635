<template>
  <button
    @click="showAlertsModal = true"
    class="invisible absolute right-2 top-1 z-20 rounded border bg-gray-50 hover:bg-gray-100 group-hover:visible"
  >
    <SparklesIcon class="h-4 w-4 text-yellow-500" />
  </button>

  <GenerateAiAlertsModal
    :show="showAlertsModal"
    :playbook="playbook"
    :playbookInstance="playbookInstance"
    :entry="entry"
    @generate="generate"
    @close="showAlertsModal = false"
  />
  <RefinementModal
    :show="showRefinementModal"
    @close="showRefinementModal = false"
    :refinement="refineAiAlert"
    @refine="refine"
  />
  <div v-if="aiError" class="m-2 mt-4 max-w-lg rounded border p-4">
    <h3 class="font-medium">AI features currently unavailable</h3>
    <p class="mt-2 text-sm text-gray-500">
      Our partner OpenAI is reporting issues on their end. They are currently
      investigating. LexPlay's AI features are currently unavailable.
    </p>
  </div>
  <div v-else-if="aiAlert.result && aiAlert.result.length > 0">
    <details :open="open">
      <summary
        class="cursor-pointer rounded-t border-gray-300 bg-rose-100 p-2 pl-3 pr-6 text-sm"
      >
        <span>
          AI Alerts
          <SparklesIcon class="ml-1 inline-block h-4 w-4 text-yellow-500" />
        </span>
      </summary>
      <div
        class="relative rounded-b-lg border-b border-l border-r border-gray-300 p-2"
      >
        <BaseSuperText
          :readonly="true"
          v-model="aiAlert.result"
          @refineOptionClick="
            refineAiAlert = $event;
            showRefinementModal = true;
          "
          :generatingAI="streaming"
        />
      </div>
    </details>

    <div class="relative mt-4">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="bg-white px-2 text-sm text-gray-500">Playbook entry</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, PropType, ref, Ref, watch } from "vue";
import { debounce } from "throttle-debounce";
import { SparklesIcon } from "@heroicons/vue/24/solid";
import { Entry, NewInteraction, Playbook, PlaybookInstance } from "@/types";
import GenerateAiAlertsModal from "@/components/sidebar/GenerateAiAlertsModal.vue";
import RefinementModal from "@/components/sidebar/RefinementModal.vue";
import ApiService from "@/services/ApiService";
import { useAIStreamer } from "@/services/AI/AIStreamer";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import BaseSuperText from "@/components/base/BaseSuperText.vue";

const {
  aiError,
  startStreaming,
  generatedOutputWithVariablesAdded,
  streaming
} = useAIStreamer();

const aiAlert = ref({
  prompt: "",
  superPrompt: "",
  result: "",
  llmModel: "gpt-4"
});

const props = defineProps({
  entry: {
    type: Object as PropType<Entry>,
    required: true
  },

  playbook: {
    type: Object as PropType<Playbook>,
    required: true
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: true
  }
});

const showAlertsModal = ref(false);
const showRefinementModal = ref(false);
const prompt = ref("");
const superPrompt = ref("");
const llmModel = ref("gpt-4");
const refineAiAlert = ref("");
const promptForStream = ref("");
const open = ref(false);

const loadPromptTemplate = async () => {
  const response = await ApiService.getPromptTemplate("AI Alert");
  const promptTemplate = response.data.promptTemplate;
  prompt.value = promptTemplate.prompt;
  superPrompt.value = promptTemplate.superPrompt;
  llmModel.value = promptTemplate.llmModel;
};

const generate = (aiVariables: { entryDescription?: string }) => {
  nextTick(async () => {
    open.value = true;
    await loadPromptTemplate();

    aiVariables.entryDescription = props.entry.entryDescription;
    promptForStream.value = parsePrompt(prompt.value, aiVariables);
    startStreaming({
      llmModel: llmModel.value,
      prompt: promptForStream.value,
      superPrompt: superPrompt.value
    });

    trackAiAlertGeneration(llmModel.value, promptForStream.value);
    showAlertsModal.value = false;
  });
};

const refine = () => {
  nextTick(async () => {
    open.value = true;
    await loadPromptTemplate();

    const inputPrompt = `${aiAlert.value.prompt}\nCan you refine the following advice as follows: ${refineAiAlert.value}`;
    const promptForStream = parsePrompt(inputPrompt, {});
    startStreaming({
      llmModel: aiAlert.value.llmModel,
      prompt: promptForStream,
      superPrompt: aiAlert.value.superPrompt
    });

    trackAiAlertGeneration(aiAlert.value.llmModel, promptForStream);
  });
};

const trackAiAlertGeneration = (llmModel: string, promptForStream: string) => {
  const interaction = {
    action: "generated AI alert",
    entryId: (props.entry as Entry).id,
    extraInfo: {
      prompt: promptForStream,
      superPrompt: superPrompt.value,
      llmModel
    },
    playbookId: props.entry.playbookId
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};

watch(generatedOutputWithVariablesAdded, () => {
  if (generatedOutputWithVariablesAdded.value && streaming.value) {
    aiAlert.value = {
      result: generatedOutputWithVariablesAdded.value,
      prompt: promptForStream.value,
      superPrompt: superPrompt.value,
      llmModel: llmModel.value
    };
    autoSavePlaybookInstance();
  }
});

watch(
  () => props.entry,
  () => {
    aiAlert.value = (props.entry as Entry).aiAlert ?? {
      prompt: "",
      superPrompt: "",
      result: "",
      llmModel: "gpt-4"
    };
  }
);

const debouncedUpdatePlaybookInstance = ref(null) as Ref<null | (() => void)>;
const autoSavePlaybookInstance = () => {
  if (debouncedUpdatePlaybookInstance.value) {
    debouncedUpdatePlaybookInstance.value();
  }
};

const actualUpdatePlaybookInstance = () => {
  const aiAlerts = props.playbookInstance.aiAlerts ?? {};
  aiAlerts[(props.entry as Entry).id.toString()] = aiAlert.value;
  const playbookInstance = {
    ...props.playbookInstance,
    aiAlerts
  };
  ApiService.updatePlaybookInstance(playbookInstance);
};

onMounted(() => {
  // Assign the debounced function in the created hook
  debouncedUpdatePlaybookInstance.value = debounce(1000, () => {
    actualUpdatePlaybookInstance();
  });
});
</script>

<style lang="scss" scoped>
details {
  summary {
    @apply cursor-pointer border border-gray-300 text-sm leading-6 focus-visible:ring-gray-200;
    &::marker {
      @apply text-gray-500;
    }
  }

  &:not([open]) summary {
    @apply rounded-lg;
  }

  &[open] summary {
    @apply rounded-t-lg;
  }

  .details-content {
    @apply relative rounded-b-lg border-b border-l border-r border-gray-300 p-2;
    overflow-wrap: anywhere;

    :first-child {
      margin-top: 0;
    }
  }
}
</style>
@/services/AI/AIPromptParser @/services/AI/AIStreamer
