import { Entry, Fallback, Tag } from "@/types";

const fields = [
  "heading",
  "entryDescription",
  "description",
  "templateWording",
  "templateComment"
] as const;

type EntryOrFallbackFields = (typeof fields)[number];

export default class EntryFilter {
  entries: Entry[];
  filterText: string;
  filterTags: Tag[];

  constructor(entries: Entry[]) {
    this.entries = entries;
    this.filterText = "";
    this.filterTags = [];
  }

  filter(filterText: string, filterTags: Tag[]): Entry[] {
    this.filterText = filterText;
    this.filterTags = filterTags;

    const filteredEntries = this.entries.filter((entry: Entry) =>
      this.entryFilter(entry)
    );

    const sortedEntries = filteredEntries.sort((a: Entry, b: Entry) => {
      const firstPosition = a.position || 0;
      const secondPosition = b.position || 0;
      if (firstPosition > secondPosition) {
        return 1;
      } else if (firstPosition == secondPosition) {
        return 0;
      } else {
        return -1;
      }
    });
    return sortedEntries;
  }

  // private

  entryFilter(entry: Entry): boolean {
    if (!this.filterForTags(entry)) {
      return false;
    }

    const foundInMainEntry = fields.some((field: string) => {
      return this.filterForValue(entry, field);
    });
    if (foundInMainEntry) return true;

    return (
      entry.fallbackScenarios?.some((scenario) => {
        return fields.some((field: string) => {
          return scenario.fallbacks.some((fallback) => {
            return this.filterForValue(fallback, field);
          });
        });
      }) ?? false
    );
  }

  filterForValue(
    entryOrFallback: Entry | Fallback,
    field: EntryOrFallbackFields
  ): boolean {
    const textToSearch = (entryOrFallback[field] ?? "").toLowerCase();
    const foundInValue =
      textToSearch.indexOf(this.filterText.toLowerCase()) > -1;
    if (foundInValue) {
      return true;
    }
    return false;
  }

  filterForTags(entry: Entry): boolean {
    if (this.filterTags.length === 0) {
      return true;
    }
    const entryTags = entry.tags ?? [];

    if (this.filterTags.some((tag) => this.tagInList(tag, entryTags))) {
      return true;
    }
    return false;
  }

  tagInList(tag: Tag, list: Tag[]): Tag | undefined {
    return list.find((t: Tag) => t.id === tag.id);
  }
}
