<template>
  <main
    class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
  >
    <div class="text-center">
      <h1
        class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl"
      >
        <span>Downloading playbook...</span>
      </h1>
      <p class="mt-6 text-base leading-7 text-gray-600">
        The downloaded playbook is in {{ formatDescription }} format.
      </p>
      <p class="mt-6 text-base leading-7 text-gray-600">
        You can close this window once the playbook is downloaded.
      </p>
    </div>
  </main>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { format as dateFormat } from "date-fns";
import ApiService from "@/services/ApiService";

const props = defineProps({
  token: {
    type: String,
    required: true
  },

  format: {
    type: String,
    required: true
  }
});

const downloaded = ref(false);

const filename = computed(() => {
  const date = dateFormat(new Date(), "yyyy.MM.dd");
  return `Playbook ${date}.` + props.format;
});

const formatDescription = computed(() => {
  switch (props.format) {
    case "pdf":
      return "PDF";
    case "xlsx":
      return "Excel";
    default:
      return "unknown";
  }
});

const downloadFile = () => {
  ApiService.downloadPlaybookFromMagicLink(props.token).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename.value); //or any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
    downloaded.value = true;
  });
};

onMounted(() => {
  downloadFile();
});
</script>
