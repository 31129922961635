<template>
  <BaseDisclosure
    v-if="fallbackScenarios.length > 0 || editMode"
    :open="open"
    buttonLabel="Fallbacks"
    color="indigo"
    @toggleOpen="open = !open"
    :nested="true"
  >
    <template v-if="orderScenarios">
      <table class="mt-4 min-w-full table-auto">
        <Draggable
          :list="fallbackScenariosThatArentDeleted"
          item-key="position"
          class="divide-y divide-gray-200 bg-white"
          animation="200"
          ghost-class="ghost"
          tag="tbody"
          @start="drag = true"
          @end="drag = false"
        >
          <template #item="{ element }">
            <tr
              class="ordering bg-white align-top"
              :class="{
                'hover:bg-gray-50': !drag
              }"
            >
              <td class="py-2 text-sm font-medium text-gray-800">
                <Bars4Icon class="mr-4 inline h-5 w-5 align-bottom" />
                {{ element.answer }}
              </td>
            </tr>
          </template>
        </Draggable>
      </table>
      <div class="sticky bottom-0 w-full bg-white/70 p-4">
        <button
          @click.prevent="saveOrder"
          class="w-full rounded border border-transparent bg-lexoo px-2.5 py-1.5 text-white bg-blend-overlay hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
        >
          Save order
        </button>
        <button
          @click.prevent="orderScenarios = false"
          class="mt-2 w-full rounded border bg-gray-50 px-2.5 py-1.5 text-gray-700 bg-blend-overlay hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
        >
          Cancel
        </button>
      </div>
    </template>
    <div
      v-else
      v-for="(fallbackScenario, index) in fallbackScenariosThatArentDeleted"
      :key="index"
      class="mb-2"
    >
      <FallbackScenarioHeading
        v-model:answer="fallbackScenarios[index].answer"
        :editMode="editMode"
        :fallbackScenario="fallbackScenario"
      />
      <div
        v-if="isFallbackScenarioOpen(fallbackScenario)"
        class="rounded-b-lg border-l border-r border-b border-indigo-200 pt-2 pl-2 pr-2 text-gray-500"
      >
        <div v-if="editMode">
          <div>
            <label for="answer" class="block text-sm font-medium text-gray-700">
              Scenario
            </label>

            <div class="mt-1">
              <input
                type="text"
                name="answer"
                placeholder="e.g. 'Other side asks for different payment term'"
                v-model="fallbackScenarios[index].answer"
                class="block w-full rounded border-gray-300 text-xs shadow-sm focus:border-indigo-500 focus:ring-lexoo"
              />
              <p v-if="open" class="mb-5 mt-2 text-xs text-gray-600">
                This is the heading for this set of fallbacks, you can describe
                the counterparty challenge they relate to.
              </p>
            </div>
          </div>
          <EditableFallback
            v-for="(fallback, index) in fallbacksThatArentDeletedFor(
              fallbackScenario
            )"
            :key="index"
            v-model="fallbackScenario.fallbacks[index]"
            :fallbackScenario="fallbackScenario"
            :numberOfFallbacks="fallbackScenario.fallbacks.length"
            :fallback="fallback"
            :index="index"
          />
          <div class="flex justify-end">
            <BaseButton
              @click="addFallback(fallbackScenario)"
              data-test="add-fallback"
              buttonStyle="white"
              size="small"
            >
              Add fallback
            </BaseButton>
          </div>
          <div class="my-2 flex justify-between">
            <BaseButton
              @click="removeScenario(fallbackScenario)"
              buttonStyle="link"
              size="small"
            >
              Delete scenario
            </BaseButton>
          </div>
        </div>
        <template v-else>
          <template v-if="fallbackScenario.fallbacks.length === 0">
            <p class="p-2 pb-4 text-gray-500">No fallbacks for this scenario</p>
          </template>
          <PlaybookFallback
            v-else
            v-for="(fallback, index) in fallbacksThatArentDeletedFor(
              fallbackScenario
            )"
            :entry="entry as Entry"
            :filterText="filterText"
            :key="fallback.id"
            :index="index"
            :playbook="playbook"
            :fallback="fallback"
            :fallbackScenario="fallbackScenario"
            :numberOfFallbacks="fallbackScenario.fallbacks.length"
          />
        </template>
      </div>
    </div>
    <div v-if="editMode" class="flex justify-end">
      <span class="isolate inline-flex rounded shadow-sm">
        <BaseButton
          @click="addFallbackScenario"
          position="left"
          buttonStyle="white"
          size="small"
        >
          Add scenario
        </BaseButton>
        <BaseButton
          v-if="fallbackScenarios.length > 1"
          @click="orderScenarios = true"
          position="right"
          buttonStyle="white"
          size="small"
        >
          Order scenarios
        </BaseButton>
      </span>
    </div>
  </BaseDisclosure>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { Bars4Icon } from "@heroicons/vue/24/outline";
import Draggable from "vuedraggable";
import BaseDisclosure from "@/components/base/BaseDisclosure.vue";
import { Entry, Fallback, FallbackScenario, NewEntry, Playbook } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";
import EditableFallback from "@/components/sidebar/EditableFallback.vue";
import PlaybookFallback from "@/components/sidebar/PlaybookFallback.vue";
import FallbackScenarioHeading from "@/components/sidebar/FallbackScenarioHeading.vue";

const props = defineProps({
  entry: {
    type: Object as PropType<Entry | NewEntry>,
    required: true
  },

  filterText: {
    type: String,
    required: false
  },

  playbook: {
    type: Object as PropType<Playbook>,
    required: false
  }
});

const fallbackScenarios = computed(() => {
  if (!props.entry.fallbackScenarios) {
    return [];
  }
  return (
    [...props.entry.fallbackScenarios].sort((a, b) => {
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    }) ?? []
  );
});
const sidebarStore = useSidebarStore();
const orderScenarios = ref(false);
const drag = ref(false);

const newRecord = computed((): boolean => {
  return !(props.entry as Entry).id;
});

const saveOrder = async () => {
  fallbackScenariosThatArentDeleted.value.forEach((fallbackScenario, index) => {
    fallbackScenario.position = index;
  });
  orderScenarios.value = false;
};

const fallbackScenariosThatArentDeleted = computed(() => {
  return fallbackScenarios.value.filter((scenario) => !scenario._destroy);
});

const editMode = computed(() => {
  if (newRecord.value) {
    return true;
  } else {
    return sidebarStore.entryEdited(props.entry as Entry);
  }
});

const open = computed({
  get() {
    if (!props.entry || newRecord.value) {
      return true;
    }
    return sidebarStore.isEntryFallbacksOpen(props.entry as Entry);
  },

  set(value: boolean) {
    if (props.entry && !newRecord.value)
      sidebarStore.setEntryFallbacksOpen(props.entry as Entry, value);
  }
});

const isFallbackScenarioOpen = (fallbackScenario: FallbackScenario) => {
  return sidebarStore.isFallbackScenarioOpen(fallbackScenario);
};

const addFallbackScenario = () => {
  const newFallback = {
    position: 0,
    description: "",
    templateWording: "",
    templateComment: ""
  };
  const newFallbackScenario = {
    fallbacks: [newFallback],
    answer: ""
  } as FallbackScenario;
  const fallbackScenarios = props.entry.fallbackScenarios ?? [];
  fallbackScenarios.push(newFallbackScenario);

  if ((props.entry as Entry).id) {
    const updatedEntry = {
      ...props.entry,
      fallbackScenarios
    };
    sidebarStore.replaceEntry(updatedEntry as Entry);
  }
  return newFallbackScenario;
};

const removeScenario = (fallbackScenario: FallbackScenario) => {
  const fallbackScenarios = props.entry.fallbackScenarios ?? [];
  const index = fallbackScenarios.indexOf(fallbackScenario);
  const scenario = fallbackScenarios[index];
  scenario._destroy = "1";
  const updatedEntry = {
    ...props.entry,
    fallbackScenarios
  };
  if (!newRecord.value) {
    sidebarStore.replaceEntry(updatedEntry as Entry);
  }
};

const fallbacksThatArentDeletedFor = (
  fallbackScenario: FallbackScenario
): Fallback[] => {
  return fallbackScenario.fallbacks.filter((fallback) => !fallback._destroy);
};

const addFallback = (fallbackScenario: FallbackScenario) => {
  if (fallbackScenarios.value.length === 0) {
    fallbackScenario = addFallbackScenario();
  }

  const position = fallbackScenario.fallbacks.length;
  const newFallback = {
    position,
    templateWording: "",
    templateComment: "",
    description: ""
  } as Fallback;
  if (fallbackScenario.id) {
    newFallback["fallbackScenarioId"] = fallbackScenario.id;
  }
  fallbackScenario.fallbacks.push(newFallback);
};
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  @apply bg-gray-100;
}

.ordering {
  @apply cursor-move;
}
</style>
