<script setup lang="ts">
/* global Word */

import {
  ArrowDownCircleIcon,
  ArrowRightCircleIcon,
  ArrowUpCircleIcon
} from "@heroicons/vue/24/outline";

import { useDocumentSearchStore } from "@/store/DocumentSearchStore";

const documentSearchStore = useDocumentSearchStore();
const props = defineProps({
  definitionTitle: {
    type: String,
    required: true
  },
  definitionCount: {
    type: Number,
    required: true
  }
});

const findNext = () => {
  Word.run(async (context) => {
    if (documentSearchStore.searchValue !== props.definitionTitle) {
      context.document.body.select("Start");
      documentSearchStore.searchIndex = 0;
      await context.sync();
    }
    documentSearchStore.searchValue = props.definitionTitle;
    console.log("Searching for: " + props.definitionTitle);
    const ranges = context.document.body.search(
      props.definitionTitle.replace("\n", " "),
      {
        matchCase: true
      }
    );
    ranges.load("items");
    await context.sync();

    const selection = context.document.getSelection();
    const compareResults = ranges.items.map((range) =>
      range.compareLocationWith(selection)
    );
    await context.sync();
    let found = false;

    for (const index in compareResults) {
      if (
        compareResults[index].value == "ContainsStart" ||
        compareResults[index].value == "After"
      ) {
        documentSearchStore.searchIndex = Number(index);

        found = true;
        break;
      }
    }
    if (!found) {
      documentSearchStore.searchIndex = 0;
      ranges.items[0].select();
    }
    ranges.items[documentSearchStore.searchIndex ?? 0].select();
    await context.sync();
  });
};

const findPrevious = () => {
  Word.run(async (context) => {
    documentSearchStore.searchValue = props.definitionTitle;
    const ranges = context.document.body.search(props.definitionTitle, {
      matchCase: true
    });
    ranges.load("items");
    await context.sync();

    const selection = context.document.getSelection();
    const items = ranges.items.reverse();
    const compareResults = items.map((range) =>
      range.compareLocationWith(selection)
    );
    await context.sync();
    let foundIndex = ranges.items.length - 1;

    for (const index in compareResults) {
      console.log(index + ": " + compareResults[index].value.toString());
      if (
        compareResults[index].value == "ContainsStart" ||
        compareResults[index].value == "Before"
      ) {
        // this.documentSearchStore.searchIndex = Number(index);
        foundIndex = Number(index);
        break;
      }
    }

    items[foundIndex].select();
    documentSearchStore.searchIndex = ranges.items.length - 1 - foundIndex;
    await context.sync();
  });
};
</script>

<template>
  <span
    v-if="
      documentSearchStore.searchValue !== definitionTitle ||
      definitionCount === 1
    "
  >
    <a
      href="#"
      @click.prevent="findNext"
      class="link hover:text-gray-900 hover:underline"
    >
      <ArrowRightCircleIcon class="inline-block h-4 w-4" aria-hidden="true" />
    </a>
  </span>
  <span v-else>
    <a
      href="#"
      @click.prevent="findNext"
      class="link hover:text-gray-900 hover:underline"
    >
      <ArrowDownCircleIcon class="inline-block h-4 w-4" aria-hidden="true" />
    </a>
    <a
      v-if="definitionCount > 1"
      href="#"
      @click.prevent="findPrevious"
      class="link hover:text-gray-900 hover:underline"
    >
      <ArrowUpCircleIcon class="inline-block h-4 w-4" aria-hidden="true" />
    </a>
  </span>
  <span
    v-if="
      documentSearchStore.searchValue == definitionTitle && definitionCount > 1
    "
    class="text-xs"
  >
    ({{ documentSearchStore.searchIndex + 1 }} of {{ definitionCount }})
  </span>
  <span v-else class="text-xs"> ({{ definitionCount }}) </span>
</template>
