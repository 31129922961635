<template>
  <div class="mx-auto mb-10 max-w-3xl rounded border p-8">
    <div class="prose mx-auto">
      <p class="text-right text-gray-500">Last updated 24th October 2023</p>
      <h1 class="text-center text-2xl">LexPlay: PRIVACY NOTICE</h1>
      <p>
        This privacy notice for Lexoo Limited (dba Lexoo) ('Lexoo', 'we', 'us',
        or 'our'), describes how and why we might collect, store, use, and/or
        share ('process') your information when you use our tool (‘LexPlay’) in
        Microsoft Word. Reading this privacy notice will help you understand
        your privacy rights and choices.
      </p>

      <p>
        If you do not agree with our policies and practices, please do not use
        LexPlay. If you still have any questions or concerns, please contact us
        at
        <a href="mailto:team@lexoo.com">team@lexoo.com</a>.
      </p>
      <h2>Summary of key points</h2>
      <p>
        This summary provides key points from our privacy notice, but you can
        find out more details about any of these topics by using our table of
        contents below.
      </p>
      <h3>What personal information do we process?</h3>
      <p>
        When you use LexPlay, we may process personal information depending on
        how you interact with Lexoo and LexPlay, the choices you make, and the
        products and features you use.
      </p>

      <h3>Do we process any sensitive personal information?</h3>
      <p>We do not process sensitive personal information.</p>

      <h3>Do we receive any information from third parties?</h3>
      <p>We do not receive any information from third parties.</p>

      <h3>How do we process your information?</h3>
      <p>
        We process your information to provide, improve, and administer LexPlay,
        communicate with you, for security and fraud prevention, and to comply
        with law.
      </p>
      <p>
        We may also process your information for other purposes with your
        consent. We process your information only when we have a valid legal
        reason to do so.
      </p>

      <h3>
        In what situations and with which types of parties do we share personal
        information?
      </h3>
      <p>
        We may share information in specific situations and with specific
        categories of third parties.
      </p>
      <h3>How do we keep your information safe?</h3>
      <p>
        We have organisational and technical processes and procedures in place
        to protect your personal information.
      </p>

      <h3>What are your rights?</h3>
      <p>
        Depending on where you are located geographically, the applicable
        privacy law may mean you have certain rights regarding your personal
        information.
      </p>
      <h3>How do you exercise your rights?</h3>
      <p>
        The easiest way to exercise your rights is by visiting
        https://info.lexoo.com/lexplay-support, or by contacting us. We will
        consider and act upon any request in accordance with applicable data
        protection laws.
      </p>

      <h3>
        Want to learn more about what Lexoo does with any information we
        collect?
      </h3>

      <p>Please review the privacy notice in full.</p>

      <h2>Table of contents</h2>
      <ol>
        <li>1. What information do we collect?</li>
        <li>2. How do we process your information?</li>
        <li>
          3. What legal bases do we rely on to process your personal
          information?
        </li>
        <li>4. When and with whom do we share your personal information?</li>
        <li>5. How do we handle your other logins?</li>
        <li>6. Is your information transferred internationally?</li>
        <li>7. How long do we keep your information?</li>
        <li>8. How do we keep your information safe?</li>
        <li>9. Do we collect information from minors?</li>
        <li>10. What are your privacy rights?</li>
        <li>11. Do California residents have specific privacy rights?</li>
        <li>12. Do we make updates to this notice?</li>
        <li>13. How can you contact us about this notice?</li>
        <li>
          14. How can you review, update, or delete the data we collect from
          you?
        </li>
      </ol>

      <h2>1. What information do we collect?</h2>
      <h3>Personal information you disclose to us</h3>
      <p>TLDR: We collect personal information that you provide to us.</p>
      <p>
        We collect personal information that you voluntarily provide to us when
        you register to use LexPlay, express an interest in obtaining
        information about us or our products, when you participate in activities
        on LexPlay, or otherwise when you contact us.
      </p>

      <h3>Personal Information Provided by You.</h3>
      <p>
        The personal information that we collect depends on the context of your
        interactions with us and LexPlay, the choices you make, and the products
        and features you use. The personal information we collect may include
        the following:
      </p>
      <ul>
        <li>name</li>
        <li>email address</li>
        <li>company</li>
      </ul>

      <h3>Sensitive Information.</h3>
      <p>We do not process sensitive information.</p>

      <h3>Single Sign On Login Data.</h3>
      <p>
        We may provide you with the option to register with us using your
        existing account details, like your Google, Microsoft, or other account.
        If you choose to register in this way, we will collect the information
        described in the section called 'HOW DO WE HANDLE YOUR SOCIAL LOGINS?'
        below.
      </p>
      <p>
        All personal information that you provide to us must be true, complete,
        and accurate, and you must notify us of any changes to such personal
        information.
      </p>

      <h3>Information automatically collected</h3>
      <p>
        TLDR: Some information — such as your Internet Protocol (IP) address
        and/or browser and device characteristics — is collected automatically
        when you use LexPlay.
      </p>
      <p>
        We automatically collect certain information when you use LexPlay. This
        information does not reveal your specific identity (like your name or
        contact information) but may include device and usage information, such
        as your IP address, browser and device characteristics, operating
        system, language preferences, referring URLs, device name, country,
        location, information about how and when you use LexPlay, and other
        technical information. This information is primarily needed to maintain
        the security and operation of LexPlay, and for our internal analytics
        and reporting purposes.
      </p>
      <p>The information we collect includes:</p>
      <ul>
        <li>
          Log and Usage Data. Log and usage data is service-related, diagnostic,
          usage, and performance information our servers automatically collect
          when you access or use LexPlay and which we record in log files.
          Depending on how you interact with us, this log data may include your
          IP address, device information, browser type, and settings and
          information about your activity in LexPlay (such as the date/time
          stamps associated with your usage, entries viewed, searches, and other
          actions you take such as which features you use), device event
          information (such as system activity, error reports, and hardware
          settings).
        </li>
        <li>
          Device Data. We collect device data such as information about your
          computer, phone, tablet, or other device you use to access LexPlay.
          Depending on the device used, this device data may include information
          such as your IP address (or proxy server), device and application
          identification numbers, location, browser type, hardware model,
          Internet service provider and/or mobile carrier, operating system, and
          system configuration information.
        </li>
        <li>
          Location Data. We collect location data such as information about your
          device's location, which can be either precise or imprecise. How much
          information we collect depends on the type and settings of the device
          you use to access LexPlay. You can opt out of allowing us to collect
          this information either by refusing access to the information or by
          disabling your Location setting on your device. However, if you choose
          to opt out, you may not be able to use LexPlay.
        </li>
      </ul>

      <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
      <p>
        TLDR: We process your information to provide, improve, and administer
        LexPlay, communicate with you, for security and fraud prevention, and to
        comply with law. We may also process your information for other purposes
        with your consent.
      </p>
      <p>
        We process your personal information for a variety of reasons, depending
        on how you interact with LexPlay, including:
      </p>
      <ul>
        <li>
          To facilitate account creation and authentication and otherwise manage
          user accounts. We may process your information so you can create and
          log in to your account, as well as keep your account in working order.
        </li>
        <li>
          To deliver and facilitate LexPlay to the user. We may process your
          information to provide you with the requested service.
        </li>
        <li>
          To respond to user inquiries/offer support to users. We may process
          your information to respond to your inquiries and solve any potential
          issues you might have with the requested service.
        </li>
        <li>
          To enable user-to-user communications. We may process your information
          if you choose to use any of our offerings that allow for communication
          with another user.
        </li>
        <li>
          To request feedback. We may process your information when necessary to
          request feedback and to contact you about your use of LexPlay.
        </li>
        <li>
          To send you marketing and promotional communications. We may process
          the personal information you send to us for our marketing purposes, if
          this is in accordance with your marketing preferences. You can opt out
          of our marketing emails at any time.
        </li>
        <li>
          To protect LexPlay. We may process your information as part of our
          efforts to keep our product safe and secure, including fraud
          monitoring and prevention.
        </li>
        <li>
          To identify usage trends. We may process information about how you use
          LexPlay to better understand how it is being used so we can improve
          them.
        </li>
        <li>
          To determine the effectiveness of our marketing and promotional
          campaigns. We may process your information to better understand how to
          provide marketing and promotional campaigns that are most relevant to
          you.
        </li>
        <li>
          To save or protect an individual's vital interest. We may process your
          information when necessary to save or protect an individual's vital
          interest, such as to prevent harm.
        </li>
      </ul>

      <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
      <p>
        TLDR: We only process your personal information when we believe it is
        necessary and we have a valid legal reason (i.e. legal basis) to do so
        under applicable law, like with your consent, to comply with laws, to
        provide you with services to enter into or fulfil our contractual
        obligations, to protect your rights, or to fulfil our legitimate
        business interests.
      </p>
      <h3>If you are located in the EU or UK, this section applies to you.</h3>
      <p>
        The General Data Protection Regulation (GDPR) and UK GDPR require us to
        explain the valid legal bases we rely on in order to process your
        personal information. As such, we may rely on the following legal bases
        to process your personal information:
      </p>
      <ul>
        <li>
          Consent. We may process your information if you have given us
          permission (i.e. consent) to use your personal information for a
          specific purpose. You can withdraw your consent at any time.
        </li>
        <li>
          Performance of a Contract. We may process your personal information
          when we believe it is necessary to fulfil our contractual obligations
          to you, including providing LexPlay or at your request prior to
          entering into a contract with you.
        </li>
        <li>
          Legitimate Interests. We may process your information when we believe
          it is reasonably necessary to achieve our legitimate business
          interests and those interests do not outweigh your interests and
          fundamental rights and freedoms. For example, we may process your
          personal information for some of the purposes described in order to:
          <ul>
            <li>
              Send users information about special offers and discounts on our
              products and services
            </li>
            <li>
              Analyse how LexPlay is used so we can improve them to engage and
              retain users
            </li>
            <li>Support our marketing activities</li>
            <li>Diagnose problems and/or prevent fraudulent activities</li>
            <li>
              Understand how our users use our product so we can improve user
              experience
            </li>
          </ul>
        </li>
        <li>
          Legal Obligations. We may process your information where we believe it
          is necessary for compliance with our legal obligations, such as to
          cooperate with a law enforcement body or regulatory agency, exercise
          or defend our legal rights, or disclose your information as evidence
          in litigation in which we are involved.
        </li>
        <li>
          Vital Interests. We may process your information where we believe it
          is necessary to protect your vital interests or the vital interests of
          a third party, such as situations involving potential threats to the
          safety of any person.
        </li>
      </ul>

      <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
      <p>
        TLDR: We may share information in specific situations described in this
        section and/or with the following categories of third parties.
      </p>
      <p>
        Vendors, Consultants, and Other Third-Party Service Providers. We may
        share your data with third-party vendors, service providers,
        contractors, or agents ('third parties') who perform services for us or
        on our behalf and require access to such information to do that work. We
        have contracts in place with our third parties, which are designed to
        help safeguard your personal information. This means that they cannot do
        anything with your personal information unless we have instructed them
        to do it.
      </p>

      <h2>5. HOW DO WE HANDLE YOUR OTHER LOGINS?</h2>
      <p>
        TLDR: If you choose to register or log in to LexPlay using another
        account (“single sign on”), we may have access to certain information
        about you.
      </p>
      <p>
        LexPlay may offer you the ability to register and log in using certain
        third-party account details. Where you choose to do this, we will
        receive certain profile information about you from the account provider.
        The profile information we receive may vary depending on the provider
        concerned, but will often include your name and email address.
      </p>
      <p>
        We will use the information we receive only for the purposes that are
        described in this privacy notice or that are otherwise made clear to you
        when using LexPlay.
      </p>
      <p>
        Please note that we do not control, and are not responsible for, other
        uses of your personal information by your third-party account provider.
        We recommend that you review their privacy notice to understand how they
        collect, use, and share your personal information. The categories of
        third parties we may share personal information with are as follows:
      </p>
      <ul>
        <li>Cloud Computing Services</li>
        <li>Communication & Collaboration Tools</li>
        <li>Data Storage Service Providers</li>
        <li>Performance Monitoring Tools</li>
        <li>Testing Tools</li>
        <li>User Account Registration & Authentication Services</li>
      </ul>

      <h2>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</h2>
      <p>
        TLDR: We may transfer, store, and process your information in countries
        other than your own.
      </p>
      <p>
        Our servers are located in Ireland. If you are accessing LexPlay from
        outside Ireland, please be aware that your information may be
        transferred to, stored, and processed by us in our facilities and by
        those third parties with whom we may share your personal information
        (see 'WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?' above),
        in the United States, and other countries.
      </p>
      <p>
        If you are a resident in the European Economic Area (EEA) or United
        Kingdom (UK), then these countries may not necessarily have data
        protection laws or other similar laws as comprehensive as those in your
        country. However, we will take all necessary measures to protect your
        personal information in accordance with this privacy notice and
        applicable law.
      </p>
      <h3>European Commission's Standard Contractual Clauses:</h3>
      <p>
        We have implemented measures to protect your personal information,
        including by using the European Commission's Standard Contractual
        Clauses (“SCCs”) for transfers of personal information between our group
        companies and between us and our third-party providers.
      </p>
      <p>
        These clauses require all recipients to protect all personal information
        that they process originating from the EEA or UK in accordance with
        European data protection laws and regulations. Our SCCs can be provided
        upon request. We have implemented similar appropriate safeguards with
        our third-party service providers and partners and further details can
        be provided upon request.
      </p>

      <h2>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
      <p>
        TLDR: We keep your information for as long as necessary to fulfil the
        purposes outlined in this privacy notice unless otherwise required by
        law.
      </p>
      <p>
        We will only keep your personal information for as long as it is
        necessary for the purposes set out in this privacy notice, unless a
        longer retention period is required or permitted by law (such as tax,
        accounting, or other legal requirements).
      </p>
      <p>
        No purpose in this notice will require us to keep your personal
        information for longer than eight four (84) months past the termination
        of the user's account.
      </p>
      <p>
        When we have no ongoing legitimate business need to process your
        personal information, we will either delete or anonymise such
        information, or, if this is not possible (for example, because your
        personal information has been stored in backup archives), then we will
        securely store your personal information and isolate it from any further
        processing until deletion is possible.
      </p>

      <h2>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
      <p>
        TLDR: We aim to protect your personal information through a system of
        organisational and technical security measures.
      </p>
      <p>
        We have implemented appropriate and reasonable technical and
        organisational security measures designed to protect the security of any
        personal information we process. However, despite our safeguards and
        efforts to secure your information, no electronic transmission over the
        Internet or information storage technology can be guaranteed to be 100%
        secure, so we cannot promise or guarantee that hackers, cybercriminals,
        or other unauthorised third parties will not be able to defeat our
        security and improperly collect, access, steal, or modify your
        information.
      </p>
      <p>
        Although we will do our best to protect your personal information,
        transmission of personal information to and from LexPlay is at your own
        risk. You should only access LexPlay within a secure environment.
      </p>

      <h2>9. DO WE COLLECT INFORMATION FROM MINORS?</h2>
      <p>
        TLDR: We do not knowingly collect data from or market to children under
        18 years of age.
      </p>
      <p>
        We do not knowingly solicit data from or market to children under 18
        years of age. By using LexPlay, you represent that you are at least 18.
        If we learn that personal information from users less than 18 years of
        age has been collected, we will deactivate the account and take
        reasonable measures to promptly delete such data from our records.
      </p>
      <p>
        If you become aware of any data we may have collected from children
        under age 18, please contact us at
        <a href="mailto:team@lexoo.com">team@lexoo.com</a>.
      </p>

      <h2>10. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
      <p>
        TLDR: In some regions, such as the European Economic Area (EEA) and
        United Kingdom (UK), you have rights that allow you greater access to
        and control over your personal information. You may review, change, or
        terminate your account at any time.
      </p>
      <p>
        In some regions (like the EEA and UK), you have certain rights under
        applicable data protection laws. These may include the right (i) to
        request access and obtain a copy of your personal information, (ii) to
        request rectification or erasure; (iii) to restrict the processing of
        your personal information; and (iv) if applicable, to data portability.
        In certain circumstances, you may also have the right to object to the
        processing of your personal information.
      </p>
      <p>
        You can make such a request by contacting us by using the contact
        details provided in the section 'HOW CAN YOU CONTACT US ABOUT THIS
        NOTICE?' below.
      </p>
      <p>
        We will consider and act upon any request in accordance with applicable
        data protection laws.
      </p>
      <p>
        If you are located in the EEA or UK and you believe we are unlawfully
        processing your personal information, you also have the right to
        complain to your Member State data protection authority or UK data
        protection authority.
      </p>
      <p>
        If you are located in Switzerland, you may contact the Federal Data
        Protection and Information Commissioner.
      </p>

      <h3>Withdrawing your consent:</h3>
      <p>
        If we are relying on your consent to process your personal information,
        which may be express and/or implied consent depending on the applicable
        law, you have the right to withdraw your consent at any time.
      </p>
      <p>
        You can withdraw your consent at any time by contacting us by using the
        contact details provided in the section 'HOW CAN YOU CONTACT US ABOUT
        THIS NOTICE?' below.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the
        processing before its withdrawal nor, when applicable law allows, will
        it affect the processing of your personal information conducted in
        reliance on lawful processing grounds other than consent.
      </p>

      <h3>Opting out of marketing and promotional communications:</h3>
      <p>
        You can unsubscribe from our marketing and promotional communications at
        any time by clicking on the unsubscribe link in the emails that we send,
        or by contacting us using the details provided in the section 'HOW CAN
        YOU CONTACT US ABOUT THIS NOTICE?'
      </p>
      <p>
        You will then be removed from the marketing lists. However, we may still
        communicate with you — for example, to send you service-related messages
        that are necessary for the administration and use of your account, to
        respond to service requests, or for other non-marketing purposes.
      </p>

      <h3>Account Information</h3>
      <p>
        If you would at any time like to review or change the information in
        your account or terminate your account, you can:
      </p>

      <h3>Contact us using the contact information provided.</h3>
      <p>
        Upon your request to terminate your account, we will deactivate your
        account and anonymise information in our active databases. We may retain
        some information in our files to prevent fraud, troubleshoot problems,
        assist with any investigations, enforce our legal terms and/or comply
        with applicable legal requirements.
      </p>
      <p>
        If you have questions or comments about your privacy rights, you may
        email us at <a href="mailto:team@lexoo.com">team@lexoo.com</a>.
      </p>

      <h2>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h2>
      <p>
        TLDR: Yes, if you are a resident of California, you are granted specific
        rights regarding access to your personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the 'Shine The
        Light' law, permits our users who are California residents to request
        and obtain from us, once a year and free of charge, information about
        categories of personal information (if any) we disclosed to third
        parties for direct marketing purposes and the names and addresses of all
        third parties with which we shared personal information in the
        immediately preceding calendar year. If you are a California resident
        and would like to make such a request, please submit your request in
        writing to us using the contact information provided below.
      </p>

      <h3>CCPA Privacy Notice</h3>
      <p>The California Code of Regulations defines a 'resident' as:</p>
      <p>
        (1) every individual who is in the State of California for other than a
        temporary or transitory purpose and (2) every individual who is
        domiciled in the State of California who is outside the State of
        California for a temporary or transitory purpose
      </p>
      <p>All other individuals are defined as 'non-residents'.</p>
      <p>
        If this definition of 'resident' applies to you, we must adhere to
        certain rights and obligations regarding your personal information.
      </p>

      <h3>What categories of personal information do we collect?</h3>
      <p>
        We have collected the following categories of personal information in
        the past twelve (12) months:
      </p>
      <table>
        <thead>
          <th>Category</th>
          <th>Examples</th>
          <th>Collected?</th>
        </thead>
        <tbody>
          <tr>
            <td>A. Identifiers</td>
            <td>
              Contact details, such as real name, alias, postal address,
              telephone or mobile contact number, unique personal identifier,
              online identifier, Internet Protocol address, email address, and
              account name
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              B. Personal information categories listed in the California
              Customer Records statute
            </td>
            <td>
              Name, contact information, education, employment, employment
              history, financial information
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>
              C. Protected classification characteristics under California or
              federal law
            </td>
            <td>Gender and date of birth</td>
            <td>No</td>
          </tr>
          <tr>
            <td>D. Commercial information</td>
            <td>
              Transaction information, purchase history, financial details,
              payment information
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>E. Biometric information</td>
            <td>Fingerprints and voiceprints</td>
            <td>No</td>
          </tr>
          <tr>
            <td>F. Internet or other similar network activity</td>
            <td>
              Browsing history, search history, online behaviour, interest data,
              interactions with our and other websites, applications, systems,
              advertisements
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>G. Geolocation data</td>
            <td>Device location</td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>
              H. Audio, electronic, visual, thermal, olfactory, or similar
            </td>
            <td>
              Images and audio, video or call recordings created in connection
              with our business activities
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>I. Professional or employment-related information</td>
            <td>
              Business contact details in order to provide you LexPlay at a
              business level or job title, work history, and professional
              qualifications if you apply for a job with us
            </td>
            <td>Yes</td>
          </tr>
          <tr>
            <td>J. Education information</td>
            <td>Student records and directory information</td>
            <td>No</td>
          </tr>
          <tr>
            <td>K. Inferences drawn from other personal information</td>
            <td>
              Inferences drawn from any of the collected personal information
              listed above to create a profile or summary about, for example, an
              individual’s preferences and characteristics
            </td>
            <td>No</td>
          </tr>
          <tr>
            <td>L. Sensitive personal info</td>
            <td></td>
            <td>No</td>
          </tr>
        </tbody>
      </table>
      <p>
        We may also collect other personal information outside of these
        categories through instances where you interact with us in person,
        online, or by phone or mail in the context of:
      </p>
      <ul>
        <li>Receiving help through our customer support channels;</li>
        <li>Participation in customer surveys or contests; and</li>
        <li>
          Facilitation in the delivery of LexPlay and to respond to your
          inquiries.
        </li>
      </ul>
      <h3>How do we use and share your personal information?</h3>
      <p>
        More information about our data collection and sharing practices is
        within this privacy notice.
      </p>
      <p>
        You may contact us by email at
        <a href="mailto:team@lexoo.com">team@lexoo.com</a> or by referring to
        the contact details at the bottom of this document.
      </p>
      <p>
        If you are using an authorised agent to exercise your right to opt out
        we may deny a request if the authorised agent does not submit proof that
        they have been validly authorised to act on your behalf.
      </p>

      <h3>Will your information be shared with anyone else?</h3>
      <p>
        We may disclose your personal information with our service providers
        pursuant to a written contract between us and each service provider.
        Each service provider is a for-profit entity that processes the
        information on our behalf, following the same strict privacy protection
        obligations mandated by the CCPA.
      </p>
      <p>
        We may use your personal information for our own business purposes, such
        as for undertaking internal research for technological development and
        demonstration. This is not considered to be 'selling' of your personal
        information.
      </p>

      <h3>Your rights with respect to your personal data</h3>
      <p><em>Right to request deletion of the data — Request to delete</em></p>
      <p>
        You can ask for the deletion of your personal information. If you ask us
        to delete your personal information, we will respect your request and
        delete your personal information, subject to certain exceptions provided
        by law, such as (but not limited to) the exercise by another consumer of
        his or her right to free speech, our compliance requirements resulting
        from a legal obligation, or any processing that may be required to
        protect against illegal activities.
      </p>
      <p><em>Right to be informed — Request to know</em></p>
      <p>Depending on the circumstances, you have a right to know:</p>
      <ul>
        <li>whether we collect and use your personal information;</li>
        <li>the categories of personal information that we collect;</li>
        <li>
          the purposes for which the collected personal information is used;
        </li>
        <li>whether we sell or share personal information to third parties;</li>
        <li>
          the categories of personal information that we sold, shared, or
          disclosed for a business purpose;
        </li>
        <li>
          the categories of third parties to whom the personal information was
          sold, shared, or disclosed for a business purpose;
        </li>
        <li>
          the business or commercial purpose for collecting, selling, or sharing
          personal information; and
        </li>
        <li>
          the specific pieces of personal information we collected about you.
        </li>
      </ul>
      <p>
        In accordance with applicable law, we are not obligated to provide or
        delete consumer information that is de-identified in response to a
        consumer request or to re-identify individual data to verify a consumer
        request.
      </p>

      <p>
        <em>
          Right to Non-Discrimination for the Exercise of a Consumer's Privacy
          Rights
        </em>
      </p>
      <p>
        We will not discriminate against you if you exercise your privacy
        rights.
      </p>

      <p>
        <em>
          Right to Limit Use and Disclosure of Sensitive Personal Information
        </em>
      </p>
      <p>We do not process consumer's sensitive personal information.</p>

      <p><em>Verification process</em></p>
      <p>
        Upon receiving your request, we will need to verify your identity to
        determine you are the same person about whom we have the information in
        our system. These verification efforts require us to ask you to provide
        information so that we can match it with information you have previously
        provided us. For instance, depending on the type of request you submit,
        we may ask you to provide certain information so that we can match the
        information you provide with the information we already have on file, or
        we may contact you through a communication method (e.g. phone or email)
        that you have previously provided to us. We may also use other
        verification methods as the circumstances dictate.
      </p>
      <p>
        We will only use personal information provided in your request to verify
        your identity or authority to make the request. To the extent possible,
        we will avoid requesting additional information from you for the
        purposes of verification. However, if we cannot verify your identity
        from the information already maintained by us, we may request that you
        provide additional information for the purposes of verifying your
        identity and for security or fraud-prevention purposes. We will delete
        such additionally provided information as soon as we finish verifying
        you.
      </p>

      <p><em>Other privacy rights</em></p>

      <ul>
        <li>You may object to the processing of your personal information.</li>
        <li>
          You may request correction of your personal data if it is incorrect or
          no longer relevant, or ask to restrict the processing of the
          information.
        </li>
        <li>
          You can designate an authorised agent to make a request under the CCPA
          on your behalf. We may deny a request from an authorised agent that
          does not submit proof that they have been validly authorised to act on
          your behalf in accordance with the CCPA.
        </li>
        <li>
          You may request to opt out from future selling or sharing of your
          personal information to third parties. Upon receiving an opt-out
          request, we will act upon the request as soon as feasibly possible,
          but no later than fifteen (15) days from the date of the request
          submission.
        </li>
      </ul>
      <p>
        To exercise these rights, you can contact us by email at
        <a href="mailto:team@lexoo.com">team@lexoo.com</a> or by referring to
        the contact details at the bottom of this document.
      </p>
      <p>
        If you have a complaint about how we handle your data, we would like to
        hear from you.
      </p>

      <h2>12. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
      <p>
        TLDR: Yes, we will update this notice as necessary to stay compliant
        with relevant laws.
      </p>
      <p>
        We may update this privacy notice from time to time. The updated version
        will be indicated by an updated 'Revised' date and the updated version
        will be effective as soon as it is accessible. If we make material
        changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a
        notification. We encourage you to review this privacy notice frequently
        to be informed of how we are protecting your information.
      </p>

      <h2>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
      <p>
        If you have questions or comments about this notice, you may email us at
        <a href="mailto:team@lexoo.com">team@lexoo.com</a> or contact us by post
        at:
      </p>
      <p>
        Lexoo Limited, Unit 16 Britannia Building, 12 Ebenezer Street, London NI
        7RP, United Kingdom
      </p>

      <h2>
        14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
      </h2>
      <p>
        Based on the applicable laws of your country, you may have the right to
        request access to the personal information we collect from you, change
        that information, or delete it.
      </p>
      <p>
        To request to review, update, or delete your personal information,
        please visit:
        <a href="https://info.lexoo.com/lexplay-support">
          https://info.lexoo.com/lexplay-support
        </a>
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
ol {
  counter-reset: section;
  list-style-type: none;
}
ol > li > ol > li > p:first-child::before {
  counter-increment: section;
  content: counters(section, ".") " ";
}
</style>
