<template>
  <span v-if="readonly && selectedColour" class="absolute right-2 inline-block">
    <span
      :class="`bg-${
        selectedColour ?? 'gray'
      }-400 inline-block h-2 w-2 flex-shrink-0 rounded-full`"
    />
    &nbsp;
  </span>
  <Listbox
    v-else-if="!readonly"
    as="span"
    class="inline-block"
    v-model="selectedColour"
  >
    <ListboxButton
      @click="open = !open"
      class="relative cursor-pointer rounded border bg-white pl-2 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
    >
      <span
        :class="`bg-${
          selectedColour ?? 'gray'
        }-400 inline-block h-2 w-2 rounded-full`"
      />
      &nbsp;
    </ListboxButton>

    <transition
      leave-active-class="transition ease-in duration-100"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <ListboxOptions
        v-show="open"
        static
        class="absolute -right-1 z-20 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
      >
        <ListboxOption
          as="template"
          v-for="colour in colours"
          :key="colour"
          :value="colour"
          v-slot="{ selected }"
        >
          <li
            @click="selectColour($event, colour)"
            class="relative z-20 cursor-pointer select-none py-2 px-3"
            :class="{ 'bg-gray-100': selected }"
          >
            <div class="flex items-center">
              <span
                :class="`bg-${colour}-400 inline-block h-2 w-2 flex-shrink-0 rounded-full`"
                aria-hidden="true"
              />
            </div>
          </li>
        </ListboxOption>
      </ListboxOptions>
    </transition>
  </Listbox>
</template>

<script setup lang="ts">
import { ref, Ref, watch, PropType } from "vue";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions
} from "@headlessui/vue";

const colours = ["gray", "green", "yellow", "red"];
const open = ref(false);

const props = defineProps({
  modelValue: String as PropType<string | null>,
  readonly: Boolean
});
const selectedColour = ref(props.modelValue) as Ref<string | null>;

const emit = defineEmits(["update:modelValue"]);

const selectColour = (event: MouseEvent, colour: string) => {
  event.preventDefault();
  selectedColour.value = colour ?? null;
  open.value = false;
};

watch(
  () => props.modelValue,
  (newValue) => {
    selectedColour.value = newValue ?? null;
  }
);

watch(
  () => selectedColour.value,
  (newValue) => {
    if (newValue) {
      if (newValue === "gray") {
        newValue = null;
      }
      emit("update:modelValue", newValue);
    }
  }
);
</script>
