<template>
  <NewEntryModal
    v-if="playbook"
    :show="showNewEntryModal"
    :playbook="playbook"
    :entryToDuplicate="entryToDuplicate"
    @create-new-entry="createNewEntry"
    @close="
      showNewEntryModal = false;
      entryToDuplicate = null;
    "
  />

  <CopyEntryModal
    v-if="client && entryToCopy"
    :show="showCopyEntryModal"
    :client="client"
    :entry="entryToCopy"
    @close="
      showCopyEntryModal = false;
      entryToCopy = null;
    "
  />

  <CopyPlaybookModal
    v-if="client && playbook"
    :client="client"
    :playbook="playbook"
    :show="showCopyPlaybookModal"
    @close="showCopyPlaybookModal = false"
  />

  <ImportFromDriveModal
    v-if="client && playbook"
    :show="showImportDriveModal"
    :playbook="playbook"
    @close="showImportDriveModal = false"
    @updated="loadEntries"
  />

  <BaseConfirmationModal
    v-if="confirmMigrate"
    title="Migrate playbook"
    description="Migrate this playbook to the new format? (The old Playbook will still be around)"
    button-text="Migrate"
    @confirm="migratePlaybook"
    @close="confirmMigrate = false"
  />

  <PlaybookSearchBar
    v-if="client"
    v-show="showFilter"
    :client="client"
    v-model:filterTags="filterTags"
    v-model:filterText="filterText"
    class="my-2"
  />

  <BulkTagModal
    :show="tagModalOpen"
    @close="tagModalOpen = false"
    @completed="entrySelection.clear()"
    :entries="filteredAndSelectedEntries"
  />

  <BaseConfirmationModal
    v-if="confirmDelete"
    title="Delete entry"
    description="Deleting this entry can't be undone. Are you sure you want to continue?"
    button-text="Delete"
    @confirm="deleteEntry"
    @close="confirmDelete = false"
  />

  <BaseAlertModal
    v-if="exportedPlaybook"
    title="Export complete"
    @close="exportedPlaybook = null"
  >
    <p class="text-sm text-gray-500">
      <a :href="exportedPlaybook.url" target="window">
        Exported to {{ exportedPlaybook.filename }}. Click here to open.
      </a>
    </p>
  </BaseAlertModal>

  <FallbacksModal
    v-if="playbook && fallbackEntry != null"
    :show="fallbackEntry != null"
    :entry="fallbackEntry"
    @close="fallbackEntry = null"
    :variables="playbook.variables"
  />

  <EscalationTemplateModal
    v-if="playbook && escalationEntry != null"
    :show="escalationEntry != null"
    :entry="escalationEntry"
    @close="escalationEntry = null"
  />

  <div v-if="playbook" class="my-2 flex items-start justify-between">
    <span
      class="mt-2 text-xs font-semibold uppercase tracking-wide text-gray-500"
    >
      {{ entryCountDescription }}
    </span>
    <span class="relative z-20 inline-flex rounded text-xs shadow-sm">
      <HeadlessMenu as="div" class="relative inline-block text-left">
        <div>
          <MenuButton
            class="inline-flex w-full justify-center rounded-l-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 focus:ring-offset-gray-100 disabled:opacity-50"
          >
            Import/export
            <ChevronDownIcon class="-mr-1 ml-2 h-4 w-4" aria-hidden="true" />
          </MenuButton>
        </div>

        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <div class="py-1">
              <MenuItem v-if="playbook.backupFolderUrl">
                <a
                  :href="playbook.backupFolderUrl"
                  target="_window"
                  class="group flex items-center px-4 py-2 text-sm text-gray-700"
                >
                  <ArchiveBoxIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Go to backup folder
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  ]"
                  @click="downloadPlaybook"
                  :disabled="downloading"
                >
                  <CloudArrowDownIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  {{ downloadButtonLabel }}
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  ]"
                  @click="showImportDriveModal = true"
                >
                  <CloudArrowUpIcon
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Import entries from Drive
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }">
                <a
                  @click="exportToDrive"
                  :disabled="exporting"
                  href="#"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  ]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path
                      d="M9.097 6.15L4.31 14.443l1.755 3.032 4.785-8.29L9.097 6.15zm-1.3 12.324h9.568l1.751-3.034H9.55l-1.752 3.034zm11.314-5.034l-4.786-8.29H10.83l4.787 8.29h3.495zM8.52 3.15h6.96L22 14.444l-3.48 6.03H5.49L2 14.444 8.52 3.15zm3.485 8.036l-1.302 2.254h2.603l-1.301-2.254z"
                    />
                  </svg>

                  {{ exportButtonLabel }}
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </transition>
      </HeadlessMenu>

      <button
        class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo disabled:opacity-50 disabled:opacity-50"
        @click="tagModalOpen = true"
        :disabled="entrySelection.entries.size === 0"
      >
        <SquaresPlusIcon class="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
        Bulk tag
      </button>
      <button
        class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo disabled:opacity-50"
        @click="showFilter = !showFilter"
      >
        <FunnelIcon class="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
        {{ showFilter ? "Hide filter" : "Show filter" }}
      </button>
      <button
        type="button"
        class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-4 py-2 font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo disabled:opacity-50"
        @click.prevent="ordering = !ordering"
      >
        <template v-if="ordering">
          <LockOpenIcon class="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
          Lock ordering
        </template>
        <template v-else>
          <LockClosedIcon class="-ml-1 mr-2 h-4 w-4" aria-hidden="true" />
          Unlock ordering
        </template>
      </button>
      <BaseButton
        type="button"
        position="middle"
        size="small"
        buttonStyle="white"
        @click.prevent="showCopyPlaybookModal = true"
      >
        <BookOpenIcon class="ml-1 mr-2 h-4 w-4" aria-hidden="true" />
        Copy playbook
      </BaseButton>
      <BaseButton
        v-if="!isLeap"
        type="button"
        position="middle"
        size="small"
        buttonStyle="white"
        @click.prevent="confirmMigrate = true"
      >
        <WrenchIcon class="ml-1 mr-2 h-4 w-4" aria-hidden="true" />
        Migrate to Leap
      </BaseButton>
      <BaseButton
        @click="showNewEntryModal = true"
        position="right"
        size="small"
        buttonStyle="white"
      >
        <PlusIcon class="ml-1 mr-2 h-4 w-4" aria-hidden="true" />
        New entry
      </BaseButton>
    </span>
  </div>

  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div
          class="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg"
        >
          <table
            v-if="ordering"
            class="min-w-full table-auto divide-y divide-gray-200"
          >
            <thead class="bg-gray-50">
              <tr>
                <th
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Heading
                </th>
              </tr>
            </thead>

            <Draggable
              :list="entries"
              item-key="position"
              class="divide-y divide-gray-200 bg-white"
              animation="200"
              ghost-class="ghost"
              tag="tbody"
              :disabled="!ordering"
              @start="drag = true"
              @end="drag = false"
              @change="moveEntry"
            >
              <template #item="{ element }">
                <tr
                  class="ordering bg-white align-top"
                  :class="{
                    'hover:bg-gray-50': !drag && ordering,
                    sorting: ordering
                  }"
                >
                  <td class="px-6 py-2 text-sm font-medium text-gray-800">
                    <Bars4Icon class="mr-4 inline h-5 w-5 align-bottom" />
                    <span
                      v-html="displayStringWithFilterResult(element.heading)"
                    />
                  </td>
                </tr>
              </template>
            </Draggable>
          </table>
          <table v-else class="min-w-full table-auto divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th class="px-6 py-4">
                  <input
                    type="checkbox"
                    class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-lexoo"
                    :indeterminate="partialSelected"
                    :checked="allEntriesSelected"
                    @click="bulkSelect"
                  />
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Heading
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Entry description
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                  v-if="!isLeap"
                >
                  Tags
                </th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody v-if="!playbook">
              <tr>
                <td
                  class="px-2 py-4 text-center align-top text-gray-500"
                  colspan="100"
                >
                  Please select a playbook to view entries.
                </td>
              </tr>
            </tbody>
            <tbody v-else-if="loading">
              <tr>
                <td colspan="100"><LoadingPanel /></td>
              </tr>
            </tbody>
            <tbody v-else-if="filteredEntries.length == 0">
              <tr>
                <td
                  class="px-2 py-4 text-center align-top text-gray-500"
                  colspan="100"
                >
                  No entries to show
                </td>
              </tr>
            </tbody>

            <tbody v-else-if="isLeap" class="divide-y divide-gray-200 bg-white">
              <EntrySuperRow
                v-for="entry in filteredEntries"
                :entry="entry"
                :entrySelection="entrySelection"
                :filterText="filterText"
                :key="entry.id"
                @delete="
                  entryToDelete = $event;
                  confirmDelete = true;
                "
                @save="saveEntry($event)"
                @edit-escalations="escalationEntry = entry"
                @duplicate="duplicateEntry(entry)"
                @copyEntry="copyEntry(entry)"
              />
            </tbody>
            <tbody v-else class="divide-y divide-gray-200 bg-white">
              <EntryRow
                v-for="entry in filteredEntries"
                :entry="entry"
                :key="entry.id"
                :entrySelection="entrySelection"
                :filterText="filterText"
                :showThirdParty="Boolean(showThirdParty)"
                @delete="
                  entryToDelete = $event;
                  confirmDelete = true;
                "
                @save="saveEntry($event)"
                @edit-fallbacks="fallbackEntry = entry"
                @edit-escalations="escalationEntry = entry"
                @duplicate="duplicateEntry(entry)"
                @copyEntry="copyEntry(entry)"
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div v-if="playbook" class="mt-2 flex justify-end">
    <BaseButton
      @click="showNewEntryModal = true"
      buttonStyle="white"
      size="small"
    >
      New entry
    </BaseButton>
  </div>
  <ReloadPlaybookPrompt v-if="playbook" :playbook="playbook" />
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";

import { format } from "date-fns";
import Draggable from "vuedraggable";
import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import {
  ArchiveBoxIcon,
  Bars4Icon,
  BookOpenIcon,
  ChevronDownIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  FunnelIcon,
  LockClosedIcon,
  LockOpenIcon,
  PlusIcon,
  SquaresPlusIcon,
  WrenchIcon
} from "@heroicons/vue/24/outline";
import { Entry, Playbook, Tag } from "@/types";
import { useAdmin } from "@/components/shared/Admin";
import { useAdminStore } from "@/store/AdminStore";
import { useUserStore } from "@/store/UserStore";

import ApiService from "@/services/ApiService";
import BaseAlertModal from "@/components/base/BaseAlertModal.vue";
import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";
import BulkTagModal from "@/components/admin/BulkTagModal.vue";
import EntryFilter from "@/services/EntryFilter";
import EntryRow from "@/components/admin/EntryRow.vue";
import EntrySuperRow from "@/components/admin/EntrySuperRow.vue";
import CopyEntryModal from "@/views/admin/CopyEntryModal.vue";
import CopyPlaybookModal from "@/views/admin/CopyPlaybookModal.vue";
import EscalationTemplateModal from "@/components/EscalationTemplateModal.vue";
import FallbacksModal from "@/components/admin/FallbacksModal.vue";
import ImportFromDriveModal from "@/components/admin/ImportFromDriveModal.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import NewEntryModal from "@/views/NewEntryModal.vue";
import PlaybookSearchBar from "@/components/sidebar/PlaybookSearchBar.vue";
import ReloadPlaybookPrompt from "@/components/ReloadPlaybookPrompt.vue";
import { displayStringWithMarkedText } from "@/helpers/TextHelpers";

type ExportedPlaybook = {
  filename: string;
  url: string;
};

export default defineComponent({
  components: {
    ArchiveBoxIcon,
    Bars4Icon,
    BaseAlertModal,
    BaseConfirmationModal,
    BookOpenIcon,
    BulkTagModal,
    ChevronDownIcon,
    EntryRow,
    EntrySuperRow,
    CloudArrowDownIcon,
    CloudArrowUpIcon,
    CopyEntryModal,
    CopyPlaybookModal,
    Draggable,
    EscalationTemplateModal,
    FallbacksModal,
    FunnelIcon,
    ImportFromDriveModal,
    LoadingPanel,
    LockClosedIcon,
    LockOpenIcon,
    HeadlessMenu,
    MenuButton,
    MenuItem,
    MenuItems,
    NewEntryModal,
    PlaybookSearchBar,
    PlusIcon,
    ReloadPlaybookPrompt,
    SquaresPlusIcon,
    WrenchIcon
  },

  setup() {
    const showNewEntryModal = ref(false);
    const showPropsSlideOver = ref(false);

    const selected = reactive(new Set<Entry>());
    const entrySelection = {
      entries: selected,
      addMultiple(newEntries: Entry[]) {
        newEntries.forEach((entry) => {
          selected.add(entry);
        });
      },
      clear() {
        selected.clear();
      },
      toggle(entry: Entry) {
        if (selected.has(entry)) {
          selected.delete(entry);
        } else {
          selected.add(entry);
        }
      }
    };

    const { client, playbook } = useAdmin();

    const userStore = useUserStore();
    const adminStore = useAdminStore();

    return {
      adminStore,
      entrySelection,
      client,
      playbook,
      showNewEntryModal,
      showPropsSlideOver,
      userStore
    };
  },

  data() {
    return {
      entryToCopy: null as Entry | null,
      entryToDelete: null as Entry | null,
      entryToDuplicate: null as Entry | null,
      confirmDelete: false,
      confirmMigrate: false,
      downloading: false,
      drag: false,
      escalationEntry: null as Entry | null,
      exporting: false,
      exportedPlaybook: null as ExportedPlaybook | null,
      fallbackEntry: null as Entry | null,
      filterText: "",
      filterTags: [],
      showCopyEntryModal: false,
      showCopyPlaybookModal: false,
      showImportDriveModal: false,
      showMoreEntry: null as Entry | null,
      showFilter: false,
      ordering: false,
      tagModalOpen: false
    };
  },

  computed: {
    allEntriesSelected(): boolean {
      return this.numberSelected === this.numberEntries;
    },

    entryCountDescription(): string {
      if (this.loading) {
        return "";
      }
      const totalCount = this.entries.length;
      const filterCount = this.filteredEntries.length;
      if (filterCount < totalCount && !this.ordering) {
        return `${filterCount} of ${totalCount} entries`;
      }
      return `${totalCount} entries`;
    },

    entries(): Entry[] {
      return this.adminStore.entries;
    },

    downloadButtonLabel() {
      if (this.downloading) {
        return "Please wait...";
      } else {
        return "Download playbook";
      }
    },

    exportButtonLabel() {
      if (this.exporting) {
        return "Please wait...";
      } else {
        return "Create backup in Drive";
      }
    },

    // This uses a combination of the selection, and filtering
    filteredAndSelectedEntries(): Entry[] {
      return Array.from(this.entrySelection.entries).filter((value: Entry) =>
        this.filteredEntries.includes(value)
      );
    },

    filteredEntries(): Entry[] {
      return new EntryFilter(this.entries).filter(
        this.filterText,
        this.filterTags
      );
    },

    isLeap() {
      return this.playbook && this.playbook.lexplayLeap;
    },

    loading() {
      return this.adminStore.entriesLoading;
    },

    numberSelected(): number {
      return this.entrySelection.entries.size;
    },

    numberEntries(): number {
      return this.filteredEntries.length;
    },

    partialSelected(): boolean {
      return (
        this.numberSelected > 0 && this.numberSelected < this.numberEntries
      );
    },

    showThirdParty() {
      return this.playbook && this.playbook.playbookType == "Universal";
    }
  },

  mounted() {
    this.loadEntries();
    this.userStore.loadTeamMembers();
  },

  watch: {
    playbook: {
      handler(playbook: Playbook | null) {
        if (playbook) {
          this.loadEntries();
        }
      }
    }
  },

  methods: {
    bulkSelect() {
      if (this.allEntriesSelected) {
        this.entrySelection.clear();
      } else {
        this.entrySelection.addMultiple(this.filteredEntries);
      }
    },

    copyEntry(entry: Entry) {
      this.entryToCopy = entry;
      this.showCopyEntryModal = true;
    },

    createNewEntry(newEntry: Entry) {
      this.adminStore.createEntry(newEntry);
    },

    deleteEntry() {
      if (this.entryToDelete) {
        this.adminStore.deleteEntry(this.entryToDelete);
        this.entryToDelete = null;
      }
    },

    displayStringWithFilterResult(value: string | null) {
      return displayStringWithMarkedText(value, this.filterText);
    },

    downloadPlaybook() {
      if (this.playbook && this.client) {
        this.downloading = true;
        const clientName = this.client.name;
        ApiService.downloadPlaybookXls(this.playbook).then((response) => {
          const date = format(new Date(), "yyyy.MM.dd");
          const filename = `${clientName} Playbook ${date}.xlsx`;
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename); //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          this.downloading = false;
        });
      }
    },

    duplicateEntry(entry: Entry) {
      this.entryToDuplicate = entry;
      this.showNewEntryModal = true;
    },

    exportToDrive() {
      if (this.playbook) {
        this.exporting = true;
        ApiService.exportPlaybookDrive(this.playbook).then(({ data }) => {
          this.exporting = false;
          this.exportedPlaybook = data.data.playbook;
        });
      }
    },

    loadEntries() {
      if (this.playbook) {
        this.adminStore.loadEntriesFromPlaybook(this.playbook);
      }
    },

    migratePlaybook() {
      if (this.playbook) {
        this.adminStore.migratePlaybook(this.playbook);
      }
    },

    moveEntry(event: { moved: { element: Entry; newIndex: number } }) {
      const context = event.moved;
      const entry = context.element;
      entry.position = context.newIndex;

      const orderedEntry = {
        id: entry.id,
        position: context.newIndex
      };

      ApiService.updateEntryPosition(orderedEntry);
      this.adminStore.replaceEntry(entry);
      this.entries.forEach((e, index) => {
        this.adminStore.updateEntryPosition(e.id, index);
      });
    },

    saveEntry(entryToSave: Entry) {
      const tags = entryToSave.tags ?? [];
      const entry: Entry = {
        ...entryToSave,
        tagIds: tags.map((tag: Tag) => tag.id)
      };

      this.adminStore.updateEntry(entry);
    }
  }
});
</script>

<style lang="scss" scoped>
.ghost {
  opacity: 0.5;
  @apply bg-gray-100;
}

.ordering {
  @apply cursor-move;
}
</style>
