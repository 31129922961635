<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block min-h-full w-full max-w-2xl transform overflow-hidden rounded-lg bg-white py-2 px-0 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mt-3 ml-4 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Escalation for {{ entry.heading }}
                </DialogTitle>
                <div>
                  <p class="text-sm text-gray-500">
                    This is where you can set the escalation for this entry.
                  </p>
                </div>
                <div class="pt-2 pr-4">
                  <div>
                    <BaseInput
                      v-model="escalationTemplate.approverName"
                      label="Approver name"
                      placeholder="Jacinda Ardern"
                    />
                  </div>
                  <div>
                    <BaseInput
                      v-model="escalationTemplate.approverEmail"
                      inputType="email"
                      label="Approver email"
                      placeholder="jacinda@newzealand.co.nz"
                    />
                  </div>
                  <div>
                    <BaseInput
                      v-model="escalationTemplate.approverJobTitle"
                      label="Approver job title"
                      placeholder="CFO"
                    />
                  </div>
                  <div>
                    <BaseInput
                      v-model="escalationTemplate.text"
                      inputType="textarea"
                      label="Escalation text"
                      rows="4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <LoadingPanel v-if="loading" />

            <div class="flex space-x-3 bg-gray-50 px-4 py-3 sm:px-6">
              <BaseButton @click="saveEscalationTemplate"> Save </BaseButton>
              <BaseButton @click="$emit('close')" buttonStyle="white">
                Cancel
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";

import { useSidebarStore } from "@/store/SidebarStore";
import LoadingPanel from "@/components/LoadingPanel.vue";
import ApiService from "@/services/ApiService";
import { Entry, EscalationTemplate } from "@/types";

export default defineComponent({
  components: {
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    LoadingPanel,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  props: {
    entry: {
      required: true,
      type: Object as PropType<Entry>
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  data() {
    const escalationTemplate: EscalationTemplate = this.entry
      .escalationTemplate || {
      entryId: this.entry.id,
      text: "",
      approverEmail: "",
      approverName: "",
      approverJobTitle: ""
    };
    return {
      escalationTemplate,
      loading: false
    };
  },

  methods: {
    saveEscalationTemplate() {
      if (this.entry.id) {
        const updateEntry: Entry = {
          ...this.entry,
          escalationTemplate: this.escalationTemplate
        };
        ApiService.updateEntry(updateEntry).then(() => {
          useSidebarStore().updateEntry(updateEntry);
          this.$emit("close");
        });
      }
    }
  }
});
</script>
