<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog as="div" class="relative z-30" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6"
            >
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-lg font-medium leading-6 text-gray-900"
                  >
                    Interactions for {{ userName }}
                  </DialogTitle>
                  <div v-if="interactions.length === 0" class="mb-20">
                    No interactions for this user yet.
                  </div>
                  <div class="mt-5">
                    <LoadingPanel v-if="loading" />
                    <ul v-else role="list" class="-mb-8">
                      <li
                        v-for="(interaction, interactionIdx) in interactions"
                        :key="interaction.id"
                      >
                        <div class="relative pb-8">
                          <span
                            v-if="interactionIdx !== interactions.length - 1"
                            class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                          <div class="relative flex space-x-3">
                            <div>
                              <span
                                :class="[
                                  getIconBackground(interaction.action),
                                  'flex h-8 w-8 items-center justify-center rounded-full ring-8 ring-white'
                                ]"
                              >
                                <component
                                  :is="getIcon(interaction.action)"
                                  class="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            </div>
                            <div class="flex-1 space-y-1">
                              <div class="flex items-center justify-between">
                                <h3 class="text-sm font-medium">
                                  {{ interaction.action }}
                                </h3>
                                <p class="text-sm text-gray-500">
                                  {{ createdDate(interaction.createdAt) }}
                                </p>
                              </div>
                              <p
                                v-for="(
                                  section, sectionIdx
                                ) in extraInfoSections(interaction)"
                                :key="sectionIdx"
                              >
                                <label class="text-sm text-gray-500">
                                  {{ section.label }}{{ ": " }}
                                </label>
                                <span class="text-sm text-gray-900">
                                  {{ section.value }}
                                </span>
                              </p>
                              <p v-if="interaction.playbookId">
                                <label class="text-sm text-gray-500">
                                  Playbook id:
                                </label>
                                <span class="text-sm text-gray-900">
                                  {{ interaction.playbookId }}
                                </span>
                              </p>
                              <p v-if="interaction.entryId">
                                <label class="text-sm text-gray-500">
                                  Entry id:
                                </label>
                                <span class="text-sm text-gray-900">
                                  {{ interaction.entryId }}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <BaseButton @click="$emit('close')" buttonStyle="white">
                  Close
                </BaseButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import {
  ChatBubbleLeftIcon,
  DocumentTextIcon,
  UserIcon
} from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Interaction, User } from "@/types";
import ApiService from "@/services/ApiService";

import LoadingPanel from "@/components/LoadingPanel.vue";

const getEventType = {
  "inserted template wording": {
    icon: DocumentTextIcon,
    iconBackground: "bg-blue-500"
  },

  "inserted template comment": {
    icon: ChatBubbleLeftIcon,
    iconBackground: "bg-green-500"
  },

  login: {
    icon: UserIcon,
    iconBackground: "bg-gray-400"
  }
};

type ExtraInfoValue = string | Record<string, string[]>;

export default defineComponent({
  components: {
    HeadlessDialog,
    DialogOverlay,
    DialogPanel,
    DialogTitle,
    LoadingPanel,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  props: {
    show: {
      required: true,
      type: Boolean
    },

    user: {
      required: true,
      type: Object as PropType<User>
    }
  },

  data() {
    return {
      interactions: [] as Interaction[],
      loading: true
    };
  },

  computed: {
    userName(): string {
      return this.user.name ? this.user.name : "Guest";
    }
  },

  methods: {
    createdDate(date: string | Date): string {
      return this.veryShortDateTime(date);
    },

    extraInfoSections(
      interaction: Interaction
    ): { label: string; value: ExtraInfoValue }[] {
      const sections: { label: string; value: ExtraInfoValue }[] = [];
      if (!interaction.extraInfo) {
        return sections;
      }
      Object.keys(interaction.extraInfo).forEach((key: string) => {
        const value: ExtraInfoValue = interaction.extraInfo[key];
        sections.push({
          label: key,
          value: value
        });
      });

      return sections;
    },

    getIcon(action: string) {
      const eventType = getEventType[action];
      if (eventType) {
        return eventType.icon;
      }
      return DocumentTextIcon;
    },

    getIconBackground(action: string) {
      const eventType = getEventType[action];
      if (eventType) {
        return eventType.iconBackground;
      }
      return "bg-green-500";
    },

    loadInteractions() {
      this.loading = true;
      ApiService.getInteractionsForUser(this.user.id).then(({ data }) => {
        this.interactions = data.interactions;
        this.loading = false;
      });
    },

    veryShortDate(date: Date | string) {
      const dateFormat = "MMM d";
      if (typeof date === "string") {
        return formatInTimeZone(parseISO(date), "Europe/London", dateFormat);
      }
      return formatInTimeZone(date, "Europe/London", dateFormat);
    },

    veryShortDateTime(date: Date | string) {
      const dateFormat = "MMM d, h:mmbbb";
      if (typeof date === "string") {
        return formatInTimeZone(parseISO(date), "Europe/London", dateFormat);
      }
      return formatInTimeZone(date, "Europe/London", dateFormat);
    }
  },

  mounted() {
    this.loadInteractions();
  }
});
</script>

<style scoped></style>
