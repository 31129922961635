<template>
  <div v-if="showAlert" :class="`bg-${colour}-50`" class="alert rounded p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <CheckCircleIcon
          v-if="status === 'success'"
          class="h-5 w-5 text-green-400"
          aria-hidden="true"
        />
        <XCircleIcon
          v-if="status === 'caution'"
          class="h-5 w-5 text-red-400"
          aria-hidden="true"
        />
        <ExclamationTriangleIcon
          v-if="status === 'info'"
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium" :class="`text-${colour}-800`">
          {{ title }}
        </h3>
        <div class="mt-2 text-sm" :class="`text-${colour}-700`">
          <p v-html="message"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XCircleIcon
} from "@heroicons/vue/24/solid";
import { useAlertStore } from "@/store/AlertStore";

export default defineComponent({
  setup() {
    const alertStore = useAlertStore();

    return {
      alertStore
    };
  },

  components: { CheckCircleIcon, ExclamationTriangleIcon, XCircleIcon },

  computed: {
    colour() {
      if (this.status === "info") {
        return "yellow";
      } else if (this.status === "success") {
        return "green";
      } else if (this.status === "caution") {
        return "red";
      } else {
        return "blue";
      }
    },

    message(): string | undefined {
      return this.alertStore.alert.message;
    },

    showAlert(): boolean {
      return Boolean(this.status && (this.message || this.title));
    },

    status(): string | undefined {
      return this.alertStore.alert.status;
    },

    textColour() {
      if (this.status === "info") {
        return "text-yellow-800";
      } else if (this.status === "success") {
        return "text-green-800";
      } else if (this.status === "caution") {
        return "text-red-800";
      } else {
        return "text-blue-800";
      }
    },

    title(): string | undefined {
      return this.alertStore.alert.title;
    }
  },

  methods: {
    closeAlert() {
      this.alertStore.clearAlert();
    }
  },

  watch: {
    message: {
      immediate: true,
      handler() {
        if (this.showAlert) {
          setTimeout(this.closeAlert, 10000);
        }
      }
    }
  }
});
</script>
