<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      auto-reopen="true"
      class="fixed inset-0 z-30 overflow-hidden"
      @close="
        $emit('close');
        clearFallbacks();
      "
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay
          class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
        <div class="fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel class="pointer-events-auto w-screen max-w-2xl">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl"
              >
                <div class="flex-1">
                  <!-- Header -->
                  <div class="bg-gray-50 px-4 py-6 sm:px-6">
                    <div class="flex items-start justify-between space-x-3">
                      <div class="space-y-1">
                        <DialogTitle class="text-lg font-medium text-gray-900">
                          Fallbacks for {{ entry.heading }}
                        </DialogTitle>
                        <p class="text-sm text-gray-500">
                          This is where you can set all the fallbacks for this
                          entry.
                        </p>
                      </div>
                      <div class="flex h-7 items-center">
                        <button
                          type="button"
                          class="text-gray-400 hover:text-gray-500"
                          @click="$emit('close')"
                        >
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  <LoadingPanel v-if="loading" />

                  <!-- container -->
                  <div v-else class="p-6">
                    <div>
                      <div class="sm:hidden">
                        <label for="tabs" class="sr-only">
                          Select a scenario
                        </label>
                        <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
                        <select
                          class="block w-full rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
                          v-model="fallbackScenario"
                        >
                          <option
                            v-for="(fb, index) in fallbackScenarios"
                            :key="index"
                            :value="fb"
                          >
                            {{ textOrdinalize(index, -1) }} scenario
                          </option>
                        </select>
                      </div>
                      <div class="hidden sm:block">
                        <div class="border-b border-gray-200">
                          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                            <button
                              v-for="(fb, index) in fallbackScenarios"
                              :key="index"
                              :class="[
                                fallbackScenario && fb.id == fallbackScenario.id
                                  ? 'border-indigo-500 text-indigo-600'
                                  : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                                'whitespace-nowrap border-b-2 py-4 px-1 text-sm font-medium focus:outline-none'
                              ]"
                              @click.prevent="fallbackScenario = fb"
                            >
                              {{ ordinalize(index) }} scenario
                            </button>
                          </nav>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-end">
                      <button
                        @click.prevent="addFallbackScenario"
                        class="mt-4 inline-flex items-center place-self-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-xs font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 sm:text-sm"
                      >
                        <PlusSmallIcon
                          class="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Add new scenario
                      </button>
                    </div>
                  </div>

                  <div v-if="fallbackScenario" class="relative px-6 text-sm">
                    <button
                      v-if="fallbackScenario != fallbackScenarios[0]"
                      @click.prevent="deleteFallbackScenario"
                      type="button"
                      class="absolute top-1 right-7 ml-6 rounded bg-white text-xs font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                    >
                      Remove scenario
                    </button>
                    <BaseInput
                      v-model="fallbackScenario.answer"
                      inputType="textarea"
                      label="Scenario"
                      placeholder="i.e. If tool is: • Publicly facing, AND • Integrated into our product"
                      rows="2"
                    />
                    <template
                      v-for="(fallback, index) in fallbackScenario.fallbacks"
                      :key="index"
                    >
                      <div class="relative">
                        <div
                          class="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-start">
                          <span class="bg-white pr-2 text-sm text-gray-500">
                            {{ textOrdinalize(index, -1) }}
                            fallback description
                          </span>
                        </div>
                      </div>
                      <div class="relative mb-4">
                        <BaseEditableText
                          :placeholder="
                            'i.e. ' +
                            (placeholders[index] ||
                              placeholders[placeholders.length - 1])
                          "
                          v-model="fallback.description"
                        />
                      </div>

                      <div class="relative">
                        <div
                          class="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-start">
                          <span class="bg-white pr-2 text-sm text-gray-500">
                            {{ textOrdinalize(index, -1) }}
                            fallback template wording
                          </span>
                        </div>
                      </div>
                      <div class="relative mb-6">
                        <SimpleEditableText
                          placeholder="Text for insertion into the document"
                          v-model="fallback.templateWording"
                        />
                      </div>
                      <div class="relative">
                        <div
                          class="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div class="w-full border-t border-gray-300" />
                        </div>
                        <div class="relative flex justify-start">
                          <span class="bg-white pr-2 text-sm text-gray-500">
                            {{ textOrdinalize(index, -1) }}
                            fallback template comment
                          </span>
                        </div>
                      </div>
                      <div class="relative mb-6">
                        <SimpleEditableText
                          placeholder="This can be inserted as a comment bubble as an explanation to the counterparty"
                          v-model="fallback.templateComment"
                        />
                      </div>
                      <button
                        @click.prevent="deleteFallback(fallback)"
                        type="button"
                        class="absolute right-6 -mt-4 rounded bg-white text-xs font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                      >
                        Remove fallback
                      </button>
                    </template>
                    <div class="relative mt-10 sm:mt-0">
                      <div
                        class="absolute inset-0 flex items-center"
                        aria-hidden="true"
                      >
                        <div class="w-full border-t border-gray-300" />
                      </div>
                      <div class="relative flex justify-center">
                        <button
                          @click.prevent="addFallback(fallbackScenario)"
                          type="button"
                          class="inline-flex items-center rounded-full border border-gray-300 bg-white px-4 py-1.5 text-xs font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 sm:text-sm"
                        >
                          <PlusSmallIcon
                            class="-ml-1.5 mr-1 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <span>Add another fallback</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="flex justify-end space-x-3 bg-gray-50 px-4 py-3 px-6"
                >
                  <BaseButton @click="saveFallbacks"> Save </BaseButton>
                  <BaseButton
                    @click="
                      $emit('close');
                      clearFallbacks();
                    "
                    buttonStyle="white"
                  >
                    Cancel
                  </BaseButton>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
const placeholders = [
  "• indemnity by vendor required • Uncapped ",
  "• indemnity by vendor required • capped at USD 5 million ",
  "• Third party IP indemnity by vendor required • capped at insurance cover (if higher than USD 2 million) or USD 2 million"
];

import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { PlusSmallIcon, XMarkIcon } from "@heroicons/vue/24/solid";

import { useAdminStore } from "@/store/AdminStore";
import BaseEditableText from "@/components/base/BaseEditableText.vue";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import ApiService from "@/services/ApiService";
import {
  Entry,
  Fallback,
  FallbackScenario,
  NewFallback,
  NewFallbackScenario
} from "@/types";
import { ordinalize, textOrdinalize } from "@/helpers/TextHelpers";

export default defineComponent({
  setup() {
    const adminStore = useAdminStore();
    return {
      adminStore
    };
  },

  components: {
    BaseEditableText,
    HeadlessDialog,
    DialogOverlay,
    DialogPanel,
    DialogTitle,
    LoadingPanel,
    PlusSmallIcon,
    SimpleEditableText,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  props: {
    entry: {
      required: true,
      type: Object as PropType<Entry>
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      deletedFallbacks: {} as Record<number, Fallback[]>,
      deletedFallbackScenarios: [] as FallbackScenario[],
      fallbackScenario: null as FallbackScenario | NewFallbackScenario | null,
      fallbackScenarios: [] as (FallbackScenario | NewFallbackScenario)[],
      loading: false,
      ordinalize,
      placeholders,
      textOrdinalize
    };
  },

  watch: {
    entry: {
      immediate: true,
      deep: true,

      handler() {
        this.clearFallbacks();
      }
    }
  },

  methods: {
    addFallback(scenario: FallbackScenario | NewFallbackScenario) {
      const fallbacksScenarioLength = scenario.fallbacks.length;
      const newFallback: NewFallback = {
        fallbackScenarioId: scenario.id,
        position: fallbacksScenarioLength,
        templateWording: "",
        templateComment: "",
        description: ""
      };
      scenario.fallbacks.push(newFallback);
    },

    addFallbackScenario() {
      const newFallbackScenario: NewFallbackScenario = {
        answer: "",
        entryId: this.entry.id,
        fallbacks: [],
        position: this.fallbackScenarios.length
      };
      this.fallbackScenarios.push(newFallbackScenario);
      this.fallbackScenario = newFallbackScenario;
      this.addFallback(this.fallbackScenario);
    },

    deleteFallback(fallback: Fallback) {
      if (this.fallbackScenario) {
        if (fallback.id && this.fallbackScenario.id) {
          fallback._destroy = "1";
          if (!this.deletedFallbacks[this.fallbackScenario.id]) {
            this.deletedFallbacks[this.fallbackScenario.id] = [];
          }
          this.deletedFallbacks[this.fallbackScenario.id].push(fallback);
        }
        const index = this.fallbackScenario.fallbacks.indexOf(fallback);
        this.fallbackScenario.fallbacks.splice(index, 1);
        if (this.fallbackScenario.fallbacks.length == 0) {
          this.addFallback(this.fallbackScenario);
        }
      }
    },

    deleteFallbackScenario() {
      if (this.fallbackScenario) {
        if (this.fallbackScenario.id) {
          this.fallbackScenario._destroy = "1";
          this.deletedFallbackScenarios.push(this.fallbackScenario);
        }

        const index = this.fallbackScenarios.indexOf(this.fallbackScenario);
        this.fallbackScenarios.splice(index, 1);
        this.fallbackScenario = this.fallbackScenarios[0];
      }
    },

    clearFallbacks() {
      if (this.entry.id) {
        this.loading = true;
        ApiService.getFallbackScenarios(this.entry.id).then(({ data }) => {
          this.fallbackScenarios = data.fallbackScenarios;
          this.loading = false;
          if (data.fallbackScenarios.length === 0) {
            this.addFallbackScenario();
          } else {
            // ensure all the scenarios has at least one fallback
            this.fallbackScenarios.forEach((scenario) => {
              if (scenario.fallbacks.length === 0) {
                this.addFallback(scenario);
              }
            });
          }
          this.fallbackScenario = data.fallbackScenarios[0];
        });
      }
    },

    saveFallbacks() {
      if (this.entry.id) {
        const scenarios = this.fallbackScenarios.concat(
          this.deletedFallbackScenarios
        );
        this.fallbackScenarios.forEach((scenario) => {
          if (scenario.id && this.deletedFallbacks[scenario.id]) {
            scenario.fallbacks = scenario.fallbacks.concat(
              this.deletedFallbacks[scenario.id]
            );
          }
        });
        this.adminStore.saveFallbackScenarios(this.entry, scenarios);
        this.$emit("close");
        this.clearFallbacks();
      }
    }
  }
});
</script>
