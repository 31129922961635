// Takes in an entry and returns the variable identifiers
import { Entry } from "@/types";

const getVariableIdentifiersFromSquigglyString = (input: string): string[] => {
  const result = new Set();
  const regex = /{\s*([^}]+)\s*}/g;
  let match = regex.exec(input);
  while (match) {
    result.add(match[1].trim());
    match = regex.exec(input);
  }
  return Array.from(result) as string[];
};

const getVariableIdentifiersFromHtml = (input: string): string[] => {
  const result = new Set();
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, "text/html");

  // Find all spans with data-type="variable"
  const spans = doc.querySelectorAll('span[data-type="variable"]');

  // Loop through them to get the data-id attribute
  spans.forEach((span) => {
    const dataId = span.getAttribute("data-id") ?? "";
    result.add(dataId.trim());
  });
  return Array.from(result) as string[];
};

export const getVariableIdentifiersFromString = (input: string): string[] => {
  let result = new Set<string>();
  result = addArrayToSet(
    result,
    getVariableIdentifiersFromSquigglyString(input)
  );
  result = addArrayToSet(result, getVariableIdentifiersFromHtml(input));
  return Array.from(result) as string[];
};

export const addArrayToSet = (
  set: Set<string>,
  array: string[]
): Set<string> => {
  array.forEach((item) => {
    set.add(item);
  });
  return set;
};

export const getVariableIdentifiersFromEntry = (entry: Entry): string[] => {
  let result = new Set<string>();
  result = addArrayToSet(
    result,
    getVariableIdentifiersFromString(entry.entryDescription)
  );
  result = addArrayToSet(
    result,
    getVariableIdentifiersFromString(entry.description)
  );

  result = addArrayToSet(
    result,
    getVariableIdentifiersFromString(entry.templateWording)
  );
  result = addArrayToSet(
    result,
    getVariableIdentifiersFromString(entry.templateComment)
  );
  (entry.fallbackScenarios ?? []).forEach((fallbackScenario) => {
    fallbackScenario.fallbacks.forEach((fallback) => {
      result = addArrayToSet(
        result,
        getVariableIdentifiersFromString(fallback.description)
      );
      result = addArrayToSet(
        result,
        getVariableIdentifiersFromString(fallback.templateWording)
      );
      result = addArrayToSet(
        result,
        getVariableIdentifiersFromString(fallback.templateComment)
      );
    });
  });

  return Array.from(result) as string[];
};

export const getVariableIdentifiersFromEntries = (
  entries: Entry[]
): string[] => {
  let result = new Set<string>();
  entries.forEach((entry) => {
    result = addArrayToSet(result, getVariableIdentifiersFromEntry(entry));
  });

  return Array.from(result) as string[];
};
