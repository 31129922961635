<template>
  <div v-if="hasError" class="mt-8 rounded bg-yellow-50 p-4 text-left">
    <div class="flex">
      <div class="flex-shrink-0">
        <ExclamationTriangleIcon
          class="h-5 w-5 text-yellow-400"
          aria-hidden="true"
        />
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-yellow-800">Sign in failed</h3>
        <div v-if="customError" class="mt-2 space-y-1 text-sm text-yellow-700">
          <p>{{ customError }}</p>
          <p>
            Contact <a href="mailto:team@lexoo.com">team@lexoo.com</a> for
            technical assistance.
          </p>
        </div>
        <div
          v-else-if="userNotFound"
          class="mt-2 space-y-1 text-sm text-yellow-700"
        >
          <p>
            The email and password combination can't be found in the system.
          </p>

          <p>If you can't remember your password you can reset it below.</p>

          <p>
            Contact
            <a href="mailto:team@lexoo.com">team@lexoo.com</a> if you need to
            create an account with LexPlay.
          </p>
        </div>

        <div
          v-else-if="userNotConfirmed"
          class="mt-2 space-y-1 text-sm text-yellow-700"
        >
          <p>
            We need to confirm your email before you login. Click the link in
            the email we sent earlier, or
            <a href="#" @click.prevent="resendConfirmation" class="underline">
              click here to resend the link.
            </a>
          </p>

          <p>
            Of you can contact
            <a href="mailto:team@lexoo.com">team@lexoo.com</a> for assistance.
          </p>
        </div>

        <div v-else class="mt-2 space-y-1 text-sm text-yellow-700">
          <p>There was a problem trying to sign you in.</p>

          <p>
            Please contact
            <a href="mailto:team@lexoo.com">team@lexoo.com</a> for assistance.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ApiService from "@/services/ApiService";

import { useRouter } from "vue-router";

import { ExclamationTriangleIcon } from "@heroicons/vue/24/solid";

const props = defineProps({
  email: String,
  generalError: Boolean,
  userNotFound: Boolean,
  userNotConfirmed: Boolean,
  customError: {
    type: String,
    default: ""
  }
});

const hasError = computed(() => {
  return (
    props.generalError ||
    props.userNotConfirmed ||
    props.userNotFound ||
    props.customError.length > 0
  );
});

const resendConfirmation = async () => {
  const router = useRouter();
  await ApiService.resendConfirmation(props.email);
  router.push("/check-your-email");
};
</script>
