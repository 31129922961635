import { computed, ref } from "vue";
import { defineStore } from "pinia";
import replaceAll from "string.prototype.replaceall";

import { OldSkoolVariable, OldSkoolVariableAnswer } from "@/types";

import { useSidebarStore } from "@/store/SidebarStore";

export const useOldSkoolVariablesStore = defineStore(
  "OldSkoolVariablesStore",
  () => {
    const sidebarStore = useSidebarStore();

    const textWithOldSkoolVariables = computed(() => {
      return (text: string | undefined): string => {
        if (!text) return "";
        oldSkoolVariableAnswers.value.forEach(
          (variableAnswer: OldSkoolVariableAnswer) => {
            text = replaceAll(
              text,
              variableAnswer.identifier,
              variableAnswer.answer
            );
          }
        );
        return text;
      };
    });

    const oldSkoolVariablesWithoutAnswers = computed((): OldSkoolVariable[] => {
      const results = [] as OldSkoolVariable[];
      if (!sidebarStore.playbook || !sidebarStore.playbookInstance) return [];

      sidebarStore.playbook.oldSkoolVariables.forEach(
        (variable: OldSkoolVariable) => {
          if (variable.id && sidebarStore.playbookInstance) {
            const answer =
              sidebarStore.playbookInstance.oldSkoolVariableAnswers[
                variable.id
              ];
            const identifier = variable.identifier;
            if (!answer && identifier) {
              results.push(variable);
            }
          }
        }
      );
      return results;
    });

    const oldSkoolVariablesWithoutAnswersForText = computed(() => {
      return (text: string): OldSkoolVariable[] => {
        const result: OldSkoolVariable[] = [];
        oldSkoolVariablesWithoutAnswers.value.forEach(
          (variable: OldSkoolVariable) => {
            if (text.includes(variable.identifier)) {
              result.push(variable);
            }
          }
        );
        return result;
      };
    });

    const oldSkoolVariableAnswers = computed((): OldSkoolVariableAnswer[] => {
      const results = [] as OldSkoolVariableAnswer[];
      if (!sidebarStore.playbook || !sidebarStore.playbookInstance) return [];
      sidebarStore.playbook.oldSkoolVariables.forEach(
        (variable: OldSkoolVariable) => {
          if (variable.id && sidebarStore.playbookInstance) {
            const answer =
              sidebarStore.playbookInstance.oldSkoolVariableAnswers[
                variable.id
              ];
            const identifier = variable.identifier;
            if (answer && identifier) {
              results.push({ identifier, answer });
            }
          }
        }
      );
      return results;
    });

    return {
      oldSkoolVariablesWithoutAnswersForText,
      textWithOldSkoolVariables
    };
  }
);
