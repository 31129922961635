<template>
  <component
    v-if="officeReady"
    :is="layoutComponent"
    :showFooter="showFooter"
    :showNavBar="showNavBar"
  />
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useSelectionStore } from "@/store/SelectionStore";
import { supportsLocalStorage } from "@/helpers/BrowserPermissions";
import BrowserLayout from "@/layouts/BrowserLayout.vue";
import WordLayout from "@/layouts/WordLayout.vue";
import { useSidebarStore } from "@/store/SidebarStore";

const sidebarStore = useSidebarStore();
const layoutComponent = computed(() => {
  if (sidebarStore.withinWord) {
    return WordLayout;
  } else {
    return BrowserLayout;
  }
});

const officeReady = ref(false);
const route = useRoute();
onMounted(() => {
  if (typeof Office == "undefined") {
    officeReady.value = true;
  } else {
    Office.onReady(() => {
      if (typeof Word !== "undefined") {
        sidebarStore.withinWord = true;
      }
      officeReady.value = true;
    });
  }

  if (supportsLocalStorage()) {
    const authorisationStore = useAuthorisationStore();
    authorisationStore.setDefaultValues();

    const selectionStore = useSelectionStore();
    document.addEventListener("selectionchange", () => {
      const selection = document.getSelection();
      selectionStore.updateSelection({ selection });
    });
  }
});

const showFooter = computed(() => {
  if (route.meta.showFooter) {
    return true;
  } else {
    return false;
  }
});

const showNavBar = computed(() => {
  if (route.meta.hideNavBar) {
    return false;
  } else {
    return true;
  }
});

watch(
  route,
  (to) => {
    if (!to.meta.title) {
      document.title = "LexPlay";
    } else {
      document.title = to.meta.title + " / LexPlay";
    }
  },
  { immediate: true }
);
</script>

<style lang="scss">
p {
  min-height: 1rem; // to ensure empty p tags are shown
}
</style>
