<template>
  <div
    v-if="onlyOne"
    class="mb-2 rounded border border-gray-200 py-3 pl-3 pr-4 text-sm"
  >
    Definition as written is not used in the document.
  </div>
  <div
    v-if="extraFuzzyMatches"
    class="rounded border border-gray-200 py-3 pl-3 pr-4 text-sm"
  >
    Found {{ extraMatchesCount }} use{{ extraMatchesCount > 1 ? "s" : "" }} of
    this definition using a different format:
    <ul class="list-inside list-disc">
      <li
        v-for="fuzzyTitle in Object.entries(definition.fuzzyTitles)"
        :key="fuzzyTitle[0]"
      >
        {{ fuzzyTitle[0] }}
        <DefinitionNavigation
          :definitionTitle="fuzzyTitle[0]"
          :definitionCount="fuzzyTitle[1]"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DefinitionNavigation from "@/components/sidebar/DefinitionNavigation.vue";

import { DocumentDefinition } from "@/types";
import { useDocumentSearchStore } from "@/store/DocumentSearchStore";

export default defineComponent({
  emits: ["error"],

  setup() {
    const documentSearchStore = useDocumentSearchStore();

    return {
      documentSearchStore
    };
  },

  components: {
    DefinitionNavigation
  },

  props: {
    definition: {
      type: Object as PropType<DocumentDefinition>,
      required: true
    }
  },

  data() {
    return {
      onlyOne: false,
      extraFuzzyMatches: false
    };
  },

  computed: {
    extraMatchesCount(): number {
      if (!this.definition.fuzzyCount || !this.definition.exactCount) {
        return 0;
      }

      return this.definition.fuzzyCount - this.definition.exactCount;
    }
  },

  methods: {
    lint() {
      if (this.definition.exactCount == 1) {
        this.onlyOne = true;
      }

      if (this.extraMatchesCount > 0) {
        this.extraFuzzyMatches = true;
      }

      if (this.onlyOne || this.extraFuzzyMatches) {
        this.$emit("error");
      }
    }
  },

  mounted() {
    this.lint();
  }
});
</script>
