<template>
  <TransitionRoot as="template" :show="show">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white px-0 py-2 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mx-4 my-3 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Create draft entry using AI.
                  <p class="mt-1 text-sm text-gray-500">Page {{ page }} of 2</p>
                </DialogTitle>

                <form v-if="page == 1" class="mt-6 space-y-8">
                  <BaseSelect
                    v-model="promptType"
                    label="Are you negotiating on your template or the counterparty's?"
                    :options="['Own template', 'Third party paper']"
                  />

                  <BaseInput
                    label="What type of contract is the playbook for?"
                    autofocus
                    v-model="contractType"
                    required
                    hint="e.g. SaaS MSA, loan agreement, NDA, services agreement, etc."
                    :errors="errors.contractType"
                  />
                  <BaseInput
                    label="Which party are you?"
                    v-model="role"
                    required
                    hint="e.g. customer, software provider, lender, borrower, etc."
                    :errors="errors.role"
                  />
                  <BaseInput
                    label="What language is the playbook in?"
                    v-model="language"
                    required
                    hint="e.g. British English, US English, French, German etc."
                    :errors="errors.language"
                  />

                  <div class="mt-4 flex space-x-3">
                    <BaseButton @action="next"> Next </BaseButton>
                    <BaseButton buttonStyle="white" @action="$emit('close')">
                      Cancel
                    </BaseButton>
                  </div>
                </form>
                <form v-else-if="page == 2" class="mt-6 space-y-8">
                  <BaseInput
                    label="Entry heading"
                    autofocus
                    v-model="entryTopic"
                    required
                    :hint="entryHeadingHint"
                    :errors="errors.entryTopic"
                  />

                  <BaseInput
                    :label="ownTemplateHeading"
                    v-model="templateClause"
                    v-bind="
                      promptType !== 'Third party paper' && {
                        required: true
                      }
                    "
                    inputType="textarea"
                    hint="Just paste the entire clause in."
                    :errors="errors.templateClause"
                  />

                  <BaseInput
                    label="Context (optional)"
                    v-model="context"
                    inputType="textarea"
                    hint="e.g. typical fallbacks, negotiation points, escalation paths etc. The AI will include this in the generated playbook entry."
                  />

                  <div class="mt-4 flex space-x-3">
                    <BaseButton @action="generate"> Generate </BaseButton>
                    <BaseButton buttonStyle="white" @action="back">
                      Back
                    </BaseButton>
                    <BaseButton buttonStyle="white" @action="$emit('close')">
                      Cancel
                    </BaseButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, onMounted, PropType, ref, watch } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";

import ApiService from "@/services/ApiService";

import { Entry, Playbook } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";

const props = defineProps({
  show: Boolean,

  entry: {
    type: Object as PropType<Entry>,
    required: true
  },

  entryHeading: {
    type: String,
    required: false
  },

  playbook: {
    type: Object as PropType<Playbook>,
    required: true
  }
});

interface FormErrors {
  contractType?: string[];
  role?: string[];
  language?: string[];
  entryTopic?: string[];
  templateClause?: string[];
}

const errors = ref<FormErrors>({});

const playbookAiVariables = computed(() => props.playbook.aiVariables);
const entryAiVariables = computed(() => props.entry.aiVariables);

const page = ref(1);
// Playbook inputs
const promptType = ref(playbookAiVariables.value?.promptType ?? "Own template");
const contractType = ref(playbookAiVariables.value?.contractType);
const role = ref(playbookAiVariables.value?.role);
const language = ref(playbookAiVariables.value?.language);

// Entry inputs
const entryTopic = ref(
  props.entry.heading ?? entryAiVariables.value?.entryTopic
);
const templateClause = ref(entryAiVariables.value?.templateClause);
const context = ref(entryAiVariables.value?.context);

const entryHeadingHint = computed(() => {
  if (promptType.value === "Own template") {
    return "e.g. Payment terms (Clause 4), Indemnity (Clause 5), Confidentiality (Clause 6), etc.";
  } else {
    return "e.g. Payment terms, Indemnity, Confidentiality, etc.";
  }
});

const ownTemplateHeading = computed(() => {
  let heading = "Your template clause for this entry";
  if (promptType.value === "Third party paper") {
    heading += " (optional)";
  }
  return heading;
});

const next = () => {
  if (validateForm()) {
    page.value = page.value + 1;
  }
};

const back = () => {
  page.value = page.value - 1;
};

const emit = defineEmits(["close", "generate"]);

const generate = async () => {
  if (validateForm()) {
    const sidebarStore = useSidebarStore();
    const updatedEntryAiVariables = {
      entryTopic: entryTopic.value,
      templateClause: templateClause.value,
      context: context.value
    };

    const updatedEntry = {
      ...props.entry,
      aiVariables: updatedEntryAiVariables
    };
    await sidebarStore.updateEntry(updatedEntry);

    const updatedPlaybookAiVariables = {
      contractType: contractType.value,
      role: role.value,
      language: language.value,
      promptType: promptType.value
    };
    const updatedPlaybook = {
      ...props.playbook,
      aiVariables: updatedPlaybookAiVariables
    };

    await ApiService.updatePlaybook(updatedPlaybook);
    await sidebarStore.updatePlaybook(updatedPlaybook);

    emit("generate");
  }
};

const validateForm = () => {
  const newErrors: FormErrors = {};

  if (page.value === 1) {
    if (!contractType.value)
      newErrors.contractType = ["This field is required"];
    if (!role.value) newErrors.role = ["This field is required"];
    if (!language.value) newErrors.language = ["This field is required"];
  } else if (page.value === 2) {
    if (!entryTopic.value) newErrors.entryTopic = ["This field is required"];
    if (promptType.value !== "Third party paper" && !templateClause.value)
      newErrors.templateClause = ["This field is required"];
  }

  errors.value = newErrors;
  return Object.keys(newErrors).length === 0;
};

onMounted(() => {
  page.value = 1;
});

watch(
  () => props.show,
  (showing) => {
    if (showing) {
      page.value = 1;
      errors.value = {};
      promptType.value =
        playbookAiVariables.value?.promptType ?? "Own template";
      contractType.value = playbookAiVariables.value?.contractType;
      role.value = playbookAiVariables.value?.role;
      language.value = playbookAiVariables.value?.language;
      entryTopic.value =
        props.entryHeading ?? entryAiVariables.value?.entryTopic;
      templateClause.value = entryAiVariables.value?.templateClause;
      context.value = entryAiVariables.value?.context;
    }
  }
);
</script>
