<template>
  <TransitionRoot as="template" :show="show" @afterLeave="clearForm">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-30 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white py-2 px-0 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mt-3 ml-4 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  New client
                </DialogTitle>
                <div v-if="created" class="mt-3">
                  <div class="flex items-center">
                    <CheckIcon class="h-6 w-6 text-green-600" />
                    <div class="ml-2">
                      <p class="text-sm font-medium leading-5 text-green-600">
                        New client - {{ name }} created successfully.
                      </p>
                    </div>
                  </div>
                </div>
                <template v-else>
                  <div class="mt-3">
                    <BaseInput
                      v-model="name"
                      placeholder="e.g. Order form"
                      name="name"
                      label="Enter a name for the client"
                      autofocus
                    />
                  </div>
                </template>
              </div>
            </div>
            <div
              v-if="!created"
              class="mt-6 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <BaseButton
                @click.prevent="$emit('close')"
                class="ml-3"
                buttonStyle="white"
              >
                Cancel
              </BaseButton>
              <BaseButton
                @click="create"
                :disabled="saving || !name || name.length == 0"
              >
                {{ saving ? "Saving..." : "Create client" }}
              </BaseButton>
            </div>
            <div
              v-else
              class="mt-6 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <BaseButton
                @click.prevent="$emit('close')"
                class="ml-3"
                buttonStyle="white"
              >
                Close
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref } from "vue";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import invariant from "tiny-invariant";
import { useAdminStore } from "@/store/AdminStore";

defineEmits(["close"]);
defineProps({ show: Boolean });

const created = ref(false);
const saving = ref(false);
const name = ref(null as string | null);

const clearForm = () => {
  created.value = false;
  saving.value = false;
  name.value = null;
};

const create = async () => {
  invariant(name.value, "Name is required");
  saving.value = true;
  const adminStore = useAdminStore();
  await adminStore.addClient({ name: name.value });
  saving.value = false;
  created.value = true;
};
</script>
