<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog as="div" class="relative z-50" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ heading ?? `Before inserting, let's fill in the [BLANKS]` }}
              </DialogTitle>

              <div class="space-y-4 py-5">
                <div
                  v-for="variable in oldSkoolVariables"
                  :key="variable.id"
                  class="sm:col-span-6"
                >
                  <label class="block text-sm font-medium text-gray-700">
                    {{ variable.prompt }}
                  </label>
                  <div class="mt-1">
                    <select
                      v-if="variable.options && variable.options.length > 0"
                      v-model="answers[variable.id]"
                      class="block w-full max-w-lg rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:max-w-xs sm:text-sm"
                    >
                      <option>Please select an option</option>
                      <option v-for="option in variable.options" :key="option">
                        {{ option }}
                      </option>
                    </select>
                    <input
                      v-else-if="variable.variableType == 'Number'"
                      type="number"
                      v-model="answers[variable.id]"
                      class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
                      placeholder="Enter a number"
                    />
                    <input
                      v-else
                      type="text"
                      v-model="answers[variable.id]"
                      class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
                    />
                  </div>
                </div>
              </div>
              <div
                v-if="insertionText"
                class="mt-3 overflow-hidden rounded-lg bg-gray-50 px-2 pb-4 pt-4 text-sm"
              >
                <label class="mb-2 block truncate text-sm text-gray-900">
                  Preview
                </label>

                <div
                  class="whitespace-pre-wrap text-gray-500"
                  v-html="textWithVariables"
                />
              </div>

              <div class="flex space-x-1 py-2">
                <BaseButton @click="saveAnswers"> {{ buttonText }} </BaseButton>
                <BaseButton @click="$emit('close')" buttonStyle="link">
                  Cancel
                </BaseButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  Dialog as HeadlessDialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import replaceAll from "string.prototype.replaceall";

import ApiService from "@/services/ApiService";
import { PlaybookInstance, OldSkoolVariable } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";

export default defineComponent({
  components: {
    HeadlessDialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot
  },

  props: {
    buttonText: {
      type: String,
      required: false,
      default: "Save"
    },

    heading: {
      type: String,
      required: false
    },

    playbookInstance: {
      type: Object as PropType<PlaybookInstance>,
      required: true
    },

    show: {
      required: true,
      type: Boolean
    },

    insertionText: {
      required: false,
      type: String
    },

    oldSkoolVariables: {
      type: Object as PropType<OldSkoolVariable[]>,
      required: true
    }
  },

  data() {
    return {
      answers: {} as Record<number, string>
    };
  },

  watch: {
    playbookInstance: {
      deep: true,
      immediate: true,
      handler() {
        this.answers = { ...this.playbookInstance.oldSkoolVariableAnswers };
      }
    }
  },

  computed: {
    textWithVariables() {
      let text = this.insertionText;
      if (!text) return "";
      this.oldSkoolVariables.forEach((variable: OldSkoolVariable) => {
        const answer = this.answers[variable.id];
        if (answer) {
          text = replaceAll(text, variable.identifier, answer);
        }
      });
      return text;
    }
  },

  methods: {
    saveAnswers() {
      const playbookInstance = {
        ...this.playbookInstance,
        oldSkoolVariableAnswers: this.answers
      };
      ApiService.updatePlaybookInstance(playbookInstance).then(() => {
        useSidebarStore().updatePlaybookInstance(playbookInstance);
        this.$emit("savedPlaybookInstance");
      });

      this.$emit("close");
    }
  }
});
</script>

<style scoped>
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* this is for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
