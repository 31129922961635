<template>
  <TransitionRoot as="template" :show="show" @afterLeave="clearForm">
    <HeadlessDialog
      as="div"
      static
      class="fixed inset-0 z-30 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white py-2 px-0 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mt-3 ml-4 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  New playbook
                </DialogTitle>
                <div v-if="newPlaybook" class="mt-3">
                  <div class="flex items-center">
                    <CheckIcon class="h-6 w-6 text-green-600" />
                    <div class="ml-2">
                      <p class="text-sm font-medium leading-5 text-green-600">
                        New playbook - {{ name }} created successfully.
                      </p>
                    </div>
                  </div>
                </div>
                <template v-else>
                  <div class="mt-3">
                    <BaseInput
                      v-model="name"
                      placeholder="e.g. Order form"
                      name="name"
                      label="Enter a name for the playbook"
                      :error="error"
                      autofocus
                    />
                  </div>
                  <SwitchGroup as="div" class="flex items-center">
                    <HeadlessSwitch
                      v-model="lexplayLeap"
                      :class="[
                        lexplayLeap ? 'bg-lexoo' : 'bg-gray-200',
                        'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2'
                      ]"
                    >
                      <span class="sr-only">LexPlay Leap</span>
                      <span
                        aria-hidden="true"
                        :class="[
                          lexplayLeap ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                        ]"
                      />
                    </HeadlessSwitch>
                    <SwitchLabel as="span" class="ml-3 text-sm">
                      <span class="font-medium text-gray-900">
                        LexPlay Leap
                      </span>
                      {{ " " }}
                      <span class="text-gray-500">(beta)</span>
                    </SwitchLabel>
                  </SwitchGroup>
                </template>
              </div>
            </div>
            <div
              v-if="!newPlaybook"
              class="mt-6 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <BaseButton
                @click.prevent="$emit('close')"
                class="ml-3"
                buttonStyle="white"
              >
                {{ "Cancel" }}
              </BaseButton>
              <BaseButton @click="create" :disabled="saving || !name">
                {{ saving ? "Saving..." : "Create playbook" }}
              </BaseButton>
            </div>
            <div
              v-else
              class="mt-6 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <BaseButton
                @click.prevent="$emit('close')"
                class="ml-3"
                buttonStyle="white"
              >
                Close
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  Switch as HeadlessSwitch,
  SwitchGroup,
  SwitchLabel,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Client, Playbook } from "@/types";
import ApiService from "@/services/ApiService";
import DirtyJson from "@/services/DirtyJson";
import { useAdminStore } from "@/store/AdminStore";
import { useAdmin } from "@/components/shared/Admin";

export default defineComponent({
  setup() {
    const { playbookId } = useAdmin();
    return {
      playbookId
    };
  },

  components: {
    CheckIcon,
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    HeadlessSwitch,
    SwitchGroup,
    SwitchLabel,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  data() {
    return {
      saving: false,
      error: null as string | null,
      lexplayLeap: true,
      name: null as string | null,
      newPlaybook: null as Playbook | null
    };
  },

  props: {
    client: {
      required: true,
      type: Object as PropType<Client>
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  methods: {
    clearForm() {
      this.newPlaybook = null;
    },

    create() {
      if (this.client && this.name) {
        this.error = null;
        this.saving = true;

        ApiService.createPlaybook(this.client.id, this.name, this.lexplayLeap)
          .then(({ data }) => {
            const adminStore = useAdminStore();
            this.newPlaybook = data.playbook;

            console.log("playbookId", data.playbook.id);
            adminStore.addPlaybookToClient(this.client.id, data.playbook);
            this.playbookId = data.playbook.id;
          })
          .catch((error) => {
            if (error.request && error.response) {
              const body = DirtyJson.parse(error.request.response);
              this.error = body.message;
            } else {
              this.error = "An unknown error occurred";
            }
          })
          .finally(() => {
            this.name = "";
            this.saving = false;
            this.lexplayLeap = true;
          });
      }
    }
  }
});
</script>

<style scoped></style>
