<template>
  <div
    class="entry-text prose prose-sm text-sm"
    ref="editable"
    v-html="formattedContent"
  ></div>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType } from "vue";
import { formattedText } from "@/helpers/TextHelpers";

export default defineComponent({
  props: {
    checkedItems: {
      type: Array as PropType<string[]>,
      default: () => {
        return [];
      }
    },

    content: {
      type: String,
      default: ""
    }
  },

  computed: {
    formattedContent() {
      return formattedText(this.content);
    }
  },

  watch: {
    checkedItems: {
      handler() {
        this.setInitialChecks();
      },
      deep: true,
      immediate: true
    },

    content() {
      this.setInitialChecks();
    }
  },

  methods: {
    setInitialChecks() {
      nextTick(() => {
        this.checkedItems.forEach((indexAsString: string) => {
          const index = Number(indexAsString);
          const isNumeric = Number.isFinite(index);

          if (isNumeric) {
            const input = (this.$refs.editable as HTMLElement).querySelectorAll(
              "input"
            )[index];
            if (input) {
              input.setAttribute("checked", "checked");
              input.checked = true;
            }
          }
        });
      });
    }
  },

  mounted() {
    (this.$refs.editable as HTMLElement).addEventListener(
      "click",
      (event: Event) => {
        const target = event.target as HTMLInputElement;
        if (
          target.tagName.toUpperCase() === "INPUT" &&
          target.closest("ul[data-type=taskList]")
        ) {
          const allInputs = (
            this.$refs.editable as HTMLElement
          ).querySelectorAll("INPUT");
          const index = Array.from(allInputs).indexOf(target);

          const updatedCheckedItems = new Set(this.checkedItems);
          if (target.checked) {
            updatedCheckedItems.add(index.toString());
          } else {
            updatedCheckedItems.delete(index.toString());
          }

          this.$emit("update:checkedItems", Array.from(updatedCheckedItems));
        }
      }
    );
  }
});
</script>

<style scoped></style>
