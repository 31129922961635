<template>
  <h3 class="text-lg font-medium leading-6 text-gray-900">Tags</h3>
  <p class="max-w-2xl text-sm text-gray-500">
    These are all tags for this playbook.
  </p>
  <div class="relative flex-1">
    <div class="py-5">
      <div class="mb-2">
        <label class="block text-sm font-medium text-gray-700"> New tag </label>
      </div>
      <div class="mt-1">
        <input
          v-model="newTag.name"
          type="text"
          class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
          placeholder="e.g. Red flag"
        />
      </div>
    </div>
    <div class="mb-2">
      <label class="block text-sm font-medium text-gray-700">Colour</label>
    </div>

    <div class="mt-1">
      <ColourPicker v-model="newTag.colour" class="mt-1" />
    </div>

    <div class="mt-5 flex flex-row-reverse sm:mt-4">
      <BaseButton @click="createTag" buttonStyle="white">
        Create tag
      </BaseButton>
    </div>

    <div class="mt-2 flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <h3
            class="text-lg font-medium leading-6 text-gray-900"
            v-if="tags.length > 0"
          >
            {{ `Tags for ${playbook.name}` }}
          </h3>
          <table class="min-w-full">
            <tr v-for="tag in tags" :key="tag.id">
              <template v-if="editTag?.id === tag.id">
                <td>
                  <div class="mb-2">
                    <div class="mt-1">
                      <input
                        type="text"
                        v-model="editTag.name"
                        class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
                      />
                    </div>
                  </div>
                  <ColourPicker v-model="editTag.colour" class="mt-1" />
                  <span class="flex justify-end rounded shadow-sm">
                    <button
                      type="button"
                      class="inline-flex items-center rounded-l-md border border-gray-300 bg-indigo-600 px-2.5 py-1.5 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                      @click="updateTag(editTag)"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      class="-ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
                      @click="editTag = null"
                    >
                      Cancel
                    </button>
                  </span>
                </td>
              </template>
              <template v-else>
                <td>
                  <BaseTag
                    :tag="tag"
                    :show-remove="true"
                    @remove="deleteTag(tag)"
                    size="large"
                  />
                  <button
                    type="button"
                    class="text-gray ml-2 inline-flex items-center rounded border border-transparent bg-gray-50 p-1 shadow-sm hover:bg-gray-100"
                    alt="Edit this tag"
                    @click="editTag = { ...tag }"
                  >
                    <PencilIcon class="h-3 w-3" aria-hidden="true" />
                  </button>
                </td>
              </template>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import invariant from "tiny-invariant";
import { PencilIcon } from "@heroicons/vue/24/outline";
import { NewTag, Playbook, Tag } from "@/types";
import { useTagStore } from "@/store/TagStore";
import BaseTag from "@/components/base/BaseTag.vue";
import ColourPicker from "@/components/admin/ColourPicker.vue";

export default defineComponent({
  setup() {
    const tagStore = useTagStore();

    return {
      tagStore
    };
  },

  components: { BaseTag, ColourPicker, PencilIcon },
  props: {
    playbook: {
      required: true,
      type: Object as PropType<Playbook>
    }
  },

  data() {
    return {
      editTag: null as Tag | null,
      newTag: {
        name: "",
        colour: "gray"
      } as NewTag,
      tags: [] as Tag[]
    };
  },

  watch: {
    playbook: {
      immediate: true,
      handler(playbook: Playbook) {
        this.tags = playbook.tags;
      }
    }
  },

  methods: {
    createTag(): void {
      invariant(this.playbook, "Playbook must be set");
      this.newTag.playbookId = this.playbook.id;

      this.tagStore.createTag({ tag: this.newTag }).then((tag) => {
        this.tags.push(tag);
        this.newTag = {
          playbookId: this.playbook.id,
          name: "",
          colour: ""
        };
      });
    },

    deleteTag(tag: Tag) {
      const tagId = tag.id;
      this.tagStore.deleteTag({ tag }).then(() => {
        const i = this.tags.findIndex((t: Tag) => t.id === tagId);
        this.tags.splice(i, 1);
      });
    },

    updateTag(tag: Tag) {
      this.tagStore.updateTag({ tag }).then((updatedTag) => {
        this.editTag = null;
        this.tags = [
          ...this.tags.map((item: Tag) =>
            item.id !== updatedTag.id ? item : { ...item, ...updatedTag }
          )
        ];
      });
    }
  }
});
</script>
