import { computed, ref, Ref, toValue } from "vue";
import ApiService from "@/services/ApiService";

import { uuid } from "vue-uuid";

import { formattedText } from "@/helpers/TextHelpers";
import { createCable } from "@/services/ActionCableService";
import { useAIStreamsStore } from "@/store/AIStreamsStore";

export type AiMessage = {
  text: string;
  isUser: boolean;
};

function findMissingNumbers(buffer) {
  // Sort the buffer based on the sequence numbers
  buffer.sort((a, b) => a.sequence_number - b.sequence_number);

  const missingNumbers = [];
  for (let i = 0; i < buffer.length - 1; i++) {
    const current = buffer[i].sequence_number;
    const next = buffer[i + 1].sequence_number;

    // Check for missing numbers
    for (let j = current + 1; j < next; j++) {
      missingNumbers.push(j);
    }
  }

  return missingNumbers;
}

export function useAIStreamer(templateClause?: string | Ref<string>) {
  let currentStreamId: string | null = null;
  const streaming = ref(false);
  const aiError = ref(false);
  const channelName = "ApplicationCable::StreamGptChannel";
  const isIncremental = ref(false);
  let cable = null;
  const generatedOutput = ref("");
  const buffer = ref<{ sequence_number: number; message: string }[]>([]);
  const subscription = ref(null);
  const streamParams = ref({});

  const orderedBufferedText = () => {
    return buffer.value
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .map((b) => b.message)
      .join("");
  };

  const generatedOutputWithVariablesAdded = computed(() => {
    let value = generatedOutput.value;
    if (!templateClause) return value;
    const formattedTemplateClause = formattedText(toValue(templateClause));
    value = value.replace(
      new RegExp(`{{\\s?OUTPUT TEMPLATE CLAUSE\\s?}}`, "gi"),
      formattedTemplateClause
    );

    return value;
  });

  const myChannelHandlers = {
    connected: () => {
      console.log("channel connected");

      if (streamParams.value) {
        ApiService.startStreamingPrompt(streamParams.value);
      }
    },
    rejected: () => console.log("channel rejected"),
    received: (data: {
      finish_reason?: string;
      message: string;
      sequence_number: number;
    }) => {
      if (data.finish_reason == "stop") {
        if (subscription.value) {
          subscription.value.unsubscribe();
        }
        if (cable) {
          cable.disconnect();
        }
        // if (cable) {
        //   cable.unsubscribe(channelName);
        // }

        generatedOutput.value = data.message + "\n";
        streaming.value = false;
        aiError.value = false;
      } else {
        useAIStreamsStore().markLastStreamedAt();
        if (isIncremental.value) {
          buffer.value.push(data);
          const missingNumbers = findMissingNumbers(buffer.value);
          generatedOutput.value = orderedBufferedText();
        } else {
          generatedOutput.value = data.message;
        }
      }
    },
    disconnected: () => console.log("channel disconnected")
  };

  const subscribeChannel = (channelHandlers: typeof myChannelHandlers) => {
    subscription.value = cable.subscriptions.create(
      { channel: channelName, room: currentStreamId },
      channelHandlers
    );
  };

  const continueStreaming = (streamId: string) => {
    currentStreamId = streamId;
    subscribeChannel(myChannelHandlers);
    streaming.value = true;
    aiError.value = false;
  };

  const startStreaming = ({
    llmModel,
    prompt,
    superPrompt,
    incremental = false,
    messages = [],
    jsonMode = false
  }: {
    llmModel: string;
    prompt: Ref<string> | string;
    superPrompt: string | null;
    incremental?: boolean;
    messages?: AiMessage[];
    jsonMode?: boolean;
  }) => {
    cable = createCable();
    currentStreamId = uuid.v4();
    subscribeChannel(myChannelHandlers);
    generatedOutput.value = "";
    buffer.value = [];
    streaming.value = true;
    isIncremental.value = incremental;
    aiError.value = false;

    streamParams.value = {
      streamId: currentStreamId,
      model: llmModel,
      prompt: toValue(prompt),
      superPrompt: superPrompt ?? "",
      messages,
      jsonMode,
      incremental
    };
    return currentStreamId;
  };

  const clearOutput = () => {
    generatedOutput.value = "";
    aiError.value = false;
  };

  return {
    aiError,
    clearOutput,
    continueStreaming,
    startStreaming,
    generatedOutputWithVariablesAdded,
    streaming
  };
}
