import { defineStore } from "pinia";

export const useSelectionStore = defineStore("SelectionStore", {
  state() {
    return {
      selectedNode: null as Node | null,
      selectedText: null as string | null
    };
  },

  actions: {
    updateSelection({ selection }: { selection: Selection | null }) {
      if (!selection) {
        this.selectedNode = null;
        this.selectedText = null;
      } else {
        if (selection.anchorNode) {
          this.selectedNode = selection.anchorNode;
        } else {
          this.selectedNode = null;
        }

        this.selectedText = selection.toString();
        if (this.selectedText.length === 0) {
          this.selectedText = null;
        }
      }
    }
  }
});
