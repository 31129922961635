import { computed, ref, Ref, watch } from "vue";
import { defineStore } from "pinia";
import isEqual from "lodash.isequal";
import { debounce } from "throttle-debounce";

import { Issue } from "@/types";
import ApiService from "@/services/ApiService";
import { useSidebarStore } from "@/store/SidebarStore";

const issueComparer = (a: Issue, b: Issue) => {
  if (!a.clauseNumber) {
    return 1;
  }
  if (!b.clauseNumber) {
    return -1;
  }
  const aParts = a.clauseNumber.toString().split(".");
  const bParts = b.clauseNumber.toString().split(".");

  const length = Math.max(aParts.length, bParts.length);

  for (let i = 0; i < length; i++) {
    const aPart = parseInt(aParts[i] || "0", 10);
    const bPart = parseInt(bParts[i] || "0", 10);

    if (aPart < bPart) return -1;
    if (aPart > bPart) return 1;
  }

  return 0;
};

export const useIssuesStore = defineStore("IssuesStore", () => {
  const entryIdsToFindIssues = ref(new Set<number>());
  const issues: Ref<Issue[]> = ref([]);
  const loaded = ref(false);

  const sidebarStore = useSidebarStore();
  const playbookInstance = computed(() => sidebarStore.playbookInstance);

  const issuesOrderedByClauseNumber = computed(() => {
    return issues.value.sort(issueComparer);
  });

  const getIssuesByEntryId = computed(() => {
    return (entryId: number) => {
      return issues.value.filter((i) => i.entryId === entryId);
    };
  });

  const bulkCreateOrUpdateIssues = async (issues: Issue[]) => {
    if (!playbookInstance.value) {
      return;
    }

    issues.forEach(async (issue) => {
      createOrUpdateIssue(issue);
    });
  };

  const createIssue = async (issue: Issue) => {
    if (!playbookInstance.value) {
      return;
    }

    const { data } = await ApiService.createIssue(
      playbookInstance.value,
      issue
    );
    issues.value.push(data.issue);
  };

  const debouncedUpdateIssue = debounce(1000, (issue: Issue) => {
    updateIssue(issue);
  });

  const clearIssues = () => {
    issues.value = [];
    loaded.value = false;
    // ALso need to destroy from database
    if (playbookInstance.value) {
      ApiService.destroyIssues(playbookInstance.value);
    }
  };

  const createOrUpdateIssue = async (issue: Issue) => {
    if (!playbookInstance.value || !issue.openAiId || !issue.entryId) {
      return;
    }

    const updatedIssue = issues.value.find(
      (i) => i.openAiId === issue.openAiId && i.entryId === issue.entryId
    );
    if (updatedIssue) {
      // Does the updated issue have anything different?
      if (isEqual(updatedIssue, issue)) {
        return;
      }
      debouncedUpdateIssue(issue);
    } else {
      createIssue(issue);
    }
  };

  const startFindingIssueForEntry = (id: number) => {
    entryIdsToFindIssues.value.add(id);
  };

  const loadIssues = async () => {
    if (!playbookInstance.value || loaded.value) {
      return;
    }

    const { data } = await ApiService.getIssues(playbookInstance.value);
    issues.value = data.issues;
    loaded.value = true;
  };

  const $reset = () => {
    entryIdsToFindIssues.value = new Set();
    issues.value = [];
    loaded.value = false;
  };

  const updateIssue = async (issue: Issue) => {
    if (!playbookInstance.value) {
      return;
    }

    const { data } = await ApiService.updateIssue(issue);
    const index = issues.value.findIndex((i) => i.id === data.issue.id);
    issues.value[index] = data.issue;
  };

  watch(
    () => playbookInstance.value,
    () => {
      if (loaded.value) {
        loaded.value = false;
        loadIssues();
      }
    }
  );

  return {
    bulkCreateOrUpdateIssues,
    clearIssues,
    debouncedUpdateIssue,
    entryIdsToFindIssues,
    issues,
    createIssue,
    createOrUpdateIssue,
    getIssuesByEntryId,
    issuesOrderedByClauseNumber,
    loadIssues,
    $reset,
    startFindingIssueForEntry,
    updateIssue
  };
});
