import { Extension } from "@tiptap/core";
import { TextSelection } from "prosemirror-state";
import { NodeType } from "prosemirror-model";

export default Extension.create({
  name: "exitBlock",

  addCommands() {
    return {
      exitBlock:
        () =>
        ({ state, dispatch }) => {
          const { schema, selection } = state;
          const { $head } = selection;
          const { parent } = $head;
          console.log("Exit block");

          if (!(parent.type instanceof NodeType)) {
            return false;
          }

          const insertPosition = $head.after(
            parent.type.name === "doc" ? 0 : 1
          );

          // Check if there's a node following the current block
          const nextNode = state.doc.resolve(insertPosition).nodeAfter;

          if (nextNode && nextNode.type.name === "paragraph") {
            // If there's already a paragraph, just move the selection to that paragraph
            const transaction = state.tr.setSelection(
              TextSelection.create(state.doc, insertPosition)
            );
            if (dispatch) {
              dispatch(transaction);
            }
          } else {
            // If there's no node or the node isn't a paragraph, insert a new paragraph
            const newParagraph = schema.nodes.paragraph.createAndFill();
            const transaction = state.tr.insert(insertPosition, newParagraph);

            // Move the cursor to the new paragraph
            const newSelection = TextSelection.create(
              transaction.doc,
              insertPosition + 1
            );
            const newTransaction = transaction.setSelection(newSelection);

            if (dispatch) {
              dispatch(newTransaction);
            }
          }

          return true;
        }
    };
  }
});
