<template>
  <BaseButton label="< Back" @action="goBack" class="mb-4" size="small" />

  <h1 v-if="clientName" class="mb-4 text-2xl font-bold">{{ clientName }}</h1>
  <h2 class="mb-4 text-xl font-bold">{{ props.templateFile }}</h2>
  <div class="mb-4 max-w-md">
    <div class="mt-1 flex rounded shadow-sm">
      <div class="relative flex flex-grow items-stretch focus-within:z-10">
        <input
          autofocus
          type="text"
          v-model="findTextModel"
          @keyup.enter="findText = findTextModel"
          class="block w-full rounded-none rounded-l-md border-gray-300 focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
          placeholder="Find text in sample terms files"
        />
      </div>
      <button
        @click="findText = findTextModel"
        type="button"
        class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
      >
        <span>Search</span>
      </button>
    </div>
  </div>

  <div class="grid grid-flow-col gap-4 overflow-x-scroll">
    <div
      v-for="(sampleTermsFileGroup, groupIndex) in sampleTermsFileGroups"
      :key="groupIndex"
      class="fixed-width-1200"
    >
      <h3 class="mb-4 text-xl font-bold">
        {{ sampleTermsFileGroup.counterparty }}
      </h3>
      <p class="mb-4 text-lg">
        {{ sampleTermsFileGroup.numberOfSampleTermsFiles }} sample terms files
      </p>

      <SampleTermsFile
        v-for="(sampleTermsFile, idx) in sampleTermsFileGroup.sampleTermsFiles"
        :key="idx"
        :findText="findText"
        :sampleTermsFile="sampleTermsFile"
        :elementHeight="elementHeight"
        @resize="elementHeight = $event"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { SampleTermsFileGroup } from "@/types";
import SampleTermsFile from "@/views/admin/PlaybookBuilder/SampleTermsFile.vue";
import ApiService from "@/services/ApiService";

const props = defineProps<{
  clientId: number;
  templateFile: string;
}>();

const findText = ref("");
const findTextModel = ref("");
const elementHeight = ref(192);

const clientName = computed(() => {
  if (sampleTermsFileGroups.value) {
    return sampleTermsFileGroups.value[0].clientName;
  }
  return null;
});

const sampleTermsFileGroups = ref<SampleTermsFileGroup[] | null>(null);

const goBack = () => {
  window.history.back();
};

onMounted(() => {
  ApiService.getSampleTermsFileGroup(props.clientId, props.templateFile).then(
    ({ data }) => {
      sampleTermsFileGroups.value = data.sampleTermsFileGroups;
    }
  );
});
</script>

<style lang="scss" scoped>
.fixed-width-1200 {
  width: 1200px;
}
</style>
