<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      static
      class="fixed inset-0 z-30 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white py-2 px-0 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mt-3 ml-4 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Copy entry:
                  <span class="font-normal text-gray-500">{{
                    entry.heading
                  }}</span>
                </DialogTitle>
                <div v-if="newEntry" class="mt-3">
                  <div class="flex items-center">
                    <CheckIcon class="h-6 w-6 text-green-600" />
                    <div class="ml-2">
                      <p class="text-sm font-medium leading-5 text-green-600">
                        Entry copied successfully.
                      </p>
                    </div>
                  </div>
                </div>
                <template v-else>
                  <div class="mt-3">
                    <BaseSelect
                      v-model="clientId"
                      :options="clientOptions"
                      placeholder="Select a client"
                      name="client"
                    />
                  </div>
                  <div class="mt-3">
                    <BaseSelect
                      v-if="clientId"
                      v-model="playbookId"
                      :options="playbookOptions"
                      placeholder="Select a playbook"
                      name="playbook"
                    />
                  </div>
                </template>
              </div>
            </div>
            <div
              class="mt-6 flex flex-row-reverse bg-gray-50 px-4 py-3 sm:px-6"
            >
              <BaseButton
                @click.prevent="$emit('close')"
                buttonStyle="white"
                class="ml-3"
              >
                {{ newEntry ? "Close" : "Cancel" }}
              </BaseButton>

              <BaseButton v-if="!newEntry" @click="copy" :disabled="copying">
                {{ copying ? "Copying..." : "Copy" }}
              </BaseButton>

              <BaseButton v-if="newEntry" @click="jumpToNewEntry">
                Jump to new entry
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType } from "vue";
import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Entry, Client } from "@/types";
import ApiService from "@/services/ApiService";
import DirtyJson from "@/services/DirtyJson";
import { useAdminStore } from "@/store/AdminStore";

export default defineComponent({
  setup() {
    const adminStore = useAdminStore();

    return {
      adminStore
    };
  },

  components: {
    CheckIcon,
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  data() {
    return {
      clientId: null as number | null,
      copying: false,
      error: null as string | null,
      newEntry: null as Entry | null,
      playbookId: null as number | null
    };
  },

  props: {
    entry: {
      required: true,
      type: Object as PropType<Entry>
    },

    client: {
      required: true,
      type: Object as PropType<Client>
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  computed: {
    clientOptions() {
      const clients = this.adminStore.clients;
      return clients
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((c: Client) => {
          return { name: c.name, value: c.id };
        });
    },

    destinationClient(): Client | null {
      if (!this.clientId) {
        return null;
      }
      return (
        this.adminStore.clients.find((c) => c.id === this.clientId) ?? null
      );
    },

    newEntryUrl() {
      if (this.newEntry) {
        return `/admin/clients/${this.newEntry.clientId}/entries/${this.newEntry.id}`;
      }
      return null;
    },

    playbookOptions(): { name: string; value: number }[] {
      if (!this.destinationClient) {
        return [];
      }
      return this.destinationClient.playbooks.map((p) => {
        return { name: p.name, value: p.id };
      });
    }
  },

  mounted() {
    this.newEntry = null;
    this.adminStore.loadClients();
  },

  methods: {
    copy() {
      if (this.playbookId) {
        this.error = null;
        this.copying = true;
        ApiService.copyEntry(this.entry.id, this.playbookId)
          .then(({ data }) => {
            this.newEntry = data.entry;
            this.adminStore.refreshClients();
          })
          .catch((error) => {
            if (error.request && error.response) {
              const body = DirtyJson.parse(error.request.response);
              this.error = body.message;
            } else {
              this.error = "An unknown error occurred";
            }
          })
          .finally(() => {
            this.copying = false;
          });
      }
    },

    jumpToNewEntry() {
      if (this.clientId && this.playbookId) {
        this.adminStore.clientId = this.clientId;
        nextTick(() => {
          this.adminStore.playbookId = this.playbookId;
          this.$emit("close");
        });
      }
    }
  }
});
</script>

<style scoped></style>
