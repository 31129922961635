<template>
  <div v-if="playbook" ref="pdfContent">
    <div class="page py-10">
      <h3
        class="border-b border-lexoo heading px-4 py-5 text-lg sm:px-6 flex font-medium text-2xl"
      >
        <BookOpenIcon class="mt-1 mr-2 h-6 w-6 text-lexoo" />
        {{ playbook.name }}
      </h3>
      <p class="mt-1 text-sm text-gray-500">
        Generated on {{ new Date().toLocaleDateString() }}
      </p>
    </div>

    <div class="page space-y-4">
      <div
        v-for="entry in entries"
        :key="entry.id"
        class="page overflow-hidden"
      >
        <div class="border-b border-lexoo heading px-4 py-5 text-lg sm:px-6">
          {{ entry.heading }}
        </div>
        <div
          class="prose px-4 py-5 sm:p-6"
          v-html="formattedDescription(entry.entryDescription)"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, Ref } from "vue";
import { BookOpenIcon } from "@heroicons/vue/24/outline";
import { downloadPlaybookPdf } from "@/services/PlaybookDownloader";

import { Entry } from "@/types";

import ApiService from "@/services/ApiService";
import { useSidebarStore } from "@/store/SidebarStore";

const emit = defineEmits(["finished-download"]);

const sidebarStore = useSidebarStore();
const client = computed(() =>
  sidebarStore.clients.find(
    (c) => c.id.toString() === props.clientId.toString()
  )
);
const playbook = computed(() => {
  if (client.value) {
    return client.value.playbooks.find(
      (p) => p.id === parseInt(props.playbookId)
    );
  }
  return null;
});
const entries = ref<Entry[]>([]);

const props = defineProps({
  clientId: {
    type: String,
    required: true
  },

  playbookId: {
    type: String,
    required: true
  }
});

const loadEntries = async () => {
  await ApiService.getEntriesForPlaybook(parseInt(props.playbookId)).then(
    ({ data }: { data: { entries: Entry[] } }) => {
      entries.value = data.entries;
    }
  );
};

onMounted(async () => {
  sidebarStore.loadClients();
  await loadEntries();
  downloadPdf();
});

const pdfContent = ref(null) as Ref<HTMLDivElement | null>;

const downloadPdf = async () => {
  console.log("Downloading");
  if (pdfContent.value) {
    if (playbook.value && pdfContent.value) {
      await downloadPlaybookPdf(playbook.value, pdfContent.value.innerHTML);
      emit("finished-download");
    }
  }
};

const formattedDescription = (description: string) => {
  let result = description.replace(/<summary/g, "<div class='summary'");
  result = result.replace(/<\/summary>/g, "</div>");
  result = result.replace(/<details/g, "<div class='details'");
  result = result.replace(/<\/details>/g, "</div>");
  return result;
};

defineExpose({
  downloadPdf
});
</script>

<style scoped lang="scss">
:deep(.details) {
  margin-left: 1rem;
  .summary {
    @apply font-bold underline;
  }
  [data-type="detailsContent"] {
    margin-left: 1rem;
  }
}

:deep(template-wording) {
  @apply mt-2 block bg-gray-50 pb-4 pl-4 pr-4 pt-0;
}

:deep(template-comment) {
  @apply mt-2 block bg-gray-50 pb-4 pl-4 pr-4 pt-0;
}

:deep(ul[data-type="taskList"]) {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: flex-start;
    padding-left: 1rem;

    > label {
      flex: 0 0 auto;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}

:deep(mark) {
  @apply inline-block bg-yellow-200 align-bottom;
}
</style>
