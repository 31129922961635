import { computed, ref, Ref, watch } from "vue";
import { Clause } from "@/types";
import { AiMessage, useAIStreamer } from "@/services/AI/AIStreamer";
import ApiService from "@/services/ApiService";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import { formatStringAsObjectWithLineNumbers } from "@/helpers/TextHelpers";
import { PromptTemplate } from "@/types";

export function useIssueDrillDown() {
  const role = ref("");
  const contractType = ref("");
  const playbookBody = ref("");
  const clauses: Ref<Clause[]> = ref([]);
  const issues = ref("");
  let promptTemplate: PromptTemplate | null = null;
  const clausesAsJson = computed(() => {
    const text = clauses.value
      .map((clause: Clause) => {
        return clause.clauseBody;
      })
      .join("\n");
    return formatStringAsObjectWithLineNumbers(text);
  });

  const { startStreaming, generatedOutputWithVariablesAdded, streaming } =
    useAIStreamer();

  const drillDown = async (args: {
    role: string;
    contractType: string;
    playbookBody: string;
    issues: string;
    clauses: Clause[];
  }) => {
    role.value = args.role;
    contractType.value = args.contractType;
    clauses.value = args.clauses;
    playbookBody.value = args.playbookBody;
    issues.value = args.issues;

    promptTemplate = await loadPromptTemplate("Issue drill down");
    return streamJson();
  };

  const drillDownRefine = async (messages: AiMessage[]) => {
    promptTemplate = await loadPromptTemplate("Issue drill down");
    // const prompt = messages[messages.length - 1].text;
    console.log("Drilldown");
    if (promptTemplate) {
      startStreaming({
        llmModel: promptTemplate.llmModel,
        prompt: "",
        superPrompt: promptTemplate.superPrompt,
        messages,
        jsonMode: promptTemplate.jsonMode
      });
    }
  };

  const streamJson = () => {
    if (!promptTemplate) {
      console.log("Error loading prompt template");
      return;
    }
    // set up the json
    const variables = {
      playbook: playbookBody.value,
      issues: issues.value,
      clausesAsJson: clausesAsJson.value,
      role: role.value,
      contractType: contractType.value
    };
    const prompt = parsePrompt(promptTemplate.prompt, variables);
    startStreaming({
      llmModel: promptTemplate.llmModel,
      prompt,
      superPrompt: promptTemplate.superPrompt,
      messages: [],
      jsonMode: promptTemplate.jsonMode
    });
    return {
      isUser: true,
      text: prompt
    };
  };

  const loadPromptTemplate = async (template: string) => {
    const response = await ApiService.getPromptTemplate(template);
    return response.data.promptTemplate;
  };

  watch(
    () => generatedOutputWithVariablesAdded.value,
    () => {
      console.log(generatedOutputWithVariablesAdded.value);
    }
  );

  return {
    drillDown,
    drillDownRefine,
    generatedOutputWithVariablesAdded,
    streaming
  };
}
