<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2
              class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
            >
              Email confirmed
            </h2>
            <p class="mt-6 text-lg leading-8 text-gray-600">
              Please download LexPlay in Microsoft Word to get started.
            </p>

            <ol class="mt-4 ml-4 list-decimal space-y-4 text-sm">
              <li>Open Microsoft Word on your computer.</li>
              <li>
                From the menu at the top, select “Insert” and click “Get
                Add-Ins”
              </li>
              <li>
                Now in the Microsoft Office App Store, search for “LexPlay”
              </li>
              <li>Once you’ve found it, click “Add” and then "Continue"</li>
              <li>
                Open LexPlay in Word (in the top menu, click "Home" then check
                the top right corner)
              </li>
            </ol>
            <div class="mt-6 border-t border-gray-900/10 pt-4">
              <p class="mb-4 text-sm leading-8">
                Or watch a short (1 min) how-to video below:
              </p>
              <div
                style="
                  position: relative;
                  padding-bottom: 64.98194945848375%;
                  height: 0;
                "
              >
                <iframe
                  src="https://www.loom.com/embed/ea09f2f4236b4780b522363a808baac3?sid=c6fc1c73-485d-430c-aa79-3294b8218668"
                  frameborder="0"
                  webkitallowfullscreen
                  mozallowfullscreen
                  allowfullscreen
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                  "
                ></iframe>
              </div>
            </div>
            <p class="mt-6 text-left text-sm">
              If you have any issues or if downloading new add-ins is blocked by
              your IT admin, please email:
              <a href="mailto:team@lexoo.com" class="hover:underline">
                team@lexoo.com
              </a>
              for help.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
