<template>
  <PlaybookNav currentStep="Definitions" />
  <BaseTitle class="flex">
    <AcademicCapIcon class="mr-2 h-6 w-6 text-lexoo" />
    Definitions
  </BaseTitle>

  <div class="mt-8 max-w-xl">
    <LoadingPanel v-if="loading" />

    <div
      v-show="!loading && definitions.length > 1"
      class="relative mb-2 flex-grow focus-within:z-10"
    >
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1"
      >
        <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
      </div>
      <input
        ref="filter"
        v-model="filterText"
        type="text"
        class="block w-full rounded border-gray-300 pl-6 text-xs focus:border-lexoo focus:ring-lexoo"
        placeholder="Filter definitions"
      />
    </div>
    <div v-if="!loading && definitions.length == 0" class="text-gray-500">
      <h2 class="mt-2 text-base leading-6 text-gray-900">
        No definitions found
      </h2>
      <p class="mt-1 text-sm text-gray-500">
        This feature looks through the contract for any defined terms and list
        them out here for easy access.
      </p>
    </div>
    <DefinitionItem
      v-for="definition in filteredDefinitions"
      :key="definition.title"
      :definition="definition"
      :filter="filterText"
    />
  </div>
</template>

<script setup lang="ts">
/* global Word, Office */

import { computed, nextTick, onMounted, ref, Ref } from "vue";
import {
  AcademicCapIcon,
  MagnifyingGlassIcon
} from "@heroicons/vue/24/outline";
import PlaybookNav from "@/components/sidebar/PlaybookNav.vue";
import { DocumentDefinition } from "@/types";
import DefinitionItem from "@/components/sidebar/DefinitionItem.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import DocumentParser from "@/services/DocumentParser";

const definitions = ref([]) as Ref<DocumentDefinition[]>;
const loading = ref(true);
const filterText = ref("");
const filter = ref(null) as Ref<HTMLInputElement | null>;

const filteredDefinitions = computed((): DocumentDefinition[] => {
  const search = filterText.value.toLowerCase();
  return definitions.value.filter((definition) =>
    definition.title.toLowerCase().includes(search)
  );
});

const loadDefinitions = (): Promise<void> => {
  return new Promise((resolve) => {
    Word.run(async (context) => {
      const document = context.document;
      const html = document.body.getHtml();
      await context.sync();
      const parser = new DocumentParser(html.value);
      definitions.value = parser.parse().definitions;
      definitions.value.sort((a, b) => a.title.localeCompare(b.title));
      loading.value = false;
      resolve();
    });
  });
};

onMounted(() => {
  Office.onReady(() => {
    loadDefinitions().then(() => {
      nextTick(() => {
        if (filter.value) {
          filter.value.focus();
        }
      });
    });
  });
});
</script>
