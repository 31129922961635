import { Decoration, DecorationSet } from "prosemirror-view";
import { Extension } from "@tiptap/core";
import { Plugin } from "@tiptap/pm/state";

export default Extension.create({
  name: "placeholder",

  addOptions() {
    return {
      aiPlaceholder: false,
      emptyNodeClass: "empty-node",
      placeholder: "Enter some text for this entry..."
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: (state) => {
            const { doc } = state;
            let isEmptyParagraphs = true;
            if (!this.editor.isEditable) return;

            doc.content.forEach((node) => {
              if (!(node.type.name === "paragraph" && node.childCount === 0)) {
                isEmptyParagraphs = false;
              }
            });

            if (doc.childCount <= 3 && isEmptyParagraphs && doc.firstChild) {
              const aiPlaceholder =
                typeof this.options.aiPlaceholder === "function"
                  ? this.options.aiPlaceholder()
                  : this.options.aiPlaceholder;
              if (!aiPlaceholder) {
                const placeHolderDecoration = Decoration.node(
                  0,
                  doc.firstChild.nodeSize,
                  {
                    class: this.options.emptyNodeClass,
                    "data-placeholder":
                      typeof this.options.placeholder === "function"
                        ? this.options.placeholder()
                        : this.options.placeholder
                  }
                );
                return DecorationSet.create(doc, [placeHolderDecoration]);
              }
            }

            return null;
          }
        }
      })
    ];
  }
});
