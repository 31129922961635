import { mergeAttributes, Node } from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";

import TipTapRefineOption from "@/components/base/TipTap/TipTapRefineOption.vue";

export interface RefineOptionOptions {
  onRefineOptionClick?: (refinement: string) => boolean;
}

export default Node.create<RefineOptionOptions>({
  name: "refineOption",
  group: "block",
  content: "block+",
  isolating: true,

  parseHTML() {
    return [
      {
        tag: "refine-option"
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["refine-option", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return VueNodeViewRenderer(TipTapRefineOption);
  },

  addKeyboardShortcuts() {
    return {
      ArrowDown: ({ editor }) => {
        const { state } = editor;
        const { selection } = state;
        const { $from } = selection;
        const isAtEnd = $from.parentOffset === $from.parent.nodeSize - 2;

        if (!isAtEnd) {
          return false;
        }

        let depth = $from.depth;
        while (depth > 0) {
          const node = $from.node(depth);
          if (node.type.name === this.name) {
            // Additional condition: check if there are nodes underneath the current one
            const nextNode = $from.nodeAfter;
            if (!nextNode) {
              return editor.commands.exitBlock();
            }
          }
          depth -= 1;
        }

        return false;
      }
    };
  }
});
