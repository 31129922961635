import axios from "axios";
import { useAlertStore } from "@/store/AlertStore";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useResetStore } from "@/store/ResetStore";
import { useSidebarStore } from "@/store/SidebarStore";
import ApiService from "@/services/ApiService";

export default class AuthService {
  admin() {
    const authorisationStore = useAuthorisationStore();
    return authorisationStore.isAdmin;
  }

  googleLoginUrl(email?: string) {
    let url = import.meta.env.VITE_API_URL + "/auth/google_oauth2";

    const redirectUri = encodeURIComponent(
      `${window.location.origin}/auth/google_oauth2/callback`
    );

    url += `?redirect_uri=${redirectUri}`;
    if (email) {
      url += `&email=${encodeURIComponent(email)}`;
    }

    return url;
  }

  oktaLoginUrl(email: string) {
    let url = import.meta.env.VITE_API_URL + "/auth/okta";

    const redirectUri = encodeURIComponent(
      `${window.location.origin}/auth/okta/callback`
    );

    url += `?redirect_uri=${redirectUri}&email=${encodeURIComponent(email)}`;

    return url;
  }

  passwordResetRequired(): boolean {
    const authorisationStore = useAuthorisationStore();
    return authorisationStore.passwordResetRequired;
  }

  signedIn(): boolean {
    const authorisationStore = useAuthorisationStore();
    const authToken = authorisationStore.authorisation;
    const signedOut = authToken === null || JSON.stringify(authToken) === null;
    return !signedOut;
  }

  signIn(payload: { email: string; password: string }) {
    const alertStore = useAlertStore();
    alertStore.clearAlert();
    return ApiService.signIn(payload).then((data) => {
      const response = data.data;
      const user = response["user"];
      user.forceReset = response["user"]["force_reset"];
      const authorisation = data.headers["authorization"];
      const authorisationStore = useAuthorisationStore();
      authorisationStore.setUser({ authorisation, user });
      const sidebarStore = useSidebarStore();
      if (typeof Office !== "undefined") {
        Office.onReady(() => {
          if (typeof Word !== "undefined") {
            sidebarStore.withinWord = true;
          }
        });
      }
    });
  }

  async signOut() {
    await ApiService.signOut();
    const resetStore = useResetStore();
    resetStore.all();
    const userStore = useAuthorisationStore();
    userStore.clearUser();
  }

  startingPath() {
    const authorisationStore = useAuthorisationStore();
    const sidebarStore = useSidebarStore();
    const subdomain = window.location.hostname.split(".")[0].toLowerCase();
    if (authorisationStore.redirect && authorisationStore.redirect.length > 0) {
      const redirect = authorisationStore.redirect;
      authorisationStore.redirect = null;
      return redirect;
    } else if (subdomain === "escalations") {
      return "/escalations";
    } else if (sidebarStore.withinWord) {
      return "/word-view";
    } else {
      return "/sidebar/playbooks";
    }
  }

  passGoogleOAuthToServer(data: { code: string; redirect_uri: string }) {
    const headers = {
      "X-Requested-With": "XMLHttpRequest"
    };
    const url = `${import.meta.env.VITE_API_URL}/auth/google_oauth2/callback`;
    return axios
      .post(url, null, {
        params: data,
        headers: headers
      })
      .then(({ data, headers }) => {
        console.log(headers);
        const response = data["data"];
        const user = response["user"];
        const authorisation = headers["authorization"];
        const authorisationStore = useAuthorisationStore();
        authorisationStore.setUser({ authorisation, user });
      });
  }

  passOktaOAuthToServer(data: {
    code: string;
    state: string;
    redirect_uri: string;
  }) {
    const headers = {
      "X-Requested-With": "XMLHttpRequest"
    };
    const url = `${import.meta.env.VITE_API_URL}/auth/okta/callback`;
    return axios
      .post(url, null, {
        params: data,
        headers: headers
      })
      .then(({ data, headers }) => {
        const response = data["data"];
        const user = response["user"];
        const authorisation = headers["authorization"];
        const authorisationStore = useAuthorisationStore();
        authorisationStore.setUser({ authorisation, user });
      });
  }
}
