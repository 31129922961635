<template>
  <div class="relative mb-2 flex-grow focus-within:z-10">
    <div
      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-1"
    >
      <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
    </div>
    <input
      type="text"
      class="block w-full rounded border-gray-300 pl-6 text-xs focus:border-indigo-500 focus:ring-lexoo"
      placeholder="Search through all your playbooks"
      v-model="filterText"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
  </div>
  <select
    v-if="isAdmin"
    v-model="clientId"
    class="mb-2 w-full rounded border border-gray-300 px-3 py-2 text-xs placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:focus:border-gray-500 dark:focus:ring-gray-900"
  >
    <option :value="null">All clients</option>
    <option v-for="client in clients" :key="client.id" :value="client.id">
      {{ client.name }}
    </option>
  </select>
  <select
    v-if="showPlaybooksSelect"
    v-model="playbookId"
    class="mb-2 w-full rounded border border-gray-300 px-3 py-2 text-xs placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:focus:border-gray-500 dark:focus:ring-gray-900"
  >
    <option :value="null">All playbooks</option>
    <option
      v-for="option in playbookOptions"
      :key="option.value"
      :value="option.value"
    >
      {{ option.text }}
    </option>
  </select>

  <LoadingPanel v-if="loading" />
  <ul v-else-if="entries.length > 0" class="-mx-4 sm:mx-0">
    <li v-for="entry in entries" :key="entry.id">
      <PlaybookEntry :entry="entry" :filterText="filterText" />
    </li>
  </ul>
  <div v-else-if="filterText.length > 2" class="text-sm text-gray-500">
    No entries found
  </div>
  <div id="hovering-checkbox-menu-portal"></div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapWritableState } from "pinia";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import ApiService from "@/services/ApiService";
import PlaybookEntry from "@/components/sidebar/PlaybookEntry.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useSidebarStore } from "@/store/SidebarStore";

export default defineComponent({
  setup() {
    const authorisationStore = useAuthorisationStore();
    const sidebarStore = useSidebarStore();

    return {
      authorisationStore,
      sidebarStore
    };
  },

  components: {
    LoadingPanel,
    PlaybookEntry,
    MagnifyingGlassIcon
  },

  data() {
    return {
      filterText: "",
      loading: false
    };
  },

  computed: {
    ...mapWritableState(useSidebarStore, {
      clientId: "peekClientId",
      entries: "peekEntries",
      playbookId: "peekPlaybookId"
    }),

    clients() {
      return this.sidebarStore.notSelfServeClients;
    },

    isAdmin() {
      return this.authorisationStore.isAdmin;
    },

    playbookOptions(): { value: number; text: string }[] {
      if (this.clients.length == 1) {
        const client = this.clients[0];
        return client.playbooks.map((playbook) => ({
          value: playbook.id,
          text: playbook.name
        }));
      } else {
        const client = this.clients.find((c) => c.id === this.clientId);
        if (client) {
          return client.playbooks.map((playbook) => ({
            value: playbook.id,
            text: playbook.name
          }));
        } else {
          return [];
        }
      }
    },

    showPlaybooksSelect(): boolean {
      return this.playbookOptions.length > 1;
    }
  },

  methods: {
    searchEntries() {
      if (
        this.filterText.length < 3 &&
        this.clientId === null &&
        this.playbookId === null
      ) {
        this.entries = [];
        return;
      }
      this.loading = true;
      ApiService.searchEntries(
        this.filterText,
        this.clientId,
        this.playbookId
      ).then(({ data }) => {
        this.entries = data.entries;
        this.loading = false;
      });
    }
  },

  watch: {
    clientId() {
      if (!this.playbookId) {
        this.searchEntries();
      } else {
        this.playbookId = null;
      }
    },

    filterText() {
      this.searchEntries();
    },

    playbookId() {
      this.searchEntries();
    }
  }
});
</script>
