import { parseISO } from "date-fns";

const isObject = (obj: unknown): obj is Record<string, unknown> => {
  return obj === Object(obj);
};
const isArray = (obj: unknown): obj is unknown[] => {
  return toString.call(obj) == "[object Array]";
};
const isString = (obj: unknown): obj is string => {
  return typeof obj === "string";
};
const isNumber = (obj: unknown): obj is number => {
  return typeof obj === "number";
};

// This complicated function goes through the tree and converts
// the fields with a _at suffixed key to a date
export const convertAtValuesToDate = (
  value: unknown
): Record<string, unknown>[] | Record<string, unknown> | string | number => {
  if (isNumber(value) || isString(value)) {
    return value;
  }
  let output;

  if (isArray(value)) {
    output = [];
    value.forEach((object: unknown) => {
      output.push(convertAtValuesToDate(object));
    });
  } else if (isObject(value)) {
    output = {} as { [key: string]: unknown };
    for (const key in value) {
      const thisObj = value[key];

      if (isObject(thisObj)) {
        output[key] = convertAtValuesToDate(thisObj);
      } else if (key.match(/_at/) && isString(thisObj)) {
        output[key] = parseISO(thisObj);
      } else {
        output[key] = thisObj;
      }
    }
  } else {
    output = {};
  }

  return output;
};
