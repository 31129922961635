<template>
  <div class="mt-2 mr-2 text-sm text-gray-700">
    <template v-if="step === 1">
      <p class="my-4">
        If you want to upload some entries from a Google sheet there are a few
        things you need to know in order to make it work:
      </p>
      <p class="my-4">
        The document needs to be a plain Google Sheet (i.e. not .xlsx). You can
        convert it within Google Sheets by clicking File > Save as Google
        Sheets.
      </p>
      <img class="my-4" src="@/assets/images/import-sheet/step-1.gif" />
    </template>
    <template v-if="step === 2">
      <p class="my-4">
        If you are replacing entries, make sure that the Heading of the entry is
        worded exactly the same. (i.e. if you want to replace a entry with a
        heading "IP Indeminity" you can't write "Ip indemnity", unless you want
        to add a new entry)
      </p>
      <p class="my-4">
        The Heading of the entry is in the "Entry" column in the spreadsheet.
      </p>

      <img src="@/assets/images/import-sheet/step-3.png" />
    </template>

    <div v-if="step === 3">
      The column headings must be exactly the following:
      <div class="my-2 overflow-hidden bg-white shadow sm:rounded">
        <ul class="divide-y divide-gray-200">
          <li>
            <div class="flex px-4 py-4 text-sm">
              <p class="font-medium">Entry</p>
              <p class="ml-1 flex-shrink-0 font-normal text-gray-500">
                This corresponds with the Heading in the Playbook.
              </p>
            </div>
          </li>

          <li>
            <div class="flex px-4 py-4 text-sm">
              <p class="font-medium">Starting position</p>

              <p class="ml-1 flex-shrink-0 font-normal text-gray-500">
                This corresponds with the text of the entry in the Playbook.
              </p>
            </div>
          </li>

          <li>
            <div class="flex px-4 py-4 text-sm">
              <p class="font-medium">Template wording</p>
            </div>
          </li>
          <li>
            <div class="flex px-4 py-4 text-sm">
              <p class="font-medium">Template comment</p>
            </div>
          </li>
        </ul>
      </div>
      <img src="@/assets/images/import-sheet/step-3.png" />
    </div>
    <template v-if="step === 4">
      <p class="my-4">
        Each tab corresponds to a tag within the playbook. If you have exactly
        the same entries on two different tabs they will have the corresponding
        tags in the playbook.
      </p>
      <p class="my-4">
        Also, if you have a tab called "No tag" then these entries will not be
        tagged.
      </p>

      <p class="my-4">
        Formatting will not be copied over - instead it will look at the plain
        text of the cell. It will also not delete entries that are already
        there. It merely creates new ones, or updates ones that already exist.
      </p>

      <img src="@/assets/images/import-sheet/step-4.png" />
    </template>
    <div v-if="step === maxSteps">
      <slot></slot>
    </div>

    <nav class="mt-3 flex items-center justify-center" aria-label="Progress">
      <p class="text-xs">Page {{ step }} of {{ maxSteps }}</p>
      <ol class="ml-8 flex items-center space-x-5">
        <li v-for="n in maxSteps" :key="n">
          <a
            v-if="step < n"
            href="#"
            @click.prevent="$emit('setStep', n)"
            class="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
          >
          </a>
          <a
            v-else-if="step === n"
            href="#"
            class="relative flex items-center justify-center"
            aria-current="step"
          >
            <span class="absolute flex h-5 w-5 p-px" aria-hidden="true">
              <span class="h-full w-full rounded-full bg-indigo-200" />
            </span>
            <span
              class="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
              aria-hidden="true"
            />
          </a>
          <a
            v-else
            href="#"
            @click.prevent="$emit('setStep', n)"
            class="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900"
          >
          </a>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      maxSteps: 5
    };
  },

  props: {
    step: Number
  },

  watch: {
    step(value) {
      if (value == this.maxSteps) {
        this.$emit("lastStep");
      } else {
        this.$emit("otherStep");
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
