<template>
  <Disclosure v-slot="{ open }" as="div" class="mb-2">
    <DisclosureButton
      class="group flex w-full justify-between rounded-lg py-2 py-2 px-4 text-left text-sm font-medium text-purple-900 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75"
      :class="{
        'bg-red-100 hover:bg-red-200 ': error,
        'bg-gray-50 hover:bg-gray-100 ': !error
      }"
    >
      <div>
        <span v-html="highlightedTitle"></span>

        <DefinitionNavigation
          :definitionTitle="definition.title"
          :definitionCount="definition.exactCount ?? 0"
        />
      </div>

      <ChevronUpIcon
        :class="open ? 'rotate-180 transform' : ''"
        class="inline-flex h-5 w-5 items-center rounded-full border border-gray-300 bg-white p-1 text-sm font-medium text-purple-500 text-gray-700 shadow-sm hover:bg-gray-50 hover:bg-gray-50"
      />
    </DisclosureButton>
    <DisclosurePanel
      static
      v-show="open"
      class="py-4 pb-2 text-sm text-gray-500"
    >
      <div
        v-if="definition.description"
        v-html="highlightedDescription"
        @click="jumpToDefinition"
        class="mb-2 cursor-pointer hover:text-gray-900"
      ></div>

      <DefinitionCountLint :definition="definition" @error="error = true" />
    </DisclosurePanel>
  </Disclosure>
</template>

<script lang="ts">
/* global Word */

import { defineComponent, PropType } from "vue";

import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/24/outline";
import { DocumentDefinition } from "@/types";

import DefinitionCountLint from "@/components/linters/DefinitionCount.vue";
import DefinitionNavigation from "@/components/sidebar/DefinitionNavigation.vue";

export default defineComponent({
  components: {
    ChevronUpIcon,
    DefinitionCountLint,
    DefinitionNavigation,
    Disclosure,
    DisclosureButton,
    DisclosurePanel
  },

  props: {
    definition: {
      type: Object as PropType<DocumentDefinition>,
      required: true
    },

    filterText: {
      type: String as PropType<string>,
      required: false
    }
  },

  computed: {
    highlightedDescription(): string {
      if (!this.definition.description) {
        return "";
      }

      return this.definition.description.replace(
        this.definition.quotedTitle ?? "",
        `<span class="font-bold">${this.definition?.quotedTitle}</span>`
      );
    },

    highlightedTitle(): string {
      const value = this.definition.title;
      if (!this.filterText || this.filterText.length === 0) {
        return value;
      }

      return value.replace(
        new RegExp(this.filterText, "gi"),
        (match) => `<mark>${match}</mark>`
      );
    }
  },

  data() {
    return {
      error: false
    };
  },

  methods: {
    jumpToDefinition() {
      Word.run(async (context) => {
        // Can't search for long descriptions
        const searchDescription = (this.definition.description ?? "").slice(
          0,
          100
        );
        const ranges = context.document.body.search(searchDescription, {
          matchCase: true
        });

        ranges.load("items");
        await context.sync();
        ranges.items[0].select("Select");
      });
    }
  }
});
</script>
