<template>
  <TransitionRoot as="template" :show="show" @afterLeave="clearForm">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white py-2 px-0 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="my-3 mx-4 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Invite a team member
                </DialogTitle>
                <p class="mt-1 text-sm text-gray-500">
                  Invited users will receive an email to join your account. You
                  will be notified when they sign up
                </p>
                <form class="mt-6 space-y-8">
                  <BaseInput label="Name" autofocus v-model="name" required />
                  <BaseInput
                    label="Email address"
                    inputType="email"
                    v-model="email"
                    :errors="errorMessages"
                    required
                  />
                  <BaseSelect
                    v-model="role"
                    label="Role"
                    placeholder="Select role"
                    :options="[
                      { name: 'Editor', value: 'editor' },
                      { name: 'Viewer', value: 'viewer' }
                    ]"
                    hint="Editors will be able to update playbooks and manage team members. Viewers will only be able to view playbooks."
                  />

                  <div class="mt-4 flex space-x-3">
                    <BaseButton @action="inviteUser" :disabled="sending">
                      {{ sending ? "Please wait..." : "Send invitation" }}
                    </BaseButton>
                    <BaseButton buttonStyle="white" @action="$emit('cancel')">
                      Cancel
                    </BaseButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import ApiService from "@/services/ApiService";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";

defineProps({
  show: Boolean
});

const emits = defineEmits(["close"]);
const sending = ref(false);
const errorMessages = ref([]) as Ref<string[]>;
const name = ref("");
const email = ref("");
const role = ref("editor") as Ref<"editor" | "viewer">;

const clearForm = () => {
  name.value = "";
  email.value = "";
  role.value = "editor";
};

const inviteUser = async () => {
  sending.value = true;
  const invitation = {
    email: email.value,
    name: name.value,
    role: role.value
  };
  let createdInvitation;
  try {
    createdInvitation = await ApiService.createInvitation(invitation);
  } catch (error) {
    if (error.response.status === 422) {
      errorMessages.value = [
        "There was an error creating this invitation. They might have already created an account with that email address."
      ];
    }
  } finally {
    sending.value = false;
  }

  if (createdInvitation) {
    emits("close");
  }
};
</script>
