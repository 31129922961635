<template>
  <div v-if="show" class="mt-4 border-t">
    <div>
      <h3 class="mb-2 mt-4 text-sm leading-6 text-gray-500">
        Results from other Playbooks:
      </h3>
      <select
        v-if="isAdmin"
        v-model="clientId"
        class="mb-2 w-full rounded border border-gray-300 px-3 py-2 text-xs placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:focus:border-gray-500 dark:focus:ring-gray-900"
      >
        <option :value="null">All clients</option>
        <option v-for="client in clients" :key="client.id" :value="client.id">
          {{ client.name }}
        </option>
      </select>
      <select
        v-if="showPlaybooksSelect"
        v-model="playbookId"
        class="mb-2 w-full rounded border border-gray-300 px-3 py-2 text-xs placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:focus:border-gray-500 dark:focus:ring-gray-900"
      >
        <option :value="null">All playbooks</option>
        <option
          v-for="option in playbookOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </option>
      </select>
      <LoadingPanel v-if="loading" />
      <ul v-else-if="entries.length > 0">
        <li v-for="entry in entries" :key="entry.id">
          <PlaybookSuperEntry
            v-if="entry.lexplayLeap"
            :entry="entry"
            :filterText="filterText"
            @autoSaveChange="$emit('autoSaveChange', $event)"
            :editMode="editMode"
          />
          <PlaybookEntry v-else :entry="entry" :filterText="filterText" />
        </li>
      </ul>
      <div v-else class="text-sm text-gray-500">
        {{
          currentPlaybookName
            ? `No entries found for search term \`${filterText}\` in ${currentPlaybookName}`
            : `No entries found search term \`${filterText}\` in other playbooks`
        }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref, watch } from "vue";
import { debounce } from "throttle-debounce";
import { Entry } from "@/types";
import ApiService from "@/services/ApiService";
import { useSidebarStore } from "@/store/SidebarStore";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import LoadingPanel from "@/components/LoadingPanel.vue";
import PlaybookEntry from "@/components/sidebar/PlaybookEntry.vue";
import PlaybookSuperEntry from "@/components/sidebar/PlaybookSuperEntry.vue";

const loading = ref(false);
const entries = ref([]) as Ref<Entry[]>;

const sidebarStore = useSidebarStore();
const authorisationStore = useAuthorisationStore();
const isAdmin = computed(() => authorisationStore.isAdmin);
const clients = computed(() => sidebarStore.notSelfServeClients);
const clientId = ref(null);
const playbookId = ref(null);

const props = defineProps({
  currentPlaybookId: {
    type: Number,
    required: true
  },

  editMode: {
    type: Boolean,
    required: false
  },

  filterText: {
    type: String,
    default: ""
  }
});

const show = computed(() => {
  return (
    props.filterText.length > 2 &&
    (clients.value.length > 0 || showPlaybooksSelect.value)
  );
});

const playbookOptions = computed(() => {
  if (clients.value.length == 1) {
    const client = clients.value[0];
    return client.playbooks.map((playbook) => ({
      value: playbook.id,
      text: playbook.name
    }));
  } else {
    const client = clients.value.find((c) => c.id === clientId.value);
    if (client) {
      return client.playbooks.map((playbook) => ({
        value: playbook.id,
        text: playbook.name
      }));
    } else {
      return [];
    }
  }
});

const currentPlaybookName = computed(() => {
  const client = clients.value.find((c) => c.id === clientId.value);
  if (client) {
    const playbook = client.playbooks.find((p) => p.id === playbookId.value);
    if (playbook) {
      return playbook.name;
    }
  }
  return null;
});

const showPlaybooksSelect = computed(() => {
  return playbookOptions.value.length > 1;
});

const debouncedSearch = debounce(1000, () => {
  ApiService.searchEntries(
    props.filterText,
    clientId.value,
    playbookId.value
  ).then(({ data }) => {
    entries.value = data.entries.filter(
      (entry: Entry) => entry.playbookId !== props.currentPlaybookId
    );
    loading.value = false;
  });
});

const searchEntries = () => {
  if (props.filterText.length < 3) {
    entries.value = [];
    return;
  }
  loading.value = true;
  debouncedSearch();
};

watch(
  () => props.filterText,
  () => {
    searchEntries();
  }
);

watch(clientId, () => {
  searchEntries();
});

watch(playbookId, () => {
  searchEntries();
});

watch(
  () => props.editMode,
  (value) => {
    if (!value) {
      // if we're switching out of edit mode I want to reload the results
      searchEntries();
    }
  }
);
</script>
