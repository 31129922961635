<template>
  <div
    class="editable-text"
    :class="isToolbarVisible ? 'toolbar-visible' : undefined"
  >
    <label class="block text-sm font-medium text-gray-700"> {{ label }} </label>
    <div class="relative mt-1">
      <div
        class="absolute top-1 left-1 z-10"
        v-if="editor"
        v-show="!urlPickerShown && isToolbarVisible"
      >
        <button
          title="Bold"
          @click="editor.chain().focus().toggleBold().run()"
          class="inline-flex cursor-pointer items-center rounded-l-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
            />
          </svg>
        </button>
        <button
          title="Italics"
          @click="editor.chain().focus().toggleItalic().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
          </svg>
        </button>
        <button
          title="Bullet list"
          @click="editor.chain().focus().toggleBulletList().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
            />
          </svg>
        </button>
        <button
          title="Highlight"
          @click="editor.chain().focus().toggleHighlight().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('highlight') }"
        >
          <svg
            class="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m20.707 5.826l-3.535-3.533a.999.999 0 0 0-1.408-.006L7.096 10.82a1.01 1.01 0 0 0-.273.488l-1.024 4.437L4 18h2.828l1.142-1.129l3.588-.828c.18-.042.345-.133.477-.262l8.667-8.535a1 1 0 0 0 .005-1.42zm-9.369 7.833l-2.121-2.12l7.243-7.131l2.12 2.12l-7.242 7.131zM4 20h16v2H4z"
            />
          </svg>
        </button>
        <button
          title="Tasklist"
          @click="editor.chain().focus().toggleTaskList().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('taskList') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
            />
          </svg>
        </button>
        <button
          title="Strikethrough"
          @click="editor.chain().focus().toggleStrike().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
            />
          </svg>
        </button>
        <button
          title="Remove link"
          v-if="editor.isActive('link')"
          @click="removeUrl"
          class="is-active relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07zM5.775 2.293l1.932-.518L8.742 5.64l-1.931.518-1.036-3.864zm9.483 16.068l1.931-.518 1.036 3.864-1.932.518-1.035-3.864zM2.293 5.775l3.864 1.036-.518 1.931-3.864-1.035.518-1.932zm16.068 9.483l3.864 1.035-.518 1.932-3.864-1.036.518-1.931z"
            />
          </svg>
        </button>
        <button
          v-else
          title="Add link"
          @click="showUrlPicker"
          class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z"
            />
          </svg>
        </button>
      </div>

      <UrlPicker
        :show="urlPickerShown"
        v-model="url"
        @selected="setLink"
        @cancel="urlPickerShown = false"
      />
      <EditorContent :editor="editor" />
    </div>
  </div>
</template>

<script lang="ts">
// Icons come from https://remixicon.com/
import { computed, defineComponent, ref } from "vue";
import { v4 as uuidv4 } from "uuid";

import { useToolbarVisibilityStore } from "@/store/ToolbarVisibilityStore";
import { EditorContent, useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import { TaskItem } from "@/components/base/TipTap/TipTapTaskItem";
import { TaskList } from "@/components/base/TipTap/TipTapTaskList";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import UrlPicker from "@/components/UrlPicker.vue";

export default defineComponent({
  setup(props, context) {
    const componentId = ref(uuidv4());
    const toolbarVisibilityStore = useToolbarVisibilityStore();
    const isToolbarVisible = computed(() => {
      return toolbarVisibilityStore.isToolbarOpen(componentId.value);
    });

    const classes = computed(() => {
      const result =
        (context.attrs.class ?? "") +
        " prose prose-sm overflow-y-scroll max-h-96";

      return result;
    });

    const editor = useEditor({
      content: props.modelValue,
      editorProps: {
        attributes: {
          class: classes.value,
          spellcheck: "true"
        }
      },
      extensions: [
        StarterKit,
        Highlight,
        Link,
        Placeholder.configure({
          placeholder: props.placeholder
        }),
        TaskList,
        TaskItem
      ],

      onFocus: () => {
        toolbarVisibilityStore.openToolbar(componentId.value);
      },

      onUpdate: ({ editor }) => {
        if (editor) {
          context.emit("update:modelValue", editor.getHTML());
        }
      }
    });

    return {
      componentId,
      editor,
      isToolbarVisible
    };
  },

  components: {
    EditorContent,
    UrlPicker
  },

  props: {
    label: String,
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: String
  },

  data() {
    return {
      urlPickerShown: false,
      url: ""
    };
  },

  watch: {
    modelValue(value) {
      if (this.editor) {
        const isSame = this.editor.getHTML() === value;

        if (isSame) {
          return;
        }

        this.editor.commands.setContent(this.modelValue, false);
      }
    }
  },

  methods: {
    focus() {
      if (this.editor) {
        this.editor.chain().focus();
      }
    },

    removeUrl() {
      if (this.editor) {
        this.editor.chain().focus().unsetLink().run();
      }
    },

    setLink(url: string) {
      if (this.editor) {
        this.urlPickerShown = false;
        this.editor.chain().focus().setLink({ href: url }).run();
      }
    },

    showUrlPicker() {
      if (this.editor) {
        this.url = this.editor.getAttributes("link").href;
        this.urlPickerShown = true;
      }
    }
  },

  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
});
</script>

<style lang="scss" scoped>
.editable-text :deep(.ProseMirror) {
  @apply min-h-[60px];
}

.toolbar-visible :deep(.ProseMirror) {
  padding-top: 40px !important;
  padding-left: 10px !important;
}

:deep(.ProseMirror) {
  @apply block w-full cursor-text rounded border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm;
}

button.is-active {
  @apply bg-gray-200 hover:bg-gray-200;
}

:deep(ul[data-type="taskList"]) {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: baseline;

    > label {
      flex: 0 0 auto;
    }
  }
}

:deep(.prose-sm ul[data-type="taskList"] > li) {
  padding: 0;
  &:before {
    content: inherit;
  }
}

:deep(.prose-sm ul[data-type="taskList"] > li > *:first-child) {
  margin: 0;
  margin-right: 0.5rem;
}

:deep(.prose-sm > ul[data-type="taskList"] > li > *:last-child) {
  margin-bottom: 0;
}
</style>
