<template>
  <BaseDisclosure
    :open="open"
    :buttonLabel="`${textOrdinalize(index, numberOfFallbacks)} fallback`"
    @toggleOpen="open = !open"
    color="light purple"
    class="mb-2"
  >
    <div
      v-if="model"
      class="mb-4 grid grid-cols-1 gap-y-6 gap-x-4 rounded bg-gray-50"
    >
      <div>
        <label class="block text-sm font-medium text-gray-700">
          Description
        </label>

        <BaseEditableText
          placeholder="Main description"
          class="mt-1 min-w-full text-xs"
          :modelValue="model.description"
          @update:modelValue="updateInput('description', $event)"
        />
      </div>

      <div>
        <label
          for="templateWording"
          class="block text-sm font-medium text-gray-700"
        >
          Template wording
        </label>

        <SimpleEditableText
          class="mt-1 min-w-full text-xs"
          placeholder="Template wording"
          :modelValue="model.templateWording"
          @update:modelValue="updateInput('templateWording', $event)"
        />
      </div>

      <div>
        <label
          for="templateComment"
          class="block text-sm font-medium text-gray-700"
        >
          Template comment
        </label>

        <SimpleEditableText
          class="mt-1 min-w-full text-xs"
          placeholder="Template comment"
          :modelValue="model.templateComment"
          @update:modelValue="updateInput('templateComment', $event)"
        />
      </div>

      <div class="pt-5">
        <div class="flex justify-between">
          <BaseButton
            @click.prevent="destroyFallback"
            size="small"
            buttonStyle="link"
          >
            Delete fallback
          </BaseButton>
        </div>
      </div>
    </div>
  </BaseDisclosure>
</template>

<script setup lang="ts">
import { computed, PropType, ref, watch } from "vue";
import { Fallback, FallbackScenario } from "@/types";
import BaseDisclosure from "@/components/base/BaseDisclosure.vue";
import BaseEditableText from "@/components/base/BaseEditableText.vue";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";
import { useSidebarStore } from "@/store/SidebarStore";
import { textOrdinalize } from "@/helpers/TextHelpers";

const emit = defineEmits(["close", "update:modelValue"]);

const props = defineProps({
  fallback: {
    type: Object as PropType<Fallback>,
    required: true
  },

  fallbackScenario: {
    type: Object as PropType<FallbackScenario>,
    required: true
  },

  modelValue: {
    type: Object as PropType<Fallback>,
    required: false
  },

  numberOfFallbacks: {
    type: Number,
    required: true
  },

  index: {
    type: Number
  }
});

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      model.value = { ...newValue };
    }
  }
);

const model = ref({ ...props.modelValue });
if (!model.value) {
  model.value = {
    fallbackScenarioId: props.fallbackScenario.id,
    position: props.fallbackScenario.fallbacks.length,
    description: "",
    templateWording: "",
    templateComment: ""
  } as Fallback;
}

const destroyFallback = () => {
  if (props.modelValue) {
    model.value._destroy = "1";
    emit("update:modelValue", model.value);
  }
};

const sidebarStore = useSidebarStore();
const open = computed({
  get() {
    if (!props.fallback || !props.fallback.id) {
      return true;
    }
    return sidebarStore.isFallbackOpen(props.fallback);
  },

  set(value: boolean) {
    sidebarStore.setFallbackOpen(props.fallback, value);
  }
});

const updateInput = (key: string, value: string) => {
  if (model.value) {
    model.value[key] = value;
    emit("update:modelValue", model.value);
  }
};
</script>
