/* eslint-disable @typescript-eslint/no-explicit-any */
const getObjectFromHash = (hash: string): Record<string, any> => {
  const result = new Map();
  if (hash[0] === "#") {
    hash = hash.substring(1);
  }
  hash.split("&").forEach((item) => {
    const [key, value] = item.split("=");
    result.set(key, decodeURIComponent(value));
  });
  return Object.fromEntries(result.entries());
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export { getObjectFromHash };
