import AutoLinker from "autolinker";
import { convert } from "html-to-text";

const formattedText = (text: string) => {
  return AutoLinker.link(text).replace(/\n/g, "<br>");
};

const htmlToPlainText = (html: string): string => {
  // Remove paragraphs from table cells
  return convert(html, {
    wordwrap: null,
    formatters: {
      // Create a formatter.
      tdParagraphFormatter: function (elem, walk, builder, formatOptions) {
        // This skips the paragraph and just walks the children.
        walk(elem.children, builder);
      },

      addNewLine: function (elem, walk, builder, formatOptions) {
        builder.openBlock({ leadingLineBreaks: 1 });
        walk(elem.children, builder);
        builder.closeBlock({ leadingLineBreaks: 1 });
      }
    },
    selectors: [
      {
        selector: "h3",
        options: { uppercase: false }
      },
      {
        selector: "td > p",
        format: "tdParagraphFormatter"
      },
      {
        selector: "tr",
        format: "addNewLine"
      },
      {
        selector: "img",
        format: "skip"
      }
    ]
  });
};

const ordinalize = (n: number) => {
  const i = n + 1;
  const j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
};

const textOrdinalize = (n: number, max: number) => {
  const i = n + 1;
  if (i === 1) {
    return "First";
  }
  if (i === max) {
    return "Final";
  }
  if (i === 2) {
    return "Second";
  }

  if (i === 3) {
    return "Third";
  }

  if (i === 4) {
    return "Fourth";
  }

  if (i === 5) {
    return "Fifth";
  }

  if (i === 6) {
    return "Sixth";
  }

  if (i === 7) {
    return "Seventh";
  }

  if (i === 8) {
    return "Eighth";
  }
  return ordinalize(n);
};

const removeTags = (html: string) => {
  if (!html.match(/<p>/g)) {
    return html;
  }
  let text = html.replace(/\n/gm, "");
  text = text.replace(/&nbsp;/g, " ");
  text = text.replace(/<br>/g, "\n");
  text = text.replace(/<\/p>/g, "\n");
  text = text.replace(/<p>/g, "");
  text = text.replace(/<strong>/g, "");
  text = text.replace(/<\/strong>/g, "");
  text = text.replace(/<em>/g, "");
  text = text.replace(/<\/em>/g, "");
  text = text.replace(/<u>/g, "");
  text = text.replace(/<\/u>/g, "");
  text = text.replace(/<mark>/g, "");
  text = text.replace(/<\/mark>/g, "");
  text = text.replace(/<span[^>]*>/g, "");
  text = text.replace(/<\/span>/g, "");
  text = text.replace(/<\/s>/g, "");
  text = text.replace(/<s>/g, "");
  text = text.replace(/<\/s>/g, "");
  text = text.replace(/&apos;/g, "'");
  text = text.replace(/&amp;/g, "&");
  text = text.replace(/&lt;/g, "<");
  text = text.replace(/&gt;/g, ">");
  text = text.replace(/&quot;/g, '"');
  return text.trim();
};

const removeElementsNotCompatibleWithWord = (html: string) => {
  let text = html.replace(/<ul[^>]*>/g, "");
  text = text.replace(/<li[^>]*>/g, "");
  text = text.replace(/<\/li>/g, "");
  text = text.replace(/<\/ul>/g, "");
  text = text.replace(/<input[^>]*>/g, "");
  text = text.replace(/<label>/g, "");
  text = text.replace(/<\/label>/g, "");
  text = text.replace(/<span>/g, "");
  text = text.replace(/<\/span>/g, "");
  text = text.replace(/<div>/g, "");
  text = text.replace(/<\/div>/g, "");
  return text;
};

const blankText = (html: string | null | undefined) => {
  if (!html) return true;
  const text = removeTags(html);
  return text === "";
};

const displayMarkupWithMarkedText = (
  html: string | null | undefined,
  markedText: string | null | undefined
): string | null => {
  if (!markedText || !html) {
    return html || null;
  }

  // Create a virtual DOM to manipulate the markup
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Use TreeWalker to iterate through all text nodes
  const walker = document.createTreeWalker(doc.body, NodeFilter.SHOW_TEXT);

  // Collect all nodes that match the criteria in an array
  const nodesToReplace: Node[] = [];
  let node;

  while ((node = walker.nextNode())) {
    const nodeValue = node.nodeValue;
    if (nodeValue && new RegExp(markedText, "i").test(nodeValue)) {
      nodesToReplace.push(node);
    }
  }

  // Iterate over the collected nodes to replace them
  nodesToReplace.forEach((node) => {
    if (!node.nodeValue) return;
    const nodeValue = node.nodeValue;
    const markedValue = nodeValue.replace(
      new RegExp(`(${markedText})`, "gi"),
      "<mark>$1</mark>"
    );
    const replacementNode = document.createElement("span");
    replacementNode.innerHTML = markedValue;

    if (node.parentNode) {
      node.parentNode.replaceChild(replacementNode, node);
    }
  });

  // Serialize and return the modified markup
  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc.body);
};

const displayStringWithMarkedText = (
  value: string | null,
  markedText: string | null | undefined
) => {
  if (!markedText) {
    return value;
  }

  if (!value) return "";

  return value.replace(
    new RegExp(escapedForRegex(markedText), "gi"),
    (match) => `<mark>${match}</mark>`
  );
};

const documentHtmlToPlainText = (value: string) => {};

const escapedForRegex = (str: string) => {
  /* eslint-disable-next-line no-useless-escape */
  return str.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};

const sortObject = (obj: Record<string, any>): Record<string, any> => {
  if (typeof obj !== "object" || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(sortObject);
  }

  const sortedObject = {};
  Object.keys(obj)
    .sort()
    .forEach((key) => {
      sortedObject[key] = sortObject(obj[key]);
    });

  return sortedObject;
};

const generateHashForObject = async (obj: Record<string, any>) => {
  const sortedObj = sortObject(obj);
  const jsonString = JSON.stringify(sortedObj);
  const encoder = new TextEncoder();
  const data = encoder.encode(jsonString);
  const hashBuffer = await crypto.subtle.digest("SHA-256", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

const getFirstParagraph = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const firstParagraph = doc.querySelector("p");
  if (firstParagraph) {
    return firstParagraph.textContent || "";
  }
  return "";
};

const closeDetails = (html: string): string => {
  // Create a new DOMParser object
  const parser = new DOMParser();

  // Use DOMParser to turn the string of HTML into a Document object
  const doc = parser.parseFromString(html, "text/html");

  // Get all the details elements
  const detailsElements = doc.querySelectorAll(".details");

  // Iterate over all details elements
  detailsElements.forEach((detailsElement) => {
    detailsElement.classList.remove("is-open");
    // If the element has the 'open' attribute, remove it
    if (detailsElement.hasAttribute("open")) {
      detailsElement.removeAttribute("open");
    }

    // Get the detailsContent div using its data-type attribute
    const detailsContentDiv = detailsElement.querySelector(
      'div[data-type="detailsContent"]'
    );

    if (
      detailsContentDiv &&
      detailsContentDiv.getAttribute("hidden") === null
    ) {
      // Add the 'hidden' attribute
      detailsContentDiv.setAttribute("hidden", "");
    }
  });

  // Turn the Document object back into a string of HTML
  let newHtml = new XMLSerializer().serializeToString(doc);

  // The XMLSerializer includes an unnecessary wrapper around the HTML, so we remove it
  newHtml = newHtml.replace(
    '<html xmlns="http://www.w3.org/1999/xhtml"><head></head><body>',
    ""
  );
  newHtml = newHtml.replace("</body></html>", "");

  return newHtml;
};

const removeFirstParagraph = (html: string): string => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");
  const firstParagraph = doc.querySelector("p");

  if (firstParagraph) {
    firstParagraph.remove();
  }

  return doc.documentElement.outerHTML;
};

import {
  diff_match_patch,
  DIFF_DELETE,
  DIFF_INSERT,
  DIFF_EQUAL
} from "diff-match-patch";

const highlightDifferences = (original: string, updated: string): string => {
  const dmp = new diff_match_patch();
  const diffs = dmp.diff_main(original, updated);
  dmp.diff_cleanupSemantic(diffs);

  let result = "";

  diffs.forEach(([operation, text]) => {
    switch (operation) {
      case DIFF_INSERT:
        result += `<ins>${text}</ins>`;
        break;
      case DIFF_DELETE:
        result += `<del>${text}</del>`;
        break;
      case DIFF_EQUAL:
        result += text;
        break;
    }
  });

  return result;
};

const truncateString = (str: string, num: number) => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

const formatStringAsObjectWithLineNumbers = (input: string) => {
  if (!input) return "{}";
  const jsonResult = input.split("\n").reduce(
    (acc, line, index) => {
      acc[index + 1] = line;
      return acc;
    },
    {} as Record<number, string>
  );
  return JSON.stringify(jsonResult, null, 2);
};

const splitDocumentByLines = (
  lineNumberHash: Record<number, string>,
  document: string
): { [key: string]: string } => {
  const lines = document.split("\n");
  const lineNumbers = Object.keys(lineNumberHash)
    .map(Number)
    .sort((a, b) => a - b);
  let resultIndex = 0;
  const result: { [key: string]: string } = {};
  lineNumbers.forEach((lineNumber, index) => {
    const startLineIndex = lineNumber - 1; // Adjust because arrays are zero-indexed
    const nextLineNum =
      index + 1 < lineNumbers.length
        ? lineNumbers[index + 1] - 1
        : lines.length;
    const segment = lines.slice(startLineIndex, nextLineNum).join("\n");
    resultIndex = resultIndex + 1;

    result[resultIndex] = segment;
  });

  return result;
};

export {
  blankText,
  closeDetails,
  formatStringAsObjectWithLineNumbers,
  highlightDifferences,
  displayMarkupWithMarkedText,
  displayStringWithMarkedText,
  formattedText,
  generateHashForObject,
  htmlToPlainText,
  ordinalize,
  getFirstParagraph,
  removeFirstParagraph,
  removeElementsNotCompatibleWithWord,
  removeTags,
  splitDocumentByLines,
  textOrdinalize,
  truncateString
};
