import {
  Node,
  mergeAttributes,
  findParentNode,
  defaultBlockAt
} from "@tiptap/core";
import { Selection } from "@tiptap/pm/state";

const DetailsContent = Node.create({
  name: "detailsContent",
  content: "block+",
  defining: true,
  selectable: false,

  addOptions: () => ({
    HTMLAttributes: {}
  }),

  parseHTML() {
    return [{ tag: `div[data-type="${this.name}"]` }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes, {
        "data-type": this.name
      }),
      0
    ];
  },

  addNodeView() {
    return ({ HTMLAttributes }) => {
      const divElement = document.createElement("div");
      const mergedAttributes = mergeAttributes(
        this.options.HTMLAttributes,
        HTMLAttributes,
        { "data-type": this.name }
      );

      Object.entries(mergedAttributes).forEach(([key, value]) => {
        divElement.setAttribute(key, value);
      });

      return {
        dom: divElement,
        contentDOM: divElement,
        ignoreMutation: (event) =>
          "selection" !== event.type &&
          (!divElement.contains(event.target) || divElement === event.target),
        update: (node) => node.type === this.type
      };
    };
  },

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { selection } = state;
        const { $from, empty } = selection;
        const parentNode = findParentNode((node) => node.type === this.type)(
          selection
        );

        if (!empty || !parentNode || !parentNode.node.childCount) return false;

        const index = $from.index(parentNode.depth);
        const { childCount } = parentNode.node;

        if (!(childCount === index + 1)) return false;

        const defaultType = parentNode.node.type.contentMatch.defaultType;
        const filledNode = defaultType?.createAndFill();

        if (!filledNode) return false;

        const resolvedPos = state.doc.resolve(parentNode.pos + 1);
        const lastChildIndex = childCount - 1;
        const lastChild = parentNode.node.child(lastChildIndex);
        const posAtIndex = resolvedPos.posAtIndex(
          lastChildIndex,
          parentNode.depth
        );

        if (!lastChild.eq(filledNode)) return false;

        const ancestorNode = $from.node(-3);

        if (!ancestorNode) return false;

        const indexAfterAncestor = $from.indexAfter(-3);
        const defaultBlock = defaultBlockAt(
          ancestorNode.contentMatchAt(indexAfterAncestor)
        );

        if (
          !defaultBlock ||
          !ancestorNode.canReplaceWith(
            indexAfterAncestor,
            indexAfterAncestor,
            defaultBlock
          )
        )
          return false;

        const newBlock = defaultBlock.createAndFill();

        if (!newBlock) return false;

        const { tr } = state;
        const posAfterAncestor = $from.after(-2);
        tr.replaceWith(posAfterAncestor, posAfterAncestor, newBlock);

        const newPos = tr.doc.resolve(posAfterAncestor);
        const newSelection = Selection.near(newPos, 1);
        tr.setSelection(newSelection);

        const deleteStartPos = posAtIndex;
        const deleteEndPos = posAtIndex + lastChild.nodeSize;

        tr.delete(deleteStartPos, deleteEndPos);
        tr.scrollIntoView();
        view.dispatch(tr);

        return true;
      }
    };
  }
});

export { DetailsContent };
export default DetailsContent;
