<template>
  <div
    class="bg-word-gray h-[calc(100vh-40px)] overflow-scroll rounded-tl rounded-tr border pl-10 pb-10"
  >
    <!-- Toolbar -->
    <div class="-ml-8 border-b">
      <img
        v-if="blur"
        src="@/assets/images/blurred-word-toolbar.png"
        width="1000"
      />
      <img v-else src="@/assets/images/word-toolbar.jpg" width="1000" />
    </div>

    <div class="mt-5 flex">
      <!-- Document Text -->
      <div class="mr-5 bg-white p-10 drop-shadow" :style="blurStyle">
        <h1><strong>Some example text</strong></h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed lobortis,
          nibh at condimentum molestie, ante tellus mattis dolor, ac vestibulum
          mauris risus eget ante.
        </p>
      </div>

      <!-- Sidebar Add-In -->

      <div
        class="-mt-5 cursor-ew-resize select-none border-l-8 border-transparent"
        @mousedown="startDrag"
      >
        <div class="bg-gray-100 drop-shadow">
          <div class="bg-word-gray text-word h-10 p-2">LexPlay</div>

          <!-- Add-in Content -->
          <iframe
            v-show="!dragging"
            src="/sidebar/playbooks?OsfOptOut=true"
            class="h-full w-full"
            :style="{ width: width + 'px' }"
          ></iframe>
          <div
            v-show="dragging"
            class="h-full w-full bg-white pt-20 text-center"
            :style="{ width: width + 'px' }"
          >
            Resizing...
          </div>
        </div>
      </div>
    </div>
  </div>

  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="open = false">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div
          class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0"
        >
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6"
            >
              <div>
                <div
                  class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
                >
                  <CheckIcon
                    class="h-6 w-6 text-green-600"
                    aria-hidden="true"
                  />
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <DialogTitle
                    as="h3"
                    class="text-base font-semibold leading-6 text-gray-900"
                  >
                    Example use of LexPlay
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">
                      This is an example of what LexPlay looks like. You can
                      download the add-in from the Microsoft store.
                    </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded-md bg-lexoo px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-80 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  @click="open = false"
                >
                  Ok
                </button>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { useRoute } from "vue-router";

import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/outline";

const open = ref(true);

const minWidth = 400;

const width = ref(minWidth);
const startWidth = ref(0);
const dragging = ref(false);
const startX = ref(0);

const route = useRoute();
const blur = ref(route.query.blur === "true");

const blurStyle = computed(() => {
  return blur.value ? "filter: blur(2px)" : "";
});

const startDrag = (e: MouseEvent) => {
  dragging.value = true;
  startX.value = e.clientX;
  startWidth.value = width.value;

  window.addEventListener("mousemove", drag);
  window.addEventListener("mouseup", stopDrag);
};

const drag = (e: MouseEvent) => {
  if (dragging.value) {
    console.log("dragging " + e.clientX);
    const diff = startX.value - e.clientX;
    console.log(
      "Start x: " +
        startX.value +
        ", current x: " +
        e.clientX +
        ", diff: " +
        diff
    );

    if (diff < 0) {
      // Dragged left, make wider
      width.value = width.value - Math.abs(diff);
      if (width.value < minWidth) {
        width.value = minWidth;
      }
    } else {
      // Dragged right, make narrower
      width.value = startWidth.value + diff;
    }
  }
};

const stopDrag = () => {
  dragging.value = false;
  window.removeEventListener("mousemove", drag);
  window.removeEventListener("mouseup", stopDrag);
};
</script>

<style scoped>
.h-full {
  height: 100vh;
}

.bg-word-gray {
  background-color: #f0f0f0;
}

.text-word {
  font-family: "Arial";
}
</style>
