<template>
  <div
    class="relative my-2 rounded-sm border-b border-gray-200 bg-gray-50 p-2 shadow"
  >
    <h3 v-if="showLabel" class="text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </h3>
    <div
      ref="templateWordingWithVariables"
      v-html="displayStringWithFilterResult(templateWordingWithVariables)"
      class="mt-2 whitespace-pre-wrap pb-6 text-sm text-gray-500 outline-0"
      @keyup.enter="insertSelectedTemplateWordingIntoWord"
      tabindex="0"
    />
    <button
      v-if="shouldCopyWording"
      @click.prevent="copyTemplateWordingToClipboard"
      type="button"
      class="absolute bottom-0 right-0 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
    >
      {{ copyButtonText }}
    </button>

    <button
      v-else
      @click.prevent="insertTemplateWordingIntoWordEnsuringVariables"
      type="button"
      class="absolute bottom-0 right-0 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
    >
      {{ templateWordingSelected ? "Paste selection" : "Paste" }}
    </button>
    <OldSkoolVariableQuestionsModal
      v-if="playbookInstance"
      :show="showVariablesPanel"
      buttonText="Paste"
      :playbookInstance="playbookInstance"
      :insertionText="textForVariablesModal"
      :oldSkoolVariables="
        oldSkoolVariablesStore.oldSkoolVariablesWithoutAnswersForText(
          textForVariablesModal
        )
      "
      @close="showVariablesPanel = false"
      @savedPlaybookInstance="insertUpdatedAnswers"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import ApiService from "@/services/ApiService";
import { displayStringWithMarkedText } from "@/helpers/TextHelpers";
import OldSkoolVariableQuestionsModal from "@/components/sidebar/OldSkoolVariableQuestionsModal.vue";

import { NewInteraction, PlaybookInstance } from "@/types";
import { useSelectionStore } from "@/store/SelectionStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { useOldSkoolVariablesStore } from "@/store/OldSkoolVariablesStore";
import { insertTextIntoDocument } from "@/helpers/WordHelpers";

export default defineComponent({
  /* global Word */

  setup() {
    const selectionStore = useSelectionStore();
    const showVariablesPanel = ref(false);
    const oldSkoolVariablesStore = useOldSkoolVariablesStore();
    const sidebarStore = useSidebarStore();
    const shouldCopyWording = computed(() => !sidebarStore.withinWord);

    const textForVariablesModal = ref("");
    const copyButtonText = ref("Copy");
    return {
      copyButtonText,
      oldSkoolVariablesStore,
      selectionStore,
      shouldCopyWording,
      showVariablesPanel,
      sidebarStore,
      textForVariablesModal
    };
  },

  components: {
    OldSkoolVariableQuestionsModal
  },

  props: {
    entryId: {
      required: false,
      type: Number
    },

    fallbackId: {
      required: false,
      type: Number
    },

    filterText: {
      required: false,
      type: String
    },

    showLabel: {
      type: Boolean,
      default: true
    },

    playbookInstance: {
      type: Object as PropType<PlaybookInstance>,
      required: false
    },

    templateWording: {
      type: String,
      required: true
    },

    label: {
      type: String,
      default: "Template wording"
    },

    copyValue: {
      type: String,
      required: false
    }
  },

  computed: {
    selectedNode(): Node | null {
      return this.selectionStore.selectedNode;
    },

    selectedText(): string | null {
      if (!this.thisNodeSelected) return null;
      return this.selectionStore.selectedText;
    },

    selectedTextWithVariables(): string | null {
      if (!this.selectedText) return null;
      return this.oldSkoolVariablesStore.textWithOldSkoolVariables(
        this.selectedText
      );
    },

    templateWordingSelected(): boolean {
      return (
        this.selectedText !== null &&
        this.selectedText.length > 0 &&
        this.thisNodeSelected
      );
    },

    templateWordingWithVariables(): string {
      return this.oldSkoolVariablesStore.textWithOldSkoolVariables(
        this.templateWording
      );
    },

    thisNodeSelected(): boolean {
      if (!this.selectedNode || !this.selectedNode.parentElement) {
        return false;
      }
      if (!this.$refs.templateWordingWithVariables) {
        return false;
      }
      return (this.$refs.templateWordingWithVariables as Node).contains(
        this.selectedNode
      );
    }
  },

  methods: {
    copyTemplateWordingToClipboard() {
      const textToCopy =
        this.selectedText || this.copyValue || this.templateWording;

      const clipboardItem = new ClipboardItem({
        "text/html": new Blob([textToCopy], { type: "text/html" }),
        "text/plain": new Blob([textToCopy], { type: "text/plain" })
      });
      this.copyButtonText = "Copied!";
      navigator.clipboard.write([clipboardItem]).then(
        (_) => {
          setTimeout(() => {
            this.copyButtonText = "Copy";
          }, 1000);
        },
        (error) => alert(error)
      );
    },

    insertTemplateWordingIntoWordEnsuringVariables() {
      const textToInsert =
        this.selectedText || this.copyValue || this.templateWording;

      if (
        this.oldSkoolVariablesStore.oldSkoolVariablesWithoutAnswersForText(
          textToInsert
        ).length > 0 &&
        this.playbookInstance
      ) {
        this.textForVariablesModal =
          this.selectedTextWithVariables || this.templateWordingWithVariables;
        this.showVariablesPanel = true;
      } else {
        this.insertTemplateWordingIntoWord(
          this.oldSkoolVariablesStore.textWithOldSkoolVariables(textToInsert)
        );
      }
    },

    displayStringWithFilterResult(value: string | null) {
      return displayStringWithMarkedText(value, this.filterText ?? null);
    },

    async insertTemplateWordingIntoWord(textToInsert: string) {
      await insertTextIntoDocument(textToInsert);
      this.trackInsertion(textToInsert);
    },

    insertSelectedTemplateWordingIntoWord() {
      if (this.templateWordingSelected) {
        this.insertTemplateWordingIntoWordEnsuringVariables();
      }
    },

    trackInsertion(text: string) {
      let partialInsert = false;
      if (this.selectedText && this.selectedText.length > 0) {
        partialInsert = true;
      }
      const extraInfo: {
        snippet: string;
        partial_insert: boolean;
        word_doc_id?: string;
      } = {
        partial_insert: partialInsert,
        snippet: text
      };
      if (this.playbookInstance) {
        extraInfo.word_doc_id = this.playbookInstance.wordDocId ?? undefined;
      }
      const interaction: NewInteraction = {
        action: "inserted template wording",
        entryId: this.entryId,
        extraInfo,
        fallbackId: this.fallbackId
      };
      ApiService.trackInteraction(interaction);
    },

    insertUpdatedAnswers() {
      this.insertTemplateWordingIntoWord(
        this.oldSkoolVariablesStore.textWithOldSkoolVariables(
          this.textForVariablesModal
        )
      );
      // }
    }
  }
});
</script>

<style lang="scss" scoped>
:deep(ins) {
  text-decoration: underline;
}

:deep(del) {
  color: #b00;
  text-decoration: line-through;
}
</style>
