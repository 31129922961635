<template>
  <div class="relative grid lg:max-w-2xl">
    <IssueBody
      v-if="issue"
      :issue="issue"
      :clauses="clauses"
      :playbookid="props.playbookId"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from "vue";
import { useLoadPlaybookInstance } from "@/services/PlaybookInstanceLoader";
import { useClausesStore } from "@/store/ClausesStore";
import { useIssuesStore } from "@/store/IssuesStore";
import IssueBody from "@/components/sidebar/IssueBody.vue";
const { loadPlaybookInstance } = useLoadPlaybookInstance();
const clausesStore = useClausesStore();
const issuesStore = useIssuesStore();

const props = defineProps({
  entryId: {
    type: String,
    required: true
  },

  playbookId: {
    type: String,
    required: true
  }
});

const clauses = computed(() => {
  const clausesStore = useClausesStore();
  return clausesStore.getClausesByEntryId(parseInt(props.entryId));
});

const issue = computed(() => {
  const issuesStore = useIssuesStore();
  return issuesStore.getIssuesByEntryId(parseInt(props.entryId))[0];
});

onMounted(async () => {
  await loadPlaybookInstance();
  await issuesStore.loadIssues();
  await clausesStore.loadClauses();
});
</script>
