<template>
  <div
    class="relative my-2 rounded-sm border-b border-gray-200 bg-gray-50 p-2 shadow"
    v-bind="$attrs"
  >
    <h3 v-if="showLabel" class="text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </h3>
    <div
      ref="templateCommentWithVariables"
      v-html="displayStringWithFilterResult(templateCommentWithVariables)"
      class="mt-2 whitespace-pre-wrap pb-6 text-sm text-gray-500 outline-0"
      @keyup.enter="insertSelectedTemplateCommentIntoWord"
      tabindex="1"
    />
    <button
      v-if="shouldCopyComment"
      @click.prevent="copyTemplateCommentToClipboard"
      type="button"
      class="absolute bottom-0 right-0 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
    >
      {{ copyButtonText }}
    </button>
    <button
      v-else
      @click.prevent="insertTemplateCommentIntoWordAsCommentEnsuringVariables"
      type="button"
      class="absolute bottom-0 right-0 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
    >
      {{ templateCommentSelected ? "Insert selection" : "Insert" }}
    </button>
  </div>
  <OldSkoolVariableQuestionsModal
    v-if="playbookInstance"
    :show="showVariablesPanel"
    buttonText="Insert"
    :playbookInstance="playbookInstance"
    :insertionText="textForVariablesModal"
    :oldSkoolVariables="
      oldSkoolVariablesStore.oldSkoolVariablesWithoutAnswersForText(
        textForVariablesModal
      )
    "
    @close="showVariablesPanel = false"
    @updateAnswers="updateAnswers"
  />
  <BaseAlertModal
    v-if="showUpdateWordModal"
    title="Update Word to use this functionality"
    @close="showUpdateWordModal = false"
  >
    <div class="space-y-4 text-sm text-gray-500">
      <p>
        Your version of Microsoft Word needs to be updated to use this
        functionality.
      </p>
      <p>Please update Word and try again.</p>
    </div>
  </BaseAlertModal>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue";
import BaseAlertModal from "@/components/base/BaseAlertModal.vue";
import OldSkoolVariableQuestionsModal from "@/components/sidebar/OldSkoolVariableQuestionsModal.vue";
import { useSidebarStore } from "@/store/SidebarStore";
import { useOldSkoolVariablesStore } from "@/store/OldSkoolVariablesStore";

import ApiService from "@/services/ApiService";
import { displayStringWithMarkedText, removeTags } from "@/helpers/TextHelpers";
import { NewInteraction, PlaybookInstance } from "@/types";
import { useSelectionStore } from "@/store/SelectionStore";

export default defineComponent({
  /* global Word */

  setup() {
    const selectionStore = useSelectionStore();
    const sidebarStore = useSidebarStore();
    const oldSkoolVariablesStore = useOldSkoolVariablesStore();
    const shouldCopyComment = computed(() => !sidebarStore.withinWord);

    const showUpdateWordModal = ref(false);
    const showVariablesPanel = ref(false);
    const textForVariablesModal = ref("");
    const copyButtonText = ref("Copy");
    return {
      copyButtonText,
      oldSkoolVariablesStore,
      selectionStore,
      shouldCopyComment,
      showUpdateWordModal,
      showVariablesPanel,
      sidebarStore,
      textForVariablesModal
    };
  },

  props: {
    entryId: {
      type: Number,
      required: false
    },

    templateComment: {
      type: String,
      required: true
    },

    fallbackId: {
      type: Number,
      required: false
    },

    filterText: {
      required: false,
      type: String
    },

    playbookInstance: {
      type: Object as PropType<PlaybookInstance>,
      required: false
    },

    showLabel: {
      type: Boolean,
      default: true
    },

    label: {
      type: String,
      default: "Template comment"
    }
  },

  components: {
    BaseAlertModal,
    OldSkoolVariableQuestionsModal
  },

  computed: {
    templateCommentSelected(): boolean {
      return (
        this.selectedText !== null &&
        this.selectedText.length > 0 &&
        this.thisNodeSelected
      );
    },

    templateCommentWithVariables(): string {
      return this.oldSkoolVariablesStore.textWithOldSkoolVariables(
        this.templateComment
      );
    },

    needToUpdateWord(): boolean {
      if (
        !Office.context.requirements ||
        !Office.context.requirements.isSetSupported("WordApi", "1.5")
      ) {
        return true;
      }

      return false;
    },

    selectedNode(): Node | null {
      return this.selectionStore.selectedNode;
    },

    selectedText(): string | null {
      if (!this.thisNodeSelected) return null;
      return this.selectionStore.selectedText;
    },

    selectedTextWithVariables(): string | null {
      if (!this.selectedText) return null;
      return this.oldSkoolVariablesStore.textWithOldSkoolVariables(
        this.templateComment
      );
    },

    thisNodeSelected(): boolean {
      if (!this.selectedNode || !this.selectedNode.parentElement) {
        return false;
      }
      if (!this.$refs.templateCommentWithVariables) {
        return false;
      }
      return (this.$refs.templateCommentWithVariables as Node).contains(
        this.selectedNode
      );
    }
  },

  methods: {
    copyTemplateCommentToClipboard() {
      const textToCopy = this.selectedText || this.templateComment;

      const clipboardItem = new ClipboardItem({
        "text/html": new Blob([textToCopy], { type: "text/html" }),
        "text/plain": new Blob([textToCopy], { type: "text/plain" })
      });
      this.copyButtonText = "Copied!";
      navigator.clipboard.write([clipboardItem]).then(
        (_) => {
          setTimeout(() => {
            this.copyButtonText = "Copy";
          }, 1000);
        },
        (error) => alert(error)
      );
    },

    displayStringWithFilterResult(value: string | null) {
      return displayStringWithMarkedText(value, this.filterText ?? null);
    },

    insertTemplateCommentIntoWordAsCommentEnsuringVariables() {
      if (this.needToUpdateWord) {
        this.showUpdateWordModal = true;
      } else {
        const textToInsert = this.selectedText || this.templateComment;
        if (
          this.oldSkoolVariablesStore.oldSkoolVariablesWithoutAnswersForText(
            textToInsert
          ).length > 0
        ) {
          this.textForVariablesModal =
            this.selectedTextWithVariables || this.templateCommentWithVariables;
          this.showVariablesPanel = true;
        } else {
          this.insertTemplateCommentIntoWordAsComment(
            this.oldSkoolVariablesStore.textWithOldSkoolVariables(textToInsert)
          );
        }
      }
    },

    insertTemplateCommentIntoWordAsComment(textToInsert: string) {
      Word.run((context: Word.RequestContext) => {
        const document = context.document;
        const range = document.getSelection();
        range.insertComment(removeTags(textToInsert));
        return context.sync();
      }).catch((error: OfficeExtension.Error) => {
        console.log("Error: " + JSON.stringify(error));
        console.log("Debug info: " + JSON.stringify(error.debugInfo));
      });

      this.trackInsertion(textToInsert);
    },

    insertSelectedTemplateCommentIntoWord() {
      if (this.templateCommentSelected) {
        this.insertTemplateCommentIntoWordAsCommentEnsuringVariables();
      }
    },

    trackInsertion(text: string) {
      let partialInsert = false;
      if (this.selectedText && this.selectedText.length > 0) {
        partialInsert = true;
      }

      const extraInfo: {
        snippet: string;
        partial_insert: boolean;
        word_doc_id?: string;
      } = {
        partial_insert: partialInsert,
        snippet: text
      };
      if (this.playbookInstance)
        extraInfo.word_doc_id = this.playbookInstance.wordDocId ?? undefined;
      const interaction: NewInteraction = {
        action: "inserted template comment",
        extraInfo,
        fallbackId: this.fallbackId,
        entryId: this.entryId
      };
      ApiService.trackInteraction(interaction);
    },

    updateAnswers(variableAnswers: Record<string, string>) {
      if (this.playbookInstance) {
        const updatedPlaybookInstance = {
          ...this.playbookInstance,
          variableAnswers
        };
        this.sidebarStore.updatePlaybookInstance(updatedPlaybookInstance);
        this.insertTemplateCommentIntoWordAsComment(
          this.oldSkoolVariablesStore.textWithOldSkoolVariables(
            this.textForVariablesModal
          )
        );
      }
    }
  }
});
</script>
