<template>
  <PlaybookNav currentStep="Generate clause" />
  <BaseTitle class="flex">
    <SparklesIcon class="mr-2 h-6 w-6 text-yellow-500" />
    Generate clause
  </BaseTitle>

  <div class="mt-8 max-w-xl">
    <p class="mt-1 text-sm leading-6 text-gray-600">
      Use AI to draft a clause.
    </p>
    <form class="mt-8 space-y-6">
      <BaseInput
        label="What type of contract is this?"
        autofocus
        v-model="contractType"
        required
        hint="e.g. SaaS MSA, loan agreement, NDA, services agreement, etc."
        class="text-xs sm:text-xs"
      />
      <BaseInput
        label="Which party are you?"
        v-model="role"
        required
        hint="e.g. customer, software provider, lender, borrower, etc."
        class="text-xs sm:text-xs"
      />
      <BaseInput
        label="Which language?"
        v-model="language"
        hint="e.g. British English, Norsk, 日本語, etc."
        class="text-xs sm:text-xs"
      />
      <BaseInput
        label="Provide some context"
        v-model="context"
        inputType="textarea"
        hint="What is it that you want the clause to address?"
        required
        class="text-xs sm:text-xs"
      />

      <span class="flex space-x-1">
        <BaseButton
          :label="generateButtonText"
          @click="generate"
          :disabled="processing"
        >
          <SparklesIcon class="mr-1 inline-block h-4 w-4 text-yellow-500" />
        </BaseButton>
        <BaseButton
          label="Clear fields"
          buttonStyle="white"
          @click="clearFields"
        />
      </span>
    </form>

    <div v-if="aiError" class="m-2 mt-4 max-w-lg rounded border p-4">
      <h3 class="font-medium">AI features currently unavailable</h3>
      <p class="mt-2 text-sm text-gray-500">
        Our partner OpenAI is reporting issues on their end. They are currently
        investigating. LexPlay's AI features are currently unavailable.
      </p>
    </div>
    <div class="mt-4">
      <div v-for="(message, index) in messages" :key="index">
        <div v-if="message.isUser" class="border-b pb-8 pt-8 text-right">
          <span
            class="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800"
          >
            You
          </span>
          <div class="text-sm">
            {{ message.text }}
          </div>
        </div>
        <div v-else class="mt-8">
          <span
            class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
          >
            AI response
          </span>
          <BaseSuperText :readonly="true" v-model="message.text" />
        </div>
      </div>
      <div v-if="generatedOutputWithVariablesAdded.length > 0" class="mt-8">
        <span
          class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
        >
          AI response
        </span>
        <BaseSuperText
          class="mt-1"
          :readonly="true"
          v-model="generatedOutputWithVariablesAdded"
          :generatingAI="streaming"
        />
      </div>
    </div>
    <div v-if="messages.length > 0" class="max-w-xl">
      <div class="mt-4">
        <textarea
          v-model="refineComment"
          rows="3"
          class="block w-full rounded-md border-0 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:leading-6"
          placeholder="Write any refinement you want here..."
        />
      </div>
      <div class="mt-2 flex justify-end">
        <BaseButton @action="refine" :disabled="processing">
          Refine
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useStorage } from "@vueuse/core";
import PlaybookNav from "@/components/sidebar/PlaybookNav.vue";
import BaseSuperText from "@/components/base/BaseSuperText.vue";
import { SparklesIcon } from "@heroicons/vue/24/solid";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import { useSidebarStore } from "@/store/SidebarStore";
import ApiService from "@/services/ApiService";
import { AiMessage, useAIStreamer } from "@/services/AI/AIStreamer";

import { NewInteraction, PlaybookAiVariables } from "@/types";

defineProps({
  playbookId: {
    type: String,
    required: false
  }
});

const sidebarStore = useSidebarStore();
const playbook = computed(() => {
  return sidebarStore.playbook;
});
const playbookAiVariables = computed((): PlaybookAiVariables => {
  if (!playbook.value) {
    return {};
  }

  return playbook.value.aiVariables;
});

const prompt = ref("");
const superPrompt = ref("");
const llmModel = ref("gpt-4");
const language = useStorage("draft-clause-language", "English");

const contractType = ref(playbookAiVariables.value.contractType);
const role = ref(playbookAiVariables.value.role);
const context = useStorage("draft-clause-context", "");

const refineComment = ref("");

const processing = ref(false);

const messages = ref<AiMessage[]>([]);

const {
  aiError,
  clearOutput,
  startStreaming,
  generatedOutputWithVariablesAdded,
  streaming
} = useAIStreamer();

watch(streaming, () => {
  if (!streaming.value) {
    messages.value.push({
      text: generatedOutputWithVariablesAdded.value,
      isUser: false
    });

    clearOutput();
    processing.value = false;
  }
});

const generateButtonText = computed(() => {
  if (streaming.value) {
    return "Please wait...";
  }

  if (messages.value.length > 0) {
    return "Generate another draft";
  }

  return "Generate draft";
});

const clearFields = () => {
  contractType.value = "";
  role.value = "";
  context.value = "";
};

const generate = async () => {
  processing.value = true;
  await loadPromptTemplate();
  messages.value = [];
  startStreaming({
    llmModel: llmModel.value,
    prompt: parsedPrompt.value,
    superPrompt: superPrompt.value
  });
  trackDrafting(llmModel.value);
};

const refine = async () => {
  processing.value = true;
  await loadPromptTemplate();
  messages.value.push({
    text: refineComment.value,
    isUser: true
  });
  trackRefinement(llmModel.value);
  refineComment.value = "";
  startStreaming({
    llmModel: llmModel.value,
    prompt: refineComment.value,
    superPrompt: superPrompt.value,
    messages: messages.value
  });
};

const loadPromptTemplate = async () => {
  const response = await ApiService.getPromptTemplate("Draft");
  const promptTemplate = response.data.promptTemplate;
  prompt.value = promptTemplate.prompt;
  superPrompt.value = promptTemplate.superPrompt;
  llmModel.value = promptTemplate.llmModel;
};

const parsedPrompt = computed(() => {
  const entryAiVariables = {
    contractType: contractType.value,
    role: role.value,
    context: context.value,
    language: language.value
  };
  const variables = { ...playbookAiVariables.value, ...entryAiVariables };
  return parsePrompt(prompt, variables);
});

const trackDrafting = (llmModel: string) => {
  const sidebarStore = useSidebarStore();
  const playbook = sidebarStore.playbook;
  const interaction = {
    action: "AI - draft clause",
    extraInfo: {
      prompt: parsedPrompt.value,
      superPrompt: superPrompt.value,
      llmModel
    },
    playbookId: playbook?.id
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};

const trackRefinement = (llmModel: string) => {
  const sidebarStore = useSidebarStore();
  const playbook = sidebarStore.playbook;
  const interaction = {
    action: "AI - draft clause - refine",
    extraInfo: {
      prompt: refineComment.value,
      superPrompt: superPrompt.value,
      llmModel
    },
    playbookId: playbook?.id
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};
</script>
@/services/AI/AIPromptParser @/services/AI/AIStreamer
