import { computed, Ref } from "vue";

import { useStorage } from "@vueuse/core";

function useDynamicStorage<T>(
  fieldName: string,
  dynamicKey: Ref<string>,
  defaultValue: T
) {
  const storage: Ref<Record<string, T>> = useStorage(fieldName, {});

  return computed({
    get(): T {
      return storage.value[dynamicKey.value] || defaultValue;
    },
    set(newValue: T) {
      storage.value[dynamicKey.value] = newValue;
    }
  });
}

export { useDynamicStorage };
