<template>
  <BaseTitle class="flex">
    <PencilIcon class="mr-2 h-6 w-6 text-lexoo" />
    AI Scratchpad
  </BaseTitle>
  <TabGroup
    @change="changeTab"
    :defaultIndex="tabs.indexOf(scratchTab)"
    as="div"
    class="max-w-lg border-b border-gray-200"
  >
    <TabList class="-mb-px flex space-x-8">
      <Tab
        v-for="(tab, index) in tabs"
        :key="index"
        as="template"
        v-slot="{ selected }"
      >
        <button :class="selected ? 'tab selected' : 'tab'">
          {{ tab }}
        </button>
      </Tab>
    </TabList>
  </TabGroup>

  <div class="grid grid-cols-2 py-10">
    <div class="max-w-lg space-y-10">
      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="mb-4 text-base font-semibold leading-7 text-gray-900">
          Prompts
        </h2>
        <BaseInput
          v-model="superPrompt"
          inputType="textarea"
          label="Super prompt"
        />

        <BaseInput
          v-model="prompt"
          inputType="textarea"
          label="Prompt"
          hint="Use {{ VARIABLE NAME }} for variables"
        />

        <BaseSelect
          v-model="llmModel"
          label="Model"
          :options="[
            'gpt-4',
            'gpt-4-turbo-preview',
            'gpt-4-1106-preview',
            'gpt-3.5-turbo',
            'claude-2.1',
            'claude-2',
            'gemini-pro'
          ]"
        />

        <SwitchGroup as="div" class="mt-6 flex items-center">
          <Switch
            v-model="jsonMode"
            :class="[
              jsonMode ? 'bg-indigo-600' : 'bg-gray-200',
              'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2'
            ]"
          >
            <span
              aria-hidden="true"
              :class="[
                jsonMode ? 'translate-x-5' : 'translate-x-0',
                'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
              ]"
            />
          </Switch>
          <SwitchLabel as="span" class="ml-3">
            <span class="text-sm font-medium text-gray-900"> JSON mode </span>
          </SwitchLabel>
        </SwitchGroup>
      </div>

      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="mb-4 text-base font-semibold leading-7 text-gray-900">
          Playbook level inputs
        </h2>
        <BaseInput
          v-model="contractType"
          label="Contract type"
          hint="{{ CONTRACT TYPE }}"
        />

        <BaseInput v-model="role" label="Role" hint="{{ ROLE }}" />
        <BaseInput v-model="language" label="Language" hint="{{ LANGUAGE }}" />
      </div>

      <div class="border-b border-gray-900/10 pb-12">
        <h2 class="mb-4 text-base font-semibold leading-7 text-gray-900">
          Entry level inputs
        </h2>
        <div class="mb-6 grid max-w-lg gap-y-3 rounded border p-2">
          <h2 class="text-base text-gray-900">Select an entry</h2>
          <p class="text-sm text-gray-500">
            This replaces {{ "\{\{ ENTRY DESCRIPTION \}\}" }}
          </p>
          <div>
            <label
              for="clientId"
              class="block text-sm font-medium text-gray-700"
            >
              Client
            </label>
            <select
              v-model="clientId"
              name="clientId"
              class="mt-1 block w-full rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lexoo focus:outline-none focus:ring-lexoo sm:text-sm"
            >
              <option disabled :value="null">Select a client</option>
              <option
                v-for="client in clients"
                :key="client.id"
                :value="client.id"
              >
                {{ client.name }}
              </option>
            </select>
          </div>

          <div v-if="client">
            <label
              for="playbookId"
              class="block text-sm font-medium text-gray-700"
            >
              Playbook
            </label>
            <div
              v-if="client && client.playbooks.length == 1"
              class="block text-sm font-medium text-gray-400"
            >
              {{ client.playbooks[0].name }}
            </div>
            <div
              v-else-if="client && client.playbooks.length == 0"
              class="block text-sm font-medium text-gray-400"
            >
              This client doesn't have a playbook yet.
            </div>
            <select
              v-else-if="client"
              v-model="playbookId"
              :disabled="!client || client.playbooks.length < 2"
              class="mt-1 block w-full rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lexoo focus:outline-none focus:ring-lexoo disabled:opacity-50 sm:text-sm"
            >
              <option disabled :value="null">Please select one</option>
              <option
                v-for="playbook in client.playbooks"
                :key="playbook.id"
                :value="playbook.id"
              >
                {{ playbook.name }}
              </option>
            </select>
          </div>
          <BaseSelect
            v-if="client && playbook"
            v-model="entryId"
            label="Entry"
            :options="entryOptions"
            hint="Users won't need to select Client and Playbook to select an entry."
          />
          <SwitchGroup
            v-if="client && playbook && entryId"
            as="div"
            class="mt-6 flex items-center"
          >
            <Switch
              v-model="entryAsHtml"
              :class="[
                entryAsHtml ? 'bg-indigo-600' : 'bg-gray-200',
                'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2'
              ]"
            >
              <span class="sr-only">Entry as HTML</span>
              <span
                aria-hidden="true"
                :class="[
                  entryAsHtml ? 'translate-x-5' : 'translate-x-0',
                  'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                ]"
              />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="text-sm font-medium text-gray-900">
                Entry description as HTML
              </span>
            </SwitchLabel>
          </SwitchGroup>
        </div>
        <BaseInput
          v-model="entryTopic"
          label="Entry topic"
          hint="{{ ENTRY TOPIC }}"
        />

        <BaseInput
          v-model="templateClause"
          label="Own template clause"
          inputType="textarea"
          hint="{{ TEMPLATE CLAUSE }}"
        />

        <BaseInput
          v-model="context"
          label="User submitted context"
          inputType="textarea"
          hint="{{ CONTEXT }}"
        />

        <BaseInput
          v-model="playbookBody"
          label="Playbook"
          inputType="textarea"
          hint="{{ PLAYBOOK }}"
        />

        <BaseInput
          v-model="contract"
          label="Contract"
          inputType="textarea"
          hint="{{ CONTRACT }}"
        />
      </div>
    </div>
    <div class="max-w-lg space-y-10">
      <div class="space-y-5 border-b border-gray-900/10 pb-12">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Output</h2>
        <BaseInput
          v-model="parsedPrompt"
          inputType="textarea"
          label="Prompt to go to Open AI"
          :disabled="true"
        />
        <div class="flex justify-end">
          <BaseButton @action="generate"> {{ generateButtonText }} </BaseButton>
        </div>
      </div>

      <template v-if="generatedOutputWithVariablesAdded.length > 0">
        <div v-if="jsonMode" class="relative border p-2">
          <code class="text-sm font-medium text-gray-900" v-html="jsonOutput" />
          <button
            @click.prevent="copyJsonOutputToClipboard"
            type="button"
            class="absolute bottom-1 right-1 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
          >
            {{ copyButtonText }}
          </button>
        </div>

        <BaseSuperText
          v-else
          v-model="generatedOutputWithVariablesAdded"
          :readonly="true"
          :generatingAI="streaming"
        />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import {
  Switch,
  SwitchGroup,
  SwitchLabel,
  TabGroup,
  TabList,
  Tab
} from "@headlessui/vue";
import { PencilIcon } from "@heroicons/vue/24/outline";
import { useStorage } from "@vueuse/core";
import { useDynamicStorage } from "@/components/shared/DynamicStorage";
import BaseSuperText from "@/components/base/BaseSuperText.vue";
import { useAdmin } from "@/components/shared/Admin";
import { useAdminStore } from "@/store/AdminStore";

import { useAIStreamer } from "@/services/AI/AIStreamer";
import { parsePrompt } from "@/services/AI/AIPromptParser";

import { htmlToPlainText } from "@/helpers/TextHelpers";

const tabs = ["Scratch pad 1", "Scratch pad 2", "Scratch pad 3"];
const scratchTab = useStorage("scratch-tab", tabs[0]);

const llmModel = useDynamicStorage<string>(
  "scratch-llm-model",
  scratchTab,
  "gpt-4"
);
const prompt = useDynamicStorage<string>("scratch-prompt", scratchTab, "");
const superPrompt = useDynamicStorage<string>(
  "scratch-super-prompt",
  scratchTab,
  ""
);
const entryId = useDynamicStorage<number | null>(
  "scratch-entry-id",
  scratchTab,
  null
);
const entryAsHtml = useDynamicStorage<boolean>(
  "scratch-entryAsHtml",
  scratchTab,
  false
);
const jsonMode = useDynamicStorage<boolean>(
  "scratch-jsonMode",
  scratchTab,
  false
);
const contract = useDynamicStorage<string>("scratch-contract", scratchTab, "");
const playbookBody = useDynamicStorage<string>(
  "scratch-playbook-body",
  scratchTab,
  ""
);
const contractType = useDynamicStorage<string>(
  "scratch-contract-type",
  scratchTab,
  ""
);
const role = useDynamicStorage<string>("scratch-role", scratchTab, "");
const language = useDynamicStorage<string>("scratch-language", scratchTab, "");
const entryTopic = useDynamicStorage<string>(
  "scratch-entry-topic",
  scratchTab,
  ""
);
const templateClause = useDynamicStorage<string>(
  "scratch-template-clause",
  scratchTab,
  ""
);
const context = useDynamicStorage("scratch-context", scratchTab, "");
const copyButtonText = ref("Copy to clipboard");
const { client, clientId, clients, playbookId, playbook } = useAdmin();

const adminStore = useAdminStore();

const entries = computed(() => {
  return adminStore.entries;
});

const entryOptions = computed(() => {
  return entries.value.map((entry) => {
    return {
      name: entry.heading,
      value: entry.id
    };
  });
});

const entry = computed(() => {
  if (!entryId.value) return null;
  return entries.value.find(
    (entry) => entry.id.toString() === entryId.value.toString()
  );
});

const entryDescription = computed(() => {
  if (!entry.value) return "";
  if (entryAsHtml.value) {
    return entry.value.entryDescription;
  }
  return htmlToPlainText(entry.value.entryDescription);
});

const jsonOutput = computed(() => {
  return JSON.stringify(generatedOutputWithVariablesAdded.value, null, 4)
    .replace(/\\n/g, "<br>")
    .replace(`\\"`, '"');
});

const playbookAiVariables = computed(() => {
  return {
    contractType,
    role,
    language
  };
});

const entryAiVariables = computed(() => {
  return {
    contract,
    entryDescription,
    entryTopic,
    templateClause,
    playbook: playbookBody,
    context
  };
});

const { startStreaming, generatedOutputWithVariablesAdded, streaming } =
  useAIStreamer(templateClause);

const parsedPrompt = computed(() => {
  if (!playbookAiVariables.value || !entryAiVariables.value)
    return prompt ?? "";
  const variables = { ...playbookAiVariables.value, ...entryAiVariables.value };

  return parsePrompt(prompt, variables);
});

const generateButtonText = computed(() => {
  if (streaming.value) {
    return "Generating...";
  }

  return "Generate";
});

const copyJsonOutputToClipboard = () => {
  navigator.clipboard.writeText(generatedOutputWithVariablesAdded.value);
  copyButtonText.value = "Copied!";
  setTimeout(() => {
    copyButtonText.value = "Copy to clipboard";
  }, 1000);
};

const changeTab = (index: number) => {
  scratchTab.value = tabs[index];
};

const generate = () => {
  startStreaming({
    llmModel: llmModel.value,
    prompt: parsedPrompt.value,
    superPrompt: superPrompt.value,
    messages: [],
    jsonMode: jsonMode.value
  });
};

const loadEntries = () => {
  if (playbook.value) {
    adminStore.loadEntriesFromPlaybook(playbook.value);
  }
};

onMounted(() => {
  loadEntries();
});

watch(
  () => playbook.value,
  () => {
    loadEntries();
  }
);
</script>

<style scoped lang="scss">
.tab {
  @apply whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium focus:outline-none;
}

.tab.selected {
  @apply border-lexoo text-lexoo;
}

.tab:not(.selected) {
  @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700;
}
</style>
@/services/AIStreamer @/services/AI/AIPromptParser @/services/AI/AIStreamer
