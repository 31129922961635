<template>
  <div>
    <TagSelect
      :modelValue="modelValue"
      :selectableTags="selectableTags"
      @update:modelValue="updateModelValue"
    />
    <span class="flex justify-end">
      <BaseButton @click="showModal = true" size="small" buttonStyle="link">
        Add new Tag
      </BaseButton>
    </span>

    <!-- Modal using Headless UI Dialog -->
    <HeadlessDialog
      v-model:open="showModal"
      class="fixed inset-0 z-30 overflow-y-auto"
    >
      <div
        class="flex min-h-screen min-h-screen items-center justify-center px-4 text-center"
      >
        <DialogOverlay
          @click="showModal = false"
          class="fixed inset-0 bg-black opacity-30"
        />

        <!-- Modal content -->
        <div
          class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
        >
          <DialogTitle class="text-lg font-medium leading-6 text-gray-900">
            Create new tag
          </DialogTitle>
          <div class="mt-2">
            <BaseInput
              v-model="newTagName"
              placeholder="Tag name"
              class="w-full"
            />
          </div>
          <div class="mt-5 flex flex-row-reverse sm:mt-4">
            <BaseButton buttonStyle="link" @click="showModal = false">
              Cancel
            </BaseButton>
            <BaseButton buttonStyle="white" @click="addNewTag">
              Create tag
            </BaseButton>
          </div>
        </div>
      </div>
    </HeadlessDialog>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle
} from "@headlessui/vue";

import TagSelect from "./TagSelect.vue";
import { Tag, NewTag } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";
const tagColours = [
  "gray",
  "red",
  "yellow",
  "green",
  "blue",
  "indigo",
  "purple",
  "pink",
  "sky"
];

export default defineComponent({
  components: { HeadlessDialog, DialogOverlay, DialogTitle, TagSelect },

  props: {
    playbookId: {
      type: Number,
      required: true
    },

    modelValue: {
      type: Array as PropType<Tag[]>,
      required: true,
      default: () => []
    },

    selectableTags: {
      type: Array as PropType<Tag[]>,
      required: true
    }
  },

  setup(props, { emit }) {
    const tags = ref<Tag[]>([]);
    const selectedTags = ref<Tag[]>([]);
    const showModal = ref(false);
    const newTagName = ref("");
    const sidebarStore = useSidebarStore();

    const updateModelValue = (newTags: Tag[]) => {
      emit("update:modelValue", newTags);
    };

    const addNewTag = () => {
      if (newTagName.value && newTagName.value.length > 0) {
        const randomColour =
          tagColours[Math.floor(Math.random() * tagColours.length)];
        const newTag: NewTag = {
          colour: randomColour,
          name: newTagName.value,
          playbookId: props.playbookId
        };
        const playbook = sidebarStore.getPlaybookById(props.playbookId);
        if (playbook) {
          sidebarStore.addTag({ playbook, tag: newTag }).then((tags) => {
            const newTag = tags.find((tag) => tag.name === newTagName.value);
            if (newTag) {
              const updatedTags = [...props.modelValue, newTag];
              updateModelValue(updatedTags);
            }

            showModal.value = false;
            newTagName.value = "";
          });
        }
      }
    };

    return {
      tags,
      selectedTags,
      showModal,
      newTagName,
      addNewTag,
      updateModelValue
    };
  }
});
</script>
