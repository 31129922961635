<template>
  <form class="max-w-lg">
    <BaseTitle class="flex">
      <img
        v-if="currentUser"
        class="mr-2 h-6 w-6 flex-none rounded-full bg-gray-50"
        :src="currentUser.image"
        alt=""
      />
      Your profile
    </BaseTitle>

    <div class="my-8 space-y-8">
      <BaseInput label="Name" v-model="name" />

      <BaseInput label="Email address" v-model="email" inputType="email" />

      <div v-if="currentUser" class="mb-4">
        <label class="block text-sm font-medium text-gray-700">
          Role <RoleTag :user="currentUser" />
        </label>

        <p v-if="currentUser.role == 'viewer'" class="text-sm text-gray-500">
          Viewers do not have the ability to create or edit playbooks and
          entries.
        </p>
        <p v-else-if="currentUser.role == 'editor'">
          Editors can update playbooks and invite team members.
        </p>
      </div>
    </div>

    <div class="mt-10 flex items-center gap-x-2">
      <BaseButton @action="save" :disabled="saving">
        {{ saveButtonText }}
      </BaseButton>

      <BaseButton @action="cancel" buttonStyle="white">Cancel</BaseButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import RoleTag from "@/components/admin/RoleTag.vue";

const authorisationStore = useAuthorisationStore();

const currentUser = computed(() => {
  return authorisationStore.user;
});

const name = ref("");
const email = ref("");
const saving = ref(false);
const saved = ref(false);

if (authorisationStore.user) {
  name.value = authorisationStore.user.name;
  email.value = authorisationStore.user.email;
}

const saveButtonText = computed(() => {
  if (saved.value) {
    return "Saved!";
  }

  return "Save";
});

const router = useRouter();
const cancel = () => {
  router.push({ name: "Word view" });
};

const save = () => {
  const user = {
    ...authorisationStore.user,
    name: name.value,
    email: email.value
  };
  saving.value = true;
  authorisationStore.updateUser(user).then(() => {
    saving.value = false;
    saved.value = true;
    setTimeout(() => {
      saved.value = false;
    }, 2000);
  });
};
</script>
