<template>
  <span
    class="inline-flex items-center whitespace-nowrap rounded-full py-0.5 font-medium text-opacity-50"
    :class="[
      `bg-${tag.colour}-100`,
      `text-${tag.colour}-800`,
      { small: size === 'small', large: size === 'large' },
      { 'with-remove': showRemove, 'without-remove': !showRemove }
    ]"
  >
    {{ tag.name }}
    <button
      v-if="showRemove"
      type="button"
      class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full focus:text-white focus:outline-none"
      :class="[
        `hover:bg-${tag.colour}-200`,
        `hover:text-${tag.colour}-500`,
        `bg-${tag.colour}-100`,
        `text-${tag.colour}-400`,
        `focus:bg-${tag.colour}-500`
      ]"
      @click.prevent="$emit('remove', tag)"
    >
      <XMarkIcon class="h-3 w-3" />
    </button>
  </span>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Tag } from "@/types";

export default defineComponent({
  components: { XMarkIcon },

  props: {
    showRemove: {
      type: Boolean,
      default: false
    },

    size: {
      type: String as PropType<"small" | "large">,
      default: "small"
    },

    tag: {
      type: Object as PropType<Tag>,
      required: true
    }
  }
});
</script>

<style lang="scss" scoped>
.with-remove {
  &.small {
    @apply pl-2 pr-0.5 text-xs;
  }
  &.large {
    @apply pl-2.5 pr-1 text-sm;
  }
}

.without-remove {
  &.small {
    @apply px-2.5 text-xs;
  }
  &.large {
    @apply px-3 text-sm;
  }
}
</style>
