import { format, formatRelative } from "date-fns";

const dateFormat = ((dateString: string | Date): string => {
  const date = new Date(dateString);
  return format(date, " do MMM yyyy hh:mmaaa");
}) as (dateString: string) => string;

const dateTimeFormat = (dateString: string | Date): string => {
  const date = new Date(dateString);
  return format(date, "d MMM yyyy H:mm bbb");
};

const relativeTimeFormat = (dateString: string | Date): string => {
  const date = new Date(dateString);
  return formatRelative(date, new Date());
};

export { dateFormat, dateTimeFormat, relativeTimeFormat };
