<template>
  <div
    ref="editableText"
    class="editable-text not-prose relative"
    :class="{
      'toolbar-visible': !readonly,
      'generating-ai': generatingAI
    }"
    v-bind="$attrs"
  >
    <div
      class="relative sticky left-0 top-0 z-10 rounded-t bg-gray-100"
      v-if="editor"
      v-show="!urlPickerShown && !readonly && !generatingAI"
    >
      <button
        title="Bold"
        @click="editor.chain().focus().toggleBold().run()"
        class="toolbarButton"
        :class="{ 'is-active': editor.isActive('bold') }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
          />
        </svg>
      </button>
      <button
        title="Italics"
        @click="editor.chain().focus().toggleItalic().run()"
        class="toolbarButton add-in:inline hidden"
        :class="{ 'is-active': editor.isActive('italic') }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
        </svg>
      </button>
      <button
        title="Bullet list"
        @click="editor.chain().focus().toggleBulletList().run()"
        class="toolbarButton"
        :class="{ 'is-active': editor.isActive('bulletList') }"
        :disabled="notSuitableForTemplateWordingOrComment"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z"
          />
        </svg>
      </button>
      <button
        title="Highlight"
        @click="editor.chain().focus().toggleHighlight().run()"
        class="toolbarButton add-in:inline hidden"
        :class="{ 'is-active': editor.isActive('highlight') }"
        :disabled="notSuitableForTemplateWordingOrComment"
      >
        <svg
          class="h-4 w-4"
          xmlns="http://www.w3.org/2000/svg"
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="m20.707 5.826l-3.535-3.533a.999.999 0 0 0-1.408-.006L7.096 10.82a1.01 1.01 0 0 0-.273.488l-1.024 4.437L4 18h2.828l1.142-1.129l3.588-.828c.18-.042.345-.133.477-.262l8.667-8.535a1 1 0 0 0 .005-1.42zm-9.369 7.833l-2.121-2.12l7.243-7.131l2.12 2.12l-7.242 7.131zM4 20h16v2H4z"
          />
        </svg>
      </button>
      <button
        title="Tasklist"
        @click="editor.chain().focus().toggleTaskList().run()"
        class="toolbarButton"
        :class="{ 'is-active': editor.isActive('taskList') }"
        :disabled="notSuitableForTemplateWordingOrComment"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M11 4h10v2H11V4zm0 4h6v2h-6V8zm0 6h10v2H11v-2zm0 4h6v2h-6v-2zM3 4h6v6H3V4zm2 2v2h2V6H5zm-2 8h6v6H3v-6zm2 2v2h2v-2H5z"
          />
        </svg>
      </button>
      <button
        title="Strikethrough"
        @click="editor.chain().focus().toggleStrike().run()"
        class="toolbarButton add-in:inline hidden"
        :class="{ 'is-active': editor.isActive('strike') }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M17.154 14c.23.516.346 1.09.346 1.72 0 1.342-.524 2.392-1.571 3.147C14.88 19.622 13.433 20 11.586 20c-1.64 0-3.263-.381-4.87-1.144V16.6c1.52.877 3.075 1.316 4.666 1.316 2.551 0 3.83-.732 3.839-2.197a2.21 2.21 0 0 0-.648-1.603l-.12-.117H3v-2h18v2h-3.846zm-4.078-3H7.629a4.086 4.086 0 0 1-.481-.522C6.716 9.92 6.5 9.246 6.5 8.452c0-1.236.466-2.287 1.397-3.153C8.83 4.433 10.271 4 12.222 4c1.471 0 2.879.328 4.222.984v2.152c-1.2-.687-2.515-1.03-3.946-1.03-2.48 0-3.719.782-3.719 2.346 0 .42.218.786.654 1.099.436.313.974.562 1.613.75.62.18 1.297.414 2.03.699z"
          />
        </svg>
      </button>
      <button
        title="Toggle list"
        @click="toggleDetails"
        :class="{ 'is-active': editor.isActive('detailsSummary') }"
        class="toolbarButton"
        :disabled="notSuitableForTemplateWordingOrComment"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path d="M16 12L10 18V6L16 12Z"></path>
        </svg>
      </button>

      <button
        title="Template wording"
        @click="editor.chain().focus().toggleTemplateWording().run()"
        class="toolbarButton"
        :class="{ 'is-active': editor.isActive('templateWording') }"
        :disabled="notSuitableForTemplateWording"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path
            d="M20 22H4C3.44772 22 3 21.5523 3 21V3C3 2.44772 3.44772 2 4 2H20C20.5523 2 21 2.44772 21 3V21C21 21.5523 20.5523 22 20 22ZM19 20V4H5V20H19ZM8 7H16V9H8V7ZM8 11H16V13H8V11ZM8 15H16V17H8V15Z"
          ></path>
        </svg>
      </button>

      <button
        title="Template comment"
        @click="editor.chain().focus().toggleTemplateComment().run()"
        class="toolbarButton"
        :class="{ 'is-active': editor.isActive('templateComment') }"
        :disabled="notSuitableForTemplateComment"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path
            d="M5.76282 17H20V5H4V18.3851L5.76282 17ZM6.45455 19L2 22.5V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V18C22 18.5523 21.5523 19 21 19H6.45455Z"
          ></path>
        </svg>
      </button>

      <button
        title="Variable"
        @click="editor.chain().focus().setVariable('').run()"
        class="toolbarButton"
        :class="{ 'is-active': editor.isActive('variable') }"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path
            d="M4 18V14.3C4 13.4716 3.32843 12.8 2.5 12.8H2V11.2H2.5C3.32843 11.2 4 10.5284 4 9.7V6C4 4.34315 5.34315 3 7 3H8V5H7C6.44772 5 6 5.44772 6 6V10.1C6 10.9858 5.42408 11.7372 4.62623 12C5.42408 12.2628 6 13.0142 6 13.9V18C6 18.5523 6.44772 19 7 19H8V21H7C5.34315 21 4 19.6569 4 18ZM20 14.3V18C20 19.6569 18.6569 21 17 21H16V19H17C17.5523 19 18 18.5523 18 18V13.9C18 13.0142 18.5759 12.2628 19.3738 12C18.5759 11.7372 18 10.9858 18 10.1V6C18 5.44772 17.5523 5 17 5H16V3H17C18.6569 3 20 4.34315 20 6V9.7C20 10.5284 20.6716 11.2 21.5 11.2H22V12.8H21.5C20.6716 12.8 20 13.4716 20 14.3Z"
          ></path>
        </svg>
      </button>

      <button
        title="Remove link"
        v-if="editor.isActive('link')"
        @click="removeUrl"
        class="is-active toolbarButton add-in:inline hidden"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M17.657 14.828l-1.414-1.414L17.657 12A4 4 0 1 0 12 6.343l-1.414 1.414-1.414-1.414 1.414-1.414a6 6 0 0 1 8.485 8.485l-1.414 1.414zm-2.829 2.829l-1.414 1.414a6 6 0 1 1-8.485-8.485l1.414-1.414 1.414 1.414L6.343 12A4 4 0 1 0 12 17.657l1.414-1.414 1.414 1.414zm0-9.9l1.415 1.415-7.071 7.07-1.415-1.414 7.071-7.07zM5.775 2.293l1.932-.518L8.742 5.64l-1.931.518-1.036-3.864zm9.483 16.068l1.931-.518 1.036 3.864-1.932.518-1.035-3.864zM2.293 5.775l3.864 1.036-.518 1.931-3.864-1.035.518-1.932zm16.068 9.483l3.864 1.035-.518 1.932-3.864-1.036.518-1.931z"
          />
        </svg>
      </button>
      <button
        v-else
        title="Add link"
        @click="showUrlPicker"
        class="toolbarButton hideOnDefault"
        :disabled="notSuitableForTemplateWordingOrComment"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          class="h-4 w-4"
          fill="currentColor"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path
            d="M13.06 8.11l1.415 1.415a7 7 0 0 1 0 9.9l-.354.353a7 7 0 0 1-9.9-9.9l1.415 1.415a5 5 0 1 0 7.071 7.071l.354-.354a5 5 0 0 0 0-7.07l-1.415-1.415 1.415-1.414zm6.718 6.011l-1.414-1.414a5 5 0 1 0-7.071-7.071l-.354.354a5 5 0 0 0 0 7.07l1.415 1.415-1.415 1.414-1.414-1.414a7 7 0 0 1 0-9.9l.354-.353a7 7 0 0 1 9.9 9.9z"
          />
        </svg>
      </button>
    </div>
    <div
      v-if="
        editor &&
        !readonly &&
        showAIButton &&
        !generatingAI &&
        isEmptyParagraphs(editor)
      "
      @click.prevent="focus()"
      :editor="editor"
      class="ai-placeholder"
    >
      <div>
        <span @click.prevent="focus()"> Start typing, or </span>
        <a @click.prevent="emits('generateAI')" href="#">
          click here to generate with AI
          <span class="inline-block align-top text-yellow-500">
            <SparklesIcon class="h-4 w-4" />
          </span>
        </a>
      </div>
    </div>
    <UrlPicker
      :show="urlPickerShown"
      v-model="url"
      @selected="setLink"
      @cancel="urlPickerShown = false"
    />
    <slot></slot>
    <div
      v-if="!readonly"
      class="relative z-10 -mb-[1em] h-4 w-full cursor-text"
      @click.prevent="addParagraphAtTop"
    ></div>

    <editor-content :editor="editor" @click="handleClick" />
  </div>
  <div
    v-if="!readonly"
    class="relative -mt-2 h-2 w-full cursor-text"
    @click.prevent="addParagraph"
  ></div>
</template>

<script setup lang="ts">
// Icons come from https://remixicon.com/
import {
  computed,
  nextTick,
  onBeforeUnmount,
  PropType,
  ref,
  useAttrs,
  watch
} from "vue";
import { TextSelection } from "prosemirror-state";

import { EditorContent, useEditor } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Details from "@/components/base/TipTap/TipTapDetails";
import DetailsSummary from "@/components/base/TipTap/TipTapDetailsSummary";
import DetailsContent from "@/components/base/TipTap/TipTapDetailsContent";
import UniqueID from "@tiptap-pro/extension-unique-id";

import { SparklesIcon } from "@heroicons/vue/24/solid";

import { displayMarkupWithMarkedText } from "@/helpers/TextHelpers";
import Deletion from "@/components/base/TipTap/TipTapDeletion";
import Insertion from "@/components/base/TipTap/TipTapInsertion";
import TemplateCommentExtension from "@/components/base/TipTap/TemplateCommentExtension";
import ExitBlockExtension from "@/components/base/TipTap/TipTapExitBlockExtension";
import RefineOptions from "@/components/base/TipTap/RefineOptions";
import RefineOption from "@/components/base/TipTap/RefineOption";
import ThreeLinePlaceholder from "@/components/base/TipTap/ThreeLinePlaceholder";
import TemplateWordingExtension from "@/components/base/TipTap/TemplateWordingExtension";
import UrlPicker from "@/components/UrlPicker.vue";

import TipTapPasteParser from "@/services/TipTapPasteParser";

import Strike from "@tiptap/extension-strike";

import Variable from "@/components/base/TipTap/VariableExtension";
import suggestion from "@/components/base/TipTap/VariableExtension/suggestion";

import { getVariableIdentifiersFromString } from "@/helpers/VariableHelpers";

const StrikeWithoutDel = Strike.extend({
  parseHTML() {
    return [
      {
        tag: "s"
      },
      {
        tag: "strike"
      }
    ];
  }
});

const emits = defineEmits([
  "taskItemChecked",
  "update:modelValue",
  "generateAI",
  "refineOptionClick"
]);

const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },

  readonly: Boolean,

  checkedTaskIds: {
    type: Array,
    default: () => []
  },

  filterText: {
    type: String,
    required: false
  },

  showAIButton: {
    type: Boolean,
    default: false
  },

  generatingAI: {
    type: Boolean,
    default: false
  },

  variableIdentifiers: {
    type: Array as PropType<string[]>,
    default: () => []
  }
});

const editableText = ref(null);

const attrs = useAttrs();
const classes = computed(() => {
  const result = (attrs.class ?? "") + " prose prose-sm";

  return result;
});

const checkTasks = (html: string): string => {
  if (!props.checkedTaskIds || props.checkedTaskIds.length === 0) {
    return html;
  }
  // Create a new DOMParser and parse the HTML
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, "text/html");

  // Find all list items
  const items = doc.querySelectorAll("li[data-id]");

  // Iterate over all list items
  items.forEach((item: Element) => {
    // Check if the data-id attribute is in the taskIds array
    const taskId = item.getAttribute("data-id");
    if (props.checkedTaskIds.includes(taskId || "")) {
      // Set the data-checked attribute and checkbox state
      item.setAttribute("data-checked", "true");
      const checkbox = item.querySelector<HTMLInputElement>(
        'input[type="checkbox"]'
      );
      if (checkbox) {
        checkbox.checked = true;
      }
    }
  });

  // Return the updated HTML
  return doc.body.innerHTML;
};

const isEmptyParagraphs = (editor) => {
  const { doc } = editor.state;
  let isEmptyParagraphs = true;

  doc.content.forEach((node) => {
    if (!(node.type.name === "paragraph" && node.childCount === 0)) {
      isEmptyParagraphs = false;
    }
  });

  return doc.childCount <= 3 && isEmptyParagraphs;
};

const editor = useEditor({
  content: checkTasks(
    displayMarkupWithMarkedText(props.modelValue, props.filterText) ?? ""
  ),
  editorProps: {
    attributes: {
      class: classes.value,
      spellcheck: "true"
    },

    transformPasted(slice) {
      return TipTapPasteParser(slice);
    }
  },
  editable: !props.readonly,
  extensions: [
    Deletion,
    Details.configure({
      persist: true,
      HTMLAttributes: {
        class: "details"
      }
    }),
    DetailsSummary,
    DetailsContent,
    ExitBlockExtension,
    Insertion,
    TemplateCommentExtension,
    StarterKit.configure({
      strike: false
    }),
    Highlight,
    Link,
    RefineOption.configure({
      onRefineOptionClick: (refinement: string) => {
        emits("refineOptionClick", refinement);
        return true;
      }
    }),
    RefineOptions,
    StrikeWithoutDel,
    TaskList,
    TaskItem.configure({
      onReadOnlyChecked: (
        node: { attrs: { id: string } },
        checked: boolean
      ) => {
        emits("taskItemChecked", node.attrs.id, checked);
        return true;
      }
    }),
    TemplateWordingExtension,
    ThreeLinePlaceholder.configure({
      placeholder: () => {
        if (props.generatingAI) {
          return "Generating AI...";
        } else if (!props.showAIButton) {
          return "Enter some text for this entry...";
        }
      }
    }),
    UniqueID.configure({
      types: ["taskItem"]
    }),
    Variable.configure({
      suggestion: {
        items: (response) => {
          if (!editor.value) {
            return [];
          }
          const extraVariables = getVariableIdentifiersFromString(
            editor.value.getHTML()
          );
          const uniqueVariables = new Set([
            ...props.variableIdentifiers,
            ...extraVariables
          ]);
          const uniqVariableIdentifiers = Array.from(uniqueVariables);
          const sortedVariableIdentifiers = uniqVariableIdentifiers.sort(
            (a, b) => {
              if (a.toLowerCase() < b.toLowerCase()) {
                return -1;
              }
              if (a.toLowerCase() > b.toLowerCase()) {
                return 1;
              }
              return 0;
            }
          );
          return sortedVariableIdentifiers.filter((variable: string) =>
            variable.toLowerCase().startsWith(response.query.toLowerCase())
          );
        },
        ...suggestion
      }
    })
  ],

  onFocus: ({ editor }) => {
    if (editor) {
      if (isEmptyParagraphs(editor)) {
        const { tr } = editor.state;
        editor.view.dispatch(tr.setSelection(TextSelection.create(tr.doc, 0)));
      }
    }
  },

  onUpdate: ({ editor }) => {
    if (editor) {
      emits("update:modelValue", editor.getHTML());
    }
  }
});

const readonlyMarkup = computed((): string => {
  return checkTasks(
    displayMarkupWithMarkedText(props.modelValue, props.filterText) ?? ""
  );
});

const notSuitableForTemplateComment = computed(() => {
  if (!editor.value) {
    return false;
  }
  return editor.value.isActive("templateWording");
});

const notSuitableForTemplateWording = computed(() => {
  if (!editor.value) {
    return false;
  }
  return editor.value.isActive("templateComment");
});

const notSuitableForTemplateWordingOrComment = computed(() => {
  return (
    notSuitableForTemplateComment.value || notSuitableForTemplateWording.value
  );
});

const urlPickerShown = ref(false);
const url = ref("");

watch(
  () => props.filterText,
  () => {
    if (editor.value && props.readonly) {
      editor.value.commands.setContent(readonlyMarkup.value);
      nextTick(() => highlightSummaryTags());
    }
  }
);

watch(
  () => props.modelValue,
  (value) => {
    if (editor.value) {
      const isSame = editor.value.getHTML() === value;
      if (isSame) {
        return;
      }
      if (props.generatingAI) {
        editor.value
          .chain()
          .setContent(value, false)
          .updateAttributes("details", { open: true })
          .run();
      } else {
        editor.value.commands.setContent(value, false);
      }
    }
  }
);

watch(
  () => props.readonly,
  (value) => {
    if (editor.value) {
      if (value) {
        editor.value.commands.setContent(readonlyMarkup.value);
      } else {
        editor.value.commands.setContent(props.modelValue);
      }
      editor.value.setEditable(!value);
    }
  }
);

const addParagraph = () => {
  if (editor.value) {
    const { doc } = editor.value.state;
    const docLength = doc.content.size;
    editor.value.chain().insertContentAt(docLength, "<p></p>").focus().run();
  }
};

const addParagraphAtTop = () => {
  if (editor.value) {
    if (isEmptyParagraphs(editor.value)) {
      editor.value.commands.setTextSelection(0);
    } else {
      editor.value.chain().insertContentAt(0, "<p></p>").focus().run();
    }
  }
};

const focus = () => {
  if (editor.value) {
    editor.value.commands.focus("start");
  }
};

// Focus is used externally
defineExpose({
  focus
});

const handleClick = (event: MouseEvent) => {
  if (!props.readonly) {
    if (editor.value && isEmptyParagraphs(editor.value)) {
      editor.value.commands.setTextSelection(0);
    }

    return;
  }
  const target = event.target as HTMLElement;
  let parentElement = target.parentElement;
  if (
    target.tagName.toLowerCase() === "summary" ||
    (parentElement && parentElement.tagName.toLowerCase() === "summary")
  ) {
    while (parentElement) {
      if (parentElement.getAttribute("data-type") === "details") {
        const buttonElement = parentElement.querySelector("button");
        if (buttonElement) {
          buttonElement.click();
        }
        break;
      }
      parentElement = parentElement.parentElement;
    }
  }
};

const removeUrl = () => {
  if (editor.value) {
    editor.value.chain().focus().unsetLink().run();
  }
};

const setLink = (url: string) => {
  if (editor.value) {
    urlPickerShown.value = false;
    editor.value.chain().focus().setLink({ href: url }).run();
  }
};

const showUrlPicker = () => {
  if (editor.value) {
    url.value = editor.value.getAttributes("link").href;
    urlPickerShown.value = true;
  }
};

const toggleDetails = () => {
  if (editor.value) {
    if (editor.value.isActive("detailsSummary")) {
      editor.value.chain().focus().unsetDetails().run();
    } else {
      editor.value.chain().focus().setDetails().run();
    }
  }
};

onBeforeUnmount(() => {
  if (editor.value) {
    editor.value.destroy();
  }
});

const clearHighlightedSummaries = () => {
  const container = editableText.value;
  if (container) {
    // Get all summary elements with the highlight-summary class
    const highlighted = container.querySelectorAll(".highlight-summary");

    highlighted.forEach((summary) => {
      summary.classList.remove("highlight-summary");
    });
  }
};

const highlightSummaryTags = async () => {
  // Clear existing highlighted summary tags

  clearHighlightedSummaries();

  const container = editableText.value;

  // Get all mark elements within this component
  if (container) {
    const marks = container.querySelectorAll("mark");

    marks.forEach((mark) => {
      let parent = mark.parentElement;

      // Loop through ancestors to find summary elements
      while (parent !== null) {
        if (
          parent.tagName.toLowerCase() === "details" ||
          parent.getAttribute("data-type") === "details"
        ) {
          const summary = parent.querySelector("summary");
          if (summary) {
            summary.classList.add("highlight-summary");
          }
        }

        parent = parent.parentElement;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.editable-text :deep(.ProseMirror) {
  @apply min-h-[60px];
}

.toolbarButton {
  @apply m-1 inline-flex cursor-pointer items-center rounded px-2 py-1 text-sm font-medium text-gray-500 hover:bg-gray-200 disabled:pointer-events-none disabled:opacity-50;
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.editable-text.toolbar-visible {
  @apply rounded border border-gray-300 shadow-sm;
}
.editable-text.generating-ai {
  @apply p-1;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;
  padding: 2px;
  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size:
      50% 50%,
      50% 50%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }

  :deep(.ProseMirror) {
    @apply m-1 bg-transparent;
  }
}

:deep(.empty-node::before) {
  content: attr(data-placeholder);
  color: #aaa;
  pointer-events: none;
  position: absolute;
}

:deep(.ProseMirror) {
  @apply block w-full cursor-text bg-white px-4 py-2 focus:outline-none;
}

:deep(.prose ul) {
  padding-left: 1em;
  & > li {
    list-style-type: disc;
    list-style-position: outside;

    &::marker {
      color: var(--tw-prose-bullets);
    }
  }
}

// Style the links
:deep(.prose a) {
  color: var(--tw-prose-links);
  @apply underline hover:opacity-70;
}

// Only show the pointer on links if we're not in edit mode
.editable-text:not(.toolbar-visible) :deep(.prose a) {
  @apply cursor-pointer;
}

// Style insertions and deletions
:deep(.prose ins) {
  @apply bg-green-200 no-underline;
}

:deep(.prose del) {
  @apply bg-red-200;
}

button.is-active {
  @apply bg-gray-200;
}

:deep(ul[data-type="taskList"]) {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: baseline;

    > label {
      flex: 0 0 auto;
    }

    > div {
      flex: 1 1 auto;
    }
  }
}

:deep(.prose-sm ul[data-type="taskList"] > li) {
  padding: 0;
  &:before {
    content: inherit;
  }
}

:deep(.prose-sm ul[data-type="taskList"] > li > *:first-child) {
  margin: 0;
  margin-right: 0.5rem;
}

// I don't even know why we have ols in here - must've been pasted in
:deep(.prose-sm ol) {
  list-style-type: decimal;
  padding-left: 1em;
  > li::marker {
    font-weight: 400;
    color: black;
  }
}

:deep(.prose-sm > ul[data-type="taskList"] > li > *:last-child) {
  margin-bottom: 0;
}

:deep(.prose-sm a) {
  @apply break-all;
}

:deep(.prose-sm .details) {
  display: flex;
  @apply mb-4 ml-[-1.5rem];

  summary {
    @apply block bg-indigo-300 p-2 pl-6 font-medium text-black hover:bg-indigo-400 focus-visible:ring-lexoo;
  }

  [data-type="detailsContent"] {
    @apply relative rounded-b-lg border border-indigo-300 p-2;
    overflow-wrap: anywhere;
  }

  // This is a nested details and shouldn't have the negative left margin and a different colour
  .details {
    @apply -ml-4;
    summary {
      @apply bg-indigo-200 text-indigo-900 hover:bg-indigo-300 focus-visible:ring-lexoo;
    }

    [data-type="detailsContent"] {
      @apply border-indigo-200;
    }

    .details {
      summary {
        @apply bg-indigo-50 text-indigo-900 hover:bg-indigo-200 focus-visible:ring-lexoo;
      }

      [data-type="detailsContent"] {
        @apply border-indigo-50;
      }
    }
  }

  &:not(.is-open) summary {
    @apply rounded-lg;
  }

  &:not(.is-open) [data-type="detailsContent"] {
    @apply hidden;
  }

  &.is-open summary {
    @apply rounded-t-lg;
  }

  > button {
    display: flex;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    position: relative;
    left: 22px;
    top: 12px;

    &::before {
      content: "\25B6";
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.75em;
      width: 1.5em;
      height: 1.5em;
    }
  }

  &.is-open > button::before {
    content: "\25BC";
  }

  > div {
    flex: 1 1 auto;
  }
}

.editable-text:not(.toolbar-visible) :deep(.prose-sm .details summary) {
  @apply cursor-pointer select-none;

  &.highlight-summary {
    &:after {
      content: "*";
      margin-left: 0.5em;
      @apply text-yellow-300;
    }
  }
}

// Don't show the delete button on the template wording / template comment if not in editing
.editable-text:not(.toolbar-visible) :deep(.prose-sm .delete-button) {
  @apply hidden;
}

.editable-text .ai-placeholder {
  @apply relative left-7 top-7 z-10 -ml-2 -mt-4 w-full pr-7 text-sm text-gray-400;
  a {
    @apply cursor-pointer text-gray-500 no-underline hover:text-black hover:underline hover:opacity-100;
  }
}
</style>
