<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
              Password update required
            </h2>
            <p class="mt-2 text-center text-sm text-gray-600">
              Welcome to LexPlay!
            </p>
          </div>
          <div class="mt-8 space-y-6 text-sm">
            <div>
              <p class="mt-2 text-gray-700">Please create a new password.</p>
            </div>
          </div>

          <form class="mt-8 space-y-6" @submit.prevent="changePassword">
            <div>
              <label for="password" class="sr-only"> New password </label>
              <input
                v-model="password"
                autofocus
                autocomplete="new-password"
                name="password"
                type="password"
                required="true"
                placeholder="New password"
                class="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
              />
              <p
                v-if="error"
                class="mt-2 text-left text-xs font-medium text-red-600"
              >
                {{ error }}
              </p>
            </div>

            <div>
              <label for="passwordConfirmation" class="sr-only">
                Confirm password
              </label>
              <input
                v-model="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                autocomplete="new-password"
                required="true"
                placeholder="Confirm password"
                class="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
              />
            </div>

            <div>
              <button
                type="submit"
                class="group relative flex w-full justify-center rounded border border-transparent bg-lexoo py-2 px-4 text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
                :disabled="
                  passwordConfirmation.length == 0 ||
                  password.length == 0 ||
                  loading
                "
              >
                {{ loading ? "Updating..." : "Change password" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <BaseAlertModal
    v-if="showPasswordChanged"
    title="Password changed successfully"
    @close="goToHome"
  >
    <p class="text-sm text-gray-500">
      Your password has been successfully changed.
    </p>
  </BaseAlertModal>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import BaseAlertModal from "@/components/base/BaseAlertModal.vue";
import { useRouter } from "vue-router";

const authorisationStore = useAuthorisationStore();

const error = ref<string | null>(null);
const password = ref("");
const passwordConfirmation = ref("");
const loading = ref(false);
const showPasswordChanged = ref(false);

watch(
  () => password.value,
  () => {
    error.value = null;
  }
);

const changePassword = () => {
  if (password.value !== passwordConfirmation.value) {
    error.value = "Passwords do not match. Please try again.";
    return;
  } else {
    loading.value = true;
    authorisationStore.changePassword(password.value).then(() => {
      loading.value = false;
      showPasswordChanged.value = true;
    });
  }
};

const router = useRouter();
const goToHome = () => {
  router.push({ name: "Word view" });
};
</script>
