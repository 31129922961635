<template>
  <div v-if="escalation" class="my-10">
    <template v-if="escalation.responseAction">
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <div
          class="-50 border-l-4 p-4"
          :class="`border-${responseColour}-400 bg-${responseColour}-50`"
        >
          <div class="flex">
            <div class="flex-shrink-0">
              <CheckIcon
                v-if="escalation.responseAction === 'Approved'"
                class="h-5 w-5 text-green-400"
                aria-hidden="true"
              />
              <XMarkIcon v-else class="h-5 w-5" :class="`text-red-400`" />
            </div>
            <p
              class="text-md ml-3 font-medium"
              :class="`text-${responseColour}-800`"
            >
              Request {{ escalation.responseAction.toLowerCase() }}
            </p>
          </div>
          <div
            class="ml-6 flex flex-col sm:ml-8 sm:mt-2 sm:flex-row sm:flex-wrap"
          >
            <span
              class="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-4"
            >
              <ClockIcon
                class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {{ respondedAt }}
            </span>
            <span
              class="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-4"
            >
              <UserIcon
                class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {{ approverText }}
            </span>
          </div>
          <div v-if="escalation.responseText" class="ml-8 mt-2">
            <p
              class="text-sm"
              :class="`text-${responseColour}-800`"
              v-html="escalation.responseText"
            />
          </div>
        </div>
        <div class="mt-4 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <p class="text-gray-500" v-html="formattedText(escalation.text)" />
          </div>
        </div>
      </div>
    </template>

    <template v-else>
      <div class="sm:mx-auto sm:w-full sm:max-w-md">
        <h2
          class="text-xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight"
        >
          Approval requested from {{ escalation.user.name }}
        </h2>
        <div>
          <span
            class="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-4"
          >
            <ClockIcon
              class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            {{ dateFormat(escalation.sentAt) }}
          </span>
        </div>

        <div class="mt-4 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-5 sm:p-6">
            <p class="text-gray-500" v-html="formattedText(escalation.text)" />
          </div>
        </div>
        <h2
          class="mt-8 text-xl font-bold leading-7 text-gray-900 sm:truncate sm:tracking-tight"
        >
          Your response
        </h2>
        <div class="mt-4 space-y-8">
          <BaseInput label="Your name" v-model="escalation.approverName" />
          <BaseInput label="Your email" v-model="escalation.approverEmail" />
          <BaseInput
            label="Your job title"
            v-model="escalation.approverJobTitle"
          />

          <BaseInput
            label="Comments (optional)"
            inputType="textarea"
            v-model="escalation.responseText"
          />

          <span class="isolate inline-flex rounded shadow-sm">
            <button
              @click="approve"
              type="button"
              class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
            >
              <CheckIcon class="-ml-1 mr-2 h-5 w-5" />Approve
            </button>
            <button
              @click="decline"
              type="button"
              class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
            >
              <XMarkIcon class="-ml-1 mr-2 h-5 w-5" />Decline
            </button>
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, Ref } from "vue";
import { CheckIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { ClockIcon, UserIcon } from "@heroicons/vue/24/solid";
import { formattedText } from "@/helpers/TextHelpers";
import { dateFormat } from "@/helpers/DateHelpers";

import { Escalation } from "@/types";
import ApiService from "@/services/ApiService";
const props = defineProps({
  identifier: {
    type: String,
    required: true
  }
});

const escalation: Ref<Escalation | null> = ref(null);

const findEscalation = () => {
  ApiService.findEscalationFromIdentifier(props.identifier).then(({ data }) => {
    escalation.value = data.escalation;
  });
};

onMounted(findEscalation);

const approve = () => {
  if (escalation.value) {
    escalation.value.responseAction = "Approved";
    escalation.value.respondedAt = new Date();
    ApiService.updateEscalation(escalation.value);
  }
};

const decline = () => {
  if (escalation.value) {
    escalation.value.responseAction = "Declined";
    escalation.value.respondedAt = new Date();
    ApiService.updateEscalation(escalation.value);
  }
};

const respondedAt = computed(() => {
  if (escalation.value && escalation.value.respondedAt) {
    return dateFormat(escalation.value.respondedAt);
  }
  return "";
});

const approverText = computed(() => {
  if (escalation.value && escalation.value.approverName) {
    return escalation.value.approverName;
  }
  return "";
});

const responseColour = computed(() => {
  if (escalation.value && escalation.value.responseAction) {
    if (escalation.value.responseAction === "Approved") {
      return "green";
    } else if (escalation.value.responseAction === "Declined") {
      return "red";
    }
  }
  return "";
});
</script>
