<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block min-h-full w-full max-w-2xl transform overflow-hidden rounded-lg bg-white py-2 px-0 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mt-3 ml-4 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Escalation for {{ entry.heading }}
                </DialogTitle>
                <div v-if="escalationTemplate" class="pt-2 pr-4">
                  <div v-if="approvers.length > 1" class="mb-6">
                    <BaseSelect
                      v-model="approver"
                      label="Approver *"
                      :options="approverOptions"
                      placeholder="Select an approver"
                    />
                  </div>
                  <template v-if="approver">
                    <div>
                      <BaseInput
                        v-model="approver.name"
                        label="Approver name *"
                        placeholder="The name of the person who needs to approve"
                      />
                    </div>
                    <div>
                      <BaseInput
                        v-model="approver.email"
                        inputType="email"
                        label="Approver email *"
                        placeholder="Email address of the person who needs to approve"
                      />
                    </div>
                    <div>
                      <BaseInput
                        v-model="approver.jobTitle"
                        label="Approver job title *"
                        placeholder="Job title of the person who needs to approve"
                      />
                    </div>
                  </template>

                  <div>
                    <BaseInput
                      v-model="matterIdentifier"
                      label="Matter identifier *"
                      placeholder="Your internal identifier for the matter"
                    />
                  </div>
                  <div>
                    <BaseInput
                      v-model="escalationTemplate.text"
                      inputType="textarea"
                      label="Escalation text"
                      rows="4"
                    />
                  </div>
                </div>
              </div>
            </div>

            <LoadingPanel v-if="loading" />

            <div
              v-if="escalationTemplate && !loading"
              class="flex justify-end space-x-3 bg-gray-50 px-4 py-3 sm:px-6"
            >
              <BaseButton @click="requestApproval" :disabled="!validForm">
                {{
                  validForm
                    ? `Request approval from ${approver && approver.name}`
                    : "Request approval"
                }}
              </BaseButton>
              <BaseButton buttonStyle="white" @click="$emit('close')">
                Cancel
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
  <BaseAlertModal
    v-if="approvalSent"
    title="Approval request sent"
    @close="approvalSent = false"
  >
    <p class="text-sm text-gray-500">
      Approval requested from {{ approver && approver.name }}
    </p>
  </BaseAlertModal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/solid";

import { useSidebarStore } from "@/store/SidebarStore";
import BaseAlertModal from "@/components/base/BaseAlertModal.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import ApiService from "@/services/ApiService";
import {
  Approver,
  Entry,
  Escalation,
  NewEscalation,
  PlaybookInstance
} from "@/types";

export default defineComponent({
  emits: ["close"],

  components: {
    BaseAlertModal,
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    LoadingPanel,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  props: {
    approvers: {
      required: true,
      type: Array as PropType<Approver[]>
    },

    entry: {
      required: true,
      type: Object as PropType<Entry>
    },

    playbookInstance: {
      required: true,
      type: Object as PropType<PlaybookInstance>
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  data() {
    const escalationTemplate = this.entry.escalationTemplate;
    let approver = null;
    if (escalationTemplate) {
      approver = {
        name: escalationTemplate.approverName,
        email: escalationTemplate.approverEmail,
        jobTitle: escalationTemplate.approverJobTitle
      };
    }

    return {
      approvalSent: false,
      approver,
      escalationTemplate,
      loading: false
    };
  },

  computed: {
    approverOptions() {
      return this.approvers.map((approver) => ({
        name: `${approver.name} (${approver.jobTitle})`,
        value: approver
      }));
    },

    matterIdentifier: {
      get(): string | null {
        const sidebarStore = useSidebarStore();
        return sidebarStore.matterIdentifier;
      },

      set(matterIdentifier: string | null) {
        const sidebarStore = useSidebarStore();
        sidebarStore.matterIdentifier = matterIdentifier;
      }
    },

    validForm(): boolean {
      if (!this.matterIdentifier || this.matterIdentifier.length === 0) {
        return false;
      }
      if (!this.approver) {
        return false;
      }
      if (!this.escalationTemplate) {
        return false;
      }
      if (
        !this.escalationTemplate.text ||
        this.escalationTemplate.text.length === 0
      ) {
        return false;
      }
      return true;
    }
  },

  methods: {
    requestApproval() {
      if (this.entry.id && this.escalationTemplate && this.approver) {
        const escalation: NewEscalation = {
          approverEmail: this.approver.email,
          approverName: this.approver.name,
          approverJobTitle: this.approver.jobTitle,
          escalationTemplateId: this.escalationTemplate.id,
          matterIdentifier: this.matterIdentifier,
          playbookInstanceId: this.playbookInstance.id,
          respondedAt: null,
          text: this.escalationTemplate.text
        };
        ApiService.createEscalation(escalation).then(({ data }) => {
          this.approvalSent = true;
          const escalations = this.entry.escalations || [];
          escalations.push(data.escalation as Escalation);
          const updatedEntry: Entry = {
            ...this.entry,
            escalations
          };
          const sidebarStore = useSidebarStore();

          sidebarStore.replaceEntry(updatedEntry);

          this.$emit("close");
        });
      }
    }
  }
});
</script>
