<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white px-0 py-2 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="ml-4 mt-3 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Copy playbook: {{ client.name }} - {{ playbook.name }}
                </DialogTitle>
                <div v-if="newPlaybook" class="mt-3">
                  <div class="flex items-center">
                    <CheckIcon class="h-6 w-6 text-green-600" />
                    <div class="ml-2">
                      <p
                        v-if="destinationClient"
                        class="text-sm font-medium leading-5 text-green-600"
                      >
                        New playbook {{ destinationClient.name }} -
                        {{ name }} created successfully.
                      </p>
                    </div>
                  </div>
                </div>
                <template v-else>
                  <div class="mt-3">
                    <BaseSelect
                      v-model="clientId"
                      :options="clientOptions"
                      placeholder="Select a client"
                      name="client"
                      label="Select a client to copy to"
                      :error="error"
                    />
                  </div>
                  <div class="mt-3">
                    <BaseInput
                      v-model="name"
                      placeholder="e.g. Main playbook"
                      name="name"
                      label="Enter a name for copied playbook"
                      :error="error"
                    />
                  </div>
                </template>
              </div>
            </div>
            <div
              class="mt-6 bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6"
            >
              <template v-if="!newPlaybook">
                <button
                  type="button"
                  class="inline-flex w-full justify-center rounded border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium font-medium text-white text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50 sm:ml-3 sm:w-auto sm:text-sm"
                  @click="copy"
                  :disabled="copying || !clientId || !name"
                >
                  {{ copying ? "Copying..." : "Copy" }}
                </button>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  @click.prevent="$emit('close')"
                  ref="cancelButtonRef"
                >
                  {{ "Cancel" }}
                </button>
              </template>
              <template v-else>
                <button
                  type="button"
                  class="mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 sm:ml-3 sm:mt-0 sm:w-auto sm:text-sm"
                  @click.prevent="jumpToNewPlaybook"
                >
                  Jump to new Playbook
                </button>
              </template>
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType } from "vue";
import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { Client, Playbook } from "@/types";
import ApiService from "@/services/ApiService";
import DirtyJson from "@/services/ApiService";
import { useAdminStore } from "@/store/AdminStore";
import { useAdmin } from "@/components/shared/Admin";

export default defineComponent({
  setup() {
    const adminStore = useAdminStore();
    const { clients } = useAdmin();

    return {
      clients,
      adminStore
    };
  },

  components: {
    CheckIcon,
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  data() {
    return {
      clientId: null as number | null,
      copying: false,
      error: null as string | null,
      name: null as string | null,
      newPlaybook: null as Playbook | null
    };
  },

  props: {
    playbook: {
      required: true,
      type: Object as PropType<Playbook>
    },

    client: {
      required: true,
      type: Object as PropType<Client>
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  computed: {
    clientOptions() {
      const clients = this.clients;
      return clients
        .sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1;
          }
          if (a.name.toLowerCase() > b.name.toLowerCase()) {
            return 1;
          }
          return 0;
        })
        .map((c: Client) => {
          return { name: c.name, value: c.id };
        });
    },

    destinationClient(): Client | null {
      return this.clients.find((c: Client) => c.id === this.clientId) ?? null;
    }
  },

  mounted() {
    this.newPlaybook = null;
  },

  watch: {
    show() {
      if (this.show) {
        this.clientId = null;
        this.copying = false;
        this.error = null;
        this.name = null;
        this.newPlaybook = null;
      }
    }
  },

  methods: {
    copy() {
      if (this.clientId && this.name) {
        this.error = null;
        this.copying = true;
        ApiService.copyPlaybook(this.playbook, this.name, this.clientId)
          .then(({ data }) => {
            this.newPlaybook = data.playbook;
            if (this.clientId) {
              this.adminStore.addPlaybookToClient(this.clientId, data.playbook);
            }
          })
          .catch((error) => {
            if (error.request && error.response) {
              const body = DirtyJson.parse(error.request.response);
              this.error = body.message;
            } else {
              this.error = "An unknown error occurred";
            }
          })
          .finally(() => {
            this.copying = false;
          });
      }
    },

    jumpToNewPlaybook() {
      this.adminStore.clientId = this.clientId;
      this.$emit("close");
      nextTick(() => {
        if (this.newPlaybook) {
          this.adminStore.playbookId = this.newPlaybook.id;
        }
      });
    }
  }
});
</script>

<style scoped></style>
