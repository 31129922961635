import { Node, mergeAttributes } from "@tiptap/core";

export interface TaskItemOptions {
  nested: boolean;
  HTMLAttributes: Record<string, string>;
}

export const inputRegex = /^\s*(\[([ |x])\])\s$/;

export const TaskItem = Node.create<TaskItemOptions>({
  name: "taskItem",

  content() {
    return this.options.nested ? "paragraph block*" : "paragraph+";
  },

  defining: true,

  addAttributes() {
    return {
      checked: {
        default: false,
        parseHTML: (element) => ({
          checked: element.getAttribute("data-checked") === "true"
        }),
        renderHTML: (attributes) => ({
          "data-checked": attributes.checked
        }),
        keepOnSplit: false
      }
    };
  },

  addOptions() {
    return { nested: false, HTMLAttributes: {} };
  },

  parseHTML() {
    return [
      {
        tag: 'li[data-type="taskItem"]',
        priority: 51
      }
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attributes = mergeAttributes(
      this.options.HTMLAttributes,
      HTMLAttributes,
      {
        "data-type": "taskItem"
      }
    );
    return [
      "li",
      attributes,
      ["label", ["input", { type: "checkbox" }, ["span"]]],
      ["div", 0]
    ];
  },

  addKeyboardShortcuts() {
    const shortcuts = {
      Enter: () => this.editor.commands.splitListItem("taskItem"),
      "Shift-Tab": () => this.editor.commands.liftListItem("taskItem")
    };

    if (!this.options.nested) {
      return shortcuts;
    }

    return {
      ...shortcuts,
      Tab: () => this.editor.commands.sinkListItem("taskItem")
    };
  },

  addNodeView() {
    return ({ node, HTMLAttributes, getPos, editor }) => {
      const listItem = document.createElement("li");
      const checkboxWrapper = document.createElement("label");
      const checkboxStyler = document.createElement("span");
      const checkbox = document.createElement("input");
      const content = document.createElement("div");

      checkboxWrapper.contentEditable = "false";
      checkbox.type = "checkbox";
      checkbox.addEventListener("change", (event) => {
        const { checked } = event.target as HTMLInputElement;

        if (typeof getPos === "function") {
          editor
            .chain()
            .focus()
            .command(({ tr }) => {
              tr.setNodeMarkup(getPos(), undefined, {
                checked
              });

              return true;
            })
            .run();
        }
      });

      if (node.attrs.checked) {
        checkbox.setAttribute("checked", "checked");
      }

      checkboxWrapper.append(checkbox, checkboxStyler);
      listItem.append(checkboxWrapper, content);

      Object.entries(HTMLAttributes).forEach(([key, value]) => {
        listItem.setAttribute(key, value);
      });

      return {
        dom: listItem,
        contentDOM: content,
        update: (updatedNode) => {
          if (updatedNode.type !== this.type) {
            return false;
          }

          if (updatedNode.attrs.checked) {
            checkbox.setAttribute("checked", "checked");
          } else {
            checkbox.removeAttribute("checked");
          }

          return true;
        }
      };
    };
  }
});
