import { ref } from "vue";

import { defineStore } from "pinia";
import { StorageSerializers, useStorage } from "@vueuse/core";

type StreamIdentifiers = Record<string, string>;

export const useAIStreamsStore = defineStore("AiStreamsStore", () => {
  const lastStreamedAt = ref<Date | null>(null);
  let debounceTimeout = null;
  const hasTimedOut = ref(false);

  const streams = useStorage("streams", <StreamIdentifiers>{}, undefined, {
    serializer: StorageSerializers.object
  });

  const clearStream = (streamIdentifiers: string[]) => {
    const streamIdentifier = streamIdentifiers.join("-");
    delete streams.value[streamIdentifier];
  };

  const setStream = (streamId: string, streamIdentifiers: string[]) => {
    const streamIdentifier = streamIdentifiers.join("-");
    streams.value[streamIdentifier] = streamId;
  };

  const getStream = (streamIdentifiers: string[]) => {
    const streamIdentifier = streamIdentifiers.join("-");
    return streams.value[streamIdentifier];
  };

  const clearLastStreamedAt = () => {
    lastStreamedAt.value = null;
    clearTimeout(debounceTimeout);
    hasTimedOut.value = false; // Reset timeout flag
  };

  const markLastStreamedAt = () => {
    lastStreamedAt.value = new Date();
    hasTimedOut.value = false; // Ensure flag is reset when streaming activity occurs
    clearTimeout(debounceTimeout);
    debounceTimeout = setTimeout(() => {
      console.log("AI streamer timed out");
      hasTimedOut.value = true; // Set flag to true on timeout
    }, 120000); // 120 seconds timeout
  };

  const $reset = () => {
    clearLastStreamedAt();
    streams.value = {};
  };

  return {
    clearLastStreamedAt,
    clearStream,
    setStream,
    getStream,
    markLastStreamedAt,
    hasTimedOut,
    $reset
  };
});
