<template>
  <div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="!showModal"
        class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div
          class="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
        >
          <a href="#" class="block py-2" @click="showModal = true">
            <PlusIcon class="mr-2 inline h-5 w-5 align-bottom" />
            Add new variable
          </a>
        </div>

        <template v-if="items.length">
          <a
            href="#"
            :class="[
              index === selectedIndex
                ? 'bg-gray-100 text-gray-900'
                : 'text-gray-700',
              'block px-4 py-2 text-sm'
            ]"
            v-for="(item, index) in items"
            :key="index"
            @click="selectItem(index)"
          >
            {{ item }}
          </a>
        </template>
      </div>
    </transition>
    <Dialog
      v-model:open="showModal"
      class="fixed inset-0 z-50 overflow-y-auto whitespace-pre-wrap"
    >
      <div
        class="flex min-h-screen min-h-screen items-center justify-center px-4 text-center"
      >
        <DialogOverlay
          @click="showModal = false"
          class="fixed inset-0 bg-black opacity-30"
        />

        <!-- Modal content -->
        <div
          class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
        >
          <DialogTitle class="text-md font-medium leading-6 text-gray-900">
            New variable
          </DialogTitle>
          <form @submit="createVariable">
            <div class="mt-2">
              <BaseInput
                v-model="newVariableIdentifier"
                placeholder=""
                class="w-full"
                :hint="`You can use this variable with the identifier { ${newVariableIdentifier} } everywhere in the playbook.`"
              />
            </div>
            <div class="mt-5 flex space-x-3 sm:mt-4">
              <BaseButton @click="createVariable"> Create </BaseButton>
              <BaseButton buttonStyle="white" @click="showModal = false">
                Cancel
              </BaseButton>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { PlusIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  items: {
    type: Array,
    required: true
  },

  command: {
    type: Function,
    required: true
  },

  query: {
    type: String,
    required: true
  }
});

const newVariableIdentifier = ref("");
const selectedIndex = ref(-1);
const showModal = ref(false);
watch(
  () => props.items,
  () => {
    selectedIndex.value = 0;
  }
);

const createVariable = () => {
  showModal.value = false;
  props.command({ id: newVariableIdentifier.value });
};

const onKeyDown = ({ event }) => {
  if (event.key === "ArrowUp") {
    upHandler();
    return true;
  }

  if (event.key === "ArrowDown") {
    downHandler();
    return true;
  }

  if (event.key === "Enter") {
    enterHandler();
    return true;
  }

  if (event.key === "}") {
    selectItem(selectedIndex.value);
    return true;
  }

  return false;
};

const upHandler = () => {
  selectedIndex.value =
    (selectedIndex.value + props.items.length - 1) % props.items.length;
};

const downHandler = () => {
  selectedIndex.value = (selectedIndex.value + 1) % props.items.length;
};

const enterHandler = () => {
  selectItem(selectedIndex.value);
};

const selectItem = (index: number) => {
  const item = props.items[index];

  if (item) {
    props.command({ id: item });
  } else {
    const newVariableName = props.query
      .replace("{", "")
      .replace("}", "")
      .trim();
    props.command({ id: newVariableName });
  }
};

defineExpose({
  onKeyDown
});
</script>
