<template>
  <div
    class="editable-text"
    :class="isToolbarVisible ? 'toolbar-visible' : undefined"
  >
    <label class="block text-sm font-medium text-gray-700"> {{ label }} </label>
    <div class="relative mt-1">
      <div
        class="absolute top-1 left-1 z-10"
        v-if="editor"
        v-show="isToolbarVisible"
      >
        <button
          title="Bold"
          @click="editor.chain().focus().toggleBold().run()"
          class="inline-flex cursor-pointer items-center rounded-l-md border border-gray-300 bg-white px-2 py-1 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M8 11h4.5a2.5 2.5 0 1 0 0-5H8v5zm10 4.5a4.5 4.5 0 0 1-4.5 4.5H6V4h6.5a4.5 4.5 0 0 1 3.256 7.606A4.498 4.498 0 0 1 18 15.5zM8 13v5h5.5a2.5 2.5 0 1 0 0-5H8z"
            />
          </svg>
        </button>
        <button
          title="Italics"
          @click="editor.chain().focus().toggleItalic().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            fill="currentColor"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
          </svg>
        </button>

        <button
          title="Underline"
          @click="editor.chain().focus().toggleUnderline().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('underline') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-4 w-4"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              d="M8 3V12C8 14.2091 9.79086 16 12 16C14.2091 16 16 14.2091 16 12V3H18V12C18 15.3137 15.3137 18 12 18C8.68629 18 6 15.3137 6 12V3H8ZM4 20H20V22H4V20Z"
            />
          </svg>
        </button>

        <button
          title="Strike"
          @click="editor.chain().focus().toggleStrike().run()"
          class="relative -ml-px inline-flex items-center border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            class="h-4 w-4"
            width="1em"
            height="1em"
          >
            <path
              d="M17.1538 14C17.3846 14.5161 17.5 15.0893 17.5 15.7196C17.5 17.0625 16.9762 18.1116 15.9286 18.867C14.8809 19.6223 13.4335 20 11.5862 20C9.94674 20 8.32335 19.6185 6.71592 18.8555V16.6009C8.23538 17.4783 9.7908 17.917 11.3822 17.917C13.9333 17.917 15.2128 17.1846 15.2208 15.7196C15.2208 15.0939 15.0049 14.5598 14.5731 14.1173C14.5339 14.0772 14.4939 14.0381 14.4531 14H3V12H21V14H17.1538ZM13.076 11H7.62908C7.4566 10.8433 7.29616 10.6692 7.14776 10.4778C6.71592 9.92084 6.5 9.24559 6.5 8.45207C6.5 7.21602 6.96583 6.165 7.89749 5.299C8.82916 4.43299 10.2706 4 12.2219 4C13.6934 4 15.1009 4.32808 16.4444 4.98426V7.13591C15.2448 6.44921 13.9293 6.10587 12.4978 6.10587C10.0187 6.10587 8.77917 6.88793 8.77917 8.45207C8.77917 8.87172 8.99709 9.23796 9.43293 9.55079C9.86878 9.86362 10.4066 10.1135 11.0463 10.3004C11.6665 10.4816 12.3431 10.7148 13.076 11H13.076Z"
            />
          </svg>
        </button>

        <button
          title="Highlight"
          @click="editor.chain().focus().toggleHighlight().run()"
          class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-1 text-xs font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
          :class="{ 'is-active': editor.isActive('highlight') }"
        >
          <svg
            class="h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="m20.707 5.826l-3.535-3.533a.999.999 0 0 0-1.408-.006L7.096 10.82a1.01 1.01 0 0 0-.273.488l-1.024 4.437L4 18h2.828l1.142-1.129l3.588-.828c.18-.042.345-.133.477-.262l8.667-8.535a1 1 0 0 0 .005-1.42zm-9.369 7.833l-2.121-2.12l7.243-7.131l2.12 2.12l-7.242 7.131zM4 20h16v2H4z"
            />
          </svg>
        </button>
      </div>
      <editor-content :editor="editor" />
    </div>
  </div>
</template>

<script lang="ts">
// Icons come from https://remiXMarkIcon.com/
import { computed, defineComponent, ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { useToolbarVisibilityStore } from "@/store/ToolbarVisibilityStore";
import { useEditor, EditorContent } from "@tiptap/vue-3";
import StarterKit from "@tiptap/starter-kit";
import Highlight from "@tiptap/extension-highlight";
import Placeholder from "@tiptap/extension-placeholder";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";

export default defineComponent({
  components: {
    EditorContent
  },

  props: {
    label: String,
    modelValue: {
      type: String,
      default: ""
    },
    placeholder: String
  },

  setup(props, context) {
    const componentId = ref(uuidv4());
    const toolbarVisibilityStore = useToolbarVisibilityStore();
    const isToolbarVisible = computed(() => {
      return toolbarVisibilityStore.isToolbarOpen(componentId.value);
    });

    const classes = computed(() => {
      const result =
        (context.attrs.class ?? "") + " overflow-y-scroll max-h-96";

      return result;
    });

    const editor = useEditor({
      content: props.modelValue,
      editorProps: {
        attributes: {
          class: classes.value,
          spellcheck: "true"
        }
      },
      extensions: [
        StarterKit,
        Highlight,
        Placeholder.configure({
          placeholder: props.placeholder
        }),
        Strike,
        Underline
      ],

      onFocus: () => {
        toolbarVisibilityStore.openToolbar(componentId.value);
      },

      onUpdate: ({ editor }) => {
        if (editor) {
          context.emit("update:modelValue", editor.getHTML());
        }
      }
    });

    return {
      editor,
      isToolbarVisible
    };
  },

  watch: {
    modelValue(value) {
      if (this.editor) {
        const isSame = this.editor.getHTML() === value;

        if (isSame) {
          return;
        }

        this.editor.commands.setContent(this.modelValue, false);
      }
    }
  },

  methods: {
    focus() {
      if (this.editor) {
        this.editor.chain().focus();
      }
    }
  },

  beforeUnmount() {
    if (this.editor) {
      this.editor.destroy();
    }
  }
});
</script>

<style lang="scss" scoped>
.editable-text :deep(.ProseMirror) {
  @apply min-h-[60px];
}
.toolbar-visible :deep(.ProseMirror) {
  padding-top: 40px !important;
  padding-left: 10px !important;
}

:deep(.ProseMirror) {
  @apply block w-full cursor-text rounded border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm;
}

button.is-active {
  @apply bg-gray-200 hover:bg-gray-200;
}
</style>
