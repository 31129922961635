<template>
  <div class="flex rounded">
    <select
      v-model="tag"
      :class="{
        'text-black-primary': tag !== null,
        'text-gray-400': tag === null,
        small: size === 'small',
        large: size === 'large'
      }"
      @change="addTag"
    >
      <option :value="null" disabled selected>{{ placeholder }}</option>
      <option
        v-for="thisTag in filteredTags"
        :key="thisTag.id"
        :value="thisTag"
      >
        {{ thisTag.name }}
      </option>
    </select>
  </div>
  <div class="mt-2 flex w-full max-w-screen-sm flex-wrap gap-1">
    <BaseTag
      v-for="thisTag in modelValue"
      :key="thisTag.id"
      :tag="thisTag"
      :show-remove="true"
      @remove="removeTag(thisTag)"
      class="mb-1"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import BaseTag from "@/components/base/BaseTag.vue";
import { Tag } from "@/types";

export default defineComponent({
  components: { BaseTag },

  props: {
    modelValue: {
      type: Array as PropType<Tag[]>,
      required: true,
      default: () => []
    },

    placeholder: {
      type: String,
      default: "Available tags"
    },

    size: {
      type: String as PropType<"large" | "small" | "extra-small">,
      default: "small"
    },

    selectableTags: {
      type: Array as PropType<Tag[]>,
      required: true
    }
  },

  data() {
    return {
      tag: null as Tag | null
    };
  },

  computed: {
    filteredTags(): Tag[] {
      return this.selectableTags.filter(
        (tag) => !this.modelTagIds.includes(tag.id)
      );
    },

    modelTagIds(): number[] {
      return this.modelValue.map((tag) => tag.id);
    }
  },

  methods: {
    addTag() {
      if (this.tag) {
        const newTags = [...this.modelValue, this.tag];
        this.$emit("update:modelValue", newTags);
        this.tag = null;
      }
    },

    removeTag(tag: Tag) {
      const updatedTags = this.modelValue.filter((el) => el != tag);
      this.$emit("update:modelValue", updatedTags);
    }
  }
});
</script>

<style lang="scss" scoped>
select {
  @apply w-full rounded border border-gray-300 px-3 py-2 placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100;

  &.large {
    @apply text-sm;
  }

  &.small {
    @apply text-xs;
  }
}
</style>
