<template>
  <BaseDisclosure
    :open="open"
    :buttonLabel="`${textOrdinalize(index, numberOfFallbacks)} fallback`"
    @toggleOpen="open = !open"
    color="light purple"
    class="mb-2"
  >
    <InlineEditableText
      v-if="playbookInstance"
      :entryId="entry.id"
      :filterText="filterText ?? ''"
      :playbookInstance="playbookInstance"
      :text="fallback.description"
      @update="updateDescription"
    />
    <InlineEditableTemplateWording
      v-if="playbookInstance && !blankTemplateWording"
      :entryId="entry.id"
      :filterText="filterText ?? ''"
      :playbookInstance="playbookInstance"
      :showLabel="true"
      :text="fallback.templateWording"
      @update="updateTemplateWording"
    />
    <InlineEditableTemplateComment
      v-if="playbookInstance && !blankTemplateComment"
      :entryId="entry.id"
      :filterText="filterText ?? ''"
      :playbookInstance="playbookInstance"
      :showLabel="true"
      :text="fallback.templateComment"
      @update="updateTemplateComment"
    />
  </BaseDisclosure>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import BaseDisclosure from "@/components/base/BaseDisclosure.vue";
import InlineEditableTemplateComment from "@/components/sidebar/InlineEditableTemplateComment.vue";
import InlineEditableText from "@/components/sidebar/InlineEditableText.vue";
import InlineEditableTemplateWording from "@/components/sidebar/InlineEditableTemplateWording.vue";
import { Entry } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";

import { blankText, textOrdinalize } from "@/helpers/TextHelpers";
import {
  Fallback,
  FallbackScenario,
  Playbook,
  PlaybookInstance
} from "@/types";

const props = defineProps({
  entry: {
    type: Object as PropType<Entry>,
    required: true
  },

  fallback: {
    type: Object as PropType<Fallback>,
    required: true
  },

  fallbackScenario: {
    type: Object as PropType<FallbackScenario>,
    required: true
  },

  filterText: {
    type: String,
    required: false
  },

  index: {
    type: Number,
    required: true
  },

  numberOfFallbacks: {
    type: Number,
    required: true
  },

  playbook: {
    type: Object as PropType<Playbook>,
    required: false
  }
});

const blankTemplateWording = computed((): boolean => {
  if (!props.fallback.templateWording) {
    return true;
  }
  return blankText(props.fallback.templateWording);
});

const blankTemplateComment = computed((): boolean => {
  if (!props.fallback.templateComment) {
    return true;
  }
  return blankText(props.fallback.templateComment);
});

const playbookInstance = computed(() => {
  return sidebarStore.playbookInstance;
});

const sidebarStore = useSidebarStore();
const open = computed({
  get() {
    if (!props.fallback || !props.fallback.id) {
      return true;
    }
    return sidebarStore.isFallbackOpen(props.fallback);
  },

  set(value: boolean) {
    sidebarStore.setFallbackOpen(props.fallback, value);
  }
});

const updateFallback = (fallback: Fallback) => {
  sidebarStore.updateFallback(
    props.entry.id,
    props.fallbackScenario.id,
    fallback
  );
};

const updateTemplateComment = (templateComment: string) => {
  const updatedFallback = { ...props.fallback, templateComment };
  updateFallback(updatedFallback);
};

const updateTemplateWording = (templateWording: string) => {
  const updatedFallback = { ...props.fallback, templateWording };
  updateFallback(updatedFallback);
};

const updateDescription = (description: string) => {
  const updatedFallback = { ...props.fallback, description };
  updateFallback(updatedFallback);
};
</script>
