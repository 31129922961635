<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2
              class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
            >
              Forgot password
            </h2>
            <p class="my-10 text-center text-sm text-gray-500">
              Enter your email address below and we'll send you instructions to
              reset your password.
            </p>
          </div>

          <form class="mt-8 space-y-6" @submit.prevent="forgotPassword">
            <div class="-space-y-px rounded shadow-sm">
              <div>
                <label for="email-address" class="sr-only">
                  Email address
                </label>
                <input
                  v-model="email"
                  autofocus
                  name="email"
                  type="email"
                  autocomplete="email"
                  required="true"
                  class="relative block w-full appearance-none rounded border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
                  placeholder="Email address"
                  :class="
                    error
                      ? 'border-none ring-2 ring-red-400 focus:ring-red-500'
                      : ''
                  "
                />
                <p v-if="error" class="mt-3 text-xs font-medium text-red-600">
                  We can't find a user with that email address.
                </p>
              </div>
            </div>
            <div>
              <button
                type="submit"
                class="group relative flex w-full justify-center rounded border border-transparent bg-lexoo py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
                :disabled="email.length == 0 || loading"
              >
                {{ loading ? "Sending..." : "Send password reset email" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/services/ApiService";

export default defineComponent({
  data() {
    return {
      email: "",
      error: false,
      loading: false
    };
  },

  methods: {
    forgotPassword() {
      this.loading = true;
      ApiService.forgotPassword(this.email)
        .then(() => {
          this.$router.push("/forgot-password-success");
        })
        .catch(() => {
          this.error = true;
        });
    }
  }
});
</script>

<style lang="scss" scoped></style>
