<template>
  <div class="mb-4">
    <label for="clientId" class="block text-sm font-medium text-gray-700">
      Client
    </label>
    <select
      v-model="clientId"
      name="clientId"
      class="mt-1 block rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
    >
      <option disabled :value="null">Select a client</option>
      <option v-for="client in clients" :key="client.id" :value="client.id">
        {{ client.name }}
      </option>
    </select>
  </div>

  <div class="mb-4">
    <label for="group" class="block text-sm font-medium text-gray-700">
      Template file
    </label>
    <BaseInput
      v-model="templateFile"
      placeholder="Unique name to describe this file - i.e. Licensing Agreement"
    />
  </div>

  <div class="mb-4">
    <label for="group" class="block text-sm font-medium text-gray-700">
      Counterparty
    </label>
    <BaseInput v-model="counterparty" placeholder="Name of the counterparty" />
  </div>

  <div class="mb-4">
    <input
      multiple="true"
      type="file"
      ref="files"
      class="text-grey-500 text-sm file:mr-3 file:rounded file:border-0 file:bg-blue-50 file:py-2 file:px-6 file:text-sm file:font-medium file:text-blue-700 hover:file:cursor-pointer hover:file:bg-amber-50 hover:file:text-amber-700"
    />
  </div>

  <div class="mb-4">
    <BaseButton
      :label="buttonLabel"
      :disabled="uploading || (!clientId && !templateFile && !counterparty)"
      @click="uploadFiles"
      buttonType="primary"
    />
  </div>
  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Client
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Template file
        </th>

        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        ></th>
      </tr>
    </thead>
    <tbody class="divide-y divide-gray-200 bg-white">
      <tr
        v-for="(
          groupedObject, groupIdx
        ) in sampleTermsGroupsGroupedByTemplateFile"
        :key="groupIdx"
      >
        <td
          class="whitespace-nowrap px-6 py-4 align-top text-sm font-medium text-gray-500"
        >
          {{ groupedObject.clientName }}
        </td>
        <td
          class="whitespace-nowrap px-6 py-4 align-top text-sm font-medium text-gray-500"
        >
          {{ groupedObject.templateFile }}
        </td>

        <td
          class="whitespace-nowrap px-6 py-4 align-top text-sm font-medium text-gray-500"
        >
          <span class="isolate inline-flex rounded shadow-sm">
            <button
              type="button"
              class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
              @click="goToSampleTermsFileGroup(groupedObject)"
            >
              View
            </button>
            <button
              type="button"
              class="relative -ml-px inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-gray-50 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
              @click="deleteGroupedObject(groupedObject)"
            >
              Delete
            </button>
          </span>
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { SampleTermsFileGroup } from "@/types";
import { useAdmin } from "@/components/shared/Admin";
import ApiService from "@/services/ApiService";

const { clients } = useAdmin();

const clientId = ref<number | null>(null);
const templateFile = ref<string | null>(null);
const counterparty = ref<string | null>(null);
const files = ref<HTMLInputElement | null>(null);

const uploading = ref(false);
const sampleTermsFileGroups = ref<SampleTermsFileGroup[]>([]);

const loadSampleTermsFileGroups = () => {
  ApiService.getSampleTermsFileGroups().then(({ data }) => {
    console.log(data);
    sampleTermsFileGroups.value = data.sampleTermsFileGroups;
  });
};

onMounted(() => {
  loadSampleTermsFileGroups();
});

const buttonLabel = computed(() => {
  if (uploading.value) {
    return "Uploading...";
  }
  return "Upload";
});
const router = useRouter();

type GroupedByTemplateFile = {
  clientId: number;
  clientName: string;
  templateFile: string;
  numberOfSampleTermsFiles: number;
};

const deleteGroupedObject = (groupedObject: GroupedByTemplateFile) => {
  ApiService.destroySampleTermsFileGroup(
    groupedObject.clientId,
    groupedObject.templateFile
  ).then(() => {
    loadSampleTermsFileGroups();
  });
};

const sampleTermsGroupsGroupedByTemplateFile = computed(() => {
  const groupedByTemplateFile = sampleTermsFileGroups.value.reduce(
    (acc, sampleTermsFileGroup) => {
      const key = `${sampleTermsFileGroup.clientId}-${sampleTermsFileGroup.templateFile}`;
      if (!acc[key]) {
        acc[key] = {
          clientId: sampleTermsFileGroup.clientId,
          clientName: sampleTermsFileGroup.clientName,
          templateFile: sampleTermsFileGroup.templateFile
        };
      }
      acc[key].numberOfSampleTermsFiles += 1;
      return acc;
    },
    {} as Record<string, GroupedByTemplateFile>
  );
  return groupedByTemplateFile;
});

const goToSampleTermsFileGroup = (
  sampleTermsFileGroup: SampleTermsFileGroup
) => {
  router.push({
    name: "SampleTermsFileGroup",
    query: {
      clientId: sampleTermsFileGroup.clientId,
      templateFile: sampleTermsFileGroup.templateFile
    }
  });
};

const uploadFiles = () => {
  if (!files.value) {
    return;
  }
  uploading.value = true;
  const filesToUpload = (files.value as HTMLInputElement).files ?? [];

  const formData = new FormData();
  for (let i = 0; i < filesToUpload.length; i++) {
    formData.append("files[]", filesToUpload[i]);
  }
  formData.append("client_id", clientId.value?.toString() ?? "");
  formData.append("template_file", templateFile.value?.toString() ?? "");
  formData.append("counterparty", counterparty.value?.toString() ?? "");
  ApiService.uploadMultipleSampleTermsFile(formData)
    .then(() => {
      loadSampleTermsFileGroups();
    })
    .catch(() => {
      alert(
        "There was an error uploading that file. Please contact Lexoo support."
      );
    })
    .finally(() => {
      uploading.value = false;
      (files.value as HTMLInputElement).value = "";
    });
};
</script>

<style lang="scss"></style>
