<template>
  <div class="mx-auto mb-10 max-w-3xl rounded border p-8">
    <div class="prose mx-auto">
      <p class="text-right text-gray-500">Last updated 24th October 2023</p>
      <h1 class="text-center text-2xl">
        LexPlay Software Licence Terms and Conditions
      </h1>

      <p class="text-center">
        PLEASE READ CAREFULLY BEFORE ACCESSING AND/OR USING LEXPLAY:
      </p>
      <p>
        These LexPlay software licence terms and conditions (including the
        attached AI Terms Addendum) (altogether the <strong>“Terms”</strong>)
        alongside any applicable Order (as defined in clause 1.1) – collectively
        the <strong>“Agreement”</strong>, is a legal contract between you (
        <strong>“you”</strong> ) and Lexoo Limited is registered and
        incorporated in England and Wales, company registration number 08900002,
        of Unit 16, Britannia Building, 12 Ebenezer Street, London, N1 7RP (
        <strong>“us”</strong> or <strong>“we”</strong> ) – individually referred
        to as <strong>“party”</strong> and collectively as
        <strong>“parties”</strong> , for:
      </p>
      <ul>
        <li>
          LexPlay, our proprietary software application that is either an add-in
          to Microsoft Word or the web browser version of LexPlay (
          <strong>“Software”</strong> or <strong>“LexPlay”</strong> ) access to
          which may be provided by us to you from time to time under this
          Agreement and which you may purchase through either Microsoft
          AppSource, Azure Marketplace or via a direct link from our website;
          and
        </li>
        <li>
          electronic documents we may make available to you from time to time (
          <strong>“Documents”</strong> ).
        </li>
      </ul>
      <p>
        The Agreement shall at all times supersede any &#39;shrinkwrap&#39; or
        &#39;clickwrap&#39; terms or any other terms with respect to the
        Software, even if you or any of your employees or officers are requested
        to agree to or adhere to such &#39;shrinkwrap&#39;, &#39;clickwrap&#39;,
        &#39;clickthrough&#39; or similar terms, whether before or after the
        date of the Amendment.
      </p>
      <p>
        We license the use of the Software and Documents to you based on the
        Agreement. We do not sell the Software or Documents to you. We remain
        the owners of the Software and Documents at all times.
      </p>
      <div class="rounded border p-4">
        <h3 class="text-center">IMPORTANT NOTICE</h3>
        <ul>
          <li>
            <p>
              BY CLICKING ON THE &quot;AGREE&quot;, &quot;ACCEPT&quot; (OR
              SIMILAR) BUTTON, OR OTHERWISE ACCESSING, DOWNLOADING, INSTALLING
              OR USING THE SOFTWARE YOU AGREE TO BE BOUND BY THE TERMS OF THE
              AGREEMENT.
            </p>
          </li>
          <li>
            <p>
              IF YOU DO NOT AGREE TO THE TERMS OF THE AGREEMENT, YOU MUST NOT
              CLICK ON THE &quot;AGREE&quot; or &quot;ACCEPT&quot; (OR SIMILAR)
              BUTTON AND YOU MAY NOT OTHERWISE ACCESS, DOWNLOAD, INSTAL OR USE
              THE SOFTWARE OR DOCUMENTS.
            </p>
          </li>
          <li>
            <p>
              WHERE YOU HAVE AGREED A CUSTOM CLOUD SERVICES AGREEMENT WITH US,
              SUCH AGREEMENT SHALL PREVAIL OVER THIS AGREEMENT.
            </p>
            <p>
              You should print a copy of the Agreement for future reference.
            </p>
          </li>
        </ul>
      </div>
      <ol>
        <li>
          <h3>LICENCE</h3>
          <ol>
            <li>
              <p>
                In consideration of payment by you of the agreed licence fees as
                set out in the Order and your agreeing to abide by the terms of
                the Agreement, we grant to you a non-exclusive, non-transferable
                licence to use the Software and the Documents on the terms of
                the Agreement. The licence is solely for your own use and
                business purposes.
              </p>

              <p>
                <strong>“Order”</strong> shall mean an order form, a statement
                of work or any other agreement, document, or email, setting out
                the amount of the licence fees, and the subscription period you
                agree to when signing up to our product.
              </p>
            </li>
            <li>
              <p>
                The Software may contain or be provided with components that are
                subject to open-source software licences. Any use of those
                components may be subject to additional terms and conditions,
                and you agree that any applicable licences governing the use of
                the components will be incorporated by reference in the
                Agreement.
              </p>
            </li>
            <li>
              <p>
                Licence is granted on a subscription basis and will expire at
                the end of the applicable subscription period you agreed to in
                the applicable Order, unless renewed.
              </p>
            </li>
            <li>
              <p>
                You are responsible to control access to, and use of, the
                Software and Documents by your end users and you are responsible
                for any use of the Software that does not comply with the
                Agreement.
              </p>
            </li>
            <li>
              <p>
                You may order Software for use by means of any legal entity that
                controls, is controlled by, or is under common control with you
                (
                <strong>“Affiliates”</strong> ). In such case the licences
                granted to you under the Agreement will apply to such
                Affiliates, but you will have the sole right to enforce the
                Agreement against us. You will remain responsible for all
                obligations under the Agreement and for your Affiliates&#39;
                compliance with the Agreement and any applicable order(s) issued
                hereunder.
              </p>
            </li>
            <li>
              <p>
                Nothing in the Agreement will restrict our right to use, profit
                from, disclose, publish, keep secret, or otherwise exploit
                ideas, suggestions, comments, input, enhancement requests,
                feedback, recommendations, or other information or know-how, in
                any form, that you may provide to us in relation to our services
                and the Software (<strong>“Feedback”</strong>), without
                compensating or crediting you or the individual providing such
                Feedback.
              </p>
            </li>
            <li>
              <p>
                In the course of your using the Software we may collect the
                Usage Data to provide and maintain the Software, to provide
                customer support, to improve and enhance our services as well as
                to detect, prevent and address technical issues.
                <strong>“Usage Data”</strong> means data and information related
                to your use of our services.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>USE AND RESTRICTIONS</h3>
          <ol>
            <li>
              <p>You undertake not to:</p>
              <ol class="alphabetic">
                <li>
                  <p>
                    knowingly access, store, distribute or transmit any viruses,
                    or any material as part of your use of the Software that are
                    illegal, unlawful, defamatory or infringe the rights of any
                    third party and shall ensure that your use of the Software
                    is in accordance with applicable laws and does not infringe
                    any third-party rights;
                  </p>
                </li>
                <li>
                  <p>
                    copy, modify, reverse engineer, decompile, or disassemble
                    the Software, or attempt to do so;
                  </p>
                </li>
                <li>
                  <p>
                    install or use any third-party software or technology in any
                    way that would subject our intellectual property or
                    technology to any other license terms;
                  </p>
                </li>
                <li>
                  <p>
                    work around any technical limitations in the Software or
                    restrictions in Documents;
                  </p>
                </li>
                <li><p>use the Software for any unlawful purpose; or</p></li>
              </ol>
            </li>
            <li>
              <p>
                distribute, sublicense, rent, lease, or lend Software, in whole
                or in part. You represent and warrant that you have the right
                and title to Customer Data.
              </p>
              <p>
                <strong>“Customer Data”</strong> means any information, data,
                and other content (excluding Usage Data) in any form or medium,
                that is submitted, posted, or otherwise transmitted by you or on
                your behalf through the Software.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>PAYMENT</h3>
          <ol>
            <li>
              <p>
                You agree to pay the subscription fees set out in the Order.
              </p>
            </li>
            <li>
              <p>
                Except where we agree otherwise, payment is via credit or debit
                card or through the use of a payment link generated at the
                sign-up time on a subscription basis. Payment is charged ahead
                of each billing period.
              </p>
            </li>
            <li>
              <p>
                If we agree that you may use a Free Trial, then we will make
                such Free Trial available to you on a trial basis, free of
                charge, until the earlier of: (i) the end of the free trial
                period agreed in the Order, (i) the start date of any
                subscription you purchase; or (iii) termination of the Free
                Trial at our discretion. A free trial period may be extended
                upon mutual agreement. Notwithstanding anything to the contrary
                in the Agreement, a Free Trial is provided &quot;AS IS.&quot; We
                MAKE NO REPRESENTATION OR WARRANTY AND, TO THE EXTENT PERMITTED
                BY APLICABLE LAW, SHALL HAVE NEITHER ANY LIABILITY OF ANY KIND,
                NOR INCUR ANY INDEMNIFICATION OBLIGATIONS WITH RESPECT TO A FREE
                TRIAL.
              </p>
              <p>
                <strong>“Free Trial”</strong> means any service or functionality
                we make available to you to try at no additional charge.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
          <ol>
            <li>
              <p>
                You acknowledge that all intellectual property rights in the
                Software and the Documents anywhere in the world belong to us,
                that rights in the Software are licensed (not sold) to you, and
                that you have no rights in, or to, the Software or the Documents
                other than the right to use them in accordance with the terms of
                the Agreement.
              </p>
            </li>
            <li>
              <p>
                You acknowledge that you have no right to have access to the
                Software in source code form.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>WARRANTIES</h3>
          <ol>
            <li>
              <p>We warrant that:</p>
              <ol class="alphabetic">
                <li>
                  <p>
                    the Software will, when properly used and on an operating
                    system for which it was designed, perform substantially in
                    accordance with the functions described in the Documents for
                    a period of 90 days from the date of your installation of OR
                    first access to the Software (
                    <strong>“Warranty Period”</strong> ).
                  </p>
                </li>
                <li>
                  <p>
                    If, within the Warranty Period, you notify us in writing of
                    any defect or fault in the Software as a result of which it
                    fails to perform substantially in accordance with the
                    Documents, we will, at our sole option, either repair or
                    replace the Software, provided that you make available all
                    the information that may be necessary to help us to remedy
                    the defect or fault, including sufficient information to
                    enable us to recreate the defect or fault.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>The above warranty does not apply:</p>
              <ol class="alphabetic">
                <li>
                  <p>
                    if the defect or fault in the Software results from you
                    having altered or modified the Software; and
                  </p>
                </li>
                <li>
                  <p>
                    if the defect or fault in the Software results from you
                    having used the Software in breach of the terms of the
                    Agreement.
                  </p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                THIS AGREEMENT SETS OUT THE FULL EXTENT OF OUR OBLIGATIONS AND
                LIABILITIES IN RESPECT OF THE SUPPLY OF THE SOFTWARE AND
                DOCUMENTS. EXCEPT AS EXPRESSLY STATED IN THIS AGREEMENT, (I) THE
                SOFTWARE IS PROVIDED AS IS; AND (II) THERE ARE NO CONDITIONS,
                WARRANTIES, REPRESENTATIONS OR OTHER TERMS, EXPRESS OR IMPLIED,
                THAT ARE BINDING ON US. ANY CONDITION, WARRANTY, REPRESENTATION
                OR OTHER TERM CONCERNING THE SUPPLY OF THE SOFTWARE AND
                DOCUMENTS WHICH MIGHT OTHERWISE BE IMPLIED INTO, OR INCORPORATED
                IN, THIS AGREEMENT WHETHER BY STATUTE, COMMON LAW OR OTHERWISE,
                IS EXCLUDED TO THE FULLEST EXTENT PERMITTED BY LAW.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>INDEMNIFICATION</h3>
          <ol>
            <li>
              <p>
                We, at our own cost, will defend you from and against any
                Lexoo-Covered Claims and will indemnify and hold you harmless
                from and against any damages or costs awarded against you
                (including reasonable attorneys&#39; fees) or agreed in
                settlement by us resulting from the Lexoo-Covered Claims.
              </p>
            </li>
            <li>
              <p>
                You, at your own cost, will defend us from and against any
                Customer-Covered Claims and will indemnify and hold harmless us
                from and against any damages or costs awarded against us
                (including reasonable attorneys&#39; fees) or agreed in
                settlement by you resulting from the Customer-Covered Claims.
              </p>
            </li>
            <li>
              <p>The following definitions apply in this clause:</p>
              <ul>
                <li>
                  <p>
                    <strong>“Customer-Covered Claim”</strong> means a
                    third-party claim alleging that your use of the Software or
                    the Customer Data infringes the rights of a third party.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>“Lexoo-Covered Claim”</strong> means a third-party
                    claim that the Software, when used by you as authorised in
                    the Agreement, infringes or misappropriates a third
                    party&#39;s intellectual property rights.
                  </p>
                </li>
              </ul>
            </li>
            <li>
              <p>
                The indemnifying party&#39;s obligations in this clause 6 are
                subject to receiving from the indemnified party: (a) prompt
                notice of the claim (but delayed notice will only reduce the
                indemnifying party&#39;s obligations to the extent it is
                prejudiced by the delay), (b) the exclusive right to control the
                claim&#39;s investigation, defense, and settlement and (c)
                reasonable cooperation at the indemnifying party&#39;s expense.
                The indemnifying party may not settle a claim without the
                indemnified party&#39;s prior approval if settlement would
                require the indemnified party to admit fault or take or refrain
                from taking any action (except regarding use of the Software
                when we are the indemnifying party). The indemnified party may
                participate in a claim with its own counsel at its own expense.
              </p>
            </li>
            <li>
              <p>
                In response to an infringement or misappropriation claim, if
                required by settlement or injunction or as we determine
                necessary to avoid material liability, we may: (a) procure
                rights for your continued use of the Software, (b) replace or
                modify the allegedly infringing portion of the Software to avoid
                infringement, without reducing the Software&#39;s overall
                functionality or (c) terminate the affected order and refund to
                you any pre-paid, unused fees for the terminated portion of the
                subscription term.
              </p>
            </li>
            <li>
              <p>
                Out obligations in this clause 6 do not apply to claims
                resulting from (a) modification or unauthorised use of the
                Software, (b) use of the Software in combination with items not
                provided by us, or (c) the Software other than the most recent
                release, if we made available (at no additional charge) a newer
                release that would avoid infringement.
              </p>
            </li>
            <li>
              <p>
                This clause 6 sets out the indemnified party&#39;s exclusive
                remedy and the indemnifying party&#39;s sole liability regarding
                third-party claims of intellectual property infringement or
                misappropriation covered by this clause 6.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>LIMITATION OF LIABILITY</h3>
          <ol>
            <li>
              <p>
                You acknowledge that the Software has not been developed to meet
                your individual requirements, including any particular
                cybersecurity requirements you might be subject to under law or
                otherwise, and that it is therefore your responsibility to
                ensure that the facilities and functions of the Software as
                described in the Documents meet your requirements.
              </p>
            </li>
            <li>
              <p>
                We only supply the Software and Documents for internal use by
                your business, and you agree not to use the Software or
                Documents for any resale purposes.
              </p>
            </li>
            <li>
              <p>
                We shall not in any circumstances whatever be liable to you,
                whether in contract, tort (including negligence), breach of
                statutory duty, or otherwise (including under any indemnity),
                arising under or in connection with the Agreement for (i) any
                special, indirect or consequential loss, damage, charges or
                expenses; or (ii) loss of profits, sales, business, or revenue;
                business interruption; loss of anticipated savings; wasted
                expenditure; loss or corruption of data or information; loss of
                business opportunity, goodwill or reputation – whether or not
                any of the losses set out in clause 7.3(ii) are direct or
                indirect.
              </p>
            </li>
            <li>
              <p>
                Other than the losses set out in clause 7.3 (for which we are
                not liable), our maximum aggregate liability under or in
                connection with the Agreement whether in contract, tort
                (including negligence) or otherwise, shall in all circumstances
                be limited to a sum equal to 100% of the license fees paid by
                you to us in the 12 months preceding the claim.
              </p>
            </li>
            <li>
              <p>
                Nothing in the Agreement shall limit or exclude our liability
                for death or personal injury resulting from our negligence;
                fraud or fraudulent misrepresentation; any other liability that
                cannot be excluded or limited by law.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>TERMINATION</h3>
          <ol>
            <li>
              <p>
                The Agreement is effective until terminated, as described below.
                The term for each order will be set forth therein.
              </p>
            </li>
            <li>
              <p>
                Termination without cause. Unless otherwise set forth in an
                order, either party may terminate the Agreement or any order
                without cause on 30 days&#39; notice. We will not provide
                refunds or credits for any partial subscription period(s) if the
                Agreement or an order is terminated without cause.
              </p>
            </li>
            <li>
              <p>
                Termination for cause. Without limiting other remedies it may
                have, either party may terminate the Agreement or any order
                immediately on notice if (i) the other party materially breaches
                the Agreement or an order, and fails to cure the breach within
                30 days after receipt of notice of the breach; or (ii) the other
                party becomes Insolvent. Upon such termination, the following
                will apply: (i) all licences granted under the Agreement will
                terminate immediately; (ii) all amounts due under any unpaid
                invoices will become due and payable immediately. If we are in
                breach, you will receive a credit for any subscription fees, for
                unused consumption for any usage period after the termination
                date.
              </p>
            </li>
            <li>
              <p>
                We may suspend use of the Software without terminating the
                Agreement during any period of material breach. We will give you
                reasonable notice before suspending the Software. Suspension
                will only be to the extent reasonably necessary.
              </p>
            </li>
            <li>
              <p>
                The terms of the Agreement, including the applicable order, that
                are likely to require performance, or have application to events
                that may occur, after the termination or expiration of the
                Agreement or any order, will survive termination or expiration,
                including all indemnity obligations and procedures.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>CONFIDENTIALITY</h3>
          <ol>
            <li>
              <p>
                “Confidential Information” is non-public information that is
                designated “confidential” or that a reasonable person should
                understand is confidential, disclosed by one party to the other
                party. Confidential Information does not include information
                that: (1) becomes publicly available without a breach of a
                confidentiality obligation; (2) the receiving party received
                lawfully from another source without a confidentiality
                obligation; (3) is independently developed; or (4) is a comment
                or suggestion volunteered about the other party&#39;s business,
                products, or services.
              </p>
            </li>
            <li>
              <p>
                Each party will take reasonable steps to protect the other&#39;s
                Confidential Information and will use the other party&#39;s
                Confidential Information only for purposes of the parties&#39;
                business relationship. Neither party will disclose Confidential
                Information to third parties, except to its representatives, and
                then only on a need-to-know basis under nondisclosure
                obligations at least as protective as the Agreement. Each party
                remains responsible for the use of Confidential Information by
                its representatives and, in the event of discovery of any
                unauthorised use or disclosure, must promptly notify the other
                party.
              </p>
            </li>
            <li>
              <p>
                Disclosure required by law. A party may disclose the other&#39;s
                Confidential Information if required by law, but only after it
                notifies the other party (if legally permissible) to enable the
                other party to seek a protective order.
              </p>
            </li>
            <li>
              <p>
                The obligations in this clause 8 apply for a period of five
                years after a party receives the Confidential Information.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>COMMUNICATIONS</h3>
          <ol>
            <li>
              <p>
                We may update the terms of the Agreement at any time on notice
                to you in accordance with this clause 10. Your continued use of
                the Software and Documents following the deemed receipt and
                service of the notice under clause 10.3 shall constitute your
                acceptance to the terms of the Agreement, as varied. If you do
                not wish to accept the terms of the Agreement (as varied) you
                must immediately stop using and accessing the Software and
                Document on the deemed receipt and service of the notice.
              </p>
            </li>
            <li>
              <p>
                If we have to contact you, we will do so by email or by pre-paid
                post to the address you provided in accordance with your order
                for or registration of the Software.
              </p>
            </li>
            <li><p>Note that any notice:</p></li>
            <li>
              <p>
                given by us to you will be deemed received and properly served
                24 hours after it is first posted on our website, 24 hours after
                an email is sent, or three days after the date of posting of any
                letter; and
              </p>
            </li>
            <li>
              <p>
                given by you to us will be deemed received and properly served
                24 hours after an email is sent, or three days after the date of
                posting of any letter.
              </p>
            </li>
            <li>
              <p>
                In proving the service of any notice, it will be sufficient to
                prove, in the case of posting on our website, that the website
                was generally accessible to the public for a period of 24 hours
                after the first posting of the notice; in the case of a letter,
                that such letter was properly addressed, stamped and placed in
                the post to the address of the recipient given for these
                purposes; and, in the case of an email, that such email was sent
                to the email address of the recipient given for these purposes.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>FORCE MAJEURE</h3>
          <ol>
            <li>
              <p>
                We will not be liable or responsible for any failure to perform,
                or delay in performance of, any of our obligations under the
                Agreement that is caused by any act or event beyond our
                reasonable control, including without limitation failure of
                public or private telecommunications networks (
                <strong>“Force Majeure Event”</strong> ).
              </p>
            </li>
            <li>
              <p>
                If a Force Majeure Event takes place that affects the
                performance of our obligations under the Agreement:
              </p>
            </li>
            <li>
              <p>
                our obligations under the Agreement will be suspended and the
                time for performance of our obligations will be extended for the
                duration of the Event Outside Our Control; and
              </p>
            </li>
            <li>
              <p>
                we will use our reasonable endeavours to find a solution by
                which our obligations under the Agreement may be performed
                despite the Event Outside Our Control.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>PRIVACY AND SECURITY</h3>
          <ol>
            <li>
              <p>
                Under data protection legislation, we are required to provide
                you with certain information about who we are, how we process
                the personal data of those individuals who use the Software and
                the Documents and for what purposes and those individuals&#39;
                rights in relation to their personal data and how to exercise
                them. This information is provided in our
                <a
                  href="https://docs.google.com/document/d/1BDp2TtySdw6jae3g9KsN12EObGwF2oYs/edit"
                >
                  Privacy Policy
                </a>
                and it is important that you read it.
              </p>
            </li>
            <li>
              <p>
                We will take appropriate security measures in accordance with
                our data security policy we may provide to you from time to
                time.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <h3>GENERAL</h3>
          <ol>
            <li>
              <p>
                We may transfer our rights and obligations under the Agreement
                to another organisation, but this will not affect your rights or
                our obligations under the Agreement.
              </p>
            </li>
            <li>
              <p>
                You may only transfer your rights or your obligations under the
                Agreement to another person if we agree in writing.
              </p>
            </li>
            <li>
              <p>
                The Agreement constitutes the entire agreement between us and
                supersedes and extinguishes all previous and contemporaneous
                agreements, promises, assurances and understandings between us,
                whether written or oral, relating to its subject matter. In the
                event of any conflict between the provisions of the terms and
                conditions contained herein and the provisions of the Order,
                these terms and conditions shall prevail.
              </p>
            </li>
            <li>
              <p>
                You acknowledge that in entering into the Agreement you do not
                rely on and shall have no remedies in respect of any statement,
                representation, assurance or warranty (whether made innocently
                or negligently) that is not set out in the Agreement.
              </p>
            </li>
            <li>
              <p>
                You agree that you shall have no claim for innocent or negligent
                misrepresentation or negligent misstatement based on any
                statement in the Agreement.
              </p>
            </li>
            <li>
              <p>
                A waiver of any right or remedy is only effective if given in
                writing and shall not be deemed a waiver of any subsequent right
                or remedy.
              </p>
            </li>
            <li>
              <p>
                A delay or failure to exercise, or the single or partial
                exercise of, any right or remedy shall not waive that or any
                other right or remedy, nor shall it prevent or restrict the
                further exercise of that or any other right or remedy.
              </p>
            </li>
            <li>
              <p>
                Each of the provisions of the Agreement operates separately. If
                any court or competent authority decides that any of them are
                unlawful or unenforceable, the remaining provisions will remain
                in full force and effect.
              </p>
            </li>
            <li>
              <p>
                The Agreement, its subject matter, and its formation (and any
                non-contractual disputes or claims) are governed by English law.
                The parties irrevocably agree to the exclusive jurisdiction of
                the courts of English courts.
              </p>
            </li>
          </ol>
        </li>
      </ol>

      <h2 class="text-center underline">AI Terms Addendum</h2>
      <p>
        The following terms (<strong>“AI Terms”</strong>) are hereby added to,
        and become an integral part of, the Agreement. Capitalised terms not
        defined in these AI Terms have the meanings given in the Agreement. The
        Agreement applies to the AI Features with the following modifications.
      </p>
      <ol>
        <li>
          <strong>Use of AI Features.</strong>
          <p>
            You may submit Customer Data (including in the form of prompts or
            queries) to the AI Features (<strong>“Inputs”</strong>) and receive
            outputs from the AI Features (<strong>“Outputs”</strong>).
          </p>
          <p>
            <strong>“AI Features”</strong> means large language models (LLMs) or
            other machine learning or artificial intelligence features of the
            Software.
          </p>
        </li>

        <li>
          <strong>Training :</strong>

          <p>
            We may use Inputs and Outputs to train or otherwise improve the AI
            Features, but only if such Inputs and Outputs have been (a)
            de-identified so that they do not identify you, your users or any
            other person and (b) aggregated with data across other customers.
            For these purposes (and without limiting your other obligations with
            respect to Customer Data generally), such data is provided by you to
            us “AS IS”.
          </p>
        </li>

        <li>
          <strong>Intellectual Property :</strong>
          <ol class="alphabetical">
            <li>
              <strong>A. Inputs</strong>
              <p>
                Except for our express rights in the Agreement, as between you
                and us, you retain all intellectual property and other rights in
                your Inputs.
              </p>
            </li>
            <li>
              <strong>B. Outputs</strong>
              <p>
                Subject to the Agreement (including the restrictions it
                contains), we hereby grant to you a non-exclusive, worldwide,
                perpetual right and licence to reproduce, distribute, publicly
                display, publicly perform, and prepare derivative works of
                Output.
              </p>
            </li>
          </ol>
        </li>

        <li>
          <strong>Similar Output.</strong>
          <p>
            you acknowledge that Outputs provided to you may be similar or
            identical to Outputs independently provided by us to others.
          </p>
        </li>
        <li>
          <strong>Infringement by Output.</strong>
          <p>
            Due to the nature of the AI Features, we do not represent or warrant
            that (a) Output does not incorporate or reflect third-party content
            or materials or (b) Output will not infringe third-party
            intellectual property rights. Claims of intellectual property
            infringement or misappropriation by Output are not included in
            Lexoo-Covered Claims
          </p>
        </li>
        <li>
          <strong>DISCLAIMER.</strong>
          <p>
            OUTPUTS ARE GENERATED THROUGH MACHINE LEARNING PROCESSES AND ARE NOT
            TESTED, VERIFIED, ENDORSED OR GUARANTEED TO BE ACCURATE, COMPLETE OR
            CURRENT. YOU SHOULD INDEPENDENTLY REVIEW AND VERIFY ALL OUTPUTS AS
            TO APPROPRIATENESS FOR ANY OR ALL OF YOUR USE CASES OR APPLICATIONS.
            THE WARRANTY DISCLAIMERS AND LIMITATIONS OF LIABILITY IN THE
            AGREEMENT APPLY TO THE AI FEATURES.
          </p>
        </li>
        <li>
          <strong>Third-Party Providers</strong>
          <p>
            7.1 We have specified in Exhibit A any third parties that provide
            the AI Features.
          </p>
          <p>
            7.2. You agree to abide by any third-party terms and conditions
            relating to the AI Features specified in Exhibit A (
            <strong>“Third-Party Terms” </strong>).
          </p>
        </li>

        <li>
          <strong>Special Restrictions on Use of AI Features.</strong>

          <p>
            8.1. You will comply with the special restrictions on use of the AI
            Features specified in Exhibit A.
          </p>
          <p>
            8.2. Without limiting any restrictions on use of the Software in the
            Agreement, you will not and will not permit anyone else to:
          </p>
          <ol class="alphabetic">
            <li>
              a) use the AI Features or any Output to infringe any third-party
              rights,
            </li>
            <li>
              b) use the AI Features or any Output to develop, train or improve
              any AI or ML models (separate from authorised use of the Cloud
              Service under this Agreement),
            </li>
            <li>c) represent any Output as being approved or vetted by us,</li>
            <li>
              d) represent any Output as being an original work or a wholly
              human-generated work,
            </li>
            <li>
              e) use the AI Features for automated decision-making that has
              legal or similarly significant effects on individuals, unless it
              does so with adequate human review and in compliance with laws, or
            </li>
            <li>
              f) use the AI Features for purposes or with effects that are
              discriminatory, harassing, harmful or unethical.
            </li>
          </ol>
        </li>
      </ol>
      <h2 class="text-center underline">Exhibit A</h2>
      <h2>Third-Party Providers:</h2>
      <p>Open AI, Anthropic (Claude)</p>
      <p>Third-Party Terms:</p>
      <p>
        Open AI Terms:
        <a href="https://openai.com/policies">https://openai.com/policies</a>
      </p>
      <p>
        Anthropic (Claude):
        <a href="https://console.anthropic.com/legal/terms">
          https://console.anthropic.com/legal/terms
        </a>
      </p>
      <h2 class="text-center underline">
        Special Restrictions on Use of AI Features:
      </h2>
      <p>
        You may only use the AI Features (i) to generate your own playbook and
        (ii) for your own legal work. You may not utilise the AI Features to
        generate playbooks for third parties unless a separate licence agreement
        is agreed between you and us covering such use.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
ol {
  counter-reset: section;
  list-style-type: none;
}
ol > li > h3::before {
  counter-increment: section;
  content: counter(section) ". ";
}
ol > li > ol > li > p:first-child::before {
  counter-increment: section;
  content: counters(section, ".") " ";
}
/* Alphabetical list style */
ol.alphabetic {
  list-style-type: none; /* Turn off default numbering */
}
ol.alphabetic > li > p:first-child::before {
  counter-increment: section;
  content: "(" counter(section, lower-alpha) ") ";
}
</style>
