<template>
  <h1 class="mb-2 text-xl font-semibold text-gray-700 dark:text-gray-200">
    {{ text }}
  </h1>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      required: true,
      type: String
    }
  }
});
</script>

<style lang="scss" scoped></style>
