<template>
  <tr class="bg-white align-top">
    <td class="px-6 py-4">
      <input
        type="checkbox"
        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-lexoo"
        :checked="entrySelection.entries.has(entry)"
        @click="entrySelection.toggle(entry)"
      />
    </td>
    <td class="px-2 py-4 align-top">
      <input
        v-if="editMode && editEntry"
        type="text"
        name="heading"
        v-model="modelHeading"
        ref="heading"
        class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
      />
      <span v-else v-html="displayStringWithFilterResult(modelHeading)" />
    </td>
    <td class="space-y-6 px-2 py-4 align-top">
      <div
        class="entry-description"
        :class="{ 'line-clamp-3': !showMore && !editMode }"
        ref="entry"
      >
        <BaseSuperText :readonly="!editMode" v-model="modelDescription" />
      </div>
      <button
        v-if="needsClamp && !editMode"
        @click.prevent="showMore = !showMore"
        class="block text-right text-xs font-medium text-red-500 hover:text-red-800"
      >
        {{ showMore ? "- Less" : "+ More" }}
      </button>
      <span v-if="editMode" class="inline-flex rounded shadow-sm">
        <BaseButton
          position="left"
          @click.prevent="
            $emit('save', editEntry);
            editEntry = null;
            editMode = false;
          "
        >
          Save
        </BaseButton>
        <BaseButton
          position="right"
          @click.prevent="editMode = false"
          buttonStyle="white"
        >
          Cancel
        </BaseButton>
      </span>
    </td>

    <td class="whitespace-normal px-4 py-4 text-right">
      <HeadlessMenu>
        <MenuButton class="rounded-full p-2 text-gray-400 hover:text-gray-600">
          <span class="sr-only">Open options</span>
          <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
        </MenuButton>

        <MenuItems
          class="absolute right-16 z-10 w-60 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none xl:right-36"
        >
          <div class="py-1">
            <MenuItem v-slot="{ active, close }">
              <a
                href="#"
                class="flex px-4 py-2 text-sm"
                :class="[
                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                ]"
                @click.prevent="
                  editMode = true;
                  editEntry = Object.assign({}, entry);
                  close();
                "
              >
                <PencilIcon
                  class="mr-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Edit</span>
              </a>
            </MenuItem>

            <MenuItem v-slot="{ active, close }">
              <a
                href="#"
                @click.prevent="
                  $emit('duplicate');
                  close();
                "
                class="flex px-4 py-2 text-sm"
                :class="[active ? 'bg-gray-100 text-lexoo' : 'text-gray-700']"
              >
                <DocumentDuplicateIcon
                  class="mr-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Duplicate</span>
              </a>
            </MenuItem>

            <MenuItem v-slot="{ active, close }">
              <a
                href="#"
                @click.prevent="
                  $emit('copyEntry', entry);
                  close();
                "
                class="flex px-4 py-2 text-sm"
                :class="[active ? 'bg-gray-100 text-lexoo' : 'text-gray-700']"
              >
                <BookOpenIcon
                  class="mr-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Copy to other playbook</span>
              </a>
            </MenuItem>

            <MenuItem v-slot="{ active, close }">
              <a
                href="#"
                @click.prevent="
                  $emit('editEscalations');
                  close();
                "
                class="flex px-4 py-2 text-sm"
                :class="{
                  'bg-gray-100': active,
                  'text-lexoo': escalationTemplatePresent
                }"
              >
                <ArrowsPointingOutIcon
                  class="mr-3 h-5 w-5 text-gray-400"
                  :class="{
                    'text-lexoo': escalationTemplatePresent
                  }"
                  aria-hidden="true"
                />
                <span
                  :class="{
                    'text-lexoo': escalationTemplatePresent
                  }"
                >
                  Escalations
                </span>
              </a>
            </MenuItem>

            <MenuItem v-slot="{ active, close }">
              <a
                href="#"
                @click.prevent="
                  $emit('delete', entry);
                  close();
                "
                class="flex px-4 py-2 text-sm"
                :class="[active ? 'lexoo bg-gray-100' : 'text-gray-700']"
              >
                <XMarkIcon
                  class="mr-3 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span>Delete</span>
              </a>
            </MenuItem>
          </div>
        </MenuItems>
      </HeadlessMenu>
    </td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType, ref } from "vue";
import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import {
  ArrowsPointingOutIcon,
  BookOpenIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  PencilIcon,
  XMarkIcon
} from "@heroicons/vue/24/solid";
import { Entry } from "@/types";
import { useAdmin } from "@/components/shared/Admin";
import BaseSuperText from "@/components/base/BaseSuperText.vue";
import { displayStringWithMarkedText } from "@/helpers/TextHelpers";

export default defineComponent({
  setup(props) {
    const { client } = useAdmin();
    const modelDescription = ref(props.entry.entryDescription);
    const modelHeading = ref(props.entry.heading);
    return { client, modelDescription, modelHeading };
  },

  components: {
    ArrowsPointingOutIcon,
    BaseSuperText,
    BookOpenIcon,
    EllipsisVerticalIcon,
    DocumentDuplicateIcon,
    HeadlessMenu,
    MenuButton,
    MenuItem,
    MenuItems,
    PencilIcon,
    XMarkIcon
  },

  props: {
    entry: {
      required: true,
      type: Object as PropType<Entry>
    },

    entrySelection: {
      required: true,
      type: Object
    },

    filterText: {
      required: true,
      type: String
    }
  },

  data() {
    return {
      editEntry: null as Entry | null,
      editMode: false,
      needsClamp: false,
      showMore: false
    };
  },

  mounted() {
    nextTick(() => {
      type Refs = {
        entry: HTMLElement;
      };
      const elm = (this.$refs as Refs).entry;
      if (elm) {
        this.needsClamp = elm.scrollHeight > elm.clientHeight;
      }
    });
  },

  methods: {
    displayStringWithFilterResult(value: string | null | undefined) {
      if (!value) {
        return "";
      }
      return displayStringWithMarkedText(value, this.filterText);
    }
  },

  computed: {
    escalationTemplatePresent() {
      return (
        this.entry.escalationTemplate &&
        this.entry.escalationTemplate.text &&
        this.entry.escalationTemplate.text.length > 0
      );
    }
  }
});
</script>

<style scoped></style>
