<template>
  <div class="max-w-7xl sm:p-6 lg:max-w-2xl lg:p-8">
    <LoadingIcon class="mx-auto h-5 w-5" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingIcon from "@/assets/images/LoadingIcon";

export default defineComponent({
  components: { LoadingIcon }
});
</script>

<style scoped></style>
