import { defineStore } from "pinia";
import { Alert } from "@/types";

export const useAlertStore = defineStore("AlertStore", {
  state() {
    return {
      alert: {} as Alert
    };
  },

  actions: {
    addAlert(alert: Alert) {
      this.alert = alert;
    },

    clearAlert() {
      this.alert = {} as Alert;
    }
  }
});
