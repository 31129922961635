<template>
  <editor-content :editor="editor" />
</template>

<script setup lang="ts">
// Icons come from https://remiXMarkIcon.com/
import tippy from "tippy.js";
import { computed, onMounted, PropType, ref, Ref, watch } from "vue";
import { Editor, EditorContent, VueRenderer } from "@tiptap/vue-3";
import { User } from "@/types";
import Document from "@tiptap/extension-document";
import HardBreak from "@tiptap/extension-hard-break";
import Mention from "@tiptap/extension-mention";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Text from "@tiptap/extension-text";

import MentionList from "@/components/base/comments/MentionList.vue";

const emit = defineEmits(["update:content", "update:mentioned", "saveComment"]);

const editor = ref(null) as Ref<Editor | null>;
const rawHtml = ref("");
const suggestionDisplayed = ref(false);

const props = defineProps({
  commenters: {
    type: Array as PropType<User[]>,
    required: true
  },

  content: {
    type: String,
    default: ""
  }
});

const mentioned = computed((): User[] => {
  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(rawHtml.value, "text/html");
  const mentionElements = htmlDoc.querySelectorAll("[data-type=mention]");
  const commenterIds = Array.from(mentionElements).map((el) =>
    el.getAttribute("data-id")
  );

  return props.commenters.filter((commenter) =>
    commenterIds.includes(commenter.id.toString())
  );
});

onMounted(() => {
  editor.value = new Editor({
    editorProps: {
      attributes: {
        class: "prose prose-sm block w-full text-xs"
      }
    },

    onUpdate: ({ editor }) => {
      rawHtml.value = editor.getHTML();
      emit("update:content", rawHtml.value);
    },

    extensions: [
      Document,
      HardBreak,
      Paragraph,
      Placeholder.configure({
        placeholder: "Comment about this entry"
      }),
      Text,
      Mention.configure({
        HTMLAttributes: {
          class: "bg-indigo-100 text-indigo-500 p-1 rounded"
        },

        submit: () => {
          emit("saveComment", props.content);
        },

        suggestionDisplayed: () => {
          return suggestionDisplayed.value;
        },

        suggestion: {
          items: (response) => {
            return props.commenters.filter(
              (commenter) =>
                commenter.name
                  .toLowerCase()
                  .startsWith(response.query.toLowerCase()) &&
                !mentioned.value.includes(commenter)
            );
          },

          render: () => {
            let component: VueRenderer;
            /* eslint-disable @typescript-eslint/no-this-alias */
            let popup: typeof tippy;

            return {
              onStart: (props) => {
                component = new VueRenderer(MentionList, {
                  editor: editor.value as Editor,
                  props
                });
                suggestionDisplayed.value = true;

                popup = tippy("body", {
                  getReferenceClientRect: props.clientRect,
                  appendTo: () => document.body,
                  content: component.element,
                  showOnCreate: true,
                  interactive: true,
                  trigger: "manual",
                  placement: "bottom-start"
                });
              },

              onUpdate(props) {
                component.updateProps(props);

                popup[0].setProps({
                  getReferenceClientRect: props.clientRect
                });
              },

              onKeyDown(props) {
                if (editor.value) {
                  const componentRef =
                    editor.value.contentComponent?.refs[component.id];
                  return componentRef?.onKeyDown(props);
                }
              },

              onExit() {
                suggestionDisplayed.value = false;

                popup[0].destroy();
                component.destroy();
              }
            };
          }
        }
      })
    ],
    content: props.content
  });
});

const focus = () => {
  if (editor.value) {
    editor.value.chain().focus();
  }
};

// Focus is used externally
defineExpose({
  focus
});

watch(
  () => props.content,
  (value) => {
    if (editor.value) {
      const isSame = editor.value.getHTML() === value;

      if (isSame) {
        return;
      }
      editor.value.commands.setContent(value, false);
    }
  }
);
watch(mentioned, (value) => {
  emit("update:mentioned", value);
});
</script>

<style lang="scss" scoped>
:deep(.ProseMirror) {
  @apply block cursor-text rounded border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm;
}

:deep(.ProseMirror.prose) {
  max-width: 100% !important;
}

:deep(.ProseMirror p.is-editor-empty:first-child::before) {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
  width: 100%;
}
</style>
