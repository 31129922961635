// toolbarVisibilityStore.ts
import { defineStore } from "pinia";

export const useToolbarVisibilityStore = defineStore("ToolbarVisibilityStore", {
  state: () => ({
    currentlyOpen: null as string | null
  }),

  actions: {
    openToolbar(id: string) {
      this.currentlyOpen = id;
    },

    isToolbarOpen(id: string) {
      return this.currentlyOpen === id;
    }
  }
});
