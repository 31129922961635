<template>
  <node-view-wrapper>
    <div
      class="relative mt-2 h-2 w-full cursor-text"
      @click.prevent="addParagraphAtTheTop"
    />
    <div
      class="relative rounded-sm border-b border-gray-200 bg-gray-50 p-2 shadow"
    >
      <div class="flex items-start justify-between space-x-3">
        <h3 class="mt-0 text-sm text-gray-500">Template comment</h3>
        <div class="flex items-center">
          <button
            type="button"
            class="delete-button text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
            @click.prevent="deleteNode"
            tabindex="-1"
          >
            <span class="sr-only">Close</span>
            <XMarkIcon class="h-3 w-3" aria-hidden="true" />
          </button>
        </div>
      </div>
      <node-view-content
        class="mt-2 mb-6 whitespace-pre-wrap text-sm text-gray-900 outline-0"
      />
      <button
        v-if="shouldCopyComment"
        type="button"
        @click.prevent="copyTemplateCommentToClipboard"
        class="absolute bottom-0 right-0 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
      >
        {{ copyButtonText }}
      </button>
      <button
        v-else
        @click.prevent="insertTemplateCommentIntoWordAsComment"
        type="button"
        class="absolute bottom-0 right-0 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
      >
        Insert into Word as comment
      </button>
    </div>
    <div
      class="relative h-2 w-full cursor-text"
      @click.prevent="addParagraphAtTheBottom"
    />
  </node-view-wrapper>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { nodeViewProps, NodeViewContent, NodeViewWrapper } from "@tiptap/vue-3";
import ApiService from "@/services/ApiService";
import { generateHTML } from "@tiptap/html";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { removeTags } from "@/helpers/TextHelpers";
import { useSidebarStore } from "@/store/SidebarStore";
import { useSelectionStore } from "@/store/SelectionStore";
import { NewInteraction } from "@/types";

const sidebarStore = useSidebarStore();
const shouldCopyComment = computed(() => !sidebarStore.withinWord);
const copyButtonText = ref("Copy");

const props = defineProps(nodeViewProps);

const extensions = computed(() => {
  return props.editor.extensionManager.extensions.filter(
    (extension) => extension.name !== "templateComment"
  );
});

const nodeAsHtml = computed(() => {
  let json = props.node.toJSON();
  json = {
    type: "paragraph",
    content: json.content
  };
  return generateHTML(json, extensions.value);
});

const deleteNode = () => {
  props.editor.commands.deleteNode("templateComment");
};

const templateCommentWithVariables = computed(() => {
  return sidebarStore.textWithVariables(selectedText.value || nodeAsHtml.value);
});

const insertTemplateCommentIntoWordAsComment = () => {
  Word.run(async (context: Word.RequestContext) => {
    const document = context.document;
    const range = document.getSelection();
    const textToInsert = templateCommentWithVariables.value;
    range.insertComment(removeTags(textToInsert));
    await context.sync();
    trackInsertion(textToInsert);
  }).catch((error: OfficeExtension.Error) => {
    console.log("Error: " + JSON.stringify(error));
    console.log("Debug info: " + JSON.stringify(error.debugInfo));
  });
};

const addParagraphAtTheTop = () => {
  const editor = props.editor;
  if (editor.isEditable) {
    const startPos = props.getPos();
    props.editor.commands.focus("start");

    props.editor
      .chain()
      .insertContentAt(startPos, { type: "paragraph" })
      .focus(startPos)
      .run();
  }
};

const addParagraphAtTheBottom = () => {
  const editor = props.editor;
  if (editor.isEditable) {
    const endPos = props.getPos() + props.node.nodeSize;
    props.editor.commands.focus("start");

    props.editor
      .chain()
      .insertContentAt(endPos, { type: "paragraph" })
      .focus(endPos)
      .run();
  }
};

const copyTemplateCommentToClipboard = () => {
  const textToCopy = templateCommentWithVariables.value;

  const clipboardItem = new ClipboardItem({
    "text/html": new Blob([textToCopy], { type: "text/html" }),
    "text/plain": new Blob([removeTags(textToCopy)], { type: "text/plain" })
  });
  copyButtonText.value = "Copied!";
  navigator.clipboard.write([clipboardItem]).then(
    (_) => {
      setTimeout(() => {
        copyButtonText.value = "Copy";
      }, 1000);
    },
    (error) => alert(error)
  );
  trackInsertion(textToCopy);
};

const trackInsertion = (text: string) => {
  let partialInsert = false;
  if (selectedText.value && selectedText.value.length > 0) {
    partialInsert = true;
  }
  const extraInfo: {
    snippet: string;
    partial_insert: boolean;
    word_doc_id?: string;
  } = {
    partial_insert: partialInsert,
    snippet: text
  };
  if (sidebarStore.playbookId) {
    const playbookInstance = sidebarStore.playbookInstance;
    if (playbookInstance) {
      extraInfo.word_doc_id = playbookInstance.wordDocId ?? undefined;
    }
    const interaction: NewInteraction = {
      action: "inserted template comment",
      extraInfo,
      playbookId: sidebarStore.playbookId
    };
    ApiService.trackInteraction(interaction);
  }
};

const selectionStore = useSelectionStore();

const selectedNode = computed(() => {
  return selectionStore.selectedNode;
});

const selectedText = computed(() => {
  if (!thisNodeSelected.value) return null;
  return selectionStore.selectedText;
});

const thisNodeSelected = computed(() => {
  if (!selectedNode.value || !selectedNode.value.parentElement) {
    return false;
  }
  if (!nodeAsHtml.value) {
    return false;
  }
  return nodeAsHtml.value.match(String(selectedNode.value));
});
</script>

<style lang="scss"></style>
