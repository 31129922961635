<template>
  <LoadingPanel v-if="modelValue.partiallyLoaded" />
  <template v-if="editMode">
    <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 rounded bg-gray-50 p-4">
      <div>
        <label for="heading" class="block text-sm font-medium text-gray-700">
          Heading
        </label>

        <div class="mt-1">
          <input
            type="text"
            name="heading"
            ref="heading"
            v-model="model.heading"
            class="block w-full rounded border-gray-300 text-xs shadow-sm focus:border-indigo-500 focus:ring-lexoo"
          />
        </div>
      </div>

      <div>
        <BaseEditableText
          label="Entry description"
          class="mt-1 min-w-full text-xs"
          v-model="model.entryDescription"
          :variables="variables"
        />
      </div>

      <Disclosure
        v-if="
          !blankDescription ||
          !blankTemplateWording ||
          !blankTemplateComment ||
          !blankThirdParty ||
          editMode
        "
        :defaultOpen="editMode"
        v-slot="{ open }"
        class="mb-2"
        as="div"
      >
        <DisclosureButton
          class="flex w-full justify-between bg-gray-200 py-2 py-2 px-4 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
          :class="open ? 'rounded-t-lg' : 'rounded-lg'"
        >
          Preferred position
          <ChevronUpIcon
            :class="open ? 'rotate-180 transform' : ''"
            class="h-5 w-5 text-gray-500"
          />
        </DisclosureButton>
        <DisclosurePanel
          v-if="(modelValue && (modelValue as Entry).id) || newRecord"
          class="grid grid-cols-1 gap-y-6 rounded-b-lg border p-3"
        >
          <div>
            <BaseEditableText
              label="Description"
              class="mt-1 min-w-full text-xs"
              v-model="model.description"
            />
          </div>
          <div>
            <SimpleEditableText
              label="Template wording"
              class="mt-1 min-w-full text-xs"
              v-model="model.templateWording"
            />
          </div>

          <div>
            <SimpleEditableText
              label="Template comment"
              class="mt-1 min-w-full text-xs"
              v-model="model.templateComment"
            />
          </div>
          <ThirdPartySection
            v-if="showThirdParty && playbook && playbookInstance"
            :entry="model"
            :filterText="filterText"
            :playbook="playbook"
            :playbookInstance="playbookInstance"
          />
        </DisclosurePanel>
      </Disclosure>

      <!-- Tags -->
      <div>
        <label class="block text-sm font-medium text-gray-700">Tags</label>
        <div class="mt-1">
          <TagManager
            v-if="playbook"
            v-model="model.tags"
            :selectableTags="usedTags"
            :playbookId="playbook.id"
          />
        </div>
      </div>
    </div>
  </template>
  <template v-if="!editMode">
    <InlineEditableText
      :entryId="(modelValue as Entry).id"
      :filterText="filterText ?? ''"
      :playbookInstance="playbookInstance"
      :text="model.entryDescription"
      @update="updateEntryDescription"
    />
    <Disclosure
      v-if="
        !blankTemplateWording ||
        !blankTemplateComment ||
        !blankThirdParty ||
        !blankDescription
      "
      v-slot="{ open }"
      class="my-2"
      as="div"
    >
      <DisclosureButton
        class="flex w-full justify-between bg-gray-100 py-2 py-2 px-4 text-left text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75"
        :class="open ? 'rounded-t-lg' : 'rounded-lg'"
      >
        Preferred position
        <ChevronUpIcon
          :class="open ? 'rotate-180 transform' : ''"
          class="h-5 w-5 text-gray-500"
        />
      </DisclosureButton>
      <DisclosurePanel
        v-if="model && (model as Entry).id"
        class="rounded-b-lg border p-3"
      >
        <InlineEditableText
          v-if="!blankDescription"
          :entryId="(model as Entry).id"
          :filterText="filterText ?? ''"
          :playbookInstance="playbookInstance"
          :text="model.description"
          @update="updateDescription"
        />
        <InlineEditableTemplateWording
          v-if="!blankTemplateWording"
          :entryId="(model as Entry).id"
          :filterText="filterText ?? ''"
          :playbookInstance="playbookInstance"
          :showLabel="true"
          :text="model.templateWording"
          @update="updateTemplateWording"
        />
        <InlineEditableTemplateComment
          v-if="!blankTemplateComment"
          :entryId="(model as Entry).id"
          :filterText="filterText ?? ''"
          :playbookInstance="playbookInstance"
          :showLabel="true"
          :text="model.templateComment"
          @update="updateTemplateComment"
        />
        <ThirdPartySection
          v-if="showThirdParty && playbook && playbookInstance"
          :entry="model"
          :filterText="filterText"
          :playbook="playbook"
          :playbookInstance="playbookInstance"
        />
      </DisclosurePanel>
    </Disclosure>
  </template>

  <FallbacksSection
    :entry="model"
    :filterText="filterText"
    :playbook="playbook"
    :playbookInstance="playbookInstance"
  />
  <EscalationsSection v-if="escalations" :escalations="escalations" />
  <template v-if="!editMode">
    <p
      v-if="model && model.tags && model.tags.length > 0"
      class="mt-4 space-x-2"
    >
      <BaseTag v-for="tag in model.tags" :key="tag.id" :tag="tag" />
    </p>

    <CommentList :entry="model as Entry" />
  </template>
</template>

<script setup lang="ts">
import { computed, onMounted, nextTick, PropType, ref, watch } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/24/outline";

import BaseEditableText from "@/components/base/BaseEditableText.vue";
import BaseTag from "@/components/base/BaseTag.vue";
import CommentList from "@/components/CommentList.vue";
import EscalationsSection from "@/components/sidebar/EscalationsSection.vue";
import FallbacksSection from "@/components/sidebar/FallbacksSection.vue";
import InlineEditableTemplateComment from "@/components/sidebar/InlineEditableTemplateComment.vue";
import InlineEditableText from "@/components/sidebar/InlineEditableText.vue";
import InlineEditableTemplateWording from "@/components/sidebar/InlineEditableTemplateWording.vue";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";
import TagManager from "@/components/TagManager.vue";
import ThirdPartySection from "@/components/sidebar/ThirdPartySection.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";

import ApiService from "@/services/ApiService";
import {
  Entry,
  Escalation,
  NewEntry,
  Playbook,
  PlaybookInstance
} from "@/types";
import { blankText } from "@/helpers/TextHelpers";
import { useSidebarStore } from "@/store/SidebarStore";

const props = defineProps({
  modelValue: {
    type: Object as PropType<Entry | NewEntry>,
    required: true
  },

  playbook: {
    type: Object as PropType<Playbook>,
    required: false
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: false
  },

  filterText: {
    type: String,
    required: false
  }
});

const sidebarStore = useSidebarStore();
const usedTags = sidebarStore.usedTags;

const emit = defineEmits(["update:modelValue"]);

const model = ref(props.modelValue);
const heading = ref(null);

const blankTemplateWording = computed((): boolean => {
  if (!props.modelValue.templateWording) {
    return true;
  }
  return blankText(props.modelValue.templateWording);
});

const blankTemplateComment = computed((): boolean => {
  if (!props.modelValue.templateComment) {
    return true;
  }
  return blankText(props.modelValue.templateComment);
});

const blankDescription = computed((): boolean => {
  if (!props.modelValue.description) {
    return true;
  }
  return blankText(props.modelValue.description);
});

const blankThirdParty = computed((): boolean => {
  const blankThirdPartyDescription = blankText(
    props.modelValue.thirdPartyDescription
  );
  const blankThirdPartyTemplateWording = blankText(
    props.modelValue.thirdPartyTemplateWording
  );
  const blankThirdPartyTemplateComment = blankText(
    props.modelValue.thirdPartyTemplateComment
  );
  return (
    blankThirdPartyDescription &&
    blankThirdPartyTemplateWording &&
    blankThirdPartyTemplateComment
  );
});

const editMode = computed(() => {
  if (newRecord.value) {
    return true;
  }
  const store = useSidebarStore();
  return store.entryEdited(props.modelValue as Entry);
});

const escalations = computed((): Escalation[] => {
  return props.modelValue.escalations ?? [];
});

const newRecord = computed((): boolean => {
  return !(props.modelValue as Entry)?.id;
});

const showThirdParty = computed(() => {
  if (
    (props.modelValue.thirdPartyDescription &&
      props.modelValue.thirdPartyDescription.length > 0) ||
    (props.modelValue.thirdPartyTemplateWording &&
      props.modelValue.thirdPartyTemplateWording.length > 0) ||
    (props.modelValue.thirdPartyTemplateComment &&
      props.modelValue.thirdPartyTemplateComment.length > 0)
  ) {
    return true;
  }
  return false;
});

const variables = computed(() => {
  return sidebarStore.variables;
});

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue !== model.value) {
      model.value = newValue;
    }
  },
  { deep: true }
);

watch(
  model,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { deep: true }
);

onMounted(() => {
  if (
    props.modelValue &&
    props.modelValue.partiallyLoaded &&
    !newRecord.value
  ) {
    ApiService.getEntry((props.modelValue as Entry).id).then(({ data }) => {
      const store = useSidebarStore();
      store.replaceEntry(data.entry);
      emit("update:modelValue", data.entry);
    });
  }
  if (newRecord.value) {
    nextTick(() => {
      if (heading.value) {
        heading.value.focus();
      }
    });
  }
});

const updateDescription = (description: string) => {
  const store = useSidebarStore();
  const entry = { ...props.modelValue, description };
  store.updateEntry(entry as Entry);
  emit("update:modelValue", entry);
};

const updateEntryDescription = (entryDescription: string) => {
  const store = useSidebarStore();
  const entry = { ...props.modelValue, entryDescription };
  store.updateEntry(entry as Entry);
  emit("update:modelValue", entry);
};

const updateTemplateComment = (templateComment: string) => {
  const store = useSidebarStore();
  const entry = { ...props.modelValue, templateComment };
  store.updateEntry(entry as Entry);
  emit("update:modelValue", entry);
};

const updateTemplateWording = (templateWording: string) => {
  const store = useSidebarStore();
  const entry = { ...props.modelValue, templateWording };
  store.updateEntry(entry as Entry);
  emit("update:modelValue", entry);
};
</script>
