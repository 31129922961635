<template>
  <BaseTitle text="Clients" />
  <p class="mb-4 text-sm text-gray-500">
    Here you can manage your clients. You can make a client self serve, which
    means they signed up by themselves, and the Lexoo team don't need access to
    their playbook.
  </p>
  <p class="mb-4 text-sm text-gray-500">
    If you want to view a client's playbooks you can temporarily undo self serve
    (just remember to put it back on).
  </p>
  <BaseButton
    class="float-right mb-2 -mt-4"
    label="Create a new client"
    buttonStyle="white"
    @click="showNewClientModal = true"
    size="small"
  />

  <table class="min-w-full divide-y divide-gray-200">
    <thead class="bg-gray-50">
      <tr>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Client
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Created on
        </th>
        <th
          scope="col"
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        ></th>
      </tr>
    </thead>
    <tbody v-if="loading">
      <tr>
        <td colspan="100"><LoadingPanel /></td>
      </tr>
    </tbody>
    <tbody v-else class="divide-y divide-gray-200 bg-white">
      <tr v-for="client in clients" :key="client.id">
        <td
          class="group flex items-center whitespace-nowrap px-6 py-4 px-3 py-2 align-top text-sm font-medium text-gray-600"
        >
          <span
            :class="[bgColourClass(client), 'mr-2 h-2.5 w-2.5 rounded-full']"
            aria-hidden="true"
          />
          <span class="truncate">
            {{ client.name }}
          </span>
        </td>
        <td class="whitespace-nowrap px-6 py-4 align-top text-sm text-gray-500">
          <time>
            {{ dateTimeFormat(client.createdAt) }}
          </time>
        </td>
        <td>
          <BaseButton
            v-if="client.selfServe"
            @action="undoSelfServe(client)"
            buttonStyle="link"
          >
            Undo self serve
          </BaseButton>
          <BaseButton
            v-else
            buttonStyle="white"
            @action="makeSelfServe(client)"
          >
            Make self serve
          </BaseButton>
        </td>
      </tr>
    </tbody>
  </table>
  <NewClientModal
    :show="showNewClientModal"
    @close="showNewClientModal = false"
  />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import BaseTitle from "@/components/BaseTitle.vue";
import { Client } from "@/types";
import { dateTimeFormat } from "@/helpers/DateHelpers";
import ApiService from "@/services/ApiService";
import { useAdminStore } from "@/store/AdminStore";
import LoadingPanel from "@/components/LoadingPanel.vue";
import NewClientModal from "@/views/admin/NewClientModal.vue";

const { loadClients } = useAdminStore();

const bgColourClass = (client: Client | null) => {
  if (!client || !client.colour) {
    return "bg-indigo-500";
  } else {
    return `bg-${client.colour}-500`;
  }
};

const clients = ref<Client[]>([]);
const loading = ref(true);
const showNewClientModal = ref(false);

const makeSelfServe = (client: Client) => {
  client.selfServe = true;
  useAdminStore().updateClient(client);
  ApiService.updateClient(client);
};

const undoSelfServe = (client: Client) => {
  client.selfServe = false;
  useAdminStore().updateClient(client);
  ApiService.updateClient(client);
};

onMounted(async () => {
  await loadClients();
  clients.value = useAdminStore().clients;
  loading.value = false;
});
</script>
