<script setup lang="ts">
import { computed, onMounted, ref, Ref } from "vue";
import { Entry, Fallback, FallbackScenario, Interaction } from "@/types";
import BaseTitle from "@/components/BaseTitle.vue";
import LoadingPanel from "@/components/LoadingPanel.vue";
import ApiService from "@/services/ApiService";
import { useUserStore } from "@/store/UserStore";

const interactions = ref<Interaction[]>([]);
const filterDate = ref("");
const loading = ref(true);
const entryId = ref("");
const playbookId = ref("");
const userId = ref(null) as Ref<string | null>;
const userStore = useUserStore();

onMounted(() => {
  ApiService.getInteractions().then(({ data }) => {
    interactions.value = data.interactions;
    loading.value = false;
  });

  userStore.loadUsers();
});

const users = computed(() => {
  return userStore.users;
});

const userOptions = computed(() => {
  const options = [
    {
      name: "Show all users",
      value: "All"
    }
  ];

  users.value.forEach((user) => {
    options.push({
      name: user.name || user.email,
      value: user.id.toString()
    });
  });
  return options;
});

const filteredInteractions = computed(() => {
  return interactions.value.filter((interaction) => {
    const applicable =
      interaction.action.includes("update") &&
      interaction.extraInfo.changes &&
      Object.keys(interaction.extraInfo.changes).length > 0 &&
      (interaction.action.includes("fallback scenario") ||
        interaction.action.includes("fallback") ||
        interaction.action.includes("entry"));
    if (!applicable) {
      return false;
    }

    let returnResult = true;
    if (
      playbookId.value &&
      interaction.playbookId &&
      playbookId.value.length > 0
    ) {
      returnResult =
        returnResult &&
        interaction.playbookId.toString() == playbookId.value.toString();
    }

    if (userId.value && userId.value != "All") {
      returnResult = Boolean(
        returnResult &&
          interaction.userId &&
          interaction.userId.toString() == userId.value.toString()
      );
    }

    if (filterDate.value) {
      const createdAt = interaction.createdAt;
      const filterDateAsDate = new Date(filterDate.value + " 10:00");
      returnResult =
        returnResult &&
        createdAt.getFullYear() === filterDateAsDate.getFullYear() &&
        createdAt.getMonth() === filterDateAsDate.getMonth() &&
        createdAt.getDate() === filterDateAsDate.getDate();
    }

    if (entryId.value && entryId.value.length > 0) {
      returnResult = Boolean(
        returnResult &&
          interaction.entryId &&
          interaction.entryId.toString() == entryId.value.toString()
      );
    }
    return returnResult;
  });
});

const getObjectType = (action: string): string => {
  if (action.includes("fallback scenario")) {
    return "Fallback Scenario";
  } else if (action.includes("fallback")) {
    return "Fallback";
  } else if (action.includes("entry")) {
    return "Entry";
  }
  return "Unknown";
};

const getObjectId = (interaction: Interaction) => {
  const objectType = getObjectType(interaction.action);
  if (objectType === "Fallback Scenario") {
    return interaction.extraInfo["fallbackScenarioId"];
  } else if (objectType === "Fallback") {
    return interaction.fallbackId;
  } else if (objectType === "Entry") {
    return interaction.entryId;
  }
  return "Unknown";
};

const rollback = async (interaction: Interaction) => {
  const changes = {};
  for (const key in interaction.extraInfo.changes) {
    changes[key] = interaction.extraInfo.changes[key][1];
  }
  changes["id"] = getObjectId(interaction);
  changes["updatedAt"] = new Date().toISOString();
  const objectType = getObjectType(interaction.action);

  if (objectType === "Fallback Scenario") {
    changes["entryId"] = interaction.entryId;
    await ApiService.updateFallbackScenario(changes as FallbackScenario);
  } else if (objectType === "Fallback") {
    await ApiService.updateFallback(changes as Fallback);
  } else if (objectType === "Entry") {
    await ApiService.updateEntry(changes as Entry);
  }
  ApiService.getInteractions().then(({ data }) => {
    interactions.value = data.interactions;
  });
};
</script>

<template>
  <BaseTitle text="Audit table" />
  <div class="my-6 gap-y-1 gap-x-1">
    <BaseSelect
      v-model="userId"
      :options="userOptions"
      placeholder="Filter by user"
    />
    <BaseInput v-model="playbookId" placeholder="Playbook id" class="w-32" />
    <BaseInput v-model="entryId" placeholder="Entry id" class="w-32" />
    <BaseInput inputType="date" v-model="filterDate" class="w-40" />
  </div>
  <table class="min-w-full divide-y divide-gray-300">
    <thead class="bg-gray-50">
      <tr>
        <th
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Playbook id
        </th>
        <th
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          User id
        </th>
        <th
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Object Type
        </th>
        <th
          class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
        >
          Changed to
        </th>
      </tr>
    </thead>
    <tbody v-if="loading">
      <tr>
        <td colspan="100"><LoadingPanel /></td>
      </tr>
    </tbody>
    <tbody v-else class="divide-y divide-gray-200">
      <tr v-for="item in filteredInteractions" :key="item.id" class="text-sm">
        <td
          class="whitespace-nowrap py-4 px-3 text-center text-sm text-gray-500"
        >
          {{ item.playbookId }}
        </td>
        <td
          class="whitespace-nowrap py-4 px-3 text-center text-sm text-gray-500"
        >
          {{ item.userId }}
        </td>

        <td
          class="whitespace-nowrap py-4 px-3 text-center text-sm text-gray-500"
        >
          {{ getObjectType(item.action) }} {{ getObjectId(item) }}
        </td>
        <td class="py-4 px-3 text-sm text-gray-500">
          <template v-if="item.extraInfo.changes">
            <div v-for="key in Object.keys(item.extraInfo.changes)" :key="key">
              <span class="text-gray-800">{{ key }}: </span>

              <span>{{ item.extraInfo.changes[key][1] }}</span>
            </div>
            <BaseButton
              v-if="getObjectId(item)"
              buttonStyle="white"
              size="small"
              @click="rollback(item)"
              class="mt-2"
            >
              Revert to this version
            </BaseButton>
          </template>
        </td>
      </tr>
    </tbody>
  </table>
</template>
