<template>
  <TransitionRoot as="template" :show="show">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block min-h-full w-full max-w-2xl transform overflow-hidden rounded-lg bg-white px-0 py-2 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="ml-4 mt-3 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Import entries from Google Drive
                </DialogTitle>
                <ImportFromDriveHelperText
                  v-if="!importing"
                  :step="step"
                  @setStep="step = $event"
                  @lastStep="showControls = true"
                  @otherStep="showControls = false"
                >
                  <div class="mr-2 mt-2">
                    <p class="my-4 text-sm text-gray-600">
                      Ok, are you ready to import some entries?
                    </p>
                    <BaseInput
                      v-model="drivePath"
                      label="Enter the URL of Google Spreadsheet file you'd like to import"
                      placeholder="e.g. https://docs.google.com/spreadsheets/d/big-long-letter-number"
                      :error="error"
                    />
                  </div>
                </ImportFromDriveHelperText>
                <template v-else>
                  <h2 class="mt-6 leading-6">{{ progress }}</h2>
                  <LoadingIcon class="mx-auto h-5 w-5" />
                </template>
              </div>
            </div>
            <div class="mt-5 flex justify-end space-x-3 px-6 sm:mt-4">
              <BaseButton buttonStyle="white" @click="$emit('close')">
                Cancel
              </BaseButton>
              <BaseButton
                v-if="showControls"
                buttonStyle="white"
                @click="importEntries"
                :disabled="importing"
              >
                {{ importingButtonLabel }}
              </BaseButton>
              <BaseButton v-else buttonStyle="white" @click="step += 1">
                Next
                <ChevronRightIcon class="h-4 w-4" />
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import {
  computed,
  getCurrentInstance,
  onMounted,
  onUnmounted,
  PropType,
  ref
} from "vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { ChevronRightIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Playbook } from "@/types";

import ApiService from "@/services/ApiService";
import DriveService from "@/services/DriveService";
import LoadingIcon from "@/assets/images/LoadingIcon";

import ImportFromDriveHelperText from "@/components/admin/ImportFromDriveHelperText.vue";
import { createCable } from "@/services/ActionCableService";
const cable = createCable();

const props = defineProps({
  show: {
    required: true,
    type: Boolean
  },

  playbook: {
    required: true,
    type: Object as PropType<Playbook>
  }
});

const emits = defineEmits(["close", "updated"]);

const drivePath = ref("");
const error = ref("");
const importing = ref(false);
const progress = ref("Starting import");
const showControls = ref(false);
const step = ref(1);

const myChannelHandlers = {
  connected: () => console.log("channel connected"),
  rejected: () => console.log("channel rejected"),
  received: (receivedMessage: Record<string, unknown>) => {
    progress.value = receivedMessage.toString();

    if (progress.value === "Completed") {
      importing.value = false;
      progress.value = "";
      emits("updated");
      emits("close");
    }

    if (progress.value === "Error") {
      importing.value = false;
    }
  },
  disconnected: () => console.log("channel disconnected")
};

const instance = getCurrentInstance();
const channelName = "ApplicationCable::EntryChannel";

const subscribeChannel = (channelHandlers: typeof myChannelHandlers) => {
  if (instance) {
    cable.subscriptions.create({ channel: channelName }, channelHandlers);
  }
};

const importingButtonLabel = computed(() => {
  if (importing.value) {
    return "Importing...";
  }
  return "Import";
});

const handleError = (statusCode: string) => {
  if (statusCode === "FAILED_PRECONDITION") {
    error.value =
      "The file isn't in the right format. Perhaps it's an .xlsx file instead of a plain Google Sheets? In which case you can convert it within Google Sheets by clicking File > Save as Google Sheets.";
  } else if (statusCode == "urlFormat") {
    error.value =
      "The url has to be in the format like https://docs.google.com/spreadsheets/d/big-long-letter-number";
  }
};

const importEntries = () => {
  subscribeChannel(myChannelHandlers);
  error.value = "";
  const folderId = DriveService.getFileIdFromUrl(drivePath.value);
  if (folderId) {
    importing.value = true;
    ApiService.importEntriesFromGoogleDrive(props.playbook, folderId);
  } else {
    handleError("urlFormat");
  }
};

onMounted(() => {
  cable.subscribe({
    channel: channelName
  });
});

onUnmounted(() => {
  cable.unsubscribe("ApplicationCable::EntryChannel");
});
</script>

<style scoped></style>
