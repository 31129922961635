<template>
  <nav class="mb-4 flex" aria-label="Breadcrumb" v-if="playbook">
    <ol role="list" class="flex max-w-full items-center space-x-1">
      <li>
        <div>
          <router-link
            to="/sidebar/playbooks"
            class="text-xs text-gray-400 hover:text-gray-500"
          >
            Playbooks
          </router-link>
        </div>
      </li>
      <li>
        <div class="flex items-center">
          <ChevronRightIcon
            class="mt-1 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />

          <router-link
            v-if="currentStep"
            :to="`/sidebar/playbooks/${playbook.id}`"
            class="ml-2 mt-1 max-w-[18em] overflow-x-hidden text-ellipsis whitespace-nowrap text-xs text-gray-500 hover:text-gray-700"
          >
            {{ playbook.name }}
          </router-link>
          <span
            class="ml-2 mt-1 max-w-[18em] overflow-x-hidden text-ellipsis whitespace-nowrap text-xs text-gray-500 hover:text-gray-700"
            v-else-if="client && client.playbooks.length === 1"
          >
            {{ playbook.name }}
          </span>

          <select
            v-else-if="client"
            class="mt-1 block w-full rounded-sm border-0 py-1.5 pl-3 pr-8 text-xs text-gray-500 focus:ring-1 focus:ring-lexoo"
            v-model="playbookToChangeToId"
          >
            <option
              v-for="pb in client.playbooks"
              :key="pb.id"
              :selected="pb.id === playbook.id"
              :value="pb.id"
            >
              {{ pb.name }}
            </option>
          </select>

          <template v-if="currentStep">
            <ChevronRightIcon
              class="mt-1 h-5 w-5 flex-shrink-0 text-gray-400"
              aria-hidden="true"
            />
            <span
              class="ml-2 mt-1 max-w-[18em] overflow-x-hidden text-ellipsis whitespace-nowrap text-xs text-gray-500 hover:text-gray-700"
              v-if="(client && client.playbooks.length === 1) || currentStep"
            >
              {{ currentStep }}
            </span>
          </template>
        </div>
      </li>
    </ol>
  </nav>

  <BaseConfirmationModal
    v-if="checkToChangePlaybook"
    title="Are you sure?"
    description="Selecting a new playbook will clear your checkboxes for this document."
    button-text="Yes"
    @confirm="changePlaybook"
    @close="checkToChangePlaybook = false"
    @cancel="playbookToChangeToId = playbook?.id ?? null"
  />

  <BaseButton
    v-if="currentStep"
    @action="goBack"
    buttonStyle="white"
    size="small"
    class="mb-4"
  >
    {{ "<" }} Back to Playbook
  </BaseButton>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { ChevronRightIcon } from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";

import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";
import { useSidebarStore } from "@/store/SidebarStore";
import PlaybookInstanceCreator from "@/services/PlaybookInstanceCreator";

const sidebarStore = useSidebarStore();

const playbookToChangeToId = ref(null as number | null);
const checkToChangePlaybook = ref(false);

const client = computed(() => sidebarStore.client);
const playbook = computed(() => sidebarStore.playbook);

defineProps({
  currentStep: {
    type: String,
    required: false
  }
});

onMounted(() => {
  sidebarStore.loadClients().then(() => {
    if (sidebarStore.playbook) {
      playbookToChangeToId.value = sidebarStore.playbook.id;
    }
  });
});

watch(
  () => sidebarStore.playbook,
  (newPlaybook) => {
    if (newPlaybook) {
      playbookToChangeToId.value = newPlaybook.id;
    }
  }
);

const changePlaybook = () => {
  if (playbookToChangeToId.value) {
    const creator = new PlaybookInstanceCreator(playbookToChangeToId.value);
    creator.create();
  }
};

// Watch for changes to playbookToChangeTo
watch(playbookToChangeToId, (newVal, oldVal) => {
  if (
    oldVal &&
    newVal &&
    newVal !== oldVal &&
    newVal !== sidebarStore.playbook?.id
  ) {
    if (sidebarStore.lexPlayId) {
      checkToChangePlaybook.value = true;
    } else {
      changePlaybook();
    }
  }
});

const router = useRouter();
const goBack = () => {
  router.push(`/sidebar/playbooks/${playbook.value?.id}`);
};
</script>
