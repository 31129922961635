<template>
  <TransitionRoot as="template" :show="show" @afterLeave="clearForm">
    <HeadlessDialog
      as="div"
      auto-reopen="true"
      class="fixed inset-0 z-30 overflow-hidden"
      @close="$emit('close')"
    >
      <div class="absolute inset-0 overflow-hidden">
        <DialogOverlay
          class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />

        <div class="fixed inset-y-0 right-0 flex max-w-full sm:pl-16">
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <div class="w-screen max-w-2xl">
              <div
                class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl"
              >
                <div class="px-4 sm:px-6">
                  <div class="flex items-start justify-between">
                    <DialogTitle class="text-lg font-medium text-gray-900">
                      New entry
                    </DialogTitle>

                    <div class="ml-3 flex h-7 items-center">
                      <button
                        class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                        @click="$emit('close')"
                      >
                        <span class="sr-only">Close panel</span>
                        <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative flex-1 px-4 sm:mt-6 sm:px-6">
                  <!-- Divider container -->
                  <div
                    class="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0"
                  >
                    <!-- Heading -->
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="heading"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Heading
                        </label>
                      </div>
                      <div class="sm:col-span-2">
                        <input
                          type="text"
                          name="heading"
                          v-model="heading"
                          ref="heading"
                          class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
                        />
                      </div>
                    </div>

                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="entryDescription"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Entry description
                        </label>
                      </div>
                      <div class="text-sm sm:col-span-2">
                        <BaseEditableText
                          class="min-w-full"
                          v-model="entryDescription"
                        />
                        <p class="mt-1 text-gray-600">
                          Use this to describe the entry in more detail.
                        </p>
                      </div>
                    </div>
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="description"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Description
                        </label>
                      </div>
                      <div class="text-sm sm:col-span-2">
                        <BaseEditableText
                          class="min-w-full"
                          v-model="description"
                        />
                        <p class="mt-1 text-gray-600">
                          Use this to describe the preferred position in more
                          detail.
                        </p>
                      </div>
                    </div>
                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="text"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Template wording
                        </label>
                      </div>
                      <div class="text-sm sm:col-span-2">
                        <SimpleEditableText v-model="templateWording" />
                        <p class="mt-1 text-gray-600">
                          Sample clause text that can be inserted into the Word
                          document.
                        </p>
                      </div>
                    </div>

                    <div
                      class="space-y-1 px-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <label
                          for="text"
                          class="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2"
                        >
                          Template comment
                        </label>
                      </div>
                      <div class="text-sm sm:col-span-2">
                        <SimpleEditableText v-model="templateComment" />
                        <p class="mt-1 text-gray-600">
                          Used to explain a given position that can be inserted
                          into Word as a comment.
                        </p>
                      </div>
                    </div>
                    <!-- Tags -->
                    <div
                      class="space-y-2 px-4 sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:space-y-0 sm:px-6 sm:py-5"
                    >
                      <div>
                        <h3 class="text-sm font-medium text-gray-900">Tags</h3>
                      </div>
                      <div class="sm:col-span-2">
                        <TagSelect
                          v-model="tags"
                          :selectableTags="selectableTags"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Action buttons -->
                <div
                  class="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6"
                >
                  <div class="flex space-x-3">
                    <BaseButton
                      @click.prevent="createEntryAndReturn"
                      buttonSize="large"
                    >
                      {{ showContinueButton ? "Save entry" : "Create entry" }}
                    </BaseButton>
                    <BaseButton
                      v-if="showContinueButton"
                      @click.prevent="createEntryAndContinue"
                      buttonSize="large"
                    >
                      Save and create new
                    </BaseButton>
                    <BaseButton
                      @click.prevent="$emit('close')"
                      buttonStyle="link"
                      buttonSize="large"
                    >
                      Cancel
                    </BaseButton>
                  </div>
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType } from "vue";
import BaseEditableText from "@/components/base/BaseEditableText.vue";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";
import TagSelect from "@/components/TagSelect.vue";
import { Entry, NewEntry, Playbook, Tag } from "@/types";
import { useAdmin } from "@/components/shared/Admin";

import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  emits: ["createNewEntry", "close"],

  setup() {
    const { tags } = useAdmin();
    return { selectableTags: tags };
  },

  components: {
    BaseEditableText,
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    SimpleEditableText,
    TagSelect,
    TransitionChild,
    TransitionRoot,
    XMarkIcon
  },

  props: {
    entryToDuplicate: {
      required: false,
      type: Object as PropType<Entry>,
      default: null
    },

    playbook: {
      required: false,
      type: Object as PropType<Playbook>,
      default: null
    },

    show: {
      required: true,
      type: Boolean
    },

    showContinueButton: {
      default: true,
      type: Boolean
    }
  },

  data() {
    return {
      heading: "",
      templateWording: "",
      templateComment: "",
      tags: [] as Tag[],
      description: "",
      entryDescription: ""
    };
  },

  watch: {
    show(value: boolean) {
      if (value) {
        nextTick(() => {
          (this.$refs as { heading: HTMLElement }).heading.focus();
        });
      }
    },

    entryToDuplicate(entry: Entry | null) {
      if (!entry) {
        this.clearForm();
      } else {
        this.heading = entry.heading || "";
        this.templateWording = entry.templateWording || "";
        this.templateComment = entry.templateComment ?? "";
        this.tags = entry.tags ?? [];
        this.description = entry.description;
        this.entryDescription = entry.entryDescription;
      }
    }
  },

  methods: {
    clearForm() {
      this.entryDescription = "";
      this.description = "";
      this.heading = "";
      this.tags = [];
      this.templateWording = "";
      this.templateComment = "";
    },

    createEntry() {
      const newEntry: NewEntry = {
        playbookId: this.playbook.id,
        heading: this.heading,
        position: 0,
        templateWording: this.templateWording,
        templateComment: this.templateComment,
        tagIds: this.tags.map((tag: Tag) => tag.id),
        description: this.description,
        entryDescription: this.entryDescription
      };
      this.$emit("createNewEntry", newEntry);
      nextTick(() => {
        (this.$refs as { heading: HTMLElement }).heading.focus();
      });
    },

    createEntryAndContinue() {
      this.createEntry();
      this.clearForm();
    },

    createEntryAndReturn() {
      this.createEntry();
      this.$emit("close");
    }
  }
});
</script>
