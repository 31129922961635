export default (callback: () => void) => {
  const currentVersion = window.__LEXPLAY_VERSION__;
  const pollInterval = 1000 * 60 * 60; // 1 hour
  setInterval(() => {
    fetch("/version.txt").then((response) => {
      if (response.ok) {
        response.text().then((text) => {
          const newVersion = text;
          if (
            newVersion.trim().length > 0 &&
            currentVersion.trim() !== newVersion.trim()
          ) {
            console.log("New version detected: ".concat(newVersion));
            callback();
          }
        });
      }
    });
  }, pollInterval);
};
