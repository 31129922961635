<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <template v-if="isGoogle">
          <div class="w-full max-w-md space-y-8">
            <div>
              <img
                class="mx-auto h-12 w-auto"
                src="@/assets/images/lexplay-300.png"
                alt="LexPlay"
              />
              <h2
                class="mt-6 mb-4 text-center text-3xl font-bold tracking-tight text-gray-900"
              >
                Hi there!
              </h2>

              <p class="mb-4 text-sm text-gray-900">
                You’ve been invited to use LexPlay, the powerful playbook tool
                built by Lexoo.
              </p>

              <p class="mb-4 text-sm text-gray-900">
                Complete the process by Signing up with Google.
              </p>

              <div class="text-center">
                <BaseButton buttonStyle="white" @action="goToGoogleSignOn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 186.69 190.5"
                    xmlns:v="https://vecta.io/nano"
                    class="h-4 w-4"
                  >
                    <g transform="translate(1184.583 765.171)">
                      <path
                        clip-path="none"
                        mask="none"
                        d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
                        fill="#4285f4"
                      />
                      <path
                        clip-path="none"
                        mask="none"
                        d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
                        fill="#34a853"
                      />
                      <path
                        clip-path="none"
                        mask="none"
                        d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
                        fill="#fbbc05"
                      />
                      <path
                        d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
                        fill="#ea4335"
                        clip-path="none"
                        mask="none"
                      />
                    </g>
                  </svg>
                  <span class="ml-2">Sign up with Google</span>
                </BaseButton>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="w-full max-w-md space-y-8">
            <div>
              <img
                class="mx-auto h-12 w-auto"
                src="@/assets/images/lexplay-300.png"
                alt="LexPlay"
              />
              <h2
                class="mt-6 mb-4 text-center text-3xl font-bold tracking-tight text-gray-900"
              >
                Hi there!
              </h2>

              <p class="mb-4 text-sm text-gray-900">
                You’ve been invited to use LexPlay, the powerful playbook tool
                built by Lexoo.
              </p>

              <p class="mb-4 text-sm text-gray-900">
                Please create a password that you'll use to log into LexPlay in
                Microsoft Word.
              </p>
              <p v-if="notFoundError" class="mb-4 font-medium text-red-600">
                Unfortunately this invitation has expired. Please contact
                <a href="mailto:team@lexoo.com">team@lexoo.com</a> for another
                invitation.
              </p>
            </div>

            <form class="space-y-6" @submit.prevent="createAccount">
              <div class="-space-y-px rounded shadow-sm">
                <div>
                  <label for="email-address" class="sr-only">
                    Email address
                  </label>
                  <input
                    v-model="email"
                    name="email"
                    type="email"
                    disabled="true"
                    autocomplete="email"
                    required="true"
                    class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo disabled:opacity-50 disabled:opacity-50 sm:text-sm"
                    placeholder="Email address"
                  />
                </div>
                <div>
                  <label for="password" class="sr-only">Password</label>
                  <input
                    v-model="password"
                    autofocus
                    name="password"
                    type="password"
                    required="true"
                    class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo disabled:opacity-50 sm:text-sm"
                    placeholder="Enter your new password"
                    :disabled="notFoundError"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  :disabled="password.length === 0 || loading"
                  class="group relative flex w-full justify-center rounded border border-transparent bg-lexoo py-2 px-4 text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50 disabled:opacity-50"
                >
                  {{ loading ? "Please wait..." : "Create account" }}
                </button>
              </div>
            </form>
            <p class="mt-4 text-sm leading-6 text-gray-500">
              By submitting this form, I agree to the
              <a
                href="/privacy-policy"
                class="font-semibold text-lexoo hover:underline"
                target="_window"
              >
                privacy&nbsp;policy
              </a>
              and
              <a
                href="/terms-and-conditions"
                class="font-semibold text-lexoo hover:underline"
                target="_window"
              >
                terms and conditions.
              </a>
            </p>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/services/ApiService";

export default defineComponent({
  data() {
    return {
      email: "",
      notFoundError: false,
      loading: false,
      password: "",
      ssoProvider: ""
    };
  },

  computed: {
    isGoogle(): boolean {
      return this.ssoProvider == "google";
    },

    token(): string {
      return this.$route.params.token;
    }
  },

  methods: {
    createAccount() {
      this.loading = true;
      ApiService.createAccountFromInvitation(
        this.password,
        this.token,
        false
      ).then(() => {
        this.$router.push("/verify-invitation-success");
      });
    },

    validateToken() {
      ApiService.validateInvitationToken(this.token)
        .then(({ data }) => {
          this.email = data.email;
          this.ssoProvider = data.ssoProvider;
        })
        .catch((error) => {
          const errorStatus = error.response.status;

          if (errorStatus == 422) {
            this.$router.push("/verify-invitation-success");
          } else {
            this.notFoundError = true;
          }
        });
    }
  },

  mounted() {
    this.validateToken();
  }
});
</script>

<style lang="scss" scoped></style>
