<template>
  <div
    v-if="showComponent"
    class="fixed bottom-0 left-0 z-20 flex w-full items-center space-x-4 bg-white bg-opacity-90 p-4 shadow-md"
  >
    <div class="animate-bounce-x">
      <ArrowLeftIcon class="h-6 w-6 text-gray-600" />
    </div>
    <span class="text-sm text-gray-600">
      Expand by dragging this side to the left
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { ArrowLeftIcon } from "@heroicons/vue/24/outline";

export default defineComponent({
  components: {
    ArrowLeftIcon
  },
  setup() {
    const showComponent = ref(false);

    const checkWindowWidth = () => {
      if (window.innerWidth === 350) {
        showComponent.value = true;
        // Set a timer to hide the component after 30 seconds (30000 milliseconds)
        setTimeout(() => {
          showComponent.value = false;
        }, 30000);
      } else {
        showComponent.value = false;
      }
    };

    const dismiss = () => {
      showComponent.value = false;
    };

    onMounted(() => {
      checkWindowWidth();
      window.addEventListener("resize", checkWindowWidth);
    });

    return {
      showComponent,
      dismiss
    };
  }
});
</script>

<style scoped lang="scss">
.animate-bounce-x {
  animation: bounce-x 1s infinite;
  @keyframes bounce-x {
    0%,
    100% {
      transform: translateX(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateX(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
}
</style>
