<template>
  <BaseTitle class="flex">
    <LifebuoyIcon class="mr-2 h-6 w-6 text-lexoo" aria-hidden="true" />
    Support
  </BaseTitle>
  <div v-if="sent" class="mt-2">
    <p class="text-sm text-gray-500">
      Thanks for logging this issue. Our team will be in touch within the next
      two business days.
    </p>
  </div>
  <div v-else class="mt-2">
    <p class="text-sm text-gray-500">
      Send a message to support if you find any bugs or have a support enquiry.
    </p>
    <BaseInput
      v-model="message"
      inputType="textarea"
      placeholder="Please enter any details of any issues you find here."
      rows="5"
      class="mt-4 max-w-lg"
    />
    <div class="mt-5 flex gap-x-2 sm:mt-4">
      <BaseButton @click="sendSupportIssue" :disabled="!message || sending">
        {{ sending ? "Please wait..." : "Send to Lexoo" }}
      </BaseButton>
      <BaseButton buttonStyle="white" @click="cancel"> Cancel </BaseButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { LifebuoyIcon } from "@heroicons/vue/24/outline";
import ApiService from "@/services/ApiService";
import { useRouter } from "vue-router";

const router = useRouter();

const message = ref("");
const sending = ref(false);
const sent = ref(false);

const sendSupportIssue = () => {
  sending.value = true;
  ApiService.sendSupportIssue("", message.value).then(() => {
    sent.value = true;
    sending.value = false;
  });
};

const cancel = () => {
  message.value = "";
  sent.value = false;
  router.push("/");
};
</script>
