<template>
  <Dialog :open="true" class="fixed inset-0 z-30 overflow-y-auto">
    <div
      class="flex min-h-screen min-h-screen items-center justify-center px-4 text-center"
    >
      <DialogOverlay
        @click="closeModal"
        class="fixed inset-0 bg-black opacity-30"
      />

      <!-- Modal content -->
      <div
        class="my-8 inline-block w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
      >
        <DialogTitle class="text-lg font-medium leading-6 text-gray-900">
          Set a temporary password for this user
        </DialogTitle>
        <template v-if="!created">
          <p class="my-4 text-sm text-gray-500">
            Sometimes the user might not get our invitation email. In that case,
            you can set a temporary password for the user. After creating this
            password you will have to manually let the user know what their
            password is.
          </p>
          <div class="mt-2">
            <BaseInput
              v-model="temporaryPassword"
              placeholder="Temporary password"
              class="w-full"
            />
          </div>
          <div class="mt-5 flex flex-row-reverse sm:mt-4">
            <BaseButton buttonStyle="link" @click="closeModal">
              Cancel
            </BaseButton>
            <BaseButton
              buttonStyle="white"
              @click="createTemporaryPassword"
              :disabled="!temporaryPassword || temporaryPassword.length == 0"
              class="disabled:opacity-50"
            >
              Save password
            </BaseButton>
          </div>
        </template>
        <template v-else>
          <div class="my-4 text-sm text-gray-500">
            The user can now log in with the following credentials:
            <ul class="mt-2">
              <li>
                Username: <strong>{{ invitation.email }}</strong>
              </li>
              <li>
                Password: <strong>{{ temporaryPassword }}</strong>
              </li>
            </ul>
          </div>
          <div class="mt-5 flex flex-row-reverse sm:mt-4">
            <BaseButton buttonStyle="white" @click="closeModal">
              Close
            </BaseButton>
          </div>
        </template>
      </div>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { PropType, Ref, ref } from "vue";
import { Dialog, DialogOverlay, DialogTitle } from "@headlessui/vue";
import { Invitation } from "@/types";
import { useUserStore } from "@/store/UserStore";

const props = defineProps({
  invitation: {
    type: Object as PropType<Invitation>,
    required: true
  }
});

const created = ref(false);
const emit = defineEmits(["close"]);

const temporaryPassword: Ref<string | null> = ref(null);

const userStore = useUserStore();

const closeModal = () => {
  temporaryPassword.value = null;
  created.value = false;
  emit("close");
};

const createTemporaryPassword = () => {
  if (temporaryPassword.value && temporaryPassword.value.length > 0) {
    userStore
      .createTemporaryPassword(props.invitation, temporaryPassword.value)
      .then(() => {
        created.value = true;
      });
  }
};
</script>
