import axios, { AxiosInstance, AxiosResponse } from "axios";

import { useAuthorisationStore } from "@/store/AuthorisationStore";

import {
  Clause,
  Client,
  Comment,
  Entry,
  Escalation,
  Fallback,
  FallbackScenario,
  Invitation,
  Issue,
  Mention,
  NewEntry,
  NewEscalation,
  NewInteraction,
  NewPlaybookTemplate,
  NewTag,
  NewOldSkoolVariable,
  OldSkoolVariable,
  OrderedEntry,
  Playbook,
  PlaybookInstance,
  PlaybookTemplate,
  PromptTemplate,
  SignUpClient,
  SignUpUser,
  Tag,
  Tenant,
  User
} from "@/types";

import { createApiClient } from "@/config/AxiosHelper";
type ApiResponse = Promise<AxiosResponse>;
const apiClient = createApiClient(true);

/* eslint-disable @typescript-eslint/no-explicit-any */
export const renameKey = (
  oldKey: string,
  newKey: string,
  userObject: Record<string, any>
) => {
  userObject[newKey] = userObject[oldKey];
  delete userObject.oldKey;
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export default {
  axiosInstance: () => apiClient, // for testing purposes

  addBeta(user: User) {
    return apiClient.patch(`/users/${user.id}/add_beta`);
  },

  removeBeta(user: User) {
    return apiClient.patch(`/users/${user.id}/remove_beta`);
  },

  changePassword(user: User, password: string, forceReset: boolean) {
    return apiClient.put(`/users/${user.id}/change_password.json`, {
      password,
      force_reset: forceReset
    });
  },

  copyEntry(entryId: number, playbookId: number) {
    return apiClient.post(`/entries/${entryId}/copy.json`, { playbookId });
  },

  copyPlaybook(
    sourcePlaybook: Playbook,
    newPlaybookName: string,
    destinationClientId: number
  ) {
    const playbookId = sourcePlaybook.id;
    const sourceClientId = sourcePlaybook.clientId;
    return apiClient.post(
      `/clients/${sourceClientId}/playbooks/${playbookId}/copy.json`,
      {
        destination_client_id: destinationClientId,
        name: newPlaybookName
      }
    );
  },

  createAccountFromInvitation(
    password: string,
    token: string,
    forceReset: boolean
  ) {
    return apiClient.post("/invitations/create_user.json", {
      password,
      token,
      force_reset: forceReset
    });
  },

  createClause(
    playbookInstance: PlaybookInstance,
    clause: Clause
  ): ApiResponse {
    return apiClient.post(
      `/playbook_instances/${playbookInstance.id}/clauses`,
      {
        clause
      }
    );
  },

  createClient({ name }: { name: string }): ApiResponse {
    return apiClient.post("/clients.json", {
      client: { name, hide_from_flow: true }
    });
  },

  createComment(comment: {
    entryId: number;
    content: string;
    mentionedIds: number[];
  }): ApiResponse {
    return apiClient.post("/comments", { comment });
  },

  createPlaybookTemplate(playbookTemplate: NewPlaybookTemplate): ApiResponse {
    return apiClient.post("/playbook_templates.json", {
      playbook_template: playbookTemplate
    });
  },

  createEntry(entry: NewEntry): ApiResponse {
    const fallbackScenariosAttributes = (entry.fallbackScenarios || []).map(
      (scenario: FallbackScenario) => {
        renameKey("fallbacks", "fallbacks_attributes", scenario);
        return scenario;
      }
    );

    entry.tagIds = (entry.tags ?? []).map((tag: Tag) => tag.id);
    const updateEntry = {
      ...entry,
      fallback_scenarios_attributes: fallbackScenariosAttributes
    };

    return apiClient.post("/entries.json", { entry: updateEntry });
  },

  createEscalation(newEscalation: NewEscalation) {
    return apiClient.post(
      `/playbook_instances/${newEscalation.playbookInstanceId}/escalations`,
      { escalation: newEscalation }
    );
  },

  createPlaybookInstance(
    playbookId: number,
    filePath: string | null,
    wordDocId: string | null
  ): ApiResponse {
    const playbookInstance = {
      playbookId,
      filePath,
      wordDocId
    };
    return apiClient.post("/playbook_instances.json", {
      playbook_instance: playbookInstance
    });
  },

  createInvitation(invitation: {
    email: string;
    name: string;
    role: string;
    clientId?: number;
  }): ApiResponse {
    return apiClient.post("/invitations.json", { invitation });
  },

  createIssue(playbookInstance: PlaybookInstance, issue: Issue): ApiResponse {
    return apiClient.post(`/playbook_instances/${playbookInstance.id}/issues`, {
      issue
    });
  },

  createPlaybook(
    clientId: number,
    name: string,
    lexplayLeap: boolean
  ): ApiResponse {
    return apiClient.post(`/clients/${clientId}/playbooks.json`, {
      playbook: { name, playbook_type: "Universal", lexplay_leap: lexplayLeap }
    });
  },

  createTag(tag: NewTag): ApiResponse {
    return apiClient.post("/tags.json", { tag });
  },

  createOldSkoolVariable(oldSkoolVariable: NewOldSkoolVariable): ApiResponse {
    return apiClient.post("/old_skool_variables.json", {
      old_skool_variable: oldSkoolVariable
    });
  },

  destroyClauses(playbookInstance: PlaybookInstance): ApiResponse {
    return apiClient.delete(
      `/playbook_instances/${playbookInstance.id}/clauses/destroy_all`
    );
  },

  destroyComment(comment: Comment): ApiResponse {
    return apiClient.delete(`/comments/${comment.id}`);
  },

  destroyEntry(entry: Entry): ApiResponse {
    return apiClient.delete(`/entries/${entry.id}`);
  },

  destroyInvitation(invitation: Invitation) {
    return apiClient.delete(`/invitations/${invitation.id}`);
  },

  destroyIssues(playbookInstance: PlaybookInstance): ApiResponse {
    return apiClient.delete(
      `/playbook_instances/${playbookInstance.id}/issues/destroy_all`
    );
  },

  destroyPlaybook(playbook: Playbook): ApiResponse {
    return apiClient.delete(`/playbooks/${playbook.id}`);
  },

  destroyPlaybookTemplate(playbookTemplate: PlaybookTemplate): ApiResponse {
    return apiClient.delete(`/playbook_templates/${playbookTemplate.id}`);
  },

  destroySampleTermsFileGroup(
    clientId: number,
    templateFile: string
  ): ApiResponse {
    return apiClient.delete(`/sample_terms_file_groups/current`, {
      params: { client_id: clientId, template_file: templateFile }
    });
  },

  destroyTag(tag: Tag): ApiResponse {
    return apiClient.delete(`/tags/${tag.id}`);
  },

  destroyOldSkoolVariable(variable: OldSkoolVariable): ApiResponse {
    return apiClient.delete(`/old_skool_variables/${variable.id}`);
  },

  exportPlaybookDrive(playbook: Playbook): ApiResponse {
    const authorisationStore = useAuthorisationStore();
    const fileClient = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/v1`,
      headers: {
        Authorization: authorisationStore.authorisation
      }
    });

    return fileClient.patch(
      `/clients/${playbook.clientId}/playbooks/${playbook.id}/export_to_drive`
    );
  },

  createPlaybookExportMagicLink(
    playbook: Playbook,
    format: string,
    rawHtml: string
  ): ApiResponse {
    return apiClient.post(`/playbook_exports`, {
      playbook_id: playbook.id,
      format,
      raw_html: rawHtml
    });
  },

  downloadPlaybookFromMagicLink(token: string): ApiResponse {
    const fileClient = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/v1`
    });

    return fileClient.get(`/playbook_exports/${token}`, {
      responseType: "blob"
    });
  },

  downloadPlaybookXls(playbook: Playbook): ApiResponse {
    const authorisationStore = useAuthorisationStore();
    const fileClient = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/v1`,
      headers: {
        Authorization: authorisationStore.authorisation
      }
    });

    return fileClient.get(
      `/clients/${playbook.clientId}/playbooks/${playbook.id}/export`,
      {
        responseType: "blob"
      }
    );
  },

  findEscalationFromIdentifier(identifier: string) {
    return apiClient.get(`/escalations/${identifier}`);
  },

  forgotPassword(email: string): ApiResponse {
    return apiClient.post("/users/forgot_password", { email });
  },

  getApprovers(clientId: number): ApiResponse {
    return apiClient.get(`/clients/${clientId}/approvers`);
  },

  getClauses(playbookInstance: PlaybookInstance): ApiResponse {
    return apiClient.get(`/playbook_instances/${playbookInstance.id}/clauses`);
  },

  getClients(): Promise<AxiosResponse> {
    return apiClient.get("/clients.json");
  },

  getCurrentUser(): ApiResponse {
    return apiClient.get("/users/current");
  },

  getEntriesForPlaybook(playbookId: number): ApiResponse {
    return apiClient.get("/entries.json", {
      params: { playbook_id: playbookId }
    });
  },

  getEntry(entryId: number): Promise<AxiosResponse> {
    return apiClient.get(`/entries/${entryId}.json`);
  },

  getEscalations(): ApiResponse {
    return apiClient.get("/escalations");
  },

  getFallbackScenarios(entryId: number): ApiResponse {
    return apiClient.get(`/entries/${entryId}/fallback_scenarios`);
  },

  getInteractionsForUser(userId: number): ApiResponse {
    return apiClient.get("/interactions", { params: { user_id: userId } });
  },

  getInteractions(): ApiResponse {
    return apiClient.get("/interactions");
  },

  getInvitations(): ApiResponse {
    return apiClient.get("/invitations");
  },

  getIssues(playbookInstance: PlaybookInstance): ApiResponse {
    return apiClient.get(`/playbook_instances/${playbookInstance.id}/issues`);
  },

  getMentions(): ApiResponse {
    return apiClient.get("/mentions");
  },

  getPlaybookInstance(
    playbookInstanceId: number,
    loadAllEntryData: boolean
  ): ApiResponse {
    const apiClientWithoutErrorHandling = createApiClient(false);
    return apiClientWithoutErrorHandling.get(
      `/playbook_instances/${playbookInstanceId}.json`,
      {
        params: { load_all_entry_data: loadAllEntryData }
      }
    );
  },

  getPlaybookInstanceFromLexPlayId(lexPlayId: string): ApiResponse {
    return apiClient.get(`/playbook_instances/by_lex_play_id.json`, {
      params: { lex_play_id: lexPlayId }
    });
  },

  getPlaybookInstanceFromWordDocId(wordDocId: string): ApiResponse {
    return apiClient.get(`/playbook_instances/by_word_doc_id.json`, {
      params: { word_doc_id: wordDocId }
    });
  },

  getPlaybookInstanceFromFilePath(filePath: string): ApiResponse {
    return apiClient.get(`/playbook_instances/by_file_path.json`, {
      params: { file_path: filePath }
    });
  },

  getPlaybookTemplates(): ApiResponse {
    return apiClient.get("/playbook_templates");
  },

  getPromptTemplate(promptType: string): ApiResponse {
    return apiClient.get(`/prompt_templates/${promptType}`);
  },

  getSampleTermsFile(sampleTermsFileId: number): ApiResponse {
    return apiClient.get(`/sample_terms_files/${sampleTermsFileId}`);
  },

  getSampleTermsFileGroups(): ApiResponse {
    return apiClient.get(`/sample_terms_file_groups`);
  },

  getSampleTermsFileGroup(clientId: number, templateFile: string): ApiResponse {
    return apiClient.get(`/sample_terms_file_groups/current`, {
      params: { client_id: clientId, template_file: templateFile }
    });
  },

  getTeamMembers(): ApiResponse {
    const apiClientWithoutErrorHandling = createApiClient(false);
    return apiClientWithoutErrorHandling.get("/team_members.json");
  },

  getTenant(): ApiResponse {
    return apiClient.get("/tenants.json");
  },

  getUserProvider(email: string): ApiResponse {
    return apiClient.get("/users/sso_provider", { params: { email } });
  },

  getUsers(): ApiResponse {
    return apiClient.get(`/users.json`);
  },

  importEntriesFromGoogleDrive(playbook: Playbook, folderId: string) {
    const clientId = playbook.clientId;
    const playbookId = playbook.id;
    return apiClient.patch(
      `/clients/${clientId}/playbooks/${playbookId}/import_from_drive`,
      {
        folderId
      }
    );
  },

  installedAddIn(): ApiResponse {
    return apiClient.post("/users/installed_add_in");
  },

  makeAdmin(userId: number): ApiResponse {
    return apiClient.patch(`/users/${userId}`, { role: "admin" });
  },

  makeEditor(userId: number): ApiResponse {
    return apiClient.patch(`/users/${userId}`, { role: "editor" });
  },

  makeViewer(userId: number): ApiResponse {
    return apiClient.patch(`/users/${userId}`, { role: "viewer" });
  },

  migratePlaybook(playbook: Playbook): ApiResponse {
    return apiClient.patch(`/playbooks/${playbook.id}/migrate`);
  },

  resendConfirmation(email: string): ApiResponse {
    return apiClient.patch(`/users/resend_confirmation`, { email });
  },

  resendInvitation(invitationId: number): ApiResponse {
    return apiClient.post(`/invitations/${invitationId}/resend`);
  },

  resetPassword(password: string, token: string): ApiResponse {
    return apiClient.post("/users/reset_password", { password, token });
  },

  saveFallbackScenarios(
    entryId: number,
    fallbackScenarios: FallbackScenario[]
  ): ApiResponse {
    const fallbackScenariosAttributes = fallbackScenarios.map(
      (scenario: FallbackScenario) => {
        renameKey("fallbacks", "fallbacks_attributes", scenario);
        return scenario;
      }
    );

    return apiClient.patch(
      `/entries/${entryId}/fallback_scenarios/bulk_update`,
      {
        entry: { fallback_scenarios_attributes: fallbackScenariosAttributes }
      }
    );
  },

  searchEntries(
    search: string,
    clientId: number | null,
    playbookId: number | null
  ): ApiResponse {
    return apiClient.get("/entries/search.json", {
      params: { client_id: clientId, playbook_id: playbookId, search }
    });
  },

  sendSupportIssue(docId: string, message: string) {
    return apiClient.post("/support_issues", {
      doc_id: docId,
      message
    });
  },

  setCheckedStatusOnEntry(
    entryId: number,
    playbookInstanceId: number,
    status: string
  ): ApiResponse {
    return apiClient.post("/checked_entries.json", {
      entry_id: entryId,
      playbook_instance_id: playbookInstanceId,
      status
    });
  },

  signIn(payload: { email: string; password: string }): ApiResponse {
    const client: AxiosInstance = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}`
    });
    return client.post("/users/sign_in", { user: payload });
  },

  signOut(): ApiResponse {
    const client: AxiosInstance = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}`
    });
    const authorisationStore = useAuthorisationStore();
    const authorisation = authorisationStore.authorisation;

    return client.delete("/users/sign_out", {
      headers: {
        Authorization: authorisation,
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
  },

  signUp(user: SignUpUser, client: SignUpClient): ApiResponse {
    return apiClient.post("/users/sign_up", { user, client });
  },

  startStreamingPrompt({
    streamId,
    model,
    prompt,
    superPrompt,
    messages,
    jsonMode,
    incremental
  }: {
    streamId: string;
    model: string;
    prompt: string;
    superPrompt: string;
    messages: { text: string; isUser: boolean }[];
    jsonMode: boolean;
    incremental: boolean;
  }): ApiResponse {
    return apiClient.post("/streaming_prompts", {
      prompt,
      model,
      stream_id: streamId,
      super_prompt: superPrompt,
      messages,
      json_mode: jsonMode,
      incremental
    });
  },

  suspendUser(userId: number): ApiResponse {
    return apiClient.patch(`/users/${userId}/suspend`);
  },

  trackInteraction(interaction: NewInteraction): ApiResponse {
    return apiClient.post("/interactions", { interaction });
  },

  unsuspendUser(userId: number): ApiResponse {
    return apiClient.patch(`/users/${userId}/unsuspend`);
  },

  updateClause(clause: Clause): ApiResponse {
    return apiClient.patch(
      `playbook_instances/${clause.playbookInstanceId}/issues/${clause.id}`,
      { clause }
    );
  },

  updateClient(client: Client): ApiResponse {
    return apiClient.patch(`/clients/${client.id}`, { client });
  },

  updateCurrentUser(user: User): ApiResponse {
    return apiClient.patch(`/users/current`, { user });
  },

  updateEntry(entry: Entry): ApiResponse {
    const fallbackScenariosAttributes = (entry.fallbackScenarios || []).map(
      (scenario: FallbackScenario) => {
        renameKey("fallbacks", "fallbacks_attributes", scenario);
        return scenario;
      }
    );

    entry.tagIds = (entry.tags ?? []).map((tag: Tag) => tag.id);
    const updateEntry = {
      ...entry,
      escalationTemplateAttributes: entry.escalationTemplate,
      fallback_scenarios_attributes: fallbackScenariosAttributes
    };

    return apiClient.patch(`/entries/${entry.id}`, { entry: updateEntry });
  },

  updateEntriesOrder(entries: Entry[]): ApiResponse {
    const entryIds = entries.map((entry: Entry) => {
      return entry.id;
    });
    return apiClient.patch("/entries/reorder", {
      entry_ids: entryIds
    });
  },

  updateEntryPosition(entry: OrderedEntry): ApiResponse {
    return apiClient.patch(`/entries/${entry.id}`, {
      position: entry.position
    });
  },

  updateFallback(fallback: Fallback): ApiResponse {
    return apiClient.patch(`/fallbacks/${fallback.id}`, {
      fallback
    });
  },

  updateFallbackScenario(fallbackScenario: FallbackScenario): ApiResponse {
    return apiClient.patch(
      `/entries/${fallbackScenario.entryId}/fallback_scenarios/${fallbackScenario.id}`,
      {
        fallback_scenario: fallbackScenario
      }
    );
  },

  updateEscalation(escalation: Escalation): ApiResponse {
    return apiClient.patch(`/escalations/${escalation.id}`, { escalation });
  },

  updateFallbackScenariosOrder(
    entryId: number,
    fallbackScenarios: FallbackScenario[]
  ): ApiResponse {
    const fallbackScenarioIds = fallbackScenarios.map(
      (fallbackScenario: FallbackScenario) => {
        return fallbackScenario.id;
      }
    );
    return apiClient.patch(`/entries/${entryId}/fallback_scenarios/reorder`, {
      fallback_scenario_ids: fallbackScenarioIds
    });
  },

  updateIssue(issue: Issue): ApiResponse {
    return apiClient.patch(
      `playbook_instances/${issue.playbookInstanceId}/issues/${issue.id}`,
      { issue }
    );
  },

  updateMention(mention: Mention): ApiResponse {
    return apiClient.patch(`/mentions/${mention.id}`, { mention });
  },

  updatePlaybook(playbook: Playbook): ApiResponse {
    return apiClient.patch(
      `/clients/${playbook.clientId}/playbooks/${playbook.id}`,
      {
        playbook
      }
    );
  },

  updatePlaybookInstance(playbookInstance: PlaybookInstance): ApiResponse {
    return apiClient.patch(`/playbook_instances/${playbookInstance.id}`, {
      playbook_instance: playbookInstance
    });
  },

  updatePlaybookInstanceCheckedTasks(playbookInstance: {
    id: number;
    checkedTasks: Record<string, string[]>;
  }): ApiResponse {
    return apiClient.patch(`/playbook_instances/${playbookInstance.id}`, {
      playbook_instance: playbookInstance
    });
  },

  updatePlaybookInstanceTags(playbookInstance: {
    id: number;
    includedTagIds: number[];
    filePath: string | null;
  }): ApiResponse {
    return apiClient.patch(`/playbook_instances/${playbookInstance.id}`, {
      playbookInstance
    });
  },

  updatePromptTemplate(promptTemplate: PromptTemplate): ApiResponse {
    return apiClient.patch(`/prompt_templates/${promptTemplate.promptType}`, {
      prompt_template: promptTemplate
    });
  },

  updateTag(tag: Tag): ApiResponse {
    return apiClient.patch(`/tags/${tag.id}`, { tag });
  },

  updateVariable(variable: OldSkoolVariable): ApiResponse {
    return apiClient.patch(`/old_skool_variables/${variable.id}.json`, {
      variable
    });
  },

  updateOldSkoolVariablesOrder(variables: OldSkoolVariable[]): ApiResponse {
    const variableIds = variables.map((variable: OldSkoolVariable) => {
      return variable.id;
    });
    return apiClient.patch("/old_skool_variables/reorder", {
      variable_ids: variableIds
    });
  },

  updateTenant(tenant: Tenant): ApiResponse {
    return apiClient.patch("/tenants.json", { tenant });
  },

  uploadSampleTermsFile(formData: FormData) {
    const authorisationStore = useAuthorisationStore();
    const fileClient = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/v1`,
      withCredentials: false, // This is the default
      headers: {
        Authorization: authorisationStore.authorisation ?? "",
        Accept: "application/json",
        "Content-Type": "application/json",
        From: `${import.meta.env.VITE_APP_FROM}`
      }
    });

    return fileClient.post("/sample_terms_files", formData);
  },

  uploadMultipleSampleTermsFile(formData: FormData) {
    const authorisationStore = useAuthorisationStore();
    const fileClient = axios.create({
      baseURL: `${import.meta.env.VITE_API_URL}/v1`,
      withCredentials: false, // This is the default
      headers: {
        Authorization: authorisationStore.authorisation ?? "",
        Accept: "application/json",
        "Content-Type": "application/json",
        From: `${import.meta.env.VITE_APP_FROM}`
      }
    });

    return fileClient.post("/sample_terms_files/bulk_create", formData);
  },

  validateConfirmationToken(token: string) {
    return apiClient.get("/users/validate_confirmation", {
      params: { token }
    });
  },

  validateInvitationToken(token: string) {
    return apiClient.get("/invitations/validate_token", {
      params: { token }
    });
  },

  validatePasswordResetToken(token: string) {
    return apiClient.get("/users/validate_token", {
      params: { token }
    });
  }
};
