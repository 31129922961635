<template>
  <div
    v-if="show"
    class="fixed inset-0 z-10 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
      <div
        @click.self="$emit('cancel')"
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span
        class="hidden sm:inline-block sm:h-screen sm:align-middle"
        aria-hidden="true"
      >
        &#8203;
      </span>

      <!--
      Modal panel, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        To: "opacity-100 translate-y-0 sm:scale-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100 translate-y-0 sm:scale-100"
        To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    -->
      <div
        class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 sm:align-middle"
      >
        <div>
          <label class="block text-sm font-medium text-gray-700">
            Set the URL for this link
          </label>
          <div class="mt-1 flex rounded shadow-sm">
            <span
              class="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
            >
              https://
            </span>
            <input
              ref="url"
              v-model="url"
              autofocus
              type="text"
              class="block w-full min-w-0 flex-1 rounded-none border-gray-300 px-3 py-2 focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
              placeholder="www.example.com"
            />

            <button
              @click="$emit('selected', 'https://' + url)"
              class="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-lexoo"
            >
              Set link
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, nextTick } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String,
      required: true,
      default: ""
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return {
      url: ""
    };
  },

  watch: {
    show(value) {
      if (value) {
        nextTick(() => {
          (this.$refs.url as HTMLInputElement).focus();
        });
        this.url = this.modelValue.replace(/(^\w+:|^)\/\//, "");
      }
    }
  }
});
</script>

<style lang="scss" scoped></style>
