<template>
  <Disclosure
    v-if="showThirdParty || editMode"
    v-slot="{ open }"
    class="mb-2"
    :class="editMode ? 'mt-2' : ''"
    as="div"
  >
    <DisclosureButton
      class="flex w-full justify-between bg-sky-100 py-2 py-2 px-4 text-left text-sm font-medium text-gray-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500 focus-visible:ring-opacity-75"
      :class="open ? 'rounded-t-lg' : 'rounded-lg'"
    >
      Third party paper
      <ChevronUpIcon
        :class="open ? 'rotate-180 transform' : ''"
        class="h-5 w-5 text-gray-500"
      />
    </DisclosureButton>
    <DisclosurePanel class="rounded-b-lg border border-sky-100 p-3">
      <div class="grid grid-cols-1 gap-y-6" v-if="editMode && model">
        <div>
          <BaseEditableText
            label="Text"
            class="mt-1 min-w-full text-xs"
            v-model="model.thirdPartyDescription"
          />
        </div>
        <div>
          <SimpleEditableText
            class="mt-1 min-w-full text-xs"
            label="Template wording"
            v-model="model.thirdPartyTemplateWording"
          />
        </div>
        <div>
          <SimpleEditableText
            class="mt-1 min-w-full text-xs"
            label="Template comment"
            v-model="model.thirdPartyTemplateComment"
          />
        </div>
      </div>
      <template v-else>
        <InlineEditableText
          v-if="playbookInstance && !blankText(entry.thirdPartyDescription)"
          :entryId="entry.id"
          :filterText="filterText ?? ''"
          :playbookInstance="playbookInstance"
          :text="entry.thirdPartyDescription"
          @update="updateDescription"
        />
        <InlineEditableTemplateWording
          v-if="playbookInstance && templateWordingPresent"
          :entryId="entry.id"
          :filterText="filterText ?? ''"
          :playbookInstance="playbookInstance"
          :text="entry.thirdPartyTemplateWording"
          @update="updateTemplateWording"
        />
        <InlineEditableTemplateComment
          v-if="playbookInstance && templateCommentPresent"
          :entryId="entry.id"
          :filterText="filterText ?? ''"
          :playbookInstance="playbookInstance"
          :text="entry.thirdPartyTemplateComment"
          @update="updateTemplateComment"
        />
      </template>
    </DisclosurePanel>
  </Disclosure>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";
import { ChevronUpIcon } from "@heroicons/vue/24/outline";
import { blankText } from "@/helpers/TextHelpers";
import { Entry, NewEntry, Playbook, PlaybookInstance } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";
import BaseEditableText from "@/components/base/BaseEditableText.vue";
import InlineEditableText from "@/components/sidebar/InlineEditableText.vue";
import InlineEditableTemplateWording from "@/components/sidebar/InlineEditableTemplateWording.vue";
import InlineEditableTemplateComment from "@/components/sidebar/InlineEditableTemplateComment.vue";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";

const props = defineProps({
  entry: {
    type: Object as PropType<Entry | NewEntry>,
    required: true
  },

  filterText: {
    required: false,
    type: String
  },

  playbook: {
    required: true,
    type: Object as PropType<Playbook>
  },

  playbookInstance: {
    required: true,
    type: Object as PropType<PlaybookInstance>
  }
});

const sidebarStore = useSidebarStore();
const editMode = computed(() => sidebarStore.entryEdited(props.entry));

const templateWordingPresent = computed(() => {
  return !blankText(props.entry.thirdPartyTemplateWording);
});

const templateCommentPresent = computed(() => {
  return !blankText(props.entry.thirdPartyTemplateComment);
});

const model = computed({
  get(): Entry | null {
    const sidebarStore = useSidebarStore();
    return sidebarStore.entry;
  },

  set(value: Entry | null) {
    const sidebarStore = useSidebarStore();
    sidebarStore.entry = value;
  }
});

const showThirdParty = computed(() => {
  return (
    !blankText(props.entry.thirdPartyDescription) ||
    templateWordingPresent.value ||
    templateCommentPresent.value
  );
});

const updateTemplateComment = (thirdPartyTemplateComment: string) => {
  const sidebarStore = useSidebarStore();
  const updatedEntry = { ...props.entry, thirdPartyTemplateComment };
  sidebarStore.updateEntry(updatedEntry);
};

const updateTemplateWording = (thirdPartyTemplateWording: string) => {
  const sidebarStore = useSidebarStore();
  const updatedEntry = { ...props.entry, thirdPartyTemplateWording };
  sidebarStore.updateEntry(updatedEntry);
};

const updateDescription = (thirdPartyDescription: string) => {
  const sidebarStore = useSidebarStore();
  const updatedEntry = { ...props.entry, thirdPartyDescription };
  sidebarStore.updateEntry(updatedEntry);
};
</script>
