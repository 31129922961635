import { computed, ref, watch } from "vue";
import ApiService from "@/services/ApiService";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import { useAIStreamer } from "@/services/AI/AIStreamer";
import { useStorage } from "@vueuse/core";
import { PromptTemplate } from "@/types";

import {
  formatStringAsObjectWithLineNumbers,
  splitDocumentByLines
} from "@/helpers/TextHelpers";

export function useClauseSplitter() {
  const contractBody = useStorage("clauses-contract-body", "");
  const splitClauses = ref({});
  let promptTemplate: PromptTemplate | null = null;
  const streamingPrompt = ref(false);
  const foundSplitClauses = ref(false);

  const {
    aiError: splitterError,
    startStreaming,
    generatedOutputWithVariablesAdded,
    streaming
  } = useAIStreamer();

  watch(
    () => streaming.value,
    () => {
      if (!streaming.value) {
        foundSplitClauses.value = true;

        splitClauses.value = splitDocumentByLines(
          JSON.parse(generatedOutputWithVariablesAdded.value),
          contractBody.value
        );
      }
    }
  );

  const contractAsJson = computed(() =>
    formatStringAsObjectWithLineNumbers(contractBody.value)
  );

  const loadPromptTemplate = async (template: string) => {
    const response = await ApiService.getPromptTemplate(template);
    return response.data.promptTemplate;
  };

  const generateSplitClauses = async (pContractBody: string) => {
    foundSplitClauses.value = false;
    contractBody.value = pContractBody;

    // set up the prompt templates
    promptTemplate = await loadPromptTemplate("Clause splitter");

    streamJson();
  };

  const streamJson = async () => {
    if (!promptTemplate) {
      console.log("Error loading prompt template");
      return;
    }
    // set up the json
    const variables = {
      contractAsJson: contractAsJson.value
    };
    const prompt = parsePrompt(promptTemplate.prompt, variables);
    streamingPrompt.value = false;
    await startStreaming({
      llmModel: promptTemplate.llmModel,
      prompt,
      superPrompt: promptTemplate.superPrompt,
      messages: [],
      jsonMode: promptTemplate.jsonMode
    });
  };

  return {
    foundSplitClauses,
    splitClauses,
    generateSplitClauses,
    splitterError,
    streaming
  };
}
