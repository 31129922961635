<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md lg:max-w-2xl">
      <img
        class="mx-auto h-10 w-auto"
        src="@/assets/images/lexplay-300.png"
        alt="LexPlay"
      />
      <h2
        class="mt-8 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Sign in to your LexPlay account
      </h2>
    </div>
    <LoginPanel />
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import LoginPanel from "@/components/signin/LoginPanel.vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";

const props = defineProps({
  redirect: {
    type: String,
    default: ""
  }
});

onMounted(() => {
  const authorisationStore = useAuthorisationStore();
  authorisationStore.redirect = props.redirect;
});
</script>
