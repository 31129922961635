<template>
  <LoadingPanel v-if="loading" />
  <div v-else class="relative lg:max-w-2xl">
    <nav class="mb-4 mt-1 flex" aria-label="Breadcrumb">
      <ol role="list" class="flex items-center space-x-1">
        <li>
          <a href="#" class="text-xs text-gray-400 hover:text-gray-500">
            Playbooks
          </a>
        </li>
      </ol>
    </nav>
    <BaseTitle class="flex">
      <BookOpenIcon class="mr-2 h-6 w-6 text-lexoo" />
      Your Playbooks
    </BaseTitle>

    <p
      v-if="client && client.playbooks.length > 0"
      class="mt-1 text-sm leading-6 text-gray-500"
    >
      Click a Playbook name below to view its entries.
    </p>
    <div v-if="clients.length > 1" class="mt-2">
      <select
        v-model="clientId"
        class="w-full rounded border border-gray-300 px-3 py-2 text-xs placeholder-gray-300 focus:border-indigo-300 focus:outline-none focus:ring focus:ring-indigo-100 dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:focus:border-gray-500 dark:focus:ring-gray-900"
      >
        <option disabled :value="undefined">Select a client</option>
        <option v-for="client in clients" :key="client.id" :value="client.id">
          {{ client.name }}
        </option>
      </select>
    </div>
    <div
      v-if="client && client.playbooks.length == 0"
      class="my-10 text-center"
    >
      <img
        class="mx-auto h-12 w-auto"
        src="@/assets/images/lexplay-300.png"
        alt="LexPlay"
      />
      <h3 class="mt-2 text-sm font-semibold text-gray-900">No playbooks</h3>
      <template v-if="hasPlaybookChangeAccess">
        <p class="mt-1 text-sm text-gray-500">
          Get started by creating a new playbook.
        </p>
        <div class="mt-6">
          <BaseButton @click="showNewPlaybookModal" buttonStyle="white">
            <PlusIcon class="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            New playbook
          </BaseButton>
        </div>
      </template>
    </div>
    <div v-else-if="client" class="mt-4 space-y-4">
      <template v-for="playbook in orderedPlaybooks" :key="playbook.id">
        <div
          @click.self="selectPlaybook(playbook)"
          class="flex shrink-0 cursor-pointer items-center justify-between gap-x-6 rounded-lg border border-gray-300 bg-white py-4 pl-4 pr-3 shadow-sm hover:bg-gray-50 focus:outline-none sm:px-6 sm:py-4"
        >
          <span @click="selectPlaybook(playbook)" class="flex items-center">
            <span class="flex flex-col text-sm">
              <span class="font-medium text-gray-900">{{ playbook.name }}</span>
              <span class="text-xs text-gray-500">
                <span class="block sm:inline">
                  Last updated:
                  <time :datetime="playbook.updatedAt.toString()">
                    {{ dateFormat(playbook.updatedAt) }}
                  </time>
                </span>
                <template v-if="playbook.numberOfEntries">
                  {{ " " }}
                  <span class="hidden sm:mx-1 sm:inline" aria-hidden="true">
                    &middot;
                  </span>
                  {{ " " }}
                  <span class="block sm:inline">
                    Number of entries: {{ playbook.numberOfEntries }}
                  </span>
                </template>
              </span>
            </span>
          </span>

          <Menu as="div" class="relative flex-none">
            <MenuButton
              class="1 -m-2 block rounded-full p-2 text-gray-500 duration-200 hover:scale-110 hover:bg-gray-100 hover:text-gray-900"
            >
              <span class="sr-only">Open options</span>
              <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute right-0 z-10 mt-2 w-52 origin-top-right rounded-md bg-white py-2 text-left shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
              >
                <MenuItem v-slot="{ active }">
                  <a
                    @click="selectPlaybook(playbook)"
                    href="#"
                    :class="[
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    ]"
                  >
                    View playbook entries
                  </a>
                </MenuItem>

                <MenuItem v-slot="{ active }">
                  <a
                    @click="editPlaybook(playbook)"
                    href="#"
                    :class="[
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    ]"
                  >
                    Change playbook name
                  </a>
                </MenuItem>

                <MenuItem v-if="!withinWord" v-slot="{ active }">
                  <a
                    @click="downloadPlaybookXls(playbook)"
                    href="#"
                    :class="[
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    ]"
                  >
                    Download as CSV
                  </a>
                </MenuItem>

                <MenuItem
                  v-if="!withinWord && playbook.lexplayLeap"
                  v-slot="{ active }"
                >
                  <a
                    @click="downloadPdfHandler(playbook)"
                    href="#"
                    :class="[
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    ]"
                  >
                    Download as PDF (beta)
                  </a>
                </MenuItem>

                <MenuItem v-slot="{ active }">
                  <a
                    @click="playbookToDelete = playbook"
                    href="#"
                    :class="[
                      active ? 'bg-gray-50' : '',
                      'block px-3 py-1 text-sm leading-6 text-gray-900'
                    ]"
                  >
                    Delete playbook
                  </a>
                </MenuItem>
              </MenuItems>
            </transition>
          </Menu>
        </div>
      </template>
    </div>
    <div
      v-if="client && client.playbooks.length > 0 && hasPlaybookChangeAccess"
      class="mt-5 flex justify-end space-x-3 sm:mt-4"
    >
      <BaseButton
        @click="showNewPlaybookModal"
        buttonStyle="white"
        class="text-sm"
      >
        New playbook
      </BaseButton>
    </div>
  </div>

  <!-- Modal using Headless UI Dialog -->
  <Dialog v-model:open="showModal" class="fixed inset-0 z-50 overflow-y-auto">
    <div
      class="flex min-h-screen min-h-screen items-center justify-center px-4 text-center"
    >
      <DialogOverlay
        @click="showModal = false"
        class="fixed inset-0 bg-black opacity-30"
      />

      <!-- Modal content -->
      <div
        class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
      >
        <DialogTitle class="text-lg font-medium leading-6 text-gray-900">
          {{ modalTitle }}
        </DialogTitle>
        <div class="mt-2">
          <BaseInput
            v-model="playbookName"
            placeholder="Playbook name"
            class="w-full"
          />
        </div>
        <div class="mt-5 flex flex-row-reverse sm:mt-4">
          <BaseButton buttonStyle="link" @click="showModal = false">
            Cancel
          </BaseButton>
          <BaseButton
            v-if="!playbookToEdit"
            buttonStyle="white"
            @click="createPlaybook"
          >
            Create
          </BaseButton>
          <BaseButton v-else buttonStyle="white" @click="updatePlaybook">
            Update
          </BaseButton>
        </div>
      </div>
    </div>
  </Dialog>
  <BaseConfirmationModal
    v-if="playbookToDelete"
    title="Delete playbook"
    :description="deleteDescription"
    button-text="Delete playbook"
    @confirm="deletePlaybook"
    @cancel="playbookToDelete = null"
  />
  <BaseConfirmationModal
    v-if="showChangePlaybookConfirmation"
    title="Are you sure you want to select a new Playbook?"
    description="Selecting a new playbook will clear your checkboxes for this document."
    button-text="Yes"
    @confirm="changePlaybook"
    @cancel="playbookToChangeToId = null"
  />

  <div
    v-if="clientId && downloadPdfPlaybookId && downloading"
    class="absolute"
    style="top: -10000px; left: -10000px"
  >
    <PdfPlaybook
      :clientId="clientId.toString()"
      :playbookId="downloadPdfPlaybookId"
      ref="pdfPlaybook"
      @finished-download="downloading = false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { BookOpenIcon, PlusIcon } from "@heroicons/vue/24/outline";

import BaseConfirmationModal from "@/components/base/BaseConfirmationModal.vue";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import { EllipsisVerticalIcon } from "@heroicons/vue/20/solid";
import { dateFormat } from "@/helpers/DateHelpers";
import LoadingPanel from "@/components/LoadingPanel.vue";

import { Client, Entry, Playbook } from "@/types";
import router from "@/router";

import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useSidebarStore } from "@/store/SidebarStore";
import ApiService from "@/services/ApiService";
import PlaybookInstanceCreator from "@/services/PlaybookInstanceCreator";
import { downloadPlaybookXls } from "@/services/PlaybookDownloader";
import PdfPlaybook from "@/components/PdfPlaybook.vue";

const sidebarStore = useSidebarStore();

const downloading = ref(false);

const client = ref<Client | null>(null);
const clients = computed(() => sidebarStore.notSelfServeClients);
const loading = ref(true);
const playbook = computed(() => sidebarStore.playbook);
const showModal = ref(false);
const playbookName = ref("");
const playbookToEdit = ref<Playbook | null>(null);
const playbookToDelete = ref<Playbook | null>(null);
const downloadPdfPlaybookId = ref<string | null>(null);
const pdfPlaybook = ref(null);
const withinWord = computed(() => sidebarStore.withinWord);

const authorisationStore = useAuthorisationStore();
const hasPlaybookChangeAccess = computed(
  () => authorisationStore.hasPlaybookChangeAccess
);

const playbookToChangeToId = ref(null as number | null);

const clientId = computed({
  get: () => client.value?.id,
  set: (value) => {
    client.value = clients.value.find((c) => c.id === value) ?? null;
  }
});

const orderedPlaybooks = computed(() => {
  if (!client.value) {
    return [];
  }

  return [...client.value.playbooks].sort(
    (a, b) => b.createdAt.getTime() - a.createdAt.getTime()
  );
});

onMounted(() => {
  sidebarStore
    .loadClients()
    .then(() => {
      if (sidebarStore.client) {
        client.value = sidebarStore.client;
      } else {
        if (clients.value.length === 1) {
          client.value = clients.value[0];
        }
      }
    })
    .finally(() => {
      loading.value = false;
    });
});

const modalTitle = computed(() => {
  if (playbookToEdit.value) {
    return "Change playbook name";
  } else {
    return "Create new playbook";
  }
});

const changePlaybook = () => {
  loading.value = true;
  if (
    playbookToChangeToId.value &&
    playbookToChangeToId.value !== playbook.value?.id
  ) {
    if (client.value) {
      sidebarStore.clientId = client.value.id;
    }

    sidebarStore.playbookId = playbookToChangeToId.value;
    const creator = new PlaybookInstanceCreator(playbookToChangeToId.value);
    creator.create();

    playbookToChangeToId.value = null;
  } else {
    goToPlaybookView();
  }
};

const createPlaybook = () => {
  if (client.value && playbookName.value) {
    ApiService.createPlaybook(client.value.id, playbookName.value, true)
      .then(({ data }) => {
        if (client.value) {
          sidebarStore.addPlaybookToClient(client.value, data.playbook);
        }
        showModal.value = false;
        playbookToChangeToId.value = data.playbook.id;
        changePlaybook();
      })

      .finally(() => {
        playbookName.value = "";
      });
  }
};

const deleteDescription = computed(() => {
  if (!playbookToDelete.value) {
    return "";
  }
  let result = `Are you sure you want to delete the ${playbookToDelete.value.name} playbook?`;
  if (
    playbookToDelete.value.numberOfEntries &&
    playbookToDelete.value.numberOfEntries > 0
  ) {
    result += `<br><br>(Doing this will also delete ${playbookToDelete.value.numberOfEntries} entries.)`;
  }
  return result;
});

const deletePlaybook = () => {
  if (playbookToDelete.value) {
    ApiService.destroyPlaybook(playbookToDelete.value)
      .then(() => {
        if (playbookToDelete.value) {
          sidebarStore.removePlaybook(playbookToDelete.value);
          playbookToDelete.value = null;
        }
      })
      .finally(() => {
        playbookToDelete.value = null;
      });
  }
};

const downloadPdfHandler = (thisPlaybook: Playbook) => {
  downloadPdfPlaybookId.value = thisPlaybook.id.toString();
  downloading.value = true;
};

const editPlaybook = (thisPlaybook: Playbook) => {
  playbookToEdit.value = thisPlaybook;
  playbookName.value = thisPlaybook.name;
  showModal.value = true;
};

const showNewPlaybookModal = () => {
  playbookToEdit.value = null;
  playbookName.value = "";
  showModal.value = true;
};

const updatePlaybook = () => {
  if (playbookToEdit.value) {
    playbookToEdit.value.name = playbookName.value;
    ApiService.updatePlaybook(playbookToEdit.value)
      .then(({ data }) => {
        sidebarStore.updatePlaybook(data.playbook);
        showModal.value = false;
      })
      .finally(() => {
        playbookToEdit.value = null;
        playbookName.value = "";
      });
  }
};

const goToPlaybookView = () => {
  console.log("Going to " + playbook.value?.id);
  router.push(`/sidebar/playbooks/${playbook.value?.id}`);
};

const anyCheckedEntries = computed(() => {
  const playbookInstance = sidebarStore.playbookInstance;
  if (!playbookInstance) {
    return false;
  }
  const numberOfCheckedEntries = playbookInstance.entries.filter(
    (entry: Entry) =>
      entry.checkedStatus === "checked" ||
      entry.checkedStatus == "flagged" ||
      entry.checkedStatus == "ignored"
  ).length;
  return numberOfCheckedEntries > 0;
});

const selectPlaybook = (thisPlaybook: Playbook) => {
  if (!playbook.value || !anyCheckedEntries.value) {
    playbookToChangeToId.value = thisPlaybook.id;
    changePlaybook();
  } else if (playbook.value && thisPlaybook.id !== playbook.value.id) {
    // This triggers the confirmation
    playbookToChangeToId.value = thisPlaybook.id;
  } else {
    goToPlaybookView();
  }
};

const showChangePlaybookConfirmation = computed(() => {
  return (
    anyCheckedEntries.value &&
    playbook.value &&
    playbookToChangeToId.value &&
    playbookToChangeToId.value !== playbook.value?.id
  );
});
</script>
