<template>
  <div class="mb-4 border-b border-gray-200">
    <PlaybookNav />

    <nav class="flex lg:max-w-2xl" aria-label="Tabs">
      <div class="-mb-px flex space-x-4 sm:space-x-8">
        <a
          @click.prevent="
            clearFilterText();
            $emit('update:modelValue', 'playbookInstance');
          "
          href="#"
          :class="[
            modelValue === 'playbookInstance'
              ? 'border-lexoo text-lexoo'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium'
          ]"
        >
          Entries
          <button
            class="align-top"
            v-if="
              modelValue === 'playbookInstance' &&
              hasPlaybookChangeAccess &&
              (editMode || !isPlaybookLite)
            "
            @click.prevent.stop="setAddingEntry"
          >
            <PlusCircleIcon
              class="h-5 w-5 text-gray-400 hover:text-lexoo"
              aria-hidden="true"
            />
          </button>
        </a>
        <a
          v-cloak
          v-if="!isPlaybookLite"
          @click.prevent="$emit('update:modelValue', 'peek')"
          href="#"
          :class="[
            modelValue === 'peek'
              ? 'border-lexoo text-lexoo'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
            'whitespace-nowrap border-b-2 px-1 pb-2 text-sm font-medium'
          ]"
        >
          Peek
        </a>
      </div>
      <div class="ml-auto">
        <Menu as="div" class="relative ml-3 inline-block text-left">
          <div>
            <MenuButton
              class="-my-2 group flex items-center rounded-full p-2 focus:outline-none"
            >
              <WrenchScrewdriverIcon
                class="mr-1 h-5 w-5 text-gray-400 group-hover:text-lexoo"
                aria-hidden="true"
              />
              <span
                class="font-medium text-sm text-gray-500 group-hover:text-gray-600"
              >
                Tools
              </span>
            </MenuButton>
          </div>

          <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
          >
            <MenuItems
              class="absolute right-0 z-30 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              :class="menuWidthClass"
            >
              <div v-if="isBeta" class="py-1">
                <MenuItem v-slot="{ active }">
                  <button
                    class="group flex w-full items-center px-4 py-2 text-sm"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    ]"
                    @click="
                      $router.push(
                        `/sidebar/playbooks/${playbookId}/tools/draft-clause`
                      )
                    "
                  >
                    <SparklesIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Generate clause
                  </button>
                </MenuItem>

                <MenuItem v-slot="{ active }">
                  <button
                    class="group flex w-full items-center px-4 py-2 text-sm"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    ]"
                    @click="
                      $router.push(
                        `/sidebar/playbooks/${playbookId}/tools/pushbacks`
                      )
                    "
                  >
                    <SparklesIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Generate pushbacks
                  </button>
                </MenuItem>

                <MenuItem v-if="withinWord" v-slot="{ active }">
                  <button
                    class="group flex w-full items-center px-4 py-2 text-sm"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    ]"
                    @click="
                      $router.push(
                        `/sidebar/playbooks/${playbookId}/tools/definitions`
                      )
                    "
                  >
                    <AcademicCapIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Definitions
                  </button>
                </MenuItem>
              </div>
              <div class="py-1">
                <MenuItem
                  v-slot="{ active }"
                  v-if="
                    playbook &&
                    ((playbook.lexplayLeap && variableIdentifiers.length > 0) ||
                      (!playbook.lexplayLeap &&
                        playbook.oldSkoolVariables.length > 0))
                  "
                >
                  <button
                    class="group flex w-full items-center px-4 py-2 text-sm"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    ]"
                    @click="showVariablesPanel = !showVariablesPanel"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      fill="currentColor"
                    >
                      <path
                        d="M4 18V14.3C4 13.4716 3.32843 12.8 2.5 12.8H2V11.2H2.5C3.32843 11.2 4 10.5284 4 9.7V6C4 4.34315 5.34315 3 7 3H8V5H7C6.44772 5 6 5.44772 6 6V10.1C6 10.9858 5.42408 11.7372 4.62623 12C5.42408 12.2628 6 13.0142 6 13.9V18C6 18.5523 6.44772 19 7 19H8V21H7C5.34315 21 4 19.6569 4 18ZM20 14.3V18C20 19.6569 18.6569 21 17 21H16V19H17C17.5523 19 18 18.5523 18 18V13.9C18 13.0142 18.5759 12.2628 19.3738 12C18.5759 11.7372 18 10.9858 18 10.1V6C18 5.44772 17.5523 5 17 5H16V3H17C18.6569 3 20 4.34315 20 6V9.7C20 10.5284 20.6716 11.2 21.5 11.2H22V12.8H21.5C20.6716 12.8 20 13.4716 20 14.3Z"
                      ></path>
                    </svg>
                    <span> Variables </span>
                  </button>
                </MenuItem>
                <MenuItem v-if="!withinWord" v-slot="{ active }">
                  <button
                    class="group flex w-full items-center px-4 py-2 text-sm"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    ]"
                    @click="handleDownloadXlsPlaybook"
                  >
                    <CloudArrowDownIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Download as CSV
                  </button>
                </MenuItem>
                <MenuItem
                  v-if="!withinWord && playbook && playbook.lexplayLeap"
                  v-slot="{ active }"
                >
                  <button
                    class="group flex w-full items-center px-4 py-2 text-sm"
                    :class="[
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                    ]"
                    @click="handleDownloadPdfPlaybook"
                  >
                    <CloudArrowDownIcon
                      class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                      aria-hidden="true"
                    />
                    Download as PDF (beta)
                  </button>
                </MenuItem>
              </div>
            </MenuItems>
          </transition>
        </Menu>
      </div>
    </nav>
  </div>

  <VariableQuestionsModal
    v-if="playbookInstance && playbook && playbook.lexplayLeap"
    :show="showVariablesPanel && variableIdentifiers.length > 0"
    :playbookInstance="playbookInstance"
    @close="showVariablesPanel = false"
    @updateVariables="playbookInstance.variables = $event"
  />

  <OldSkoolVariableQuestionsModal
    v-else-if="playbookInstance && playbook && !playbook.lexplayLeap"
    :show="showVariablesPanel && playbook.oldSkoolVariables.length > 0"
    :playbookInstance="playbookInstance"
    :oldSkoolVariables="playbook?.oldSkoolVariables || []"
    @close="showVariablesPanel = false"
    @updateAnswers="playbookInstance.oldSkoolVariableAnswers = $event"
  />

  <div
    v-if="client && playbookId && downloading"
    :class="{ hidden: !downloading }"
    class="absolute"
    style="top: -10000px; left: -10000px"
  >
    <PdfPlaybook
      @finished-download="downloading = false"
      :clientId="client.id.toString()"
      :playbookId="playbookId.toString()"
      ref="pdfPlaybook"
    />
  </div>
</template>

<script setup lang="ts">
/* global Office */
import { computed, PropType, ref } from "vue";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { NewEntry } from "@/types";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { SparklesIcon } from "@heroicons/vue/24/solid";
import { WrenchScrewdriverIcon } from "@heroicons/vue/24/outline";
import {
  AcademicCapIcon,
  CloudArrowDownIcon,
  PlusCircleIcon
} from "@heroicons/vue/24/outline";
import PlaybookNav from "@/components/sidebar/PlaybookNav.vue";
import { downloadPlaybookXls } from "@/services/PlaybookDownloader";
import VariableQuestionsModal from "@/components/sidebar/VariableQuestionsModal.vue";
import OldSkoolVariableQuestionsModal from "@/components/sidebar/OldSkoolVariableQuestionsModal.vue";
import PdfPlaybook from "@/components/PdfPlaybook.vue";

defineEmits(["update:modelValue"]);

const authorisationStore = useAuthorisationStore();
const sidebarStore = useSidebarStore();

const downloading = ref(false);
const showVariablesPanel = ref(false);

const withinWord = computed(() => sidebarStore.withinWord);
const pdfPlaybook = ref(null);

const client = computed(() => sidebarStore.client);
const playbook = computed(() => sidebarStore.playbook);
const playbookId = computed(() => sidebarStore.playbookId);
const playbookInstance = computed(() => sidebarStore.playbookInstance);
const variableIdentifiers = computed(() => sidebarStore.variableIdentifiers);

const editMode = computed({
  get: () => sidebarStore.editMode,
  set: (value) => (sidebarStore.editMode = value)
});

const hasPlaybookChangeAccess = computed(
  () => authorisationStore.hasPlaybookChangeAccess
);

const isBeta = computed(() => authorisationStore.isBeta);

const isPlaybookLite = computed(() => playbook.value?.lexplayLeap ?? false);
const menuWidthClass = computed(() => {
  if (client.value && client.value.playbooks.length > 1) {
    return "w-64";
  }
  return "w-52";
});

defineProps({
  modelValue: {
    type: String as PropType<"playbookInstance" | "peek" | "issues">,
    required: true
  }
});

const clearFilterText = () => {
  sidebarStore.filterText = "";
};

const handleDownloadXlsPlaybook = () => {
  if (playbook.value && client.value) {
    downloading.value = true;
    downloadPlaybookXls(playbook.value);
  }
};

const handleDownloadPdfPlaybook = () => {
  if (playbook.value && client.value) {
    downloading.value = true;
    // nextTick(() => {
    //   if (pdfPlaybook.value) {
    //     (pdfPlaybook.value as typeof PdfPlaybook).downloadPdf();
    //   }
    // });
  }
};

const setAddingEntry = () => {
  if (!playbook.value) {
    return;
  }

  if (isPlaybookLite.value) {
    const newEntry = {
      playbookId: playbookId.value,
      position: 0
    };
    sidebarStore.createEntry(newEntry as NewEntry);
  } else {
    sidebarStore.setAddingEntry(0);
  }
};
</script>
