<script setup lang="ts">
import { PropType } from "vue";
import { PencilIcon } from "@heroicons/vue/24/solid";
import { useInlineEdit } from "@/components/sidebar/InlineEdit";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";
import TemplateCommentDisplay from "@/components/TemplateCommentDisplay.vue";
import { PlaybookInstance } from "@/types";

const props = defineProps({
  entryId: {
    type: Number,
    required: true
  },

  showLabel: {
    type: Boolean,
    default: false
  },

  text: {
    type: String,
    required: true
  },

  filterText: {
    type: String,
    required: true
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: false
  }
});

const emit = defineEmits(["update"]);
const {
  editBox,
  editing,
  hasPlaybookChangeAccess,
  model,
  startEditing,
  saveValue
} = useInlineEdit(emit, props);
</script>

<template>
  <div class="group relative">
    <div v-if="editing && model">
      <SimpleEditableText
        ref="editBox"
        class="mt-1 min-w-full text-xs"
        v-model="model"
      />
      <div class="my-4 flex justify-end">
        <span class="rounded shadow-sm">
          <BaseButton @click="saveValue" size="small" position="left">
            Save
          </BaseButton>

          <BaseButton
            @click="editing = false"
            size="small"
            position="right"
            buttonStyle="white"
          >
            Cancel
          </BaseButton>
        </span>
      </div>
    </div>
    <TemplateCommentDisplay
      v-else
      :showLabel="showLabel"
      :entryId="entryId"
      :templateComment="text"
      :playbookInstance="playbookInstance"
      :filterText="filterText"
    />
    <button
      v-if="hasPlaybookChangeAccess && !editing"
      type="button"
      class="invisible absolute top-1 right-1 rounded bg-white px-1 py-1 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 group-hover:visible"
      @click="startEditing"
    >
      <PencilIcon class="h-4 w-4" />
    </button>
  </div>
</template>
