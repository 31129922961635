<template>
  <div class="mt-2 inline-flex rounded-md shadow-sm">
    <input
      type="text"
      :value="text"
      class="inline rounded-none rounded-l-md border-0 bg-gray-50 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6"
      :style="{ width: inputWidth + 'px' }"
    />
    <button
      type="button"
      @click="copyText"
      data-tippy-content="Copied!"
      class="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
      :class="{ 'show-tippy': copied }"
    >
      <CheckIcon v-if="copied" class="h-4 w-4 text-green-400" />
      <ClipboardDocumentIcon v-else class="h-4 w-4" />
      <span v-if="copied" class="absolute -left-2 -top-6 text-green-400">
        Copied!
      </span>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, Ref } from "vue";

import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/vue/24/outline";

const props = defineProps({
  text: {
    type: String,
    required: true
  }
});
const inputWidth = computed(() => props.text.length * 8 + 20);

const copied = ref(false);
const slotSpan: Ref<HTMLElement | null> = ref(null);

const copyText = () => {
  let text = "";
  if (slotSpan.value) {
    text = slotSpan.value.innerText;
  }
  navigator.clipboard.writeText(text).then(() => {
    copied.value = true;

    setTimeout(() => {
      copied.value = false;
    }, 2000);
  });
};
</script>

<style scoped>
/* Ensure the shadow element has the same font properties as the input */
.hidden {
  position: absolute;
  visibility: hidden;
  height: 0;
  overflow: hidden;
  white-space: nowrap; /* Prevent text from wrapping */
}
</style>
