import axios from "axios";
import humps from "humps";
import { AxiosInstance, AxiosTransformer } from "axios";

import router from "@/router";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useSidebarStore } from "@/store/SidebarStore";
import { convertAtValuesToDate } from "@/config/DateConverter";

type AnyObject = { [key: string]: any };

function deepSnakeCaseKeys(obj: any, ignoreTransform = false): any {
  if (Array.isArray(obj)) {
    return obj.map((val) => deepSnakeCaseKeys(val, ignoreTransform));
  } else if (obj instanceof Date) {
    return obj;
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
      const shouldIgnore = key === "variables";
      const snakeKey = ignoreTransform ? key : humps.decamelize(key);
      acc[snakeKey] = deepSnakeCaseKeys(obj[key], shouldIgnore);
      return acc;
    }, {});
  } else {
    return obj;
  }
}

function deepCamelizeKeys(obj: any, ignoreTransform = false): any {
  if (Array.isArray(obj)) {
    return obj.map((val) => deepCamelizeKeys(val, ignoreTransform));
  } else if (obj instanceof Date) {
    return obj;
  } else if (obj !== null && typeof obj === "object") {
    return Object.keys(obj).reduce((acc: AnyObject, key: string) => {
      const shouldIgnore = key === "variables";
      const camelKey = ignoreTransform ? key : humps.camelize(key);
      acc[camelKey] = deepCamelizeKeys(obj[key], shouldIgnore);
      return acc;
    }, {});
  } else {
    return obj;
  }
}

// I'm having to do this to satisfy the fact that for some reason the
// transformers in axios can be an array, an object, or undefined.
const safeAxiosTransformer = (
  transformable: AxiosTransformer[] | AxiosTransformer | undefined
): AxiosTransformer[] => {
  if (Array.isArray(transformable)) {
    return transformable;
  }
  if (transformable) {
    return [transformable];
  }
  return [];
};

export const defaultTransformResponse = (): AxiosTransformer[] => {
  const defaults = axios.defaults || {};
  return safeAxiosTransformer(defaults.transformResponse);
};

export const defaultTransformRequest = (): AxiosTransformer[] => {
  const defaults = axios.defaults || {};
  return safeAxiosTransformer(defaults.transformRequest);
};

const createApiClient = (handleErrors: boolean) => {
  const apiClient: AxiosInstance = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/v1`,
    withCredentials: false, // This is the default
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      From: `${import.meta.env.VITE_FROM}`,
      "X-Client-Version": `${import.meta.env.LEXPLAY_VERSION}`
    },
    transformResponse: [
      ...defaultTransformResponse(),
      (data) => convertAtValuesToDate(data),
      (data) => deepCamelizeKeys(data),
      (data) => data.data
    ],
    transformRequest: [
      (data) => deepSnakeCaseKeys(data),
      ...defaultTransformRequest()
    ]
  });

  // Add a request interceptor
  apiClient.interceptors.request.use((config) => {
    const authorisationStore = useAuthorisationStore();
    const authorisation = authorisationStore.authorisation;
    if (authorisation) {
      config.headers.Authorization = authorisation;
    }

    const actionCableIdentifier = authorisationStore.actionCableIdentifier;
    if (actionCableIdentifier) {
      config.headers["X-Action-Cable-Identifier"] = actionCableIdentifier;
    }

    const sidebarStore = useSidebarStore();
    config.headers["X-Within-Word"] = sidebarStore.withinWord;
    return config;
  });

  if (handleErrors) {
    apiClient.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const authorisationStore = useAuthorisationStore();
        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          authorisationStore.clearUser().then(() => {
            router.push("/login");
          });

          authorisationStore.setAuthExpiredAlert();
        }

        return Promise.reject(error);
      }
    );
  }
  return apiClient;
};

export { createApiClient };
