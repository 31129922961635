export default class OoxmlParser {
  ooxml: string;

  constructor(ooxml: string) {
    this.ooxml = ooxml;
  }

  docId(): string | null {
    const xpath = "string(//w:settings/w15:docId/@w15:val)";
    const doc = new DOMParser();
    const xmlDoc = doc.parseFromString(this.ooxml, "text/xml");
    const resolver = (prefix: string | null): string | null => {
      if (!prefix) {
        return null;
      }
      return (
        {
          w: "http://schemas.openxmlformats.org/wordprocessingml/2006/main",
          w15: "http://schemas.microsoft.com/office/word/2012/wordml"
        }[prefix] ?? null
      );
    };
    const result = xmlDoc.evaluate(
      xpath,
      xmlDoc,
      resolver,
      XPathResult.STRING_TYPE,
      null
    );
    if (result.stringValue) {
      return result.stringValue;
    }
    return null;
  }
}
