<template>
  <div
    v-if="showCheckBox"
    v-click-away="onClickAway"
    @mouseover="showMenu"
    @mouseleave="leaveHover"
  >
    <button
      @click="
        checked = !checked;
        open = false;
      "
      :class="{ checked }"
      ref="btnRef"
    >
      &nbsp;
    </button>
    <Teleport to="#bottom-of-body" v-if="open">
      <Menu>
        <div
          :style="{
            position: 'absolute',
            top: position.top + 'px',
            left: position.left + 'px'
          }"
          class="z-30"
          :show="open"
        >
          <MenuItems
            static
            class="w-30 rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            @mouseover="showMenu"
            @mouseleave="leaveHover"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active }" class="relative py-2 pl-8 pr-4">
                <a
                  @click.prevent="check"
                  href="#"
                  class="focus:outline-none"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  ]"
                >
                  <span>Tick</span>
                  <span
                    v-if="checked"
                    class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-lexoo"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </a>
              </MenuItem>

              <MenuItem v-slot="{ active }" class="relative py-2 pl-8 pr-4">
                <a
                  href="#"
                  class="focus:outline-none"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  ]"
                  @click.prevent="ignore"
                >
                  <span>Ignore</span>
                  <span
                    v-if="ignored && !checked"
                    class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-lexoo"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active }" class="relative py-2 pl-8 pr-4">
                <a
                  href="#"
                  class="focus:outline-none"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm'
                  ]"
                  @click.prevent="flag"
                >
                  <span>Flag</span>
                  <span
                    v-if="flagged && !checked"
                    class="absolute inset-y-0 left-0 flex items-center pl-1.5 text-lexoo"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </div>
      </Menu>
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onBeforeUpdate, reactive, ref } from "vue";
import { Menu, MenuItem, MenuItems } from "@headlessui/vue";
import { CheckIcon } from "@heroicons/vue/24/solid";
import { directive as vClickAway } from "vue3-click-away";
import { useSidebarStore } from "@/store/SidebarStore";

const sidebarStore = useSidebarStore();
const showCheckBox = computed(() => sidebarStore.withinWord);

const onClickAway = () => {
  closeMenu();
};

const props = defineProps({
  modelValue: String,
  targetPosition: {
    type: String,
    default: "right"
  }
});

const check = () => {
  checked.value = !checked.value;
  closeMenu();
};

const checked = computed({
  get() {
    return props.modelValue == "checked";
  },
  set(value: boolean) {
    if (value) {
      updateValue("checked");
    } else {
      updateValue("unchecked");
    }
  }
});

const btnRef = ref<HTMLButtonElement | null>(null);
const position = reactive({ top: 0, left: 0 });
const calculatePosition = () => {
  const rect = btnRef.value?.getBoundingClientRect();
  if (rect) {
    position.top = window.scrollY + rect.top + rect.height;

    if (props.targetPosition == "right") {
      position.left = window.scrollX + rect.left;
    } else if (props.targetPosition == "left") {
      position.left = window.scrollX + rect.left - rect.width * 4;
    }
  }
};

onMounted(calculatePosition);
onBeforeUpdate(calculatePosition);

const hoverTimer = ref(undefined);

const leaveHover = () => {
  if (hoverTimer.value) {
    clearTimeout(hoverTimer.value);
  }
  hoverTimer.value = setTimeout(() => {
    open.value = false;
  }, 100);
};

const closeMenu = () => {
  open.value = false;
  clearTimeout(hoverTimer.value);
};

const showMenu = () => {
  calculatePosition();
  open.value = true;
  clearTimeout(hoverTimer.value);
};

const flagged = computed({
  get() {
    return props.modelValue == "flagged";
  },
  set(value: boolean) {
    if (value) {
      updateValue("flagged");
    } else {
      updateValue("unchecked");
    }
  }
});

const flag = () => {
  flagged.value = !flagged.value;
  closeMenu();
};

const ignored = computed({
  get() {
    return props.modelValue == "ignored";
  },
  set(value: boolean) {
    if (value) {
      updateValue("ignored");
    } else {
      updateValue("unchecked");
    }
  }
});

const ignore = () => {
  ignored.value = !ignored.value;
  closeMenu();
};

const open = ref(false);

const emit = defineEmits(["update:modelValue"]);

const updateValue = (value: string) => {
  emit("update:modelValue", value);
};
</script>

<style scoped lang="scss">
button {
  appearance: none;
  flex-shrink: 1;
  @apply inline-block h-4 w-4 rounded border border-gray-300 bg-white;
  &.checked {
    background-color: currentColor;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    @apply border-0  text-lexoo;
  }
}
</style>
