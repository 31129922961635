<template>
  <div class="divide-y-gray-200 max-w-lg space-y-8 divide-y">
    <div class="pt-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        General settings
      </h3>
      <p class="max-w-2xl text-sm text-gray-500">
        These are some general settings for this client.
      </p>
      <div class="mt-6">
        <label class="block text-sm font-medium text-gray-700">Colour</label>
      </div>

      <div class="mt-1">
        <ColourPicker v-model="clientColour" class="mt-1" />
      </div>

      <div class="mt-6">
        <BaseSelect
          v-model="ssoProvider"
          :options="providerOptions"
          label="Auth provider"
        />
      </div>

      <SwitchGroup as="div" class="mt-6 flex items-center">
        <Switch
          v-model="backupsEnabled"
          :class="[
            backupsEnabled ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2'
          ]"
        >
          <span class="sr-only">Daily playbook backups</span>
          <span
            aria-hidden="true"
            :class="[
              backupsEnabled ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            ]"
          />
        </Switch>
        <SwitchLabel as="span" class="ml-3">
          <span class="text-sm font-medium text-gray-900">
            Daily playbook backups
          </span>
        </SwitchLabel>
      </SwitchGroup>

      <SwitchGroup as="div" class="mt-6 flex items-center">
        <Switch
          v-model="trial"
          :class="[
            trial ? 'bg-indigo-600' : 'bg-gray-200',
            'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2'
          ]"
        >
          <span class="sr-only">On trial</span>
          <span
            aria-hidden="true"
            :class="[
              trial ? 'translate-x-5' : 'translate-x-0',
              'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
            ]"
          />
        </Switch>
        <SwitchLabel as="span" class="ml-3">
          <span class="text-sm font-medium text-gray-900"> On trial </span>
        </SwitchLabel>
      </SwitchGroup>
      <div v-if="trial" class="mt-6 flex items-center">
        <BaseInput
          label="Trial expires on"
          inputType="date"
          v-model="trialExpirationAt"
          class="w-40"
        />
      </div>

      <div class="mt-5 flex flex-row-reverse sm:mt-4">
        <BaseButton @click="saveClient" :disabled="saved" buttonStyle="white">
          Save general settings
        </BaseButton>
        <transition
          enter-active-class="transition ease-out duration-100 transform"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition ease-in duration-1000 transform"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <span
            v-show="saved"
            class="mt-3 mr-3 inline-flex justify-center pt-1.5 text-indigo-600"
          >
            Saved!
          </span>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, Ref, watch } from "vue";
import { Switch, SwitchGroup, SwitchLabel } from "@headlessui/vue";
import { Client } from "@/types";
import ApiService from "@/services/ApiService";
import ColourPicker from "@/components/admin/ColourPicker.vue";

const props = defineProps({
  client: {
    required: true,
    type: Object as PropType<Client>
  }
});

const backupsEnabled = ref(false) as Ref<boolean | undefined>;
const clientColour = ref("indigo");
const ssoProvider = ref("");
const saved = ref(false);
const trial = ref(false);
const trialExpirationAt = ref(null) as Ref<Date | null>;

const providerOptions = computed(() => {
  return [
    { name: "Email", value: null },
    { name: "Google", value: "google" }
  ];
});

watch(
  () => props.client,
  (value: Client) => {
    backupsEnabled.value = value.backupsEnabled;
    clientColour.value = value.colour;
    ssoProvider.value = value.ssoProvider;
    trial.value = value.trial;
    trialExpirationAt.value = value.trialExpirationAt;
  },
  { immediate: true }
);

const saveClient = () => {
  const client = {
    ...props.client,
    colour: clientColour.value,
    backupsEnabled: backupsEnabled.value,
    ssoProvider: ssoProvider.value,
    trial: trial.value,
    trialExpirationAt: trialExpirationAt.value
  };
  console.log("Saving client");
  ApiService.updateClient(client).then(() => {
    saved.value = true;
    setTimeout(() => {
      saved.value = false;
    }, 2000);
  });
};
</script>
