import { computed, ref, Ref, watch } from "vue";
import { defineStore } from "pinia";
import isEqual from "lodash.isequal";
import { debounce } from "throttle-debounce";

import { generateHashForObject } from "@/helpers/TextHelpers";
import { Clause } from "@/types";
import ApiService from "@/services/ApiService";
import { useSidebarStore } from "@/store/SidebarStore";

export const useClausesStore = defineStore("ClausesStore", () => {
  const clauses: Ref<Clause[]> = ref([]);
  const loaded = ref(false);

  const sidebarStore = useSidebarStore();
  const playbookInstance = computed(() => sidebarStore.playbookInstance);
  const creatingClauseTemporaryIds = ref(new Set<string>());
  const selectedClause = ref<Clause | null>(null);

  const generateOpenAiId = async (clause: Clause) => {
    const objectToTest = {
      clauseBody: clause.clauseBody,
      entryId: clause.entryId,
      playbookInstanceId: clause.playbookInstanceId
    };

    return await generateHashForObject(objectToTest);
  };

  const clauseAlreadyExists = async (clause: Clause) => {
    for (const existingClause of clauses.value) {
      if (!existingClause.openAiId) {
        existingClause.openAiId = await generateOpenAiId(clause);
      }
    }

    if (!clause.openAiId) {
      clause.openAiId = await generateHashForObject(clause);
    }

    return clauses.value.some((i) => i.openAiId === clause.openAiId);
  };

  const getClausesByEntryId = computed(() => {
    return (entryId: number) => {
      return clauses.value.filter((i) => {
        return i.entryId.toString() === entryId.toString();
      });
    };
  });

  const bulkCreateOrUpdateClauses = async (clauses: Clause[]) => {
    if (!playbookInstance.value) {
      return;
    }

    clauses.forEach(async (clause) => {
      createOrUpdateClause(clause);
    });
  };

  const createClause = async (clause: Clause) => {
    clause.openAiId = await generateOpenAiId(clause);
    const alreadyExists = await clauseAlreadyExists(clause);
    if (
      !playbookInstance.value ||
      !clause.clauseBody ||
      clause.clauseBody.length == 0 ||
      alreadyExists ||
      creatingClauseTemporaryIds.value.has(clause.openAiId)
    ) {
      return;
    }
    clauses.value.push(clause);
    creatingClauseTemporaryIds.value.add(clause.openAiId);
    const { data } = await ApiService.createClause(
      playbookInstance.value,
      clause
    );
    const newClause = data.clause;
    const index = clauses.value.findIndex(
      (i) => i.openAiId === data.clause.openAiId
    );
    clauses.value[index] = newClause;
  };

  const debouncedUpdateClause = debounce(1000, (clause: Clause) => {
    updateClause(clause);
  });

  const clearClauses = () => {
    clauses.value = [];
    loaded.value = false;
    // ALso need to destroy from database
    if (playbookInstance.value) {
      ApiService.destroyClauses(playbookInstance.value);
    }
    creatingClauseTemporaryIds.value = new Set<string>();
  };

  const createOrUpdateClause = async (clause: Clause) => {
    if (!playbookInstance.value || !clause.id) {
      return;
    }

    const updatedClause = clauses.value.find((i) => i.id === clause.id);
    if (updatedClause) {
      // Does the updated clause have anything different?
      if (isEqual(updatedClause, clause)) {
        return;
      }
      debouncedUpdateClause(clause);
    } else {
      createClause(clause);
    }
  };

  const loadClauses = async () => {
    if (!playbookInstance.value || loaded.value) {
      return;
    }

    const { data } = await ApiService.getClauses(playbookInstance.value);
    clauses.value = data.clauses;
    loaded.value = true;
    creatingClauseTemporaryIds.value = new Set<string>();
  };

  const $reset = () => {
    clauses.value = [];
    selectedClause.value = null;
  };

  const updateClause = async (clause: Clause) => {
    if (!playbookInstance.value) {
      return;
    }

    const { data } = await ApiService.updateClause(clause);
    const index = clauses.value.findIndex((i) => i.id === data.clause.id);
    clauses.value[index] = data.clause;
  };

  const selectClause = (clause: Clause) => {
    selectedClause.value = clause;
  };

  watch(
    () => playbookInstance.value,
    () => {
      if (loaded.value) {
        loaded.value = false;
        loadClauses();
      }
    }
  );

  return {
    bulkCreateOrUpdateClauses,
    clearClauses,
    debouncedUpdateClause,
    clauses,
    createClause,
    createOrUpdateClause,
    getClausesByEntryId,
    loadClauses,
    $reset,
    selectClause,
    selectedClause,
    updateClause
  };
});
