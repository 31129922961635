import { computed, ref, Ref, watch } from "vue";

import { PromptTemplate } from "@/types";

import ApiService from "@/services/ApiService";
import { parsePrompt } from "@/services/AI/AIPromptParser";
import { useAIStreamer } from "@/services/AI/AIStreamer";

import { getWordDocumentAsHtml } from "@/helpers/WordHelpers";
import { clausesFromRow } from "@/helpers/PromptHelpers";
import { useAIStreamsStore } from "@/store/AIStreamsStore";
import { useClausesStore } from "@/store/ClausesStore";
import { useIssuesStore } from "@/store/IssuesStore";
import { useSidebarStore } from "@/store/SidebarStore";

export function useClausesFinder() {
  const csv = ref("");
  let promptTemplate: PromptTemplate | null = null;
  const streamingPrompt = ref(false);
  const aiStreamsStore = useAIStreamsStore();
  const clausesStore = useClausesStore();
  const issuesStore = useIssuesStore();
  const sidebarStore = useSidebarStore();
  const contractAsJson = ref({});
  const documentAsHtml = ref("");
  const definitions = ref([]) as Ref<{ title: string; description: string }[]>;
  const foundEntryIds = ref(new Set<string>());

  const {
    aiError: clausesFinderError,
    startStreaming,
    generatedOutputWithVariablesAdded,
    streaming
  } = useAIStreamer();

  const playbookBody = ref("");

  const playbookInstance = computed(() => sidebarStore.playbookInstance);

  const findClauses = async (
    pContractAsJson: Record<number, string>,
    pPlaybookBody: string,
    pDefinitions: any[]
  ) => {
    contractAsJson.value = pContractAsJson;
    playbookBody.value = pPlaybookBody;
    definitions.value = pDefinitions;

    // set up the prompt templates
    promptTemplate = await loadPromptTemplate("Clauses finder");
    foundEntryIds.value = new Set<string>();

    if (sidebarStore.withinWord) {
      documentAsHtml.value = await getWordDocumentAsHtml();
    }

    csv.value = "";
    streamJson();
  };

  const streamIdentifiers = () => {
    if (!playbookInstance.value) {
      return [];
    }

    return ["clauses", playbookInstance.value.id.toString()];
  };

  const streamJson = async () => {
    if (!promptTemplate) {
      console.log("Error loading prompt template");
      return;
    }
    // set up the json
    const variables = {
      playbook: playbookBody.value,
      contractAsJson: JSON.stringify(contractAsJson.value)
    };
    const prompt = parsePrompt(promptTemplate.prompt, variables);
    streamingPrompt.value = false;
    const currentStreamId = await startStreaming({
      llmModel: promptTemplate.llmModel,
      prompt,
      superPrompt: promptTemplate.superPrompt,
      messages: [],
      jsonMode: promptTemplate.jsonMode
    });

    aiStreamsStore.setStream(currentStreamId, streamIdentifiers());
  };

  const loadPromptTemplate = async (template: string) => {
    const response = await ApiService.getPromptTemplate(template);
    return response.data.promptTemplate;
  };

  const createClausesFromDefinitions = async (entryId: number) => {
    const clausesForEntry = clausesStore.getClausesByEntryId(entryId);
    if (definitions.value.length > 0) {
      for (const definition of definitions.value) {
        if (clausesForEntry.some((c) => c.clauseBody.match(definition.title))) {
          const clauseBody = definition.title + " " + definition.description;
          const newClause = {
            entryId,
            playbookInstanceId: playbookInstance.value?.id ?? 0,
            clauseBody,
            done: true,
            entryDone: true,
            lineNumber: -1
          };
          await clausesStore.createClause(newClause);
        }
      }
    }
  };

  function splitTextByNewLine(text) {
    // Split the text by \n and filter out any empty lines or lines without a trailing \n
    return text
      .split("\n")
      .filter((line) => line && text.includes(line + "\n"));
  }

  const createClausesFromCsv = async () => {
    if (!playbookInstance.value) {
      return;
    }

    const csvRows = splitTextByNewLine(csv.value);
    for (const row of csvRows) {
      const [entryId, ...clauseLineNumbers] = row.split(",").map(Number);
      if (foundEntryIds.value.has(entryId.toString())) {
        continue;
      }
      foundEntryIds.value.add(entryId.toString());

      const parsedClauses = await clausesFromRow(
        entryId,
        clauseLineNumbers,
        contractAsJson.value,
        playbookInstance.value?.id ?? 0
      );
      if (parsedClauses.length > 0) {
        await Promise.all(
          parsedClauses.map((clause) => clausesStore.createClause(clause))
        );
        if (!issuesStore.entryIdsToFindIssues.has(entryId)) {
          // Add any definitions
          if (sidebarStore.withinWord) {
            await createClausesFromDefinitions(entryId);
          }
          issuesStore.startFindingIssueForEntry(entryId);
        }
      }
    }
  };

  watch(
    () => generatedOutputWithVariablesAdded.value,
    () => {
      csv.value = generatedOutputWithVariablesAdded.value;
      createClausesFromCsv();
    }
  );

  watch(
    () => streaming.value,
    () => {
      if (!streaming.value) {
        aiStreamsStore.clearStream(streamIdentifiers());
      }
    }
  );

  return {
    clausesFinderError,
    findClauses,
    streaming
  };
}
