import JSON5 from "json5";
import { jsonrepair } from "@/services/jsonrepair/jsonrepair";

export default {
  parse: (input: string) => {
    try {
      const repaired = jsonrepair(input);
      return JSON5.parse(repaired);
    } catch {
      return input;
    }
  }
};
