<template>
  <h3 class="text-sm">{{ sampleTermsFile.filename }}</h3>
  <span v-if="sampleTermsFile.lastAuthor" class="text-sm text-gray-500">
    {{ sampleTermsFile.lastAuthor }} on
    {{ sampleTermsFile.lastChangedAt }}
  </span>
  <span v-else class="text-sm text-gray-500">&nbsp;</span>
  <div
    class="diff relative mb-10 h-48 resize-y overflow-scroll bg-gray-100 pr-80"
    :style="{
      height: `${elementHeight}px`
    }"
    ref="diffElement"
  >
    <div
      class="absolute z-50 w-full"
      :style="{
        top: `${(comment && comment.top) || 0}px`,
        left: '700px'
      }"
    >
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="comment && comment.show"
          class="pointer-events-auto max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div class="p-4">
            <div class="flex items-start">
              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                  {{ comment.author }}
                </p>
                <div class="mt-1 text-sm text-gray-500">
                  <div v-html="comment.action.replace('\n', '<br />')" />
                  <span v-if="comment.date">
                    on {{ dateFormat(comment.date) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="sampleTermsFile" v-html="languageHtml" class="prose bg-white" />
  </div>
</template>
<script setup lang="ts">
import { onUnmounted, onMounted, ref, watch } from "vue";
import { SampleTermsFile } from "@/types";
import { dateFormat } from "@/helpers/DateHelpers";
import { displayStringWithMarkedText } from "@/helpers/TextHelpers";

const emit = defineEmits(["resize"]);

const props = defineProps<{
  findText: string | null;
  sampleTermsFile: SampleTermsFile;
  elementHeight: number;
}>();

type Comment = {
  action: string;
  author?: string;
  date?: string;
  top: number;
  left: number;
  show: boolean;
};

const mouseDown = ref(false);
let resizeObserver: ResizeObserver;

const onResize = (domElement: HTMLElement, callback: () => void) => {
  resizeObserver = new ResizeObserver(() => callback());
  resizeObserver.observe(domElement);
};

const diffElement = ref<HTMLElement | null>(null);
const comment = ref<Comment | null>(null);
const languageHtml = ref<string>("");

const setLanguageHtml = () => {
  return new Promise((resolve) => {
    const html = props.sampleTermsFile.html;
    languageHtml.value =
      displayStringWithMarkedText(html, props.findText) ?? "";
    resolve(true);
  });
};

watch(
  () => props.findText,
  (findText) => {
    setLanguageHtml().then(() => {
      if (diffElement.value && (!findText || findText.length === 0)) {
        diffElement.value.scrollTop = 0;
      } else if (diffElement.value && findText) {
        const element = diffElement.value.querySelector("mark");
        if (element) {
          diffElement.value.scrollTop = element.offsetTop;
        }
      }
    });
  }
);

const emitResizeEvent = (event: Event) => {
  emit("resize", (event.target as HTMLInputElement).offsetHeight);
};

const trackMouseDown = () => {
  mouseDown.value = true;
};

const trackMouseUp = () => {
  mouseDown.value = false;
};

const showComment = (event: PointerEvent) => {
  const elem: HTMLElement | null = (event.target as HTMLElement).closest(
    "[data-author]"
  );
  if (elem && elem.getAttribute("data-author")) {
    const { author, date } = elem.dataset;

    let action;
    if (elem.tagName === "INS") {
      action = "Inserted";
    } else if (elem.tagName === "DEL") {
      action = "Deleted";
    } else {
      action = elem.getAttribute("data-comment") || ("Changed" as string);
    }
    // const top = event.clientY;
    // const top = elem.offsetTop;
    const top = elem.offsetTop;
    console.log(elem.offsetTop);
    console.log(event);

    const left = event.clientX;
    comment.value = {
      action,
      author,
      date,
      top,
      left,
      show: true
    };
  }
};

onMounted(() => {
  if (diffElement.value) {
    diffElement.value.addEventListener("elementResize", emitResizeEvent);
    diffElement.value.addEventListener("mousedown", trackMouseDown);
    diffElement.value.addEventListener("mouseup", trackMouseUp);
    diffElement.value.addEventListener("click", showComment);

    onResize(diffElement.value, () => {
      if (!mouseDown.value || !diffElement.value) return;
      emit("resize", diffElement.value.offsetHeight);
    });

    setLanguageHtml();
  }
});

onUnmounted(() => {
  // clean up
  if (diffElement.value) {
    diffElement.value.removeEventListener("elementResize", emitResizeEvent);
    diffElement.value.removeEventListener("mousedown", trackMouseDown);
    diffElement.value.removeEventListener("mouseup", trackMouseUp);
    diffElement.value.removeEventListener("click", showComment);
  }
});
</script>

<style lang="scss">
.diff {
  @apply mt-4 rounded-lg border border-gray-200;

  overflow: auto;
  overflow: auto;
  font-size: 13px;
  list-style: none;
}

.diff .prose {
  @apply p-4;
}

.diff del,
.diff ins,
.diff comment {
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
}

.diff ins {
  background: #dfd;
  color: #080;
  &:hover {
    background: #cfc;
  }
}

.diff del {
  background: #fee;
  color: #b00;
  text-decoration: line-through;
  &:hover {
    background: #fdd;
  }
}

.diff comment {
  color: #008;
  background: #eef;
  &:hover {
    background: #ddf;
  }
}

.diff comment:after {
  content: "💬";
  display: inline-block;
  width: 14px;
  height: 12px;
}
</style>
