import { defineStore } from "pinia";

export const useJumpToEntryStore = defineStore("JumpToEntryStore", {
  state() {
    return {
      entryId: null as string | null,
      clientId: null as string | null
    };
  },

  actions: {
    clear() {
      this.entryId = null;
      this.clientId = null;
    }
  }
});
