import { onMounted, computed, reactive } from "vue";
import { Client, Playbook, Tag } from "@/types";
import { useAdminStore } from "@/store/AdminStore";
import { useJumpToEntryStore } from "@/store/JumpToEntryStore";

export function useAdmin() {
  const adminStore = useAdminStore();
  const clientId = computed({
    get(): number | null {
      if (typeof adminStore.clientId === "string") {
        return parseInt(adminStore.clientId);
      } else {
        return adminStore.clientId;
      }
    },

    set(clientId: string | number | null) {
      if (typeof clientId !== "number") clientId = null;

      const clientIds = clients.value.map((c) => c.id);
      if (clientId !== null && !clientIds.includes(clientId)) {
        clientId = null;
      }
      adminStore.clientId = clientId;
    }
  });

  const clients = computed((): Client[] => {
    return adminStore.clients;
  });

  const client = computed((): Client | null => {
    if (clients.value.length === 1) {
      return clients.value[0];
    }
    if (!clientId.value) {
      return null;
    }
    const client = clients.value.filter((c) => c.id === clientId.value)[0];
    if (client) {
      return reactive(client);
    } else {
      return null;
    }
  });

  const loadClients = () => {
    adminStore.loadClients();
  };

  const playbookId = computed({
    get(): number | null {
      if (
        client.value &&
        playbooks.value.length === 1 &&
        !adminStore.playbookId
      ) {
        return playbooks.value[0].id;
      } else if (typeof adminStore.playbookId === "string") {
        return parseInt(adminStore.playbookId);
      } else {
        return adminStore.playbookId;
      }
    },

    set(playbookId: string | number | null) {
      if (typeof playbookId !== "number") playbookId = null;

      adminStore.playbookId = playbookId;
    }
  });

  const playbook = computed((): Playbook | null => {
    if (!playbookId.value || !client.value) {
      return null;
    }
    const playbook = client.value.playbooks.filter(
      (p) => p.id === playbookId.value
    )[0];
    if (playbook) {
      return reactive(playbook);
    } else {
      return null;
    }
  });

  const playbooks = computed((): Playbook[] => {
    if (!client.value) {
      return [];
    }

    return client.value.playbooks;
  });

  const tags = computed((): Tag[] => {
    if (!playbook.value) {
      return [];
    }
    return reactive(playbook.value.tags);
  });

  onMounted(() => {
    loadClients();
    const jumpToEntryStore = useJumpToEntryStore();
    if (jumpToEntryStore.clientId) {
      const clientIdAsNumber = parseInt(
        jumpToEntryStore.clientId as string,
        10
      );
      adminStore.clientId = clientIdAsNumber;
    }
  });

  return {
    client,
    clientId,
    clients,
    loadClients,
    playbook,
    playbookId,
    tags
  };
}
