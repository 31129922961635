<template>
  <div
    v-if="clauses.length > 0 && withinWord"
    class="p-2 bg-blue-100 rounded mb-2"
  >
    <a
      href="#"
      @click.prevent="jumpToClause(clauses[0])"
      class="text-sm underline"
    >
      Jump to clause
    </a>

    <span
      v-if="clauses.length > 1"
      class="isolate inline-flex rounded-md shadow-sm ml-2"
    >
      <button
        v-for="(clause, index) in clauses"
        :key="index"
        type="button"
        class="relative inline-flex items-center bg-white text-sm text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
        :class="{
          'rounded-r-md': index === numberOfClauses - 1,
          'rounded-l-md': index == 0,
          '-ml-px ': index > 0,
          'underline decoration-lexoo': selectedClause === clause,
          'px-3 py-1 ': props.clauses.length < 10,
          'px-2 py-1 ': props.clauses.length > 9
        }"
        @click.prevent="jumpToClause(clause)"
      >
        {{ index + 1 }}
      </button>
    </span>
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref, Ref } from "vue";

import { Clause } from "@/types";
import { jumpToText } from "@/helpers/WordHelpers";

import { useClausesStore } from "@/store/ClausesStore";
const clausesStore = useClausesStore();
const selectedClause = computed(() => clausesStore.selectedClause);

import { useSidebarStore } from "@/store/SidebarStore";
const sidebarStore = useSidebarStore();

const props = defineProps({
  clauses: {
    type: Array as PropType<Clause[]>,
    required: true
  }
});

const numberOfClauses = computed(() => props.clauses.length);
const withinWord = computed(() => sidebarStore.withinWord);

const removePrefixFromArray = (text: string, prefixes: string[]): string => {
  for (let prefix of prefixes) {
    // Check if the text starts with the current prefix
    if (text.startsWith(prefix)) {
      // Remove the prefix from the start of the text
      // Plus 1 for the space after the prefix
      return text.slice(prefix.length).trimStart();
    }
  }
  // Return the original text if no prefix matches
  return text;
};

const jumpToClause = async (clause: Clause) => {
  clausesStore.selectClause(clause);
  try {
    await jumpToText(clause.clauseBody.trim());
  } catch (error) {
    const definitionTitles = sidebarStore.definitions.map(
      (definition) => definition.title
    );
    const clauseBodyWithoutDefinitions = removePrefixFromArray(
      clause.clauseBody.trim(),
      definitionTitles
    );
    console.log("Jumping to clause without definitions", definitionTitles);
    jumpToText(clauseBodyWithoutDefinitions);
  }
};

const calculateFontSize = computed(() => {
  if (props.clauses.length < 10) {
    return 18;
  }
  return 10;
});
</script>
