import { defineStore } from "pinia";
import { StorageSerializers, useStorage } from "@vueuse/core";
import { useAlertStore } from "@/store/AlertStore";
import { Alert, User } from "@/types";
import ApiService from "@/services/ApiService";
import { uuid } from "vue-uuid";

export const useAuthorisationStore = defineStore("AuthorisationStore", {
  state() {
    const actionCableIdentifier = uuid.v4();
    return {
      authorisation: useStorage<string | null>("authorisation", null),
      actionCableIdentifier,
      redirect: useStorage<string | null>("redirect", null),
      user: useStorage<User | null>("user", null, undefined, {
        serializer: StorageSerializers.object
      })
    };
  },

  actions: {
    changePassword(password: string): Promise<void> {
      return new Promise((resolve) => {
        if (!this.user) {
          resolve();
        } else {
          ApiService.changePassword(this.user, password, false).then(
            ({ data }) => {
              this.user = data.user;
              resolve();
            }
          );
        }
      });
    },

    clearUser(): Promise<void> {
      return new Promise((resolve) => {
        this.user = null;
        this.authorisation = null;

        resolve();
      });
    },

    setDefaultValues(): Promise<void> {
      return new Promise((resolve) => {
        if (this.authorisation) {
          // Update current user from the server, just to be sure
          ApiService.getCurrentUser().then(({ data }) => {
            this.user = data.user;
            resolve();
          });
        } else {
          this.clearUser();
          resolve();
        }
      });
    },

    setUser({
      authorisation,
      user
    }: {
      authorisation: string | null;
      user: User | null;
    }): Promise<void> {
      return new Promise((resolve) => {
        this.authorisation = authorisation;
        this.user = user;
        resolve();
      });
    },

    setAuthExpiredAlert() {
      const alert: Alert = {
        message:
          "Your login has expired for security purposes. Please login again.",
        status: "info",
        title: "Login expired"
      };
      const alertStore = useAlertStore();
      alertStore.alert = alert;
    },

    updateUser(user: User): Promise<void> {
      return new Promise((resolve) => {
        ApiService.updateCurrentUser(user).then(() => {
          this.user = user;
          resolve();
        });
      });
    }
  },

  getters: {
    hasPlaybookChangeAccess(): boolean {
      return this.isAdmin || this.isEditor;
    },

    isBeta(): boolean {
      if (!this.user) {
        return false;
      }
      return this.user.beta;
    },

    isAdmin(): boolean {
      if (!this.user) {
        return false;
      }

      return this.user.role === "admin";
    },

    isEditor(): boolean {
      if (!this.user) {
        return false;
      }
      return this.user.role === "editor";
    },

    passwordResetRequired(): boolean {
      if (!this.user) {
        return false;
      }
      return this.user.forceReset;
    },

    usesOtka(): boolean {
      if (!this.user) {
        return false;
      }
      return this.user.ssoProvider == "otka";
    }
  }
});
