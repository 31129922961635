<template>
  <p>Logging in. Please wait...</p>
</template>

<script setup lang="ts">
/* global Office */
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import AuthService from "@/services/AuthService";
import { getObjectFromHash } from "@/helpers/links";
import { useAlertStore } from "@/store/AlertStore";
import { useSidebarStore } from "@/store/SidebarStore";

const sidebarStore = useSidebarStore();

const loginSuccessful = (code: string) => {
  const authService = new AuthService();
  const redirect_uri = window.location.origin + "/auth/google_oauth2/callback";
  authService.passGoogleOAuthToServer({ code, redirect_uri }).then(() => {
    if (sidebarStore.withinWord) {
      Office.onReady(() => {
        if (Office.context.ui) {
          Office.context.ui.messageParent("true");
        } else {
          window.location.replace(authService.startingPath());
        }
      });
    } else {
      window.location.replace(authService.startingPath());
    }
  });
};

const loginUnsuccessful = () => {
  const alertStore = useAlertStore();
  const message =
    "There was a problem logging you in using Google Single Sign on." +
    "<br><br>Please contact your Google administrator.";
  alertStore.addAlert({
    message,
    status: "caution",
    title: "Login unsuccessful"
  });

  Office.onReady(() => {
    if (Office.context.ui) {
      Office.context.ui.messageParent("false");
    } else {
      window.location.replace("/login");
    }
  });
};

const getCode = () => {
  const route = useRoute();
  if (route.query && route.query.code) {
    return route.query.code;
  }

  const params = getObjectFromHash(route.hash);
  return params["code"];
};

onMounted(() => {
  const code = getCode();
  if (code) {
    loginSuccessful(code);
  } else {
    loginUnsuccessful();
  }
});
</script>
