<template>
  <div v-if="currentUser" class="flex flex-col">
    <div class="flex items-start justify-between">
      <span
        class="mt-2 text-xs font-semibold uppercase tracking-wide text-gray-500"
      >
        {{ filteredUsers.length }} of {{ users.length }} users
      </span>
      <select
        v-model="clientId"
        name="clientId"
        class="align-right mt-1 mb-4 block rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
      >
        <option :value="null">All clients</option>
        <option v-for="client in clients" :key="client.id" :value="client.id">
          {{ client.name }}
        </option>
      </select>
    </div>
    <div class="-my-2 sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
        <div class="border-b border-gray-200 shadow sm:rounded-lg">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500"
                >
                  User
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Client
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                >
                  Created on
                </th>

                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">Edit</span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y divide-gray-200 bg-white">
              <tr v-if="filteredUsers.length == 0">
                <td
                  colspan="4"
                  class="whitespace-nowrap px-6 py-4 align-top text-sm text-gray-500"
                >
                  No users found
                </td>
              </tr>
              <tr v-for="user in filteredUsers" :key="user.email">
                <td class="whitespace-nowrap px-6 py-4 align-top">
                  <div class="flex items-center">
                    <div class="h-10 w-10 flex-shrink-0">
                      <img :src="user.image" class="h-10 w-10 rounded-full" />
                    </div>
                    <div class="ml-4">
                      <div class="text-sm font-medium text-gray-900">
                        {{ user.name ? user.name : "Guest" }}
                        <RoleTag :user="user" />
                        <span
                          class="ml-2 inline-flex items-center rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
                          v-if="user.beta"
                        >
                          BETA
                        </span>
                      </div>
                      <div class="text-sm text-gray-500">
                        {{ user.email }}
                      </div>
                      <div
                        v-if="user.lastAccessedAt"
                        class="flex items-center text-xs text-gray-500"
                      >
                        <CalendarIcon
                          class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Last accessed:
                          {{ " " }}
                          <time :datetime="user.lastAccessedAt">{{
                            dateTimeFormat(user.lastAccessedAt)
                          }}</time>
                        </p>
                      </div>

                      <div
                        v-if="user.numberOfPlaybooks > 0"
                        class="flex items-center text-xs text-gray-500"
                      >
                        <BookOpenIcon
                          class="mr-1.5 h-4 w-4 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          Playbook instances:
                          {{ user.numberOfPlaybooks }}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  class="group flex items-center whitespace-nowrap px-6 py-4 px-3 py-2 align-top text-sm font-medium text-gray-600"
                >
                  <template v-if="user.client">
                    <span
                      :class="[
                        bgColourClass(user.client),
                        'mr-2 h-2.5 w-2.5 rounded-full'
                      ]"
                      aria-hidden="true"
                    />
                    <span class="truncate">
                      {{ user.client.name }}
                    </span>
                  </template>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 align-top text-sm text-gray-500"
                >
                  {{ dateTimeFormat(user.createdAt) }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right align-top text-sm font-medium"
                >
                  <HeadlessMenu
                    as="div"
                    class="relative inline-block text-left"
                  >
                    <div>
                      <MenuButton
                        class="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 focus:ring-offset-gray-100"
                      >
                        <span class="sr-only">Open options</span>
                        <EllipsisVerticalIcon
                          class="h-5 w-5"
                          aria-hidden="true"
                        />
                      </MenuButton>
                    </div>

                    <transition
                      enter-active-class="transition ease-out duration-100"
                      enter-from-class="transform opacity-0 scale-95"
                      enter-to-class="transform opacity-100 scale-100"
                      leave-active-class="transition ease-in duration-75"
                      leave-from-class="transform opacity-100 scale-100"
                      leave-to-class="transform opacity-0 scale-95"
                    >
                      <MenuItems
                        class="absolute right-0 z-10 z-30 mt-2 w-56 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div class="py-1">
                          <MenuItem v-slot="{ active, close }" as="div">
                            <a
                              @click.prevent="
                                close();
                                $emit('showInteractions', user);
                              "
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Interactions
                            </a>
                          </MenuItem>
                          <MenuItem
                            v-if="user.id !== currentUser.id"
                            v-slot="{ active }"
                            as="div"
                          >
                            <a
                              v-if="user.status === 'active'"
                              @click.prevent="suspend(user)"
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Suspend
                            </a>
                            <a
                              v-else
                              @click.prevent="unsuspend(user)"
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Unsuspend
                            </a>
                          </MenuItem>
                          <MenuItem
                            v-if="
                              user.status === 'active' &&
                              user.id !== currentUser.id
                            "
                            v-slot="{ active, close }"
                            as="div"
                          >
                            <a
                              v-if="user.role === 'viewer'"
                              @click.prevent="
                                makeEditor(user);
                                close();
                              "
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Make editor
                            </a>
                            <a
                              v-else-if="user.role === 'editor'"
                              @click.prevent="
                                makeViewer(user);
                                close();
                              "
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Make viewer
                            </a>
                          </MenuItem>
                          <MenuItem
                            v-if="
                              user.status === 'active' &&
                              user.id !== currentUser.id
                            "
                            v-slot="{ active, close }"
                            as="div"
                          >
                            <a
                              @click.prevent="
                                resetPasswordUser = user;
                                close();
                              "
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Change password
                            </a>
                          </MenuItem>
                          <MenuItem
                            v-if="
                              user.status === 'active' &&
                              user.id !== currentUser.id
                            "
                            v-slot="{ active, close }"
                            as="div"
                          >
                            <a
                              v-if="user.beta"
                              @click.prevent="
                                removeBeta(user);
                                close();
                              "
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Remove AI beta access
                            </a>
                            <a
                              v-else-if="user.role === 'editor'"
                              @click.prevent="
                                addBeta(user);
                                close();
                              "
                              href="#"
                              :class="[
                                active
                                  ? 'bg-gray-100 text-gray-900'
                                  : 'text-gray-700',
                                'block px-4 py-2 text-sm'
                              ]"
                            >
                              Add AI beta access
                            </a>
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </transition>
                  </HeadlessMenu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal using Headless UI Dialog -->
  <HeadlessDialog
    v-model:open="showResetPasswordModal"
    class="fixed inset-0 z-30 overflow-y-auto"
  >
    <div
      class="flex min-h-screen min-h-screen items-center justify-center px-4 text-center"
    >
      <DialogOverlay
        @click="
          showResetPasswordModal = false;
          passwordChangedSuccessfully = false;
        "
        class="fixed inset-0 bg-black opacity-30"
      />

      <!-- Modal content -->
      <div
        class="my-8 inline-block w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left align-middle shadow-xl transition-all"
      >
        <DialogTitle class="text-lg font-medium leading-6 text-gray-900">
          Change password for
          {{ resetPasswordUser?.name ? resetPasswordUser.name : "Guest" }}
        </DialogTitle>
        <template v-if="passwordChangedSuccessfully">
          <div v-if="passwordChangedSuccessfully" class="mt-2">
            <p class="text-sm text-gray-800">
              Password for
              {{ resetPasswordUser?.name ? resetPasswordUser.name : "Guest" }}
              has been changed successfully.
            </p>
            <p class="text-sm text-gray-800">
              They will be prompted to change their password after they login
              successfully.
            </p>
          </div>
          <div class="mt-5 flex flex-row-reverse sm:mt-4">
            <BaseButton
              buttonStyle="link"
              @click="
                showResetPasswordModal = false;
                passwordChangedSuccessfully = false;
              "
            >
              Close
            </BaseButton>
          </div>
        </template>
        <template v-else>
          <div class="mt-2">
            <BaseInput
              v-model="newPassword"
              placeholder="New password"
              class="w-full"
            />
          </div>
          <div class="mt-5 flex flex-row-reverse sm:mt-4">
            <BaseButton
              buttonStyle="link"
              @click="showResetPasswordModal = false"
            >
              Cancel
            </BaseButton>
            <BaseButton buttonStyle="white" @click="changePassword">
              Change password
            </BaseButton>
          </div>
        </template>
      </div>
    </div>
  </HeadlessDialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";

import {
  BookOpenIcon,
  CalendarIcon,
  EllipsisVerticalIcon
} from "@heroicons/vue/24/solid";

import ApiService from "@/services/ApiService";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useUserStore } from "@/store/UserStore";

import { dateTimeFormat } from "@/helpers/DateHelpers";
import { useAdmin } from "@/components/shared/Admin";

import { Client, User } from "@/types";

import RoleTag from "@/components/admin/RoleTag.vue";

export default defineComponent({
  emits: ["showInteractions"],

  setup() {
    const authorisationStore = useAuthorisationStore();
    const { clients } = useAdmin();
    const userStore = useUserStore();

    return {
      authorisationStore,
      clients,
      dateTimeFormat,
      userStore
    };
  },

  props: {
    users: {
      type: Array as PropType<User[]>,
      required: true
    }
  },

  components: {
    BookOpenIcon,
    CalendarIcon,
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    EllipsisVerticalIcon,
    HeadlessMenu,
    MenuButton,
    MenuItem,
    MenuItems,
    RoleTag
  },

  computed: {
    currentUser(): User | null {
      return this.authorisationStore.user;
    },

    filteredUsers(): User[] {
      if (!this.clientId) {
        return this.users;
      }
      return this.users.filter((user) => {
        return user.clientId === this.clientId;
      });
    },

    showResetPasswordModal: {
      get(): boolean {
        return !!this.resetPasswordUser;
      },

      set(value: boolean) {
        if (!value) {
          this.resetPasswordUser = null;
        }
      }
    }
  },

  data() {
    return {
      clientId: null as number | null,
      newPassword: "",
      passwordChangedSuccessfully: false,
      resetPasswordUser: null as User | null
    };
  },

  methods: {
    bgColourClass(client: Client | null) {
      if (!client || !client.colour) {
        return "bg-indigo-500";
      } else {
        return `bg-${client.colour}-500`;
      }
    },

    capitalize(str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    changePassword() {
      if (this.resetPasswordUser && this.newPassword.length > 0) {
        ApiService.changePassword(
          this.resetPasswordUser,
          this.newPassword,
          true
        ).then(() => {
          // this.resetPasswordUser = null;
          // this.newPassword = "";
          this.passwordChangedSuccessfully = true;
        });
      }
    },

    makeAdmin(user: User) {
      ApiService.makeAdmin(user.id).then(() => {
        user.role = "admin";
      });
    },

    makeEditor(user: User) {
      ApiService.makeEditor(user.id).then(() => {
        user.role = "editor";
      });
    },

    makeViewer(user: User) {
      ApiService.makeViewer(user.id).then(() => {
        user.role = "viewer";
      });
    },

    playbooksForUser(user: User): { id: number; name: string }[] {
      const client = this.clients.find((c: Client) => c.id === user.clientId);
      if (!client) {
        return [];
      }
      return client.playbooks.map((p) => ({
        name: p.name,
        id: p.id
      }));
    },

    addBeta(user: User) {
      ApiService.addBeta(user).then(() => {
        user.beta = true;
      });
    },

    removeBeta(user: User) {
      ApiService.removeBeta(user).then(() => {
        user.beta = false;
      });
    },

    suspend(user: User) {
      ApiService.suspendUser(user.id).then(() => {
        user.status = "suspended";
      });
    },

    unsuspend(user: User) {
      ApiService.unsuspendUser(user.id).then(() => {
        user.status = "active";
      });
    }
  }
});
</script>
