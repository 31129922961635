<template>
  <div class="grid auto-cols-max grid-flow-col gap-1">
    <div
      :class="[
        { 'border-blue-500': selectedColor === color },
        `bg-${color}-100`,
        'border',
        'hover:border-blue-500',
        'cursor-pointer'
      ]"
      class="h-8 w-8 rounded"
      v-for="color in colors"
      :key="color"
      @click="selectColor(color)"
    ></div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    modelValue: {
      type: String
    }
  },

  data() {
    return {
      selectedColor: null as null | string | undefined
    };
  },

  computed: {
    colors() {
      return [
        "gray",
        "red",
        "yellow",
        "green",
        "blue",
        "indigo",
        "purple",
        "pink"
      ];
    }
  },

  methods: {
    selectColor(color: string) {
      this.selectedColor = color;
      this.$emit("update:modelValue", color);
    }
  },

  mounted() {
    this.selectedColor = this.modelValue;
  }
});
</script>

<style lang="scss" scoped></style>
