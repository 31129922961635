<template>
  <TransitionRoot as="template" :show="show">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white px-0 py-2 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="mx-4 my-3 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="mb-4 text-lg font-medium leading-6 text-gray-900"
                >
                  AI Alerts
                </DialogTitle>

                <BaseInput
                  label="What type of contract is this?"
                  autofocus
                  v-model="contractType"
                  required
                  hint="e.g. SaaS MSA, loan agreement, NDA, services agreement, etc."
                  class="text-xs sm:text-xs"
                />
                <BaseInput
                  label="Which party are you?"
                  v-model="role"
                  required
                  hint="e.g. customer, software provider, lender, borrower, etc."
                  class="text-xs sm:text-xs"
                />

                <BaseInput
                  label="Paste in the relevant clause here (optional)"
                  v-model="templateClause"
                  inputType="textarea"
                  required
                  class="text-xs sm:text-xs"
                />

                <div class="mt-4 flex space-x-3">
                  <BaseButton @action="generate">
                    <SparklesIcon
                      class="mr-1 inline-block h-4 w-4 text-yellow-500"
                    />
                    Generate
                  </BaseButton>
                  <BaseButton buttonStyle="white" @action="$emit('close')">
                    Cancel
                  </BaseButton>
                </div>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { SparklesIcon } from "@heroicons/vue/24/solid";

import { Entry, Playbook, PlaybookInstance } from "@/types";
import ApiService from "@/services/ApiService";
import { useSidebarStore } from "@/store/SidebarStore";

const props = defineProps({
  show: Boolean,

  entry: {
    type: Object as PropType<Entry>,
    required: true
  },

  playbook: {
    type: Object as PropType<Playbook>,
    required: true
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: true
  }
});

const playbookAiVariables = computed(() => props.playbook.aiVariables);
const contractType = ref(playbookAiVariables.value?.contractType);
const role = ref(playbookAiVariables.value?.role);

// Entry inputs
const templateClause = ref("");
const emit = defineEmits(["close", "generate"]);

const generate = async () => {
  const aiAlertVariables = {
    contractType: contractType.value,
    role: role.value,
    templateClause: templateClause.value
  };
  const updatedPlaybook = { ...props.playbook };
  updatedPlaybook.aiVariables["contractType"] = contractType.value;
  updatedPlaybook.aiVariables["role"] = role.value;

  await ApiService.updatePlaybook(updatedPlaybook);
  const sidebarStore = useSidebarStore();
  await sidebarStore.updatePlaybook(updatedPlaybook);
  emit("generate", aiAlertVariables);
};
</script>
