<template>
  <nav class="bg-lexoo">
    <div class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
      <div class="relative flex h-12 items-center justify-between">
        <div
          class="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex w-20 flex-shrink-0 items-center text-white">
            <LexPlayLogo class="block h-8" />
          </div>
        </div>
        <div
          v-if="user"
          class="absolute inset-y-0 left-0 mt-1 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <!-- Profile dropdown -->
          <HeadlessMenu v-if="user" as="div" class="relative md:ml-3">
            <div>
              <MenuButton
                class="inline-flex w-full justify-center text-sm font-medium focus:outline-none"
              >
                <span class="sr-only">Open user menu</span>
                <Bars3Icon class="h-6 w-6 text-white" />
              </MenuButton>
            </div>

            <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95"
            >
              <MenuItems
                class="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none md:right-0 md:[left:inherit]"
              >
                <div class="py-1">
                  <MenuItem v-slot="{ active, close }">
                    <a
                      @click.prevent="
                        close();
                        goToProfile();
                      "
                      href="#"
                      :class="[
                        'group flex items-center rounded-md p-2 text-sm',
                        active && 'bg-gray-100 text-gray-900'
                      ]"
                    >
                      <img
                        :src="user.image"
                        alt=""
                        class="h-6 w-6 flex-none rounded-full"
                      />
                      <span class="ml-3 flex-auto truncate">Your profile</span>
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active, close }">
                    <a
                      @click.prevent="
                        close();
                        goToPlaybooks();
                      "
                      href="#"
                      :class="[
                        'group flex items-center rounded-md  p-2 text-sm',
                        active && 'bg-gray-100 text-gray-900'
                      ]"
                    >
                      Playbooks
                    </a>
                  </MenuItem>
                  <MenuItem
                    v-if="user.role !== 'viewer'"
                    v-slot="{ active, close }"
                  >
                    <a
                      @click.prevent="
                        close();
                        goToUsers();
                      "
                      href="#"
                      :class="[
                        'group flex items-center rounded-md  p-2 text-sm',
                        active && 'bg-gray-100 text-gray-900'
                      ]"
                    >
                      Invite team
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active, close }">
                    <a
                      @click.prevent="
                        close();
                        goToSupport();
                      "
                      href="#"
                      :class="[
                        'group flex items-center rounded-md  p-2 text-sm',
                        active && 'bg-gray-100 text-gray-900'
                      ]"
                    >
                      Support
                    </a>
                  </MenuItem>
                  <MenuItem v-slot="{ active, close }">
                    <a
                      @click.prevent="
                        close();
                        signOut();
                      "
                      href="#"
                      :class="[
                        'group flex items-center rounded-md  p-2 text-sm',
                        active && 'bg-gray-100 text-gray-900'
                      ]"
                    >
                      Sign out
                    </a>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </HeadlessMenu>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Bars3Icon } from "@heroicons/vue/24/outline";
import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItems,
  MenuItem
} from "@headlessui/vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { User } from "@/types";
import LexPlayLogo from "@/assets/images/LexPlayLogo.svg";

export default defineComponent({
  setup() {
    const authorisationStore = useAuthorisationStore();
    return {
      authorisationStore
    };
  },

  components: {
    Bars3Icon,
    LexPlayLogo,
    HeadlessMenu,
    MenuButton,
    MenuItems,
    MenuItem
  },

  data() {
    return {
      profileOpened: false
    };
  },

  computed: {
    admin(): boolean {
      return this.authorisationStore.isAdmin;
    },

    inEscalations(): boolean {
      const subdomain = window.location.hostname.split(".")[0].toLowerCase();
      return subdomain === "escalations";
    },

    user(): User | null {
      return this.authorisationStore.user;
    }
  },

  methods: {
    goToPlaybooks() {
      this.$router.push("/sidebar/playbooks");
    },

    goToProfile() {
      this.$router.push("/profile");
    },

    goToSupport() {
      this.$router.push("/support");
    },

    goToUsers() {
      this.$router.push("/users");
    },

    signOut() {
      this.$router.push("/logout");
    }
  }
});
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-gray-900 text-white;
}

.nav-link {
  @apply rounded px-3 py-2 text-sm font-medium;
  &.mobile {
    @apply block text-base;
  }
}

.nav-link:not(.router-link-active) {
  @apply text-white hover:bg-gray-700 hover:text-white;
}
</style>
