import { uuid } from "vue-uuid";
import { htmlToPlainText } from "@/helpers/TextHelpers";
import DiffMatchPatch from "diff-match-patch";

import HtmlToOoxmlConverter from "@/services/HtmlToOoxmlConverter";
import OoxmlParser from "@/services/OoxmlParser";
import {
  removeElementsNotCompatibleWithWord,
  removeTags
} from "@/helpers/TextHelpers";

const filePathFromUrl = (fileUrl: string | null): string => {
  if (!fileUrl) {
    return "";
  }
  return fileUrl.substring(0, fileUrl.lastIndexOf("/"));
};

const getWordDocIdFromDocument = (): Promise<string | null> => {
  return new Promise((resolve) => {
    if (typeof Word === "undefined") {
      resolve(null);
    } else {
      Word.run(async (context) => {
        const body = context.document.body;
        const ooxmlObject = body.getOoxml(); // Get the items
        await context.sync();
        const ooxml = ooxmlObject.value;
        if (ooxml) {
          const parser = new OoxmlParser(ooxml);
          resolve(parser.docId());
        } else {
          resolve(null);
        }
      });
    }
  });
};

const getWordDocumentAsText = async () => {
  const html = await getWordDocumentAsHtml();
  return htmlToPlainText(html);
};

const getWordDocumentAsHtml = async () => {
  return Word.run(async (context) => {
    const body = context.document.body;
    const htmlObject = body.getHtml(); // Get the items
    await context.sync();
    return htmlObject.value;
  });
};

const getFilePathFromDocument = (): Promise<string | null> => {
  return new Promise((resolve) => {
    if (officeDocumentIsUnavailable()) {
      console.log("Can't find Office Context");
      resolve(null);
    }
    Office.context.document.getFilePropertiesAsync((asyncResult) => {
      const filePath = filePathFromUrl(asyncResult.value.url);
      if (filePath === "") {
        console.log("File path is blank");
        resolve(null);
      } else {
        console.log("File path is " + filePath);
        resolve(filePath);
      }
    });
  });
};

const getLexPlayIdFromDocument = (): string | null => {
  if (officeDocumentIsUnavailable()) {
    return null;
  }
  return Office.context.document.settings.get("lexplayId") ?? null;
};

const officeDocumentIsUnavailable = (): boolean => {
  return (
    typeof Office === "undefined" || !Office.context || !Office.context.document
  );
};

const generateLexPlayIdInDocument = (): string | null => {
  if (officeDocumentIsUnavailable()) {
    return null;
  }
  let lexPlayId = getLexPlayIdFromDocument();
  if (lexPlayId) {
    return lexPlayId;
  } else {
    lexPlayId = uuid.v4();
    Office.context.document.settings.set("lexplayId", lexPlayId);
    Office.context.document.settings.saveAsync();
  }

  return lexPlayId;
};

const convertToSearchText = (text: string): string => {
  const searchText = text.trim().replace(/^\d+/, "").trim();

  const characterNumber = 20;
  const first50 = searchText.substring(0, characterNumber);
  const last50 = searchText.substring(searchText.length - characterNumber);

  const searchString = first50 + "*" + last50;
  searchString
    .replace("\n", "^p")
    .replace("\r", "^p")
    .replace("\t", "*")
    .replace(/[()]/, "?")
    .trim();
  // Remove last character if it's a *
  if (searchString[searchString.length - 1] === "*") {
    return searchString.substring(0, searchString.length - 1);
  }
  // If the first word is a number, remove it

  return searchString;
};

const replaceTextInDocument = async (
  textToInsert: string,
  existingText: string
) => {
  await Word.run(async (context) => {
    textToInsert = removeTags(textToInsert);
    const dmp = new DiffMatchPatch();
    const diffs = dmp.diff_main(existingText, textToInsert);
    dmp.diff_cleanupSemantic(diffs);

    let previousText = null;
    for (const [op, text] of diffs) {
      switch (op) {
        case 0: // No change
          previousText = text;
          console.log("No change", text);
          break;
        case -1: {
          // Deletion
          // Logic to delete text
          const selection = context.document.getSelection();
          selection.load("text");
          await context.sync();

          const selectionRange = selection.getRange(Word.RangeLocation.whole);
          console.log("Searching for", convertToSearchText(text));
          const rangesToDelete = selectionRange.search(
            convertToSearchText(text),
            {
              matchWildcards: true,
              ignoreSpace: true,
              ignorePunct: true
            }
          );
          rangesToDelete.load("items");

          await context.sync();

          const item = rangesToDelete.items[0];
          if (item) {
            item.delete();
            await context.sync();
            console.log("Deleted", text);
          }

          break;
        }
        case 1: {
          // Insertion
          let rangeToInsert;
          console.log("Inserting...");

          if (previousText) {
            console.log("Searching for previous text", previousText);
            const selection = context.document.getSelection();
            selection.load("text");
            await context.sync();

            const selectionRange = selection.getRange(Word.RangeLocation.whole);
            rangeToInsert = selectionRange.search(
              convertToSearchText(previousText),
              {
                matchWildcards: true,
                ignoreSpace: true
              }
            );
            rangeToInsert.load("items");
            await context.sync();
            console.log(rangeToInsert.items);

            rangeToInsert.items[0].insertText(text, Word.InsertLocation.end);
          } else {
            const rangeToInsert = context.document
              .getSelection()
              .getRange(Word.RangeLocation.start);

            await context.sync();
            // rangeToInsert = selection.getRange(Word.RangeLocation.start);
            console.log("Inserting at start");
            rangeToInsert.insertText(text, Word.InsertLocation.start);
            console.log("Wait for it...");
          }

          await context.sync();
          console.log("Inserted", text);
          break;
        }
      }
    }
    await context.sync();
  });
};

const insertTextIntoDocumentWithFormatting = async (textToInsert: string) => {
  // no text selected, so just the old school method:
  await Word.run(async (context) => {
    const cursorOrSelection = context.document.getSelection();
    context.load(cursorOrSelection);
    await context.sync();
    const location = cursorOrSelection.isEmpty
      ? Word.InsertLocation.end
      : Word.InsertLocation.replace;

    const selection = context.document.getSelection();
    selection.load("font");
    await context.sync();

    const fontSize = selection.font.size;
    const fontFamily = selection.font.name;

    textToInsert = removeElementsNotCompatibleWithWord(textToInsert);
    console.log(textToInsert);
    const converter = new HtmlToOoxmlConverter(textToInsert, {
      fontSize,
      fontFamily
    });
    const ooxmlContent = converter.convert();

    const range = context.document.getSelection();

    range.insertOoxml(ooxmlContent + " ", location);

    await context.sync();
  });
};

const insertTextIntoDocument = async (textToInsert: string) => {
  const selectedText = await Word.run(async (context) => {
    const selection = context.document.getSelection();
    selection.load("text");
    await context.sync();

    return selection.text;
  });
  if (selectedText.length > 0) {
    replaceTextInDocument(textToInsert, selectedText);
  } else {
    insertTextIntoDocumentWithFormatting(textToInsert);
  }
};

const jumpToText = async (text: string) => {
  // Run a batch operation against the Word object model.
  await Word.run(async (context) => {
    // Queue a command to search the document and ignore punctuation.
    console.log("Searching for", convertToSearchText(text));
    const searchResults = context.document.body.search(
      convertToSearchText(text),
      {
        matchWildcards: true,
        ignoreSpace: true
      }
    );

    searchResults.load("items");

    // Synchronize the document state.
    await context.sync();
    if (searchResults.items.length > 0) {
      searchResults.items[0].select();

      // Synchronize the document state.
      await context.sync();
    } else {
      throw "Text not found in document";
    }
  });
};

const removeLexPlayIdFromDocument = (): void => {
  if (officeDocumentIsUnavailable()) {
    return;
  }
  Office.context.document.settings.remove("lexplayId");
  Office.context.document.settings.saveAsync();
};

export {
  filePathFromUrl,
  generateLexPlayIdInDocument,
  getFilePathFromDocument,
  getLexPlayIdFromDocument,
  getWordDocIdFromDocument,
  getWordDocumentAsHtml,
  getWordDocumentAsText,
  insertTextIntoDocument,
  jumpToText,
  removeLexPlayIdFromDocument
};
