<template>
  <div>
    <BaseButton @click="goBack" buttonStyle="white" size="small">
      &lt; Back to playbook
    </BaseButton>
    <div class="text-gray-800 space-y-3 my-3 text-sm">
      <p v-if="refinements.length == 0"><strong>Issue:</strong></p>
      <BaseSuperText
        v-if="refinements.length == 0"
        v-model="description"
        v-autosize
      />
      <div v-else>
        <p v-html="description" />
      </div>
    </div>
    <template v-if="refinements.length == 0">
      <div class="flex">
        <BaseButton @click="performDrillDown" :disabled="streaming">
          <SparklesIcon class="h-4 w-4 text-yellow-500" />
          {{ streaming ? "Please wait..." : "Generate AI solution" }}
        </BaseButton>
      </div>
      <p class="text-sm leading-6 text-gray-600">
        Note: feel free to edit the issue and the clause before generating
        solution.
      </p>
    </template>
    <template v-else>
      <div class="mt-4" :class="streaming ? 'generating-ai' : ''">
        <p
          v-if="streaming"
          class="my-4 flex items-center space-x-2 text-sm text-gray-500"
        >
          Please wait...
        </p>
        <div
          v-for="(refinement, index) in refinementsExcludingFirst"
          :key="index"
          class=""
        >
          <div v-if="refinement.isUser" class="border-b pb-8 pt-8 text-right">
            <span
              class="inline-flex items-center rounded-full bg-indigo-100 px-2.5 py-0.5 text-xs font-medium text-indigo-800"
            >
              You
            </span>
            <div class="text-sm">
              {{ refinement.text }}
            </div>
          </div>
          <div v-else class="mt-8">
            <span
              v-if="index !== 0"
              class="inline-flex items-center rounded-full bg-gray-100 px-2.5 py-0.5 text-xs font-medium text-gray-800"
            >
              AI response
            </span>
            <TemplateWordingDisplay
              v-if="
                refinement.suggestedDrafting &&
                refinement.suggestedDrafting.length > 0
              "
              :templateWording="refinement.suggestedDrafting"
              label="Suggested drafting"
              class="mt-4"
              :copyValue="refinement.suggestedDrafting"
            />
            <TemplateCommentDisplay
              v-if="
                refinement.suggestedDraftingJustification &&
                refinement.suggestedDraftingJustification.length > 0
              "
              :templateComment="refinement.suggestedDraftingJustification"
              label="Justification"
              class="mt-4"
            />
          </div>
        </div>
      </div>

      <div v-if="refinements.length > 0 && !streaming" class="max-w-xl">
        <div class="mt-4">
          <textarea
            v-model="refineComment"
            rows="3"
            class="block w-full rounded-md border-0 py-1.5 text-sm text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:leading-6"
            placeholder="Write any refinement you want here..."
          />
        </div>
        <div class="my-2 flex justify-end">
          <BaseButton @action="refine" :disabled="streaming">
            Refine
          </BaseButton>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, Directive, PropType, ref, watch } from "vue";
import { useRouter } from "vue-router";
import { Clause, Entry, Issue, IssueRefinement, NewInteraction } from "@/types";
import { SparklesIcon } from "@heroicons/vue/24/outline";
import BaseSuperText from "@/components/base/BaseSuperText.vue";

import DirtyJson from "@/services/DirtyJson";
import { useIssueDrillDown } from "@/services/AI/IssueDrillDown";
import { useSidebarStore } from "@/store/SidebarStore";
import { useIssuesStore } from "@/store/IssuesStore";
import { draftingFromDrillDownJson } from "@/helpers/PromptHelpers";

import ApiService from "@/services/ApiService";
import TemplateWordingDisplay from "@/components/TemplateWordingDisplay.vue";
import TemplateCommentDisplay from "@/components/TemplateCommentDisplay.vue";

const refinements = ref<IssueRefinement[]>([]);

const sidebarStore = useSidebarStore();
const entries = computed(() => sidebarStore.entries);
const description = ref("");
const router = useRouter();

const {
  drillDown,
  drillDownRefine,
  generatedOutputWithVariablesAdded,
  streaming
} = useIssueDrillDown();

// Define the autosize directive
const vAutosize: Directive = {
  mounted(el: HTMLTextAreaElement) {
    const resizeTextarea = () => {
      el.style.height = "auto";
      el.style.height = `${el.scrollHeight}px`;
    };

    // Initialize the textarea size
    resizeTextarea();

    // Add the event listener
    el.addEventListener("input", resizeTextarea);

    // Cleanup function
    const cleanup = () => {
      el.removeEventListener("input", resizeTextarea);
    };

    // Call cleanup when the element is unmounted
    el.addEventListener("unmounted", cleanup);
  }
};

const relevantPlaybookEntry = computed((): Entry | null => {
  return (
    entries.value.find((e) => e.id && e.id === props.issue.entryId) ?? null
  );
});

const relevantPlaybookEntryDescription = computed(() => {
  if (relevantPlaybookEntry.value) {
    return relevantPlaybookEntry.value.entryDescription;
  }
  return "";
});

const role = ref("");
const contractType = ref("");
const refineComment = ref("");

const emit = defineEmits(["back"]);

const props = defineProps({
  clauses: {
    type: Array as PropType<Clause[]>,
    required: true
  },

  issue: {
    type: Object as PropType<Issue>,
    required: true
  }
});

const refinementsExcludingFirst = computed(() => {
  return refinements.value.slice(1);
});

const goBack = () => {
  const playbook = sidebarStore.playbook;
  if (!playbook) {
    router.push(`/sidebar/playbooks`);
  } else {
    router.push(`/sidebar/playbooks/${playbook.id}`);
  }
};

const performDrillDown = async () => {
  await saveIssueInputs();
  trackDrillDown();
  const refinement = await drillDown({
    role: role.value ?? "",
    contractType: contractType.value ?? "",
    playbookBody: relevantPlaybookEntryDescription.value,
    issues: description.value,
    clauses: props.clauses
  });
  if (refinement) {
    refinements.value = [refinement];
    saveRefinements();
  } else {
    refinements.value = [];
  }
};

const refine = () => {
  refinements.value.push({
    text: refineComment.value,
    isUser: true
  });
  trackRefinement();
  drillDownRefine(refinements.value);
  refineComment.value = "";
  saveRefinements();
};

const saveIssueInputs = async () => {
  const updatedIssue = {
    ...props.issue,
    description: description.value
  };
  await useIssuesStore().updateIssue(updatedIssue);
};

const saveRefinements = () => {
  const updatedIssue = {
    ...props.issue,
    refinements: refinements.value
  };
  useIssuesStore().debouncedUpdateIssue(updatedIssue);
};

const trackDrillDown = () => {
  const playbook = sidebarStore.playbook;
  if (!playbook) {
    return;
  }
  const interaction = {
    action: "AI - drill down issue",
    extraInfo: {
      role: role.value,
      contractType: contractType.value,
      description: description.value
    },
    entryId: props.issue.entryId,
    playbookId: playbook.id
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};

const trackRefinement = () => {
  const playbook = sidebarStore.playbook;
  if (!playbook) {
    return;
  }
  const interaction = {
    action: "AI - refine issue",
    extraInfo: {
      role: role.value,
      contractType: contractType.value,
      description: description.value,
      refineComment: refineComment.value
    },
    entryId: props.issue.entryId,
    playbookId: playbook.id
  };

  ApiService.trackInteraction(interaction as NewInteraction);
};

const generateRefinement = (jsonValue: string) => {
  const refinementValue = draftingFromDrillDownJson(jsonValue);
  refinements.value.push({
    suggestedDrafting: refinementValue.suggestedDrafting,
    suggestedDraftingJustification:
      refinementValue.suggestedDraftingJustification,
    text: generatedOutputWithVariablesAdded.value,
    isUser: false
  });
};

watch(streaming, () => {
  if (!streaming.value) {
    const json = DirtyJson.parse(generatedOutputWithVariablesAdded.value);

    if (Array.isArray(json.fixes)) {
      json.fixes.forEach((row: string) => {
        generateRefinement(row);
      });
    } else if (Array.isArray(json)) {
      json.forEach((row: string) => {
        generateRefinement(row);
      });
    } else {
      generateRefinement(json);
    }
    saveRefinements();
  }
});

watch(
  () => props.issue,
  () => {
    refinements.value = props.issue.refinements;
    description.value = props.issue.description;
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scoped>
@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
.generating-ai {
  @apply py-1 px-2;
  position: relative;
  z-index: 0;
  border-radius: 10px;
  overflow: hidden;
  &::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-color: #399953;
    background-repeat: no-repeat;
    background-size:
      50% 50%,
      50% 50%;
    background-position:
      0 0,
      100% 0,
      100% 100%,
      0 100%;
    background-image: linear-gradient(#399953, #399953),
      linear-gradient(#fbb300, #fbb300), linear-gradient(#d53e33, #d53e33),
      linear-gradient(#377af5, #377af5);
    animation: rotate 4s linear infinite;
  }

  &::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: white;
    border-radius: 5px;
  }

  :deep(.ProseMirror) {
    @apply m-1 bg-transparent;
  }
}
</style>
