import { defineStore } from "pinia";

export const useDocumentSearchStore = defineStore("DocumentSearchStore", {
  state() {
    return {
      searchIndex: 0 as number,
      searchValue: null as string | null
    };
  }
});
