import DirtyJson from "@/services/DirtyJson";
import { Clause, Entry, Issue } from "@/types";
import { htmlToPlainText } from "@/helpers/TextHelpers";

type ClausesJson = {
  eid: number; // Playbook Entry Id
  chs: string[]; // Clause notations
};

type IssueJson = {
  h: string; // Heading
  cl: string; // Clause
  i: string; // Issue
  c: string; // Clause Number
  eid: number; // Playbook Entry Id
  id: string;
};

type IssuesJson = {
  issues: IssueJson[];
  Issues?: IssueJson[];
};

function extractUsingDiffNotation(
  chunkNotation: string,
  documentRows: string[]
): string {
  // Parse the chunk notation
  const regex = /@@ line number (\d+), goes on for (\d+) lines? @@/;
  const match = regex.exec(chunkNotation);

  if (!match) {
    return "";
  }

  // Extract line number and the number of lines
  const [_, lineNumber, numberOfLines] = match.map(Number);

  // Validate line numbers
  if (lineNumber < 1 || lineNumber > documentRows.length || numberOfLines < 1) {
    console.error("Invalid line number or number of lines in chunk notation");
    return "";
  }

  // Calculate end line ensuring it doesn't exceed document length
  const endLine = Math.min(lineNumber + numberOfLines - 1, documentRows.length);

  // Extract the relevant chunk
  let chunk = "";
  for (let i = lineNumber; i <= endLine; i++) {
    chunk += documentRows[i - 1]; // Array is 0-indexed
    if (i < endLine) {
      chunk += "\n"; // Add carriage return except for the last line
    }
  }

  return chunk;
}

const clausesFromRow = (
  entryId: number,
  clauseLineNumbers: number[],
  contractAsJson: Record<number, string>,
  playbookInstanceId: number
): Clause[] => {
  const result: Clause[] = [];
  clauseLineNumbers.forEach((clauseLineNumber: number, index: number) => {
    const clauseBody = contractAsJson[clauseLineNumber];
    result.push({
      clauseBody,
      lineNumber: clauseLineNumber,
      entryId,
      playbookInstanceId
    });
  });
  return result;
};

const clausesFromContractJson = (
  rawJson: string,
  contractBody: string,
  playbookInstanceId: number
): Clause[] => {
  const result: Clause[] = [];

  let json = { clauses: [] };

  json = DirtyJson.parse(rawJson);

  (json.clauses || []).forEach((clausesJson: ClausesJson) => {
    const entryId = clausesJson.eid;

    const contractBodyRows = contractBody.split("\n");
    (clausesJson.chs || []).forEach((clauseNotation: string) => {
      const entryDone = clausesJson.done;
      const clauseBody =
        extractUsingDiffNotation(clauseNotation, contractBodyRows) ?? [];

      result.push({
        clauseBody,
        entryDone,
        done: clausesJson.done,
        entryId,
        playbookInstanceId
      });
    });
  });

  return result;
};

type DraftingJson = {
  s?: string;
  j?: string;
};

const draftingFromDrillDownJson = (
  rawJson: string
): { suggestedDrafting?: string; suggestedDraftingJustification?: string } => {
  let json: DraftingJson = {};

  json = DirtyJson.parse(rawJson);

  const suggestedDrafting = json.s;
  const suggestedDraftingJustification = json.j;

  return { suggestedDrafting, suggestedDraftingJustification };
};

const entriesToString = (entries: Entry[]): string => {
  return htmlToPlainText(
    entries
      .map((entry) => {
        return `#${entry.id} ${entry.heading}\n${entry.entryDescription}`;
      })
      .join("\n\n")
  ).replace(/\n{3,}/g, "\n\n");
};

export {
  clausesFromRow,
  clausesFromContractJson,
  draftingFromDrillDownJson,
  entriesToString
};
