import { computed, nextTick, ref, Ref } from "vue";
import { useAuthorisationStore } from "@/store/AuthorisationStore";

export function useInlineEdit(
  emit: (event: string, value: string) => unknown,
  props: { text: string }
) {
  const editing = ref(false);
  const model: Ref<string | undefined> = ref(undefined);
  const editBox: Ref<{ focus: () => void } | null> = ref(null);

  const authorisationStore = useAuthorisationStore();
  const hasPlaybookChangeAccess = computed(() => {
    return authorisationStore.hasPlaybookChangeAccess;
  });

  const saveValue = () => {
    editing.value = false;
    if (model.value) {
      emit("update", model.value);
    }
  };

  const startEditing = () => {
    editing.value = true;
    model.value = props.text;
    nextTick(() => {
      if (editBox.value) {
        editBox.value.focus();
      }
    });
  };

  return {
    editBox,
    editing,
    hasPlaybookChangeAccess,
    model,
    props,
    startEditing,
    saveValue
  };
}
