<template>
  <TransitionRoot as="template" :show="show">
    <Dialog as="div" class="relative z-50" @close="$emit('close')">
      <TransitionChild
        as="template"
        enter="ease-out duration-300"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in duration-200"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enter-to="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 translate-y-0 sm:scale-100"
            leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <DialogPanel
              class="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
            >
              <DialogTitle
                as="h3"
                class="text-lg font-medium leading-6 text-gray-900"
              >
                {{ heading ?? `Before inserting, let's fill in the [BLANKS]` }}
              </DialogTitle>

              <div class="space-y-4 py-5">
                <div
                  v-for="variableIdentifier in Object.keys(variables)"
                  :key="variableIdentifier"
                  class="sm:col-span-6"
                >
                  <label class="block text-sm font-medium text-gray-700">
                    {{ `{ ${variableIdentifier} }` }}
                  </label>
                  <div class="mt-1">
                    <input
                      type="text"
                      v-model="variables[variableIdentifier]"
                      class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
                    />
                  </div>
                </div>
              </div>

              <div class="flex space-x-1 py-2">
                <BaseButton @click="saveVariables">
                  {{ buttonText }}
                </BaseButton>
                <BaseButton @click="$emit('close')" buttonStyle="white">
                  Cancel
                </BaseButton>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, ref, Ref, onMounted, PropType, watch } from "vue";
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { PlaybookInstance } from "@/types";
import { useSidebarStore } from "@/store/SidebarStore";

const props = defineProps({
  buttonText: {
    type: String,
    required: false,
    default: "Save"
  },

  heading: {
    type: String,
    required: false
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: true
  },

  show: {
    required: true,
    type: Boolean
  },

  insertionText: {
    required: false,
    type: String
  }
});

const variables = ref({}) as Ref<Record<string, string>>;

const variableIdentifiers = computed(() => {
  const sideBarStore = useSidebarStore();
  return sideBarStore.variableIdentifiers;
});

const sideBarStore = useSidebarStore();

const emit = defineEmits(["close", "updateVariables"]);
const saveVariables = () => {
  sideBarStore.updateVariables(variables.value);
  emit("updateVariables", variables.value);

  emit("close");
};

const getVariablesFromPlaybookInstance = () => {
  const sideBarStore = useSidebarStore();
  if (sideBarStore.playbookInstance) {
    if (!props.playbookInstance) return {};
    const identifiers = variableIdentifiers.value;
    const variablesWithEmptyValues: { [key: string]: string } = {};
    identifiers.forEach((identifier: string) => {
      variablesWithEmptyValues[identifier] =
        props.playbookInstance.variables[identifier] ?? "";
    });

    variables.value = { ...variablesWithEmptyValues };
  }
};

onMounted(() => {
  getVariablesFromPlaybookInstance();
});

watch(
  () => props.playbookInstance,
  () => {
    getVariablesFromPlaybookInstance();
  },
  {
    deep: true
  }
);
</script>
