import { createApp } from "vue";
import { createPinia } from "pinia";

import App from "./App.vue";
import router from "./router";
import "./index.css";
import { useAuthorisationStore } from "@/store/AuthorisationStore";

import { useSelectionStore } from "@/store/SelectionStore";

import DesignSystem from "@lexoo/lexoo-design-system-2";
import "@lexoo/lexoo-design-system-2/dist/style.css";

const app = createApp(App);

app.use(createPinia()).use(router).use(DesignSystem);
app.mount("#app");

const authorisationStore = useAuthorisationStore();
authorisationStore.setDefaultValues();

const selectionStore = useSelectionStore();
document.addEventListener("selectionchange", () => {
  const selection = document.getSelection();
  selectionStore.updateSelection({ selection });
});
