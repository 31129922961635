<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2
              class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
            >
              Set your new password
            </h2>
          </div>
          <form class="mt-8 space-y-6" @submit.prevent="resetPassword">
            <div class="-space-y-px rounded shadow-sm">
              <div>
                <label for="email-address" class="sr-only">
                  Email address
                </label>
                <input
                  v-model="email"
                  name="email"
                  type="email"
                  disabled="true"
                  autocomplete="email"
                  required="true"
                  class="relative block w-full appearance-none rounded-none rounded-t-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo disabled:opacity-50 disabled:opacity-50 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label for="password" class="sr-only">Password</label>
                <input
                  v-model="password"
                  autofocus
                  name="password"
                  type="password"
                  required="true"
                  class="relative block w-full appearance-none rounded-none rounded-b-md border border-gray-300 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-lexoo sm:text-sm"
                  placeholder="Enter your new password"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                :disabled="password.length === 0 || loading"
                class="group relative flex w-full justify-center rounded border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50 disabled:opacity-50"
              >
                {{ loading ? "Resetting..." : "Reset password" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ApiService from "@/services/ApiService";
import { useAuthorisationStore } from "@/store/AuthorisationStore";
import { useAlertStore } from "@/store/AlertStore";

export default defineComponent({
  data() {
    return {
      email: "",
      loading: false,
      password: ""
    };
  },

  computed: {
    token() {
      return this.$route.params.token;
    }
  },

  methods: {
    resetPassword() {
      this.loading = true;
      ApiService.resetPassword(this.password, this.token).then((data) => {
        const response = data.data;
        const user = response["user"];
        const authorisation = data.headers["authorization"];
        const authorisationStore = useAuthorisationStore();
        authorisationStore.setUser({ authorisation, user });
        const alertStore = useAlertStore();
        alertStore.alert = {
          status: "success",
          title: "Password reset successfully",
          message: "We've logged you in with your new password."
        };
        this.$router.push("/word-view");
      });
    },

    validateToken() {
      ApiService.validatePasswordResetToken(this.token).then(({ data }) => {
        this.email = data.email;
      });
    }
  },

  mounted() {
    this.validateToken();
  }
});
</script>

<style lang="scss" scoped></style>
