<template>
  <div class="flex min-h-full">
    <div class="mx-auto pt-6">
      <div
        class="flex min-h-full items-center justify-center px-4 pb-12 sm:px-6 lg:px-8"
      >
        <div class="w-full max-w-md space-y-8">
          <div>
            <img
              class="mx-auto h-12 w-auto"
              src="@/assets/images/lexplay-300.png"
              alt="LexPlay"
            />
            <h2
              class="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900"
            >
              Check your email
            </h2>
            <p class="mt-10 text-left text-sm text-gray-900">
              We just sent you an email. Click the link to finish creating your
              account.
            </p>

            <p class="mt-4 text-left text-sm">
              If you haven't received it within a couple minutes, double check
              your junk/spam folder or contact
              <a href="mailto:team@lexoo.com">team@lexoo.com</a>.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
