import {
  Node,
  findParentNode,
  findChildren,
  mergeAttributes,
  defaultBlockAt,
  isActive
} from "@tiptap/core";
import { VueNodeViewRenderer } from "@tiptap/vue-3";
import { Plugin, PluginKey, TextSelection } from "@tiptap/pm/state";
import { GapCursor } from "@tiptap/pm/gapcursor";

import TipTapDetailsComponent from "@/components/base/TipTap/TipTapDetails.vue";

const isEditableAtPos = (pos: number, editor: any): boolean => {
  return null !== editor.view.domAtPos(pos).node.offsetParent;
};

const handleArrowKeys = (editor: any, direction: string): boolean => {
  const { state, view, extensionManager } = editor;
  const { schema, selection } = state;
  const { empty, $anchor } = selection;
  const isGapCursorExtensionEnabled = !!extensionManager.extensions.find(
    (extension: { name: string }) => extension.name === "gapCursor"
  );

  if (
    !empty ||
    $anchor.parent.type !== schema.nodes.detailsSummary ||
    !isGapCursorExtensionEnabled
  )
    return false;
  if (
    direction === "right" &&
    $anchor.parentOffset !== $anchor.parent.nodeSize - 2
  )
    return false;

  const parentNode = findParentNode(
    (node) => node.type === schema.nodes.details
  )(selection);
  if (!parentNode) return false;

  const children = findChildren(
    parentNode.node,
    (node) => node.type === schema.nodes.detailsContent
  );
  if (!children.length) return false;

  if (isEditableAtPos(parentNode.start + children[0].pos + 1, editor))
    return false;

  const cursorPos = state.doc.resolve(
    parentNode.pos + parentNode.node.nodeSize
  );
  const gapCursorPos = GapCursor.findFrom(cursorPos, 1, false);
  if (!gapCursorPos) return false;

  const { tr } = state;
  const gapCursor = new GapCursor(gapCursorPos);
  tr.setSelection(gapCursor);
  tr.scrollIntoView();
  view.dispatch(tr);
  return true;
};

export default Node.create({
  name: "details",
  content: "detailsSummary detailsContent",
  group: "block",
  defining: true,
  isolating: true,
  allowGapCursor: false,
  addOptions: () => ({
    persist: false,
    openClassName: "is-open",
    HTMLAttributes: {}
  }),

  addAttributes() {
    return this.options.persist
      ? {
          open: {
            default: false,
            parseHTML: (element) => element.hasAttribute("open"),
            renderHTML: ({ open }) => (open ? { open: "" } : {})
          }
        }
      : [];
  },

  parseHTML: () => [{ tag: "details" }],

  renderHTML({ HTMLAttributes }) {
    return [
      "details",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  },

  addNodeView() {
    return VueNodeViewRenderer(TipTapDetailsComponent);
  },

  addCommands() {
    return {
      setDetails:
        () =>
        ({ state, chain }) => {
          const { schema, selection } = state;
          const { $from, $to } = selection;
          const blockRange = $from.blockRange($to);

          if (!blockRange) return false;

          const slice = state.doc.slice(blockRange.start, blockRange.end);

          if (
            !schema.nodes.detailsContent.contentMatch.matchFragment(
              slice.content
            )
          )
            return false;

          const contentJSON = slice.toJSON()?.content || [];
          return chain()
            .insertContentAt(
              { from: blockRange.start, to: blockRange.end },
              {
                type: this.name,
                content: [
                  { type: "detailsSummary" },
                  { type: "detailsContent", content: contentJSON }
                ],
                attrs: { open: true }
              }
            )
            .setTextSelection(blockRange.start + 2)
            .run();
        },

      unsetDetails:
        () =>
        ({ state, chain }) => {
          const { selection, schema } = state;
          const parentNode = findParentNode((node) => node.type === this.type)(
            selection
          );
          if (!parentNode) return false;

          const summaryNodes = findChildren(
            parentNode.node,
            (node) => node.type === schema.nodes.detailsSummary
          );
          const contentNodes = findChildren(
            parentNode.node,
            (node) => node.type === schema.nodes.detailsContent
          );
          if (!summaryNodes.length || !contentNodes.length) return false;

          const summaryNode = summaryNodes[0];
          const contentNode = contentNodes[0];
          const parentNodePos = parentNode.pos;
          const range = {
            from: parentNodePos,
            to: parentNodePos + parentNode.node.nodeSize
          };
          const contentJSON = contentNode.node.content.toJSON() || [];
          const defaultBlockType = defaultBlockAt(
            state.doc.resolve(parentNodePos).parent.type.contentMatch
          );
          const content = [
            defaultBlockType
              ? defaultBlockType.create(null, summaryNode.node.content).toJSON()
              : undefined,
            ...contentJSON
          ];

          return chain()
            .insertContentAt(range, content)
            .setTextSelection(parentNodePos + 1)
            .run();
        }
    };
  },
  addKeyboardShortcuts() {
    return {
      Backspace: () => {
        const { schema, selection } = this.editor.state;
        const { empty, $anchor } = selection;
        if (!empty || $anchor.parent.type !== schema.nodes.detailsSummary) {
          return false;
        }
        if ($anchor.parentOffset !== 0) {
          return this.editor.commands.command(({ tr }) => {
            const start = $anchor.pos - 1;
            const end = $anchor.pos;
            tr.delete(start, end);
            return true;
          });
        }
        return this.editor.commands.unsetDetails();
      },
      Enter: ({ editor }) => {
        const { state, view } = editor;
        const { schema, selection } = state;
        const { $head } = selection;
        if ($head.parent.type !== schema.nodes.detailsSummary) {
          return false;
        }
        const isEditable = isEditableAtPos($head.after() + 1, editor);
        const node = isEditable
          ? state.doc.nodeAt($head.after())
          : $head.node(-2);
        if (!node) {
          return false;
        }
        const index = isEditable ? 0 : $head.indexAfter(-1);
        const defaultBlock = defaultBlockAt(node.contentMatchAt(index));
        if (!defaultBlock || !node.canReplaceWith(index, index, defaultBlock)) {
          return false;
        }
        const defaultBlockNode = defaultBlock.createAndFill();
        if (!defaultBlockNode) {
          return false;
        }
        const pos = isEditable ? $head.after() + 1 : $head.after(-1);
        const tr = state.tr.replaceWith(pos, pos, defaultBlockNode);
        const resolvedPos = tr.doc.resolve(pos);
        const newSelection = TextSelection.near(resolvedPos, 1);
        tr.setSelection(newSelection);
        tr.scrollIntoView();
        view.dispatch(tr);
        return true;
      },
      ArrowRight: ({ editor }) => handleArrowKeys(editor, "right"),
      ArrowDown: ({ editor }) => handleArrowKeys(editor, "down")
    };
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        key: new PluginKey("detailsSelection"),
        appendTransaction: (transactions, oldState, newState) => {
          const { editor, type } = this;
          const hasSelectionChanged = transactions.some(
            (transaction) => transaction.selectionSet
          );

          if (
            !hasSelectionChanged ||
            !oldState.selection.empty ||
            !newState.selection.empty
          )
            return;
          if (!isActive(newState, type.name)) return;

          const { $from } = newState.selection;
          if (isEditableAtPos($from.pos, editor)) return;

          const enclosingNodeInfo = (() => {
            for (let depth = $from.depth; depth > 0; depth -= 1) {
              const node = $from.node(depth);
              const isDetailsNode = node.type === type;
              const isEditable = isEditableAtPos($from.start(depth), editor);
              if (isDetailsNode && isEditable) {
                return {
                  pos: depth > 0 ? $from.before(depth) : 0,
                  start: $from.start(depth),
                  depth,
                  node
                };
              }
            }
          })();

          if (!enclosingNodeInfo) return;

          const summaryNodes = findChildren(
            enclosingNodeInfo.node,
            (node) => node.type === newState.schema.nodes.detailsSummary
          );

          if (!summaryNodes.length) return;

          const summaryNode = summaryNodes[0];
          const selectionDirection =
            oldState.selection.from < newState.selection.from
              ? "forward"
              : "backward";
          const selectionPos =
            selectionDirection === "forward"
              ? enclosingNodeInfo.start + summaryNode.pos
              : enclosingNodeInfo.pos +
                summaryNode.pos +
                summaryNode.node.nodeSize;
          const textSelection = TextSelection.create(
            newState.doc,
            selectionPos
          );
          return newState.tr.setSelection(textSelection);
        }
      })
    ];
  }
});
