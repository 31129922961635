<script setup lang="ts">
import { onMounted, reactive, ref } from "vue";
import { Escalation } from "@/types";
import ApiService from "@/services/ApiService";
import EscalationRow from "@/components/EscalationRow.vue";
import LoadingIcon from "@/assets/images/LoadingIcon";

let escalations: Escalation[] = reactive([]);
const loaded = ref(false);

onMounted(async () => {
  ApiService.getEscalations().then(({ data }) => {
    escalations = data.escalations;
    loaded.value = true;
  });
});
</script>

<template>
  <div class="overflow-hidden bg-white sm:rounded-lg sm:shadow">
    <div class="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
      <h3 class="text-lg font-medium leading-6 text-gray-900">Escalations</h3>
    </div>

    <div v-if="!loaded" class="px-4 py-5 sm:px-6">
      <LoadingIcon class="mx-auto h-5 w-5" />
    </div>

    <div v-else-if="escalations.length === 0" class="px-4 py-5 sm:px-6">
      <p class="text-sm text-gray-500">There are no escalations to display.</p>
    </div>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="escalation in escalations" :key="escalation.id" class="p-4">
        <EscalationRow :escalation="escalation" />
      </li>
    </ul>
  </div>
</template>
