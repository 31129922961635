import {
  getFilePathFromDocument,
  getWordDocIdFromDocument,
  removeLexPlayIdFromDocument
} from "@/helpers/WordHelpers";

import ApiService from "@/services/ApiService";

import { useSidebarStore } from "@/store/SidebarStore";

import router from "@/router";

export default class PlaybookInstanceCreator {
  filePath: string | null;
  wordDocId: string | null;
  playbookId: number;
  jumpToEntryId: number | undefined;

  constructor(playbookId: number, jumpToEntryId?: number) {
    this.playbookId = playbookId;
    this.filePath = null;
    this.wordDocId = null;
    this.jumpToEntryId = jumpToEntryId;
  }

  getFilePathFromDocument = (): Promise<void> => {
    return new Promise((resolve) => {
      getFilePathFromDocument().then((filePath: string | null) => {
        this.filePath = filePath;
        resolve();
      });
    });
  };

  getWordDocIdFromDocument = (): Promise<void> => {
    return new Promise((resolve) => {
      getWordDocIdFromDocument().then((docId: string | null) => {
        this.wordDocId = docId;
        resolve();
      });
    });
  };

  create = () => {
    removeLexPlayIdFromDocument();
    const sidebarStore = useSidebarStore();
    sidebarStore.clearEntries();
    sidebarStore.entriesLoading = true;
    console.log("Creating playbook instance");
    this.getFilePathFromDocument()
      .then(this.getWordDocIdFromDocument)
      .then(() => {
        ApiService.createPlaybookInstance(
          this.playbookId,
          this.filePath,
          this.wordDocId
        ).then(({ data }) => {
          const newPlaybookInstance = data.playbookInstance;
          // Ensure we get the full entries
          ApiService.getPlaybookInstance(newPlaybookInstance.id, true).then(
            ({ data }) => {
              sidebarStore.setPlaybookInstance(data.playbookInstance);
              let url = `/sidebar/playbooks/${this.playbookId}`;
              if (this.jumpToEntryId) {
                url += `/entries/${this.jumpToEntryId}`;
              }
              router.push(url);
            }
          );
        });
      });
  };
}
