import { Node, Slice, Fragment } from "prosemirror-model";

function traverseFragment(fragment: Fragment | Node) {
  const nodes: Node[] = [];
  fragment.forEach((child: Node, offset) => {
    if (child.type.name === "details") {
      let detailsContentNode = null as Node | null;
      let detailsSummaryNode = null as Node | null;

      child.forEach((childNode) => {
        if (childNode.type.name === "detailsContent") {
          detailsContentNode = childNode;
        } else if (childNode.type.name === "detailsSummary") {
          detailsSummaryNode = childNode;
        }
      });

      if (
        detailsContentNode &&
        !detailsSummaryNode &&
        detailsContentNode.firstChild
      ) {
        nodes.push(
          detailsContentNode.firstChild.copy(
            traverseFragment(detailsContentNode.firstChild)
          )
        );
      } else {
        nodes.push(child.copy(traverseFragment(child)));
      }
    } else {
      nodes.push(child.copy(traverseFragment(child)));
    }
  });
  return Fragment.fromArray(nodes);
}

export default (slice: Slice) => {
  const fragment = slice.content;
  const result = traverseFragment(fragment);
  return new Slice(result, slice.openStart, slice.openEnd);
};
