interface HtmlToOoxmlOptions {
  fontFamily?: string;
  fontSize?: number;
}

export default class HtmlToOoxmlConverter {
  private html: string;
  private options: HtmlToOoxmlOptions;

  constructor(html: string, options: HtmlToOoxmlOptions = {}) {
    this.html = html;
    this.options = options;
  }

  convert(): string {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(
      `<body>${this.html}</body>`,
      "text/html"
    );
    const { fontFamily, fontSize } = this.options;
    const xmlOutput: string[] = [];

    if (fontFamily || fontSize) {
      xmlOutput.push(
        '<?xml version="1.0" encoding="UTF-8" standalone="yes"?>',
        '<pkg:package xmlns:pkg="http://schemas.microsoft.com/office/2006/xmlPackage">',
        '<pkg:part pkg:name="/_rels/.rels" pkg:contentType="application/vnd.openxmlformats-package.relationships+xml">',
        "<pkg:xmlData>",
        '<Relationships xmlns="http://schemas.openxmlformats.org/package/2006/relationships">',
        '<Relationship Id="rId1" Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/officeDocument" Target="word/document.xml"/>',
        "</Relationships>",
        "</pkg:xmlData>",
        "</pkg:part>",
        '<pkg:part pkg:name="/word/document.xml" pkg:contentType="application/vnd.openxmlformats-officedocument.wordprocessingml.document.main+xml">',
        "<pkg:xmlData>",
        '<w:document xmlns:w="http://schemas.openxmlformats.org/wordprocessingml/2006/main">',
        "<w:body>"
      );
    }

    // Helper function to encode XML
    const encodeXML = (str: string): string => {
      return str.replace(/[<>&'"]/g, (c) => {
        switch (c) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case "&":
            return "&amp;";
          case "'":
            return "&apos;";
          case '"':
            return "&quot;";
        }
        return "";
      });
    };

    const buildRunProperties = (element: HTMLElement | null): string => {
      let properties = "";
      if (element) {
        properties += buildRunProperties(element.parentElement);
        const tagName = element.tagName.toLowerCase();
        if (tagName === "strong") {
          properties += "<w:b/><w:bCs/>";
        } else if (tagName === "u") {
          properties += '<w:u w:val="single"/>';
        } else if (tagName === "em") {
          properties += "<w:i/><w:iCs/>";
        } else if (tagName === "a") {
          properties += '<w:rStyle w:val="Hyperlink"/>';
        } else if (tagName === "s") {
          properties += '<w:strike w:val="true"/>';
        }
      }

      // Add font family and font size to run properties
      if (fontFamily) {
        properties += `<w:rFonts w:ascii="${fontFamily}" w:hAnsi="${fontFamily}" w:eastAsia="${fontFamily}" w:cs="${fontFamily}"/>`;
      }
      if (fontSize) {
        properties += `<w:sz w:val="${fontSize * 2}"/><w:szCs w:val="${
          fontSize * 2
        }"/>`;
      }
      return properties;
    };

    const processNode = (node: Node): void => {
      if (node.nodeType === Node.TEXT_NODE && node.textContent) {
        const lines = node.textContent.split("\n");
        for (let i = 0; i < lines.length; i++) {
          if (i > 0) {
            xmlOutput.push("</w:p><w:p>");
          }
          const runProperties = buildRunProperties(node.parentElement);
          xmlOutput.push(
            `<w:r><w:rPr>${runProperties}</w:rPr><w:t xml:space="preserve">${encodeXML(
              lines[i]
            )}</w:t></w:r>`
          );
        }
      } else if (node.nodeType === Node.ELEMENT_NODE) {
        const elementNode = node as HTMLElement;
        const tagName = elementNode.tagName.toLowerCase();
        if (tagName === "p") {
          xmlOutput.push("<w:p>");
          processChildren(elementNode);
          xmlOutput.push("</w:p>");
        } else if (
          tagName === "strong" ||
          tagName === "u" ||
          tagName === "em" ||
          tagName === "a" ||
          tagName === "br" ||
          tagName == "s"
        ) {
          if (tagName === "br") {
            xmlOutput.push("<w:br/>");
          } else {
            processChildren(elementNode);
          }
        } else {
          processChildren(elementNode);
        }
      }
    };

    const processChildren = (parentNode: Node): void => {
      for (const childNode of parentNode.childNodes) {
        processNode(childNode);
      }
    };

    const firstChild = xmlDoc.body.firstChild;
    if (firstChild && firstChild.nodeName.toLowerCase() !== "p") {
      xmlOutput.push("<w:p>");
    }
    processChildren(xmlDoc.body);

    const lastChild = xmlDoc.body.lastChild;
    if (lastChild && lastChild.nodeName.toLowerCase() !== "p") {
      xmlOutput.push("</w:p>");
    }

    if (fontFamily || fontSize) {
      xmlOutput.push(
        "</w:body></w:document></pkg:xmlData></pkg:part></pkg:package>"
      );
    }
    const xmlOutputString = xmlOutput.join("");

    // Add a space to the end of the document
    const lastRunIndex = xmlOutputString.lastIndexOf("</w:r>");
    if (lastRunIndex !== -1) {
      const xmlOutputBeforeLastRun = xmlOutputString.slice(0, lastRunIndex);
      const xmlOutputAfterLastRun = xmlOutputString.slice(lastRunIndex);

      return (
        xmlOutputBeforeLastRun +
        '<w:t xml:space="preserve"> </w:t>' +
        xmlOutputAfterLastRun
      );
    } else {
      return xmlOutputString;
    }
  }
}
