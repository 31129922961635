import { ref } from "vue";
import { useRouter } from "vue-router";
import ApiService from "@/services/ApiService";
import { useSidebarStore } from "@/store/SidebarStore";
import {
  getFilePathFromDocument,
  getLexPlayIdFromDocument,
  getWordDocIdFromDocument
} from "@/helpers/WordHelpers";

export function useLoadPlaybookInstance() {
  const foundPlaybookInstance = ref(false);
  const docIdToSearch = ref<string | null>(null);
  const lexPlayIdToSearch = ref<string | null>(null);
  const filePath = ref<string | null>(null);
  const sidebarStore = useSidebarStore();
  const router = useRouter();

  const tryToSetPlaybookInstance = (data) => {
    if (data.playbookInstance) {
      console.log("Found playbook instance", data.playbookInstance);
      sidebarStore.setPlaybookInstance(data.playbookInstance);
      foundPlaybookInstance.value = true;
    }
  };

  const tryToLoadPlaybookInstanceFromLexPlayId = async () => {
    if (lexPlayIdToSearch.value) {
      try {
        const { data } = await ApiService.getPlaybookInstanceFromLexPlayId(
          lexPlayIdToSearch.value
        );
        tryToSetPlaybookInstance(data);
      } catch (e) {
        console.error(e);
        return;
      }
    }
  };

  const tryToLoadPlaybookInstanceFromWordDocId = async () => {
    if (foundPlaybookInstance.value || !docIdToSearch.value) {
      return;
    }
    try {
      const { data } = await ApiService.getPlaybookInstanceFromWordDocId(
        docIdToSearch.value
      );

      tryToSetPlaybookInstance(data);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const tryToLoadPlaybookInstanceFromFilePath = async () => {
    if (foundPlaybookInstance.value) {
      return;
    }

    filePath.value = await getFilePathFromDocument();
    if (!filePath.value) {
      return;
    }
    try {
      const { data } = await ApiService.getPlaybookInstanceFromFilePath(
        filePath.value
      );
      tryToSetPlaybookInstance(data);
    } catch (e) {
      console.error(e);
      return;
    }
  };

  const fillInPlaybookInstanceData = async () => {
    if (sidebarStore.playbookInstanceId) {
      const { data } = await ApiService.getPlaybookInstance(
        sidebarStore.playbookInstanceId,
        false
      );
      if (data.playbookInstance) {
        foundPlaybookInstance.value = true;
        sidebarStore.setPlaybookInstance(data.playbookInstance);
      }
    }
  };

  const redirectToNewPlaybook = () => {
    router.replace("/sidebar/playbooks");
  };

  const loadPlaybookInstance = async () => {
    sidebarStore.setEntriesLoading();
    filePath.value = ""; // Reset file path because Word's going to find it
    docIdToSearch.value = null;
    foundPlaybookInstance.value = false;
    try {
      await sidebarStore.loadClients();
      lexPlayIdToSearch.value = await getLexPlayIdFromDocument();
      docIdToSearch.value = await getWordDocIdFromDocument();
      await tryToLoadPlaybookInstanceFromLexPlayId();
      await tryToLoadPlaybookInstanceFromWordDocId();
      await tryToLoadPlaybookInstanceFromFilePath();
      await fillInPlaybookInstanceData();

      if (!foundPlaybookInstance.value) {
        redirectToNewPlaybook();
      }
    } finally {
      sidebarStore.setEntriesLoaded();
    }
  };

  return {
    docIdToSearch,
    filePath,
    loadPlaybookInstance
  };
}
