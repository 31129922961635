<template>
  <BaseTitle text="Playbooks" />
  <div class="mb-6 grid max-w-lg gap-y-3">
    <div>
      <label for="clientId" class="block text-sm font-medium text-gray-700">
        Client
      </label>
      <select
        v-model="clientId"
        name="clientId"
        class="mt-1 block w-full rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lexoo focus:outline-none focus:ring-lexoo sm:text-sm"
      >
        <option disabled :value="null">Select a client</option>
        <option v-for="client in clients" :key="client.id" :value="client.id">
          {{ client.name }}
        </option>
      </select>
    </div>

    <div v-if="client">
      <label for="playbookId" class="block text-sm font-medium text-gray-700">
        Playbook
      </label>
      <div
        v-if="client && client.playbooks.length == 1"
        class="block text-sm font-medium text-gray-400"
      >
        {{ client.playbooks[0].name }}
      </div>
      <div
        v-else-if="client && client.playbooks.length == 0"
        class="block text-sm font-medium text-gray-400"
      >
        This client doesn't have a playbook yet.
      </div>
      <select
        v-else-if="client"
        v-model="playbookId"
        :disabled="!client || client.playbooks.length < 2"
        class="mt-1 block w-full rounded border-gray-300 py-2 pl-3 pr-10 text-base focus:border-lexoo focus:outline-none focus:ring-lexoo disabled:opacity-50 sm:text-sm"
      >
        <option disabled :value="null">Please select one</option>
        <option
          v-for="playbook in client.playbooks"
          :key="playbook.id"
          :value="playbook.id"
        >
          {{ playbook.name }}
        </option>
      </select>
    </div>
  </div>
  <BaseButton
    v-if="client"
    class="float-right mt-2"
    label="Create a new playbook"
    @click="showNewPlaybookModal = true"
    size="small"
    buttonStyle="white"
  />
  <div v-if="clientId" class="border-b border-gray-200 pb-5 sm:pb-0">
    <div class="mt-3 sm:mt-4">
      <div class="hidden sm:block">
        <nav class="-mb-px flex space-x-8">
          <router-link to="/admin/playbooks/entries" class="tab">
            Entries
          </router-link>
          <router-link to="/admin/playbooks/playbook-settings" class="tab">
            Playbook settings
          </router-link>
          <router-link to="/admin/playbooks/client-settings" class="tab">
            Client settings
          </router-link>
        </nav>
      </div>
    </div>
  </div>
  <router-view />

  <NewPlaybookModal
    v-if="client"
    :client="client"
    :show="showNewPlaybookModal"
    @close="showNewPlaybookModal = false"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import BaseTitle from "@/components/BaseTitle.vue";
import { useAdmin } from "@/components/shared/Admin";
import NewPlaybookModal from "@/views/admin/NewPlaybookModal.vue";

export default defineComponent({
  components: { BaseTitle, NewPlaybookModal },

  setup() {
    const { client, clientId, clients, playbookId } = useAdmin();
    const showNewPlaybookModal = ref(false);
    return { client, clientId, clients, playbookId, showNewPlaybookModal };
  },

  watch: {
    clientId() {
      this.playbookId = null;
      if (this.client && this.client.playbooks.length === 1) {
        this.playbookId = this.client.playbooks[0].id;
      }
    }
  }
});
</script>

<style scoped lang="scss">
.router-link-active {
  @apply border-lexoo text-lexoo;
}

.tab {
  @apply whitespace-nowrap border-b-2 px-1 pb-4 text-sm font-medium;
}

.tab:not(.router-link-active) {
  @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700;
}
</style>
