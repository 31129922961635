<template>
  <div class="group relative">
    <button
      v-if="hasPlaybookChangeAccess && !editing"
      type="button"
      class="invisible absolute right-1 top-0 rounded bg-white px-1 py-1 text-xs font-semibold text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 group-hover:visible"
      @click="startEditing"
    >
      <PencilIcon class="h-4 w-4" />
    </button>
    <div v-if="editing && model">
      <BaseEditableText
        ref="editBox"
        class="mt-1 min-w-full text-xs"
        v-model="model"
      />

      <div class="my-4 flex justify-end">
        <span class="rounded shadow-sm">
          <BaseButton @click="saveValue" size="small" position="left">
            Save
          </BaseButton>

          <BaseButton
            @click="editing = false"
            size="small"
            position="right"
            buttonStyle="white"
          >
            Cancel
          </BaseButton>
        </span>
      </div>
    </div>
    <DisplayedEditableText
      v-else-if="displayStringWithFilterResult"
      class="my-2"
      :content="displayStringWithFilterResult"
      v-model:checkedItems="checkedItems"
      @update:checkedItems="saveCheckedItems($event)"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, PropType, ref, Ref, watch } from "vue";
import BaseEditableText from "@/components/base/BaseEditableText.vue";
import DisplayedEditableText from "@/components/base/DisplayedEditableText.vue";
import { PlaybookInstance } from "@/types";
import { useInlineEdit } from "@/components/sidebar/InlineEdit";
import { useOldSkoolVariablesStore } from "@/store/OldSkoolVariablesStore";
import { displayStringWithMarkedText } from "@/helpers/TextHelpers";
import { PencilIcon } from "@heroicons/vue/24/solid";
import ApiService from "@/services/ApiService";

const props = defineProps({
  entryId: {
    type: Number,
    required: true
  },

  text: {
    type: String,
    required: true
  },

  filterText: {
    type: String,
    required: true
  },

  playbookInstance: {
    type: Object as PropType<PlaybookInstance>,
    required: false
  }
});
const emit = defineEmits(["update"]);

const {
  editBox,
  editing,
  hasPlaybookChangeAccess,
  model,
  startEditing,
  saveValue
} = useInlineEdit(emit, props);

const checkedItems: Ref<string[]> = ref([]);

watch(
  () => props.playbookInstance,
  (newPlaybookInstance: PlaybookInstance) => {
    if (newPlaybookInstance) {
      nextTick(() => {
        if (!newPlaybookInstance.checkedTasks) {
          newPlaybookInstance.checkedTasks = {};
        }
        checkedItems.value =
          newPlaybookInstance.checkedTasks[props.entryId.toString()] ?? [];
      });
    }
  },
  { deep: true, immediate: true }
);

const displayStringWithFilterResult = computed(() => {
  const oldSkoolVariablesStore = useOldSkoolVariablesStore();
  const result = oldSkoolVariablesStore.textWithOldSkoolVariables(props.text);

  return displayStringWithMarkedText(result, props.filterText);
});

const saveCheckedItems = (checkedItems: string[]) => {
  if (!props.playbookInstance) {
    return;
  }
  const checkedTasks =
    props.playbookInstance.checkedTasks ?? ({} as { [key: string]: string[] });
  const entryId = props.entryId;
  checkedTasks[entryId.toString()] = checkedItems;
  const playbookInstance = {
    id: props.playbookInstance.id,
    checkedTasks
  };
  ApiService.updatePlaybookInstanceCheckedTasks(playbookInstance);
};
</script>

<style lang="scss" scoped>
:deep(.editable-text .ProseMirror) {
  @apply min-h-[60px];
}

:deep(.ProseMirror) {
  @apply block w-full cursor-text rounded border border-gray-300 bg-white px-4 py-2 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm;
}

:deep(ul[data-type="taskList"]) {
  list-style: none;
  padding: 0;

  li {
    display: flex;
    align-items: baseline;

    > label {
      flex: 0 0 auto;
    }
  }
}

:deep(.prose-sm ul[data-type="taskList"] > li) {
  padding: 0;
  &:before {
    content: inherit;
  }
}

:deep(.prose-sm ul[data-type="taskList"] > li > *:first-child) {
  margin: 0;
  margin-right: 0.5rem;
}

:deep(.prose-sm > ul[data-type="taskList"] > li > *:last-child) {
  margin-bottom: 0;
}
</style>
