import { Node, mergeAttributes } from "@tiptap/core";

const DetailsSummary = Node.create({
  name: "detailsSummary",
  content: "text*",
  defining: true,
  selectable: false,
  isolating: true,
  addOptions: () => ({
    HTMLAttributes: {}
  }),
  parseHTML: () => [
    {
      tag: "summary"
    }
  ],

  renderHTML({ HTMLAttributes }: { HTMLAttributes: Record<string, any> }) {
    return [
      "summary",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0
    ];
  }
});

export { DetailsSummary };
export default DetailsSummary;
