<template>
  <ul
    class="max-h-56 overflow-auto rounded bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
  >
    <li
      :class="[
        index === selectedIndex ? 'bg-indigo-600 text-white' : 'text-gray-900',
        'relative cursor-default select-none py-2 pl-3 pr-9'
      ]"
      v-for="(commenter, index) in items"
      :key="commenter.id"
      :value="commenter.id"
      @click="selectItem(index)"
    >
      <div class="flex items-center">
        <img
          :src="commenter.image"
          alt=""
          class="h-6 w-6 flex-shrink-0 rounded-full"
        />
        <span
          :class="[
            index === selectedIndex ? 'font-semibold' : 'font-normal',
            'ml-3 block truncate'
          ]"
        >
          {{ commenter.name }}
        </span>
      </div>
    </li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { User } from "@/types";

export default defineComponent({
  props: {
    items: {
      type: Array as PropType<User[]>,
      required: true
    },

    command: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      selectedIndex: 0
    };
  },

  methods: {
    onKeyDown({ event }: { event: { key: string } }): boolean {
      if (event.key === "ArrowUp") {
        this.upHandler();
        return true;
      }

      if (event.key === "ArrowDown") {
        this.downHandler();
        return true;
      }

      if (event.key === "Enter") {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler(): void {
      this.selectedIndex =
        (this.selectedIndex + this.items.length - 1) % this.items.length;
    },

    downHandler(): void {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.length;
    },

    enterHandler(): void {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index: number): void {
      const item = this.items[index];

      if (item) {
        // this.command(item);
        this.command({ id: item.id, label: item.name });
      }
    }
  }
});
</script>
