<template>
  <div class="lg:max-w-2xl">
    <BaseTitle class="flex">
      <CubeTransparentIcon class="mr-2 h-6 w-6 text-lexoo" aria-hidden="true" />
      SSO Provider
    </BaseTitle>
    <p class="my-4 text-sm text-gray-500">
      Note: this is beta functionality and we currently only support Okta. If
      you would like to enable Okta for your account please contact
      <a href="mailto:team@lexoo.com">team@lexoo.com</a> to enable.
    </p>
    <p class="my-4 text-sm text-gray-500">
      Details about setting up Okta can be found here:
      <a
        class="block underline"
        href="https://playbook.lexoo.com/okta-configuration-manual"
        target="_window"
      >
        https://playbook.lexoo.com/okta-configuration-manual
      </a>
    </p>

    <form @submit="saveTenant">
      <div class="mt-2">
        <BaseInput
          label="Okta domain"
          v-model="oktaOrgUrl"
          placeholder="e.g. mycompany.okta.com"
          class="w-full"
        />
      </div>
      <div class="mt-2">
        <BaseInput
          label="Okta client id"
          v-model="oktaClientId"
          class="w-full"
        />
      </div>

      <div class="mt-2">
        <BaseInput
          label="Okta client secret"
          v-model="oktaClientSecret"
          class="w-full"
          inputType="password"
        />
      </div>
      <div class="mt-5 flex space-x-3 sm:mt-4">
        <BaseButton @click="saveTenant"> {{ buttonLabel }}</BaseButton>
      </div>
    </form>

    <p class="my-4 text-sm text-gray-500">
      Note: once the credentials have been updated, you will need to log out and
      log back in with your Okta account.
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { CubeTransparentIcon } from "@heroicons/vue/24/outline";

import ApiService from "@/services/ApiService";

const oktaOrgUrl = ref("");
const oktaClientId = ref("");
const oktaClientSecret = ref("");

const loadTenant = () => {
  ApiService.getTenant().then(({ data }) => {
    oktaOrgUrl.value = data.tenant.oktaOrgUrl;
    oktaClientId.value = data.tenant.oktaClientId;
    oktaClientSecret.value = data.tenant.oktaClientSecret;
  });
};

const saving = ref(false);
const buttonLabel = computed(() => {
  if (saving.value) {
    return "Updating...";
  } else {
    return "Update";
  }
});

const saveTenant = () => {
  saving.value = true;
  ApiService.updateTenant({
    oktaOrgUrl: oktaOrgUrl.value,
    oktaClientId: oktaClientId.value,
    oktaClientSecret: oktaClientSecret.value
  }).then(() => {
    saving.value = false;
  });
};

onMounted(loadTenant);
</script>
