<template>
  <div>
    <button
      class="w-100 flex items-center justify-center rounded bg-lexoo px-4 py-2 text-sm font-medium text-white hover:opacity-80"
      @click="loginWithGoogle"
    >
      <svg
        class="mr-2 h-4 w-4"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 488 512"
      >
        <path
          fill="currentColor"
          d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
        />
      </svg>
      Sign in with Google
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, Ref } from "vue";
import AuthService from "@/services/AuthService";

const wordDialogWindow = ref(null) as Ref<Office.Dialog | null>;

const authService = new AuthService();

const handleWordDialog = () => {
  if (wordDialogWindow.value) {
    wordDialogWindow.value.close();
    window.location.replace("/word-view");
  }
};

const loginWithGoogle = () => {
  if (!Office.context.host) {
    console.log("Not running in Office context");
    window.location.href = authService.googleLoginUrl();
  } else {
    console.log("Running in Office context");
    Office.onReady(() => {
      Office.context.ui.displayDialogAsync(
        authService.googleLoginUrl(),
        { displayInIframe: false, height: 60, width: 30 },
        (asyncResult) => {
          wordDialogWindow.value = asyncResult.value;
          wordDialogWindow.value.addEventHandler(
            Office.EventType.DialogMessageReceived,
            handleWordDialog
          );
          wordDialogWindow.value.addEventHandler(
            Office.EventType.DialogEventReceived,
            handleWordDialog
          );
        }
      );
    });
  }
};
</script>

<style lang="scss" scoped></style>
