import { Playbook } from "@/types";

import ApiService from "@/services/ApiService";

const redirectToPlaybookDownload = (token: string, format: string) => {
  // Construct the magic link URL with the token
  // Get the current protocol (http: or https:), domain name (hostname), and port number (if any)
  const protocol = window.location.protocol; // e.g., "http:"
  const hostname = window.location.hostname; // e.g., "example.com"
  const port = window.location.port; // e.g., "8080" or empty string if no port specified

  // Construct the full URL, including the port number if it exists
  const currentDomain = protocol + "//" + hostname + (port ? ":" + port : "");

  const magicLinkUrl = `${currentDomain}/playbook-download/${token}.${format}`;
  if (Office.context.ui) {
    Office.context.ui.openBrowserWindow(magicLinkUrl);
  } else {
    window.open(magicLinkUrl, "_blank");
  }
};

const downloadPlaybookXls = async (playbook: Playbook) => {
  ApiService.createPlaybookExportMagicLink(playbook, "xlsx", "").then(
    ({ data }) => {
      const token = data.token;
      redirectToPlaybookDownload(token, "xlsx");
    }
  );
};

const downloadPlaybookPdf = async (playbook: Playbook, rawHtml: string) => {
  await ApiService.createPlaybookExportMagicLink(playbook, "pdf", rawHtml).then(
    ({ data }) => {
      const token = data.token;
      redirectToPlaybookDownload(token, "pdf");
    }
  );
};

export { downloadPlaybookXls, downloadPlaybookPdf };
