<template>
  <div v-if="user">
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog
        as="div"
        class="relative z-50 lg:hidden"
        @close="sidebarOpen = false"
      >
        <TransitionChild
          as="template"
          enter="transition-opacity ease-linear duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-900/80" />
        </TransitionChild>

        <div class="fixed inset-0 flex">
          <TransitionChild
            as="template"
            enter="transition ease-in-out duration-300 transform"
            enter-from="-translate-x-full"
            enter-to="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leave-from="translate-x-0"
            leave-to="-translate-x-full"
          >
            <DialogPanel class="relative mr-16 flex w-full max-w-xs flex-1">
              <TransitionChild
                as="template"
                enter="ease-in-out duration-300"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-300"
                leave-from="opacity-100"
                leave-to="opacity-0"
              >
                <div
                  class="absolute left-full top-0 flex w-16 justify-center pt-5"
                >
                  <button
                    type="button"
                    class="-m-2.5 p-2.5"
                    @click="sidebarOpen = false"
                  >
                    <span class="sr-only">Close sidebar</span>
                    <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
              <!-- Sidebar component, swap this element with another sidebar if you like -->
              <div
                class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2"
              >
                <div class="flex h-16 shrink-0 items-center">
                  <img
                    class="h-8 w-auto"
                    src="@/assets/images/lexplay-192.png"
                    alt="LexPlay"
                  />
                </div>
                <nav class="flex flex-1 flex-col">
                  <ul
                    @click="sidebarOpen = false"
                    role="list"
                    class="flex flex-1 flex-col gap-y-7"
                  >
                    <li>
                      <ul role="list" class="-mx-2 space-y-1">
                        <li v-for="item in navigation" :key="item.name">
                          <component
                            :is="item.disabled ? 'span' : 'router-link'"
                            :to="item.href"
                            class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                            :class="{ 'disabled-link': item.disabled }"
                          >
                            <component
                              v-if="item.icon"
                              :is="item.icon"
                              class="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                            {{ item.name }}
                          </component>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div
      class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col"
    >
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div
        class="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6"
      >
        <div class="flex h-16 shrink-0 items-center">
          <a href="/">
            <LexPlayLogo class="w-32 text-lexoo" />
          </a>
        </div>
        <div>
          <div class="relative mb-2 flex-grow focus-within:z-10">
            <div
              class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
            >
              <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
            </div>
            <input
              @keyup.escape="filterText = ''"
              type="text"
              class="block w-full rounded border-gray-300 pl-7 text-xs focus:border-lexoo focus:ring-lexoo"
              placeholder="Search all playbook entries"
              v-model="filterText"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            />
            <div
              v-if="filterText"
              class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
              @click="filterText = ''"
            >
              <XMarkIcon class="h-5 w-5 text-lexoo" aria-hidden="true" />
            </div>
          </div>
          <QuickSearchResults :search="filterText" />
        </div>

        <nav class="flex flex-1 flex-col">
          <ul role="list" class="flex flex-1 flex-col gap-y-7">
            <li>
              <ul role="list" class="-mx-2 space-y-1">
                <li v-for="item in navigation" :key="item.name">
                  <component
                    :is="item.disabled ? 'span' : 'router-link'"
                    :to="item.href"
                    class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                    :class="{
                      'disabled-link': item.disabled
                    }"
                  >
                    <component
                      v-if="item.icon"
                      :is="item.icon"
                      class="h-6 w-6 shrink-0 group-hover:text-lexoo"
                      aria-hidden="true"
                    />

                    {{ item.name }}

                    <StarIcon
                      v-if="shouldHighlightItem(item)"
                      class="-ml-1 h-4 w-4 shrink-0 text-yellow-400"
                    />
                  </component>
                </li>
              </ul>
            </li>
            <li v-if="isAdmin">
              <div class="text-xs font-semibold leading-6 text-gray-400">
                Admin
              </div>
              <ul role="list" class="-mx-2 mt-2 space-y-1">
                <li>
                  <ul role="list" class="space-y-1">
                    <li v-for="item in adminLinks" :key="item.name">
                      <router-link
                        :to="item.href"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6"
                      >
                        <component
                          :is="item.icon"
                          class="h-6 w-6 shrink-0"
                          aria-hidden="true"
                        />
                        {{ item.name }}
                      </router-link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>

            <li v-if="user" class="-mx-6 mt-auto">
              <Menu as="div" class="relative">
                <MenuButton
                  class="group flex w-full items-center gap-x-4 px-6 py-3 text-left text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                >
                  <img
                    v-if="user.image"
                    :src="user.image"
                    class="h-8 w-8 rounded-full rounded-full bg-gray-50 grayscale group-hover:filter-none"
                  />
                  <span>{{ user.name }}</span>
                  <ChevronDownIcon
                    class="ml-2 h-5 w-5 grow text-gray-400"
                    aria-hidden="true"
                  />
                </MenuButton>
                <transition
                  enter-active-class="transition ease-out duration-100"
                  enter-from-class="transform opacity-0 scale-95"
                  enter-to-class="transform opacity-100 scale-100"
                  leave-active-class="transition ease-in duration-75"
                  leave-from-class="transform opacity-100 scale-100"
                  leave-to-class="transform opacity-0 scale-95"
                >
                  <MenuItems
                    class="absolute bottom-10 right-1 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
                  >
                    <MenuItem
                      v-for="item in userNavigation"
                      :key="item.name"
                      v-slot="{ active, close }"
                    >
                      <a
                        href="#"
                        @click.prevent="
                          router.push(item.href);
                          close();
                        "
                        :class="[
                          active ? 'bg-gray-50' : '',
                          'block px-3 py-1 text-sm leading-6 text-gray-900'
                        ]"
                      >
                        {{ item.name }}
                      </a>
                    </MenuItem>
                  </MenuItems>
                </transition>
              </Menu>
            </li>
          </ul>
        </nav>
      </div>
    </div>

    <div
      class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden"
    >
      <button
        type="button"
        class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        @click="sidebarOpen = true"
      >
        <span class="sr-only">Open sidebar</span>
        <Bars3Icon class="h-6 w-6" aria-hidden="true" />
      </button>
      <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">
        {{ routeName }}
      </div>
      <Menu as="div" class="relative">
        <MenuButton>
          <img
            v-if="user.image"
            :src="user.image"
            class="h-8 w-8 rounded-full rounded-full bg-gray-50 group-hover:filter-none"
          />
        </MenuButton>
        <transition
          enter-active-class="transition ease-out duration-100"
          enter-from-class="transform opacity-0 scale-95"
          enter-to-class="transform opacity-100 scale-100"
          leave-active-class="transition ease-in duration-75"
          leave-from-class="transform opacity-100 scale-100"
          leave-to-class="transform opacity-0 scale-95"
        >
          <MenuItems
            class="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
          >
            <MenuItem
              v-for="item in userNavigation"
              :key="item.name"
              v-slot="{ active, close }"
            >
              <a
                href="#"
                @click.prevent="
                  router.push(item.href);
                  close();
                "
                :class="[
                  active ? 'bg-gray-50' : '',
                  'block px-3 py-1 text-sm leading-6 text-gray-900'
                ]"
              >
                {{ item.name }}
              </a>
            </MenuItem>
          </MenuItems>
        </transition>
      </Menu>
    </div>

    <slot></slot>
  </div>
  <div v-else>
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { useRouter } from "vue-router";
import LexPlayLogo from "@/assets/images/LexPlayLogo.svg";

import { useAuthorisationStore } from "@/store/AuthorisationStore";

import QuickSearchResults from "@/components/sidebar/QuickSearchResults.vue";

import {
  BookOpenIcon,
  BuildingOffice2Icon,
  ChevronDownIcon,
  CubeTransparentIcon,
  LifebuoyIcon,
  PencilIcon,
  PuzzlePieceIcon,
  SparklesIcon,
  UsersIcon,
  XMarkIcon
} from "@heroicons/vue/24/outline";

import { StarIcon } from "@heroicons/vue/24/solid";
import { Bars3Icon, MagnifyingGlassIcon } from "@heroicons/vue/20/solid";
import WordLogo from "@/assets/images/microsoft-word-icon.svg";

const authorisationStore = useAuthorisationStore();
const user = computed(() => authorisationStore.user);
const isAdmin = computed(() => authorisationStore.isAdmin);

const filterText = ref("");

const isViewer = computed(() => user.value && user.value.role === "viewer");
const isBeta = computed(() => authorisationStore.isBeta);

const navigation = [
  {
    name: "Playbooks",
    href: "/sidebar/playbooks",
    icon: BookOpenIcon
  },
  {
    name: "Invite team",
    href: "/users",
    icon: UsersIcon,
    disabled: isViewer.value
  },
  {
    name: "Support",
    href: "/support",
    icon: LifebuoyIcon
  },
  {
    name: "Install add-in",
    href: "/install-add-in-instructions",
    icon: WordLogo
  },
  {
    name: "SSO Provider",
    href: "/sso-provider",
    icon: CubeTransparentIcon,
    disabled: isBeta.value
  }
];

const adminLinks = [
  { name: "Clients", href: "/admin/clients", icon: BuildingOffice2Icon },
  {
    name: "Playbooks by client",
    href: "/admin/playbooks/entries",
    icon: BookOpenIcon
  },
  { name: "Users", href: "/admin/users", icon: UsersIcon },
  {
    name: "Playbook templates",
    href: "/admin/playbook-templates",
    icon: PuzzlePieceIcon
  },
  {
    name: "AI Prompts",
    href: "/admin/prompt-templates",
    icon: SparklesIcon
  },
  {
    name: "AI Scratchpad",
    href: "/admin/ai-scratchpad",
    icon: PencilIcon
  }
];

const userNavigation = [
  { name: "Your profile", href: "/profile" },
  { name: "Sign out", href: "/logout" }
];

const sidebarOpen = ref(false);
const router = useRouter();

const routeName = computed(() => {
  const route = router.currentRoute.value;
  if (route.meta.title) {
    return route.meta.title;
  } else {
    return "LexPlay";
  }
});

const shouldHighlightItem = (item) => {
  if (!user.value) {
    return false;
  }
  return item.name === "Install add-in" && user.value.installedAddInAt === null;
};
</script>

<style lang="scss" scoped>
.router-link-active {
  @apply bg-gray-50 text-lexoo;

  :deep(svg path) {
    @apply filter-none;
  }

  img {
    @apply filter-none;
  }
}

nav a:not(.router-link-active) {
  @apply cursor-pointer text-gray-700 hover:bg-gray-50 hover:text-lexoo;
}

.disabled-link {
  @apply cursor-not-allowed text-gray-400 hover:bg-gray-50;

  svg {
    @apply text-gray-400;
  }
}

:deep(svg path) {
  @apply group-hover:filter-none;
}
</style>
