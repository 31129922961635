<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-10 w-auto"
        src="@/assets/images/lexplay-300.png"
        alt="LexPlay"
      />
      <h2
        class="mt-8 text-center text-3xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Start your 30-day free trial
      </h2>
      <p class="mt-2 text-center text-sm leading-6 text-gray-500">
        Negotiate contracts faster by using a LexPlay Playbook
      </p>

      <p class="mt-2 text-center text-sm leading-6 text-gray-500">
        Already have a LexPlay account?
      </p>
      <p class="mt-2 text-center text-sm leading-6 text-gray-500">
        <a href="/login" class="underline hover:text-gray-800">
          Click here to sign in.
        </a>
      </p>
    </div>

    <div class="sm:mx-auto sm:w-full sm:max-w-[480px]">
      <div class="px-6 py-10 sm:px-12">
        <div v-if="hasError" class="rounded bg-yellow-50 p-4 text-left">
          <div class="flex">
            <div class="flex-shrink-0">
              <ExclamationTriangleIcon
                class="h-5 w-5 text-yellow-400"
                aria-hidden="true"
              />
            </div>
            <div class="ml-3">
              <h3 class="text-sm font-medium text-yellow-800">
                Sign up failed
              </h3>
              <div class="mt-2 space-y-1 text-sm text-yellow-700">
                <p>There was a problem creating this user account.</p>

                <p>
                  Perhaps you already have an account with this email address?
                  If so you can login
                  <router-link to="/login" class="underline">here</router-link>.
                </p>

                <p>
                  Or you can contact
                  <a href="mailto:team@lexoo.com">team@lexoo.com</a> for
                  assistance.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div v-if="false" class="text-center">
          <BaseButton buttonStyle="white" @action="goToGoogleSignOn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 186.69 190.5"
              xmlns:v="https://vecta.io/nano"
              class="h-4 w-4"
            >
              <g transform="translate(1184.583 765.171)">
                <path
                  clip-path="none"
                  mask="none"
                  d="M-1089.333-687.239v36.888h51.262c-2.251 11.863-9.006 21.908-19.137 28.662l30.913 23.986c18.011-16.625 28.402-41.044 28.402-70.052 0-6.754-.606-13.249-1.732-19.483z"
                  fill="#4285f4"
                />
                <path
                  clip-path="none"
                  mask="none"
                  d="M-1142.714-651.791l-6.972 5.337-24.679 19.223h0c15.673 31.086 47.796 52.561 85.03 52.561 25.717 0 47.278-8.486 63.038-23.033l-30.913-23.986c-8.486 5.715-19.31 9.179-32.125 9.179-24.765 0-45.806-16.712-53.34-39.226z"
                  fill="#34a853"
                />
                <path
                  clip-path="none"
                  mask="none"
                  d="M-1174.365-712.61c-6.494 12.815-10.217 27.276-10.217 42.689s3.723 29.874 10.217 42.689c0 .086 31.693-24.592 31.693-24.592-1.905-5.715-3.031-11.776-3.031-18.098s1.126-12.383 3.031-18.098z"
                  fill="#fbbc05"
                />
                <path
                  d="M-1089.333-727.244c14.028 0 26.497 4.849 36.455 14.201l27.276-27.276c-16.539-15.413-38.013-24.852-63.731-24.852-37.234 0-69.359 21.388-85.032 52.561l31.692 24.592c7.533-22.514 28.575-39.226 53.34-39.226z"
                  fill="#ea4335"
                  clip-path="none"
                  mask="none"
                />
              </g>
            </svg>
            <span class="ml-2">Sign up with Google</span>
          </BaseButton>
        </div>

        <div v-if="false" class="relative my-8">
          <div class="absolute inset-0 flex items-center" aria-hidden="true">
            <div class="w-full border-t border-gray-200" />
          </div>
          <div
            class="relative flex justify-center text-sm font-medium leading-6"
          >
            <span class="bg-white px-6 text-gray-400">
              Or sign up through email
            </span>
          </div>
        </div>

        <form class="space-y-6" @submit.prevent="signUp">
          <div>
            <label
              for="name"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Name
            </label>
            <div class="mt-2">
              <input
                v-model="name"
                name="name"
                type="text"
                autocomplete="name"
                required="true"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="email"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div class="mt-2">
              <input
                v-model="email"
                name="email"
                type="email"
                autocomplete="email"
                required="true"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="companyName"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Company name (optional)
            </label>
            <div class="mt-2">
              <input
                v-model="companyName"
                name="companyName"
                type="text"
                autocomplete="organization"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="password"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Password
            </label>
            <div class="mt-2">
              <input
                v-model="password"
                name="password"
                type="password"
                autocomplete="new-password"
                required="true"
                class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              for="passwordConfirmation"
              class="block text-sm font-medium leading-6 text-gray-900"
            >
              Confirm password
            </label>
            <div class="relative mt-2">
              <input
                v-model="passwordConfirmation"
                name="passwordConfirmation"
                type="password"
                autocomplete="new-password"
                required="true"
                class="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-lexoo sm:text-sm sm:leading-6"
                :class="{
                  'pr-10 text-red-900  focus:ring-red-500': passwordsDontMatch,
                  'text-gray-900 ': !passwordsDontMatch
                }"
              />
              <div
                v-if="passwordsDontMatch"
                class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3"
              >
                <ExclamationCircleIcon
                  class="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            </div>
            <p
              v-if="passwordsDontMatch"
              class="mt-2 text-sm text-red-600"
              id="email-error"
            >
              Password and confirmation don't match.
            </p>
          </div>

          <div>
            <button
              type="submit"
              :disabled="loading || !okToSubmit"
              class="group relative flex w-full justify-center rounded border border-transparent bg-lexoo py-2 px-4 text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50 disabled:opacity-50"
            >
              <span class="absolute inset-y-0 left-0 flex items-center pl-3">
                <LockClosedIcon
                  class="h-5 w-5 text-white opacity-80 group-hover:opacity-100"
                  aria-hidden="true"
                />
              </span>
              {{ loading ? "Please wait..." : "Create account" }}
            </button>
            <p class="mt-4 text-sm leading-6 text-gray-500">
              By submitting this form, I agree to the
              <a
                href="/privacy-policy"
                class="font-semibold text-lexoo hover:underline"
                target="_window"
              >
                privacy&nbsp;policy
              </a>
              and
              <a
                href="/terms-and-conditions"
                class="font-semibold text-lexoo hover:underline"
                target="_window"
              >
                terms and conditions.
              </a>
            </p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  LockClosedIcon
} from "@heroicons/vue/24/solid";

import { SignUpClient, SignUpUser } from "@/types";
import ApiService from "@/services/ApiService";
import AuthService from "@/services/AuthService";
import router from "@/router";

const loading = ref(false);

const hasError = ref(false);
const name = ref("");
const email = ref("");
const password = ref("");
const passwordConfirmation = ref("");
const companyName = ref("");

const okToSubmit = computed(() => {
  if (passwordsDontMatch.value) {
    return false;
  }

  return (
    name.value.length > 0 &&
    email.value.length > 0 &&
    password.value.length &&
    passwordConfirmation.value.length > 0
  );
});

const passwordsDontMatch = computed(() => {
  // Don't check until they've typed something
  if (passwordConfirmation.value.length < password.value.length) {
    return false;
  }

  return password.value !== passwordConfirmation.value;
});

const signUp = async () => {
  loading.value = true;
  hasError.value = false;
  const userAttributes: SignUpUser = {
    name: name.value,
    email: email.value,
    password: password.value,
    passwordConfirmation: passwordConfirmation.value
  };

  const clientAttributes: SignUpClient = {
    name: companyName.value
  };
  try {
    const response = await ApiService.signUp(userAttributes, clientAttributes);
    if (response.status == 201) {
      router.push("/check-your-email");
    } else if (response.status == 200) {
      router.push("/verify-invitation-success");
    }
  } catch {
    hasError.value = true;
  }

  loading.value = false;
};

onMounted(() => {
  const authService = new AuthService();
  if (authService.signedIn()) {
    router.push("/");
  }
});

const goToGoogleSignOn = () => {
  const authService = new AuthService();
  window.location.href = authService.googleLoginUrl();
};
</script>
