<template>
  <div>
    <div class="relative mb-2 flex-grow focus-within:z-10">
      <div
        class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2"
      >
        <MagnifyingGlassIcon class="h-4 w-4 text-gray-400" />
      </div>
      <input
        @keyup.escape="clearSearch"
        type="text"
        class="block w-full rounded border-gray-300 pl-7 text-xs focus:border-lexoo focus:ring-lexoo"
        placeholder="Search playbook"
        :value="filterText"
        @input="$emit('update:filterText', $event.target.value)"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        ref="searchInput"
      />
      <div
        v-if="filterText"
        class="absolute inset-y-0 right-0 flex cursor-pointer items-center pr-3"
        @click="clearSearch"
      >
        <XMarkIcon class="h-5 w-5 text-lexoo" aria-hidden="true" />
      </div>
    </div>

    <TagSelect
      v-if="usedTags.length > 0"
      :modelValue="filterTags"
      @update:modelValue="
        $emit('update:filterTags', $event);
        $emit('changedTags');
      "
      placeholder="Filter by tag"
      :selectableTags="usedTags"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, PropType, ref } from "vue";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";
import { useSidebarStore } from "@/store/SidebarStore";
import TagSelect from "@/components/TagSelect.vue";
import { Client, Tag } from "@/types";

const sidebarStore = useSidebarStore();
const usedTags = computed(() => sidebarStore.usedTags);

defineProps({
  client: {
    type: Object as PropType<Client>,
    required: true
  },

  filterTags: {
    type: Array as PropType<Tag[]>,
    default: () => []
  },

  filterText: {
    type: String,
    default: ""
  }
});

const searchInput = ref<HTMLInputElement | null>(null);

const emits = defineEmits(["update:filterText"]);
const clearSearch = () => {
  emits("update:filterText", "");
  searchInput.value?.focus();
};
</script>
