<template>
  <div class="sm:mx-auto sm:w-full sm:max-w-[400px]">
    <div class="px-6 sm:px-12">
      <SignInErrors
        :email="email"
        :generalError="generalError"
        :userNotFound="userNotFound"
        :userNotConfirmed="userNotConfirmed"
        :customError="customError"
      />
      <form
        v-if="!showPassword"
        class="mt-8 space-y-6 text-left"
        @submit.prevent="next"
      >
        <BaseInput
          v-model="email"
          inputType="email"
          autofocus
          required="true"
          label="Email address"
        />
        <div>
          <button
            type="submit"
            :disabled="loading"
            class="group relative flex w-full justify-center rounded border border-transparent bg-lexoo py-2 px-4 text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
          >
            {{ loading ? "Please wait..." : "Next" }}
          </button>
        </div>
      </form>
      <form v-else class="mt-8 space-y-6 text-left" @submit.prevent="signIn">
        <BaseInput
          v-model="email"
          inputType="email"
          autofocus
          required="true"
          label="Email address"
          :disabled="true"
        />
        <BaseInput
          v-model="password"
          inputType="password"
          autofocus
          required="true"
          label="Password"
          ref="passwordInput"
        />

        <div>
          <button
            type="submit"
            :disabled="loading"
            class="group relative flex w-full justify-center rounded border border-transparent bg-lexoo py-2 px-4 text-sm font-medium text-white hover:opacity-90 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2 disabled:opacity-50"
          >
            <span class="absolute inset-y-0 left-0 flex items-center pl-3">
              <LockClosedIcon
                class="h-5 w-5 text-white opacity-80 group-hover:opacity-100"
                aria-hidden="true"
              />
            </span>
            {{ loading ? "Signing in..." : "Sign in" }}
          </button>
        </div>
      </form>
      <div v-if="showPassword" class="flex items-center justify-between">
        <div class="text-sm">
          <router-link
            to="/forgot-password"
            class="font-medium text-lexoo underline hover:opacity-90"
          >
            Forgot your password?
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { LockClosedIcon } from "@heroicons/vue/24/solid";
import ApiService from "@/services/ApiService";
import AuthService from "@/services/AuthService";
import SignInErrors from "@/components/signin/SignInErrors.vue";

const props = defineProps({
  initialEmail: {
    type: String,
    default: ""
  }
});
const email = ref(props.initialEmail);
const password = ref("");
const loading = ref(false);
const showPassword = ref(false);
const userNotConfirmed = ref(false);
const userNotFound = ref(false);
const generalError = ref(false);
const customError = ref("");
const wordDialogWindow = ref(null as Office.Dialog | null);
const passwordInput = ref(null);

const router = useRouter();

const next = async () => {
  loading.value = true;
  const { data } = await ApiService.getUserProvider(email.value);

  if (data.ssoProvider == "google") {
    loginWithGoogle();
  } else if (data.ssoProvider == "okta") {
    loginWithOkta();
  } else {
    loading.value = false;
    showPassword.value = true;
    nextTick(() => {
      if (passwordInput.value) {
        passwordInput.value.focusInput();
      }
    });
  }
};

const handleWordDialog = () => {
  if (wordDialogWindow.value) {
    wordDialogWindow.value.close();
    window.location.replace("/word-view");
  }
};

const loginWithGoogle = () => {
  const authService = new AuthService();
  if (!Office.context.host) {
    console.log("Not running in Office context");
    window.location.href = authService.googleLoginUrl(email.value);
  } else {
    console.log("Running in Office context");
    Office.onReady(() => {
      Office.context.ui.displayDialogAsync(
        authService.googleLoginUrl(email.value),
        { displayInIframe: false, height: 60, width: 30 },
        (asyncResult) => {
          wordDialogWindow.value = asyncResult.value;
          wordDialogWindow.value.addEventHandler(
            Office.EventType.DialogMessageReceived,
            handleWordDialog
          );
          wordDialogWindow.value.addEventHandler(
            Office.EventType.DialogEventReceived,
            handleWordDialog
          );
        }
      );
    });
  }
};

const loginWithOkta = () => {
  const authService = new AuthService();
  if (!Office.context.host) {
    console.log("Not running in Office context");
    window.location.href = authService.oktaLoginUrl(email.value);
  } else {
    console.log("Running in Office context");
    Office.onReady(() => {
      Office.context.ui.displayDialogAsync(
        authService.oktaLoginUrl(email.value),
        { displayInIframe: false, height: 60, width: 30 },
        (asyncResult) => {
          wordDialogWindow.value = asyncResult.value;
          wordDialogWindow.value.addEventHandler(
            Office.EventType.DialogMessageReceived,
            handleWordDialog
          );
          wordDialogWindow.value.addEventHandler(
            Office.EventType.DialogEventReceived,
            handleWordDialog
          );
        }
      );
    });
  }
};

const signIn = () => {
  userNotConfirmed.value = false;
  userNotFound.value = false;
  generalError.value = false;
  loading.value = true;
  const authService = new AuthService();
  authService
    .signIn({
      email: email.value,
      password: password.value
    })
    .then(() => {
      const passwordResetRequired = authService.passwordResetRequired();
      if (passwordResetRequired) {
        router.push("/change-password");
        return;
      } else if (typeof Office !== "undefined" && Office.context.document) {
        router.push("/word-view");
      } else {
        router.push(authService.startingPath());
      }
    })
    .catch((error) => {
      console.log(JSON.stringify(error.response));
      loading.value = false;

      const errorStatus = error.response.status;
      const errorMessage = error.response.data.message;
      if (errorStatus == 404) {
        userNotFound.value = true;
      } else if (errorStatus == 403 && errorMessage == "Not Confirmed") {
        userNotConfirmed.value = true;
      } else {
        generalError.value = true;
      }
    });
};

onMounted(() => {
  const authService = new AuthService();
  if (authService.signedIn()) {
    router.push(authService.startingPath());
  }
  showPassword.value = false;
  if (typeof Office != "undefined") {
    Office.onReady();
  }

  const route = useRoute();
  if (route.query["error"]) {
    customError.value = route.query["error"].toString();
  }
});
</script>
