<template>
  <tr class="bg-white align-top">
    <template v-if="editMode && editEntry">
      <td>&nbsp;</td>
      <td class="px-2 py-4 align-top">
        <input
          type="text"
          name="heading"
          v-model="editEntry.heading"
          ref="heading"
          class="block w-full rounded border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-lexoo sm:text-sm"
        />
      </td>
      <td class="space-y-6 px-2 py-4 align-top">
        <BaseEditableText
          label="Entry description"
          v-model="editEntry.entryDescription"
        />

        <BaseEditableText label="Description" v-model="editEntry.description" />

        <SimpleEditableText
          label="Template wording"
          v-model="editEntry.templateWording"
        />

        <SimpleEditableText
          label="Template comment"
          v-model="editEntry.templateComment"
        />
        <template v-if="showThirdParty">
          <BaseEditableText
            label="Third party text"
            v-model="editEntry.thirdPartyDescription"
          />

          <SimpleEditableText
            label="Third party template wording"
            v-model="editEntry.thirdPartyTemplateWording"
          />

          <SimpleEditableText
            label="Third party template comment"
            v-model="editEntry.thirdPartyTemplateComment"
          />
        </template>
      </td>
      <td class="w-36 px-2 py-4 align-top">
        <TagSelect v-model="editEntry.tags" :selectableTags="tags" />
      </td>
      <td class="py-4 px-2 align-top">
        <span class="inline-flex rounded shadow-sm">
          <BaseButton
            position="left"
            @click.prevent="
              $emit('save', editEntry);
              editEntry = null;
              editMode = false;
            "
          >
            Save
          </BaseButton>
          <BaseButton
            position="right"
            @click.prevent="editMode = false"
            buttonStyle="white"
          >
            Cancel
          </BaseButton>
        </span>
      </td>
    </template>
    <template v-else>
      <td class="px-6 py-4">
        <input
          type="checkbox"
          class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-lexoo"
          :checked="entrySelection.entries.has(entry)"
          @click="entrySelection.toggle(entry)"
        />
      </td>
      <td class="px-6 py-4 text-sm font-medium text-gray-900">
        <span
          v-if="entry.heading"
          v-html="displayStringWithFilterResult(entry.heading)"
        />
      </td>
      <td class="space-y-2 px-6 py-4 text-sm text-gray-900">
        <div
          class="entry-description"
          :class="{ 'line-clamp-3': !showMore }"
          ref="entry"
        >
          <div
            class="prose prose-sm"
            v-html="
              displayStringWithReadonlyInputs(
                displayStringWithFilterResult(entry.entryDescription)
              )
            "
          />

          <div
            class="prose prose-sm"
            v-html="
              displayStringWithReadonlyInputs(
                displayStringWithFilterResult(entry.description)
              )
            "
          />

          <div
            class="mt-3 overflow-hidden rounded-lg bg-gray-50"
            v-if="entry.templateWording"
          >
            <h3 class="mt-4 mb-2 ml-4 truncate text-sm text-gray-500">
              Template wording
            </h3>
            <p
              class="whitespace-pre-wrap px-4 pb-5"
              v-html="
                displayStringWithReadonlyInputs(
                  displayStringWithFilterResult(entry.templateWording)
                )
              "
            />
          </div>

          <div
            class="mt-3 overflow-hidden rounded-lg bg-gray-50"
            v-if="entry.templateComment"
          >
            <h3 class="mt-4 mb-2 ml-4 truncate text-sm text-gray-500">
              Template comment
            </h3>
            <p
              class="whitespace-pre-wrap px-4 pb-5"
              v-html="
                displayStringWithReadonlyInputs(
                  displayStringWithFilterResult(entry.templateComment)
                )
              "
            />
          </div>

          <template v-if="showThirdParty">
            <div
              class="mt-3 overflow-hidden"
              v-if="entry.thirdPartyDescription"
            >
              <h3 class="mt-4 mb-2 ml-4 truncate text-sm text-gray-500">
                Third party description
              </h3>
              <p
                class="whitespace-pre-wrap px-4 pb-5"
                v-html="
                  displayStringWithReadonlyInputs(
                    displayStringWithFilterResult(entry.thirdPartyDescription)
                  )
                "
              />
            </div>

            <div
              class="mt-3 overflow-hidden rounded-lg bg-gray-50"
              v-if="entry.thirdPartyTemplateWording"
            >
              <h3 class="mt-4 mb-2 ml-4 truncate text-sm text-gray-500">
                Third party template wording
              </h3>
              <p
                class="whitespace-pre-wrap px-4 pb-5"
                v-html="
                  displayStringWithReadonlyInputs(
                    displayStringWithFilterResult(
                      entry.thirdPartyTemplateWording
                    )
                  )
                "
              />
            </div>

            <div
              class="mt-3 overflow-hidden rounded-lg bg-gray-50"
              v-if="entry.thirdPartyTemplateComment"
            >
              <h3 class="mt-4 mb-2 ml-4 truncate text-sm text-gray-500">
                Third party template comment
              </h3>
              <p
                class="whitespace-pre-wrap px-4 pb-5"
                v-html="
                  displayStringWithReadonlyInputs(
                    displayStringWithFilterResult(
                      entry.thirdPartyTemplateComment
                    )
                  )
                "
              />
            </div>
          </template>

          <CommentList :entry="entry" />
        </div>
        <button
          v-if="needsClamp"
          @click.prevent="showMore = !showMore"
          class="block text-right text-xs font-medium text-red-500 hover:text-red-800"
        >
          {{ showMore ? "- Less" : "+ More" }}
        </button>
      </td>
      <td class="whitespace-normal px-4 py-4 text-right">
        <HeadlessMenu>
          <MenuButton
            class="rounded-full p-2 text-gray-400 hover:text-gray-600"
          >
            <span class="sr-only">Open options</span>
            <EllipsisVerticalIcon class="h-5 w-5" aria-hidden="true" />
          </MenuButton>

          <MenuItems
            class="absolute right-16 z-10 w-60 origin-top-right rounded bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none xl:right-36"
          >
            <div class="py-1">
              <MenuItem v-slot="{ active, close }">
                <a
                  href="#"
                  class="flex px-4 py-2 text-sm"
                  :class="[
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700'
                  ]"
                  @click.prevent="
                    editMode = true;
                    editEntry = Object.assign({}, entry);
                    close();
                  "
                >
                  <PencilIcon
                    class="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Edit</span>
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active, close }">
                <a
                  href="#"
                  @click.prevent="
                    $emit('editEscalations');
                    close();
                  "
                  class="flex px-4 py-2 text-sm"
                  :class="{
                    'bg-gray-100': active,
                    'text-lexoo': escalationTemplatePresent
                  }"
                >
                  <ArrowsPointingOutIcon
                    class="mr-3 h-5 w-5 text-gray-400"
                    :class="{
                      'text-lexoo': escalationTemplatePresent
                    }"
                    aria-hidden="true"
                  />
                  <span
                    :class="{
                      'text-lexoo': escalationTemplatePresent
                    }"
                  >
                    Escalations
                  </span>
                </a>
              </MenuItem>
              <MenuItem v-slot="{ active, close }">
                <a
                  href="#"
                  @click.prevent="
                    $emit('editFallbacks');
                    close();
                  "
                  class="flex px-4 py-2 text-sm"
                  :class="{
                    'bg-gray-100': active,
                    'text-lexoo': fallbackScenariosPresent
                  }"
                >
                  <ExclamationCircleIcon
                    class="mr-3 h-5 w-5 text-gray-400"
                    :class="{
                      'text-lexoo': fallbackScenariosPresent
                    }"
                    aria-hidden="true"
                  />
                  <span>Fallbacks</span>
                </a>
              </MenuItem>

              <MenuItem v-slot="{ active, close }">
                <a
                  href="#"
                  @click.prevent="
                    $emit('duplicate');
                    close();
                  "
                  class="flex px-4 py-2 text-sm"
                  :class="[active ? 'bg-gray-100 text-lexoo' : 'text-gray-700']"
                >
                  <DocumentDuplicateIcon
                    class="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Duplicate</span>
                </a>
              </MenuItem>

              <MenuItem v-slot="{ active, close }">
                <a
                  href="#"
                  @click.prevent="
                    $emit('copyEntry', entry);
                    close();
                  "
                  class="flex px-4 py-2 text-sm"
                  :class="[active ? 'bg-gray-100 text-lexoo' : 'text-gray-700']"
                >
                  <BookOpenIcon
                    class="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Copy to other playbook</span>
                </a>
              </MenuItem>

              <MenuItem v-slot="{ active, close }">
                <a
                  href="#"
                  @click.prevent="
                    $emit('delete', entry);
                    close();
                  "
                  class="flex px-4 py-2 text-sm"
                  :class="[active ? 'lexoo bg-gray-100' : 'text-gray-700']"
                >
                  <XMarkIcon
                    class="mr-3 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Delete</span>
                </a>
              </MenuItem>
            </div>
          </MenuItems>
        </HeadlessMenu>
        <div>
          <BaseTag v-for="tag in entry.tags" :key="tag.id" :tag="tag" />
        </div>
      </td>
    </template>
  </tr>
</template>

<script lang="ts">
import { defineComponent, nextTick, PropType } from "vue";
import {
  Menu as HeadlessMenu,
  MenuButton,
  MenuItem,
  MenuItems
} from "@headlessui/vue";
import {
  ArrowsPointingOutIcon,
  BookOpenIcon,
  DocumentDuplicateIcon,
  EllipsisVerticalIcon,
  ExclamationCircleIcon,
  PencilIcon,
  XMarkIcon
} from "@heroicons/vue/24/solid";
import invariant from "tiny-invariant";
import { Entry } from "@/types";
import { useAdmin } from "@/components/shared/Admin";
import BaseEditableText from "@/components/base/BaseEditableText.vue";
import BaseTag from "@/components/base/BaseTag.vue";
import CommentList from "@/components/CommentList.vue";
import SimpleEditableText from "@/components/base/SimpleEditableText.vue";
import TagSelect from "@/components/TagSelect.vue";
import { useJumpToEntryStore } from "@/store/JumpToEntryStore";
import { displayStringWithMarkedText } from "@/helpers/TextHelpers";

export default defineComponent({
  setup() {
    const { client, tags } = useAdmin();
    return { client, tags };
  },

  components: {
    ArrowsPointingOutIcon,
    BaseEditableText,
    BaseTag,
    BookOpenIcon,
    CommentList,
    EllipsisVerticalIcon,
    DocumentDuplicateIcon,
    ExclamationCircleIcon,
    HeadlessMenu,
    MenuButton,
    MenuItem,
    MenuItems,
    PencilIcon,
    SimpleEditableText,
    TagSelect,
    XMarkIcon
  },

  props: {
    entry: {
      required: true,
      type: Object as PropType<Entry>
    },

    entrySelection: {
      required: true,
      type: Object
    },

    filterText: {
      required: true,
      type: String
    },

    showThirdParty: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      editEntry: null as Entry | null,
      editMode: false,
      needsClamp: false,
      showMore: false
    };
  },

  computed: {
    escalationTemplatePresent() {
      return (
        this.entry.escalationTemplate &&
        this.entry.escalationTemplate.text &&
        this.entry.escalationTemplate.text.length > 0
      );
    },

    fallbackScenariosPresent() {
      return (
        this.entry.fallbackScenarios &&
        this.entry.fallbackScenarios.length > 0 &&
        this.entry.fallbackScenarios[0].fallbacks.length > 0 &&
        this.entry.fallbackScenarios[0].fallbacks[0].description.length > 0
      );
    },

    jumpToEntry() {
      invariant(this.entry.id, "entry.id is null");
      const jumpToEntryStore = useJumpToEntryStore();
      return this.entry.id.toString() === jumpToEntryStore.entryId;
    }
  },

  mounted() {
    nextTick(() => {
      type Refs = {
        entry: HTMLElement;
      };
      const elm = (this.$refs as Refs).entry;
      if (elm) {
        this.needsClamp = elm.scrollHeight > elm.clientHeight;
      }

      if (this.jumpToEntry) {
        this.showMore = true;
        (this.$refs as Refs)["entry"].scrollIntoView({
          behavior: "smooth"
        });
        const jumpToEntryStore = useJumpToEntryStore();
        jumpToEntryStore.clear();
      }
    });
  },

  methods: {
    displayStringWithFilterResult(value: string | null) {
      return displayStringWithMarkedText(value, this.filterText);
    },

    displayStringWithReadonlyInputs(value: string | null): string {
      if (!value) {
        return "";
      }

      return value.replace(
        new RegExp("<input ", "gi"),
        (match) => `${match}disabled="disabled" `
      );
    }
  }
});
</script>

<style scoped></style>
