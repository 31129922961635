<template>
  <TransitionRoot as="template" :show="show">
    <HeadlessDialog
      as="div"
      static
      class="fixed inset-0 z-30 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div
        class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle"
          >
            <div class="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
                tabindex="-1"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div
                class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10"
              >
                <SquaresPlusIcon
                  class="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              </div>
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  Bulk add tags to
                  {{ entries.length }}
                  {{ entries.length == 1 ? "entry" : "entries" }}.
                </DialogTitle>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    <TagSelect v-model="bulkTags" :selectableTags="tags" />
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-4 flex justify-end space-x-3">
              <BaseButton @click="bulkTag"> Add tags </BaseButton>
              <BaseButton buttonStyle="white" @click="$emit('close')">
                Cancel
              </BaseButton>
            </div>
          </div>
        </TransitionChild>
      </div>
    </HeadlessDialog>
  </TransitionRoot>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import {
  Dialog as HeadlessDialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";
import { SquaresPlusIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import { Entry, Tag } from "@/types";
import ApiService from "@/services/ApiService";
import { useAdmin } from "@/components/shared/Admin";
import TagSelect from "@/components/TagSelect.vue";

export default defineComponent({
  components: {
    HeadlessDialog,
    DialogOverlay,
    DialogTitle,
    TagSelect,
    TransitionChild,
    TransitionRoot,
    SquaresPlusIcon,
    XMarkIcon
  },

  setup() {
    const { tags } = useAdmin();
    return { tags };
  },

  props: {
    entries: {
      type: Array as PropType<Entry[]>,
      required: true
    },

    show: {
      required: true,
      type: Boolean
    }
  },

  data() {
    return { bulkTags: [] as Tag[] };
  },

  methods: {
    bulkTag() {
      this.entries.forEach((entry) => {
        const tagIds = new Set(entry.tags.map((tag: Tag) => tag.id));

        this.bulkTags.forEach((tag: Tag) => {
          if (!tagIds.has(tag.id)) {
            entry.tags.push(tag);
            const updateEntry: Entry = {
              ...entry,
              tagIds: entry.tags.map((tag: Tag) => tag.id)
            };
            ApiService.updateEntry(updateEntry);
          }
        });
      });
      this.bulkTags = [];
      this.$emit("completed");
      this.$emit("close");
    }
  }
});
</script>

<style scoped></style>
