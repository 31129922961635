import { defineStore } from "pinia";

import { NewTag, Tag } from "@/types";

import ApiService from "@/services/ApiService";

export const useTagStore = defineStore("TagStore", {
  actions: {
    createTag({ tag }: { tag: NewTag }): Promise<Tag> {
      return new Promise((resolve) => {
        ApiService.createTag(tag).then(({ data }) => {
          resolve(data.tag);
        });
      });
    },

    deleteTag({ tag }: { tag: Tag }): Promise<void> {
      return new Promise((resolve) => {
        ApiService.destroyTag(tag).then(() => {
          resolve();
        });
      });
    },

    updateTag({ tag }: { tag: Tag }): Promise<Tag> {
      return new Promise((resolve) => {
        ApiService.updateTag(tag).then(({ data }) => {
          resolve(data.tag);
        });
      });
    }
  }
});
