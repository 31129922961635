<template>
  <div class="my-6">
    <ClientForm v-if="client" :client="client" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useAdmin } from "@/components/shared/Admin";

import ClientForm from "@/components/admin/ClientForm.vue";

export default defineComponent({
  setup() {
    const { client } = useAdmin();
    return {
      client
    };
  },

  components: {
    ClientForm
  }
});
</script>

<style scoped></style>
