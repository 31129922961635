<template>
  <TransitionRoot as="template" :show="show">
    <Dialog
      as="div"
      static
      class="fixed inset-0 z-50 overflow-y-auto"
      @close="$emit('close')"
      :open="show"
    >
      <div class="block min-h-screen p-0 text-center">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:h-screen sm:align-middle"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="my-8 inline-block w-full max-w-xl transform overflow-hidden rounded-lg bg-white px-0 py-2 text-left align-middle shadow-xl transition-all"
          >
            <div class="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                class="rounded bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-lexoo focus:ring-offset-2"
                @click.prevent="$emit('close')"
              >
                <span class="sr-only">Close</span>
                <XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="flex items-start">
              <div class="ml-4 mt-3 w-full text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg font-medium leading-6 text-gray-900"
                >
                  AI Review
                </DialogTitle>
                <p class="mt-1 text-sm leading-6 text-gray-600">
                  Use AI to find issues in this contract and generate potential
                  solutions.
                </p>
                <form class="mt-4 space-y-6">
                  <BaseInput
                    label="Contract"
                    v-model="contractBody"
                    inputType="textarea"
                    required
                    class="text-xs sm:text-xs"
                  />

                  <BaseInput
                    label="Playbook"
                    v-model="fullPlaybookString"
                    inputType="textarea"
                    required
                    class="text-xs sm:text-xs"
                  />

                  <BaseInput
                    label="What type of contract is this?"
                    autofocus
                    v-model="contractType"
                    required
                    hint="e.g. SaaS MSA, loan agreement, NDA, services agreement, etc."
                    class="text-xs sm:text-xs"
                  />
                  <BaseInput
                    label="Which party are you?"
                    v-model="role"
                    required
                    hint="e.g. customer, software provider, lender, borrower, etc."
                    class="text-xs sm:text-xs"
                  />

                  <span class="mt-4 flex space-x-3">
                    <BaseButton
                      :label="finding ? 'Please wait...' : 'Find issues'"
                      @click="generateIssues"
                      :disabled="finding"
                    >
                      <SparklesIcon
                        class="mr-1 inline-block h-4 w-4 text-yellow-500"
                      />
                    </BaseButton>
                    <BaseButton buttonStyle="white" @action="$emit('close')">
                      Cancel
                    </BaseButton>
                  </span>
                </form>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useStorage } from "@vueuse/core";

import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from "@headlessui/vue";

import { SparklesIcon, XMarkIcon } from "@heroicons/vue/24/solid";

import { useSidebarStore } from "@/store/SidebarStore";
import { entriesToString } from "@/helpers/PromptHelpers";
import { getWordDocumentAsText } from "@/helpers/WordHelpers";

import { PlaybookAiVariables } from "@/types";

const contractBody = useStorage("clauses-contract-body", "");

const emit = defineEmits(["close", "generate"]);

const sidebarStore = useSidebarStore();
const finding = ref(false);

defineProps({
  show: {
    type: Boolean,
    required: true
  }
});

const entries = computed(() => sidebarStore.entries);
const fullPlaybookString = computed(() => {
  return entriesToString(entries.value);
});
const playbook = computed(() => sidebarStore.playbook);

// This has to go after playbook
const playbookAiVariables = computed((): PlaybookAiVariables => {
  if (!playbook.value) {
    return {};
  }
  return playbook.value.aiVariables;
});

// This has to go after playbookAiVariables
const contractType = useStorage(
  "issues-contract-type",
  playbookAiVariables.value.contractType
);

const role = useStorage("issues-role", playbookAiVariables.value?.role);

const generateIssues = () => {
  finding.value = true;
  const params = {
    role: role.value,
    contractType: contractType.value,
    contractBody: contractBody.value,
    fullPlaybookString: fullPlaybookString.value
  };
  console.log(params);
  emit("generate", params);
  finding.value = false;
};

onMounted(async () => {
  if (sidebarStore.withinWord) {
    contractBody.value = await getWordDocumentAsText();
  }
  finding.value = false;
});
</script>

<style scoped></style>
